import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Modal, Input, Table, Tooltip } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import DataTable from "react-data-table-component";
import { remarkslist, remarksupdate } from './../../Store/Action/Master/Remarksmaster';
import { Toaster } from '../../Libs/Toaster';
import { PlusSquareTwoTone, LikeOutlined, DislikeOutlined, PoweroffOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';


const mapStateToProps = (state) => ({
    lists: state.Remarksmaster.remarksmasters

})
const Remarksmaster = ({
    remarkslist,
    lists,
    remarksupdate
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [remarkValue, setRemarkValue] = useState({
        id: '',
        description: '',
        desc: ''
    })
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const { Search } = Input;

    const handleOk = () => {
        setAddFormSubmit(true)
        if (formValidator.current.allValid()) {
            if (remarkValue.desc == remarkValue.description) {
                Toaster.warning('No Changes Done');
                setModaVisible(false)
            } else {
                const updateremarks = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateRemarksMaster",
                            {
                                "r_id": remarkValue.id,
                                "r_description": remarkValue.description,
                            }
                        ]
                    }
                ]
                remarksupdate({
                    payload: updateremarks,
                    paramid: paramid
                })
                setModaVisible(false)
            }
        }
    }
    const handleCancel = () => {
        setModaVisible(false)
    }
    const openModal = (r_id, description) => {
        setRemarkValue({
            ...remarkValue,
            id: r_id,
            description: description,
            desc: description
        })
        setModaVisible(true)
    }

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])

    // const columns = [
    //     {
    //         name: 'S.No',
    //         selector: (row, index) => index + 1,
    //         sortable: false,
    //         width: '100px'

    //     },
    //     {
    //         name: 'Remarks',
    //         selector: (row) => row.r_description,
    //         sortable: false,
    //     },
    //     {
    //         name: 'Edit',
    //         selector: (row) => {
    //             return <span className="btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
    //                 onClick={() => {
    //                     openModal(row.r_id, row.r_description)
    //                 }}><i className="ri-edit-line"></i>
    //             </span>
    //         },
    //         sortable: false,
    //         width: '150px'
    //     },
    // ];

    const columns = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '20px',
            render: (text, record, index) => {
                console.log(record, 'record')
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Remarks',
            dataIndex: 'r_description',
            key: 'r_description',
            ellipsis: true,
            width: '150px',

        },
        {
            title: <><label>Actions</label></>,
            dataIndex: 'u_c_name',
            align:'center',
            className:'actiontd',
            width: '30px',
            render: (text, record) => {
                return (
                    <Link className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" onClick={(e) => {
                            openModal(record.r_id, record.r_description)
                        }}>
                        <Tooltip title="Edit" color='green'>
                            <i className="ri-edit-line"></i></Tooltip>
                    </Link>

                )
            },
            sortable: false,
        },
    ];

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetRemarksData",
                        {

                        }
                    ]
                }
            ]

            remarkslist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    const FilterComponent = ({ filterText, onFilter, onClear }) => {
        return (
            <>

                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            type="text"
                            placeholder="Search Here..."
                            value={filterText}
                            onChange={onFilter}
                            autoFocus
                        />
                    </div>
                </div>

            </>
        )
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <FilterComponent
                    onFilter={(e) => {
                        setFilterText(e.target.value)
                    }}
                    onClear={handleClear}
                    filterText={filterText}
                />

            </>

        );
    }, [filterText, resetPaginationToggle]);

    const filteredItems = lists && lists.length && lists.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const [baseData, setBaseData] = useState([]);
    console.log(baseData,'baseData')
    // const [commonSearch, setCommonSearch] = useState();
    useEffect(() => {
        if (lists && lists.length > 0) {
            setBaseData(lists);
        }
    }, [lists])


    const searchRecords = (e) => {
        console.log(e.target.value, 'esttes')
        const filterTable = lists.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setBaseData(filterTable)
    };

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>Master</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <Link>Remarks Master</Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">

                                <div class="search-box cusMargin">
                                    <input class="search-txt" type="text" onChange={searchRecords} placeholder="Type to Search" />
                                    <a class="search-btn">
                                        <i class="fas fa-search"></i>
                                    </a>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">

                                    <div className="card-body" style={{ padding: '0px' }}>

                                        {/* <DataTable
                                                columns={columns}
                                                data={filteredItems}
                                                fixedHeader={dataTableProperties.fixedHeader}
                                                pagination
                                                subHeader
                                                subHeaderComponent={subHeaderComponent}
                                            /> */}
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            dataSource={baseData}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
            <Modal title="Remarks" visible={modalVisible} footer={null} maskClosable={false} onCancel={handleCancel} className="newStyleModalPassword" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <input type="text" className='form-control' value={remarkValue.description} onChange={(e) => {
                                setRemarkValue({
                                    ...remarkValue,
                                    description: e.target.value
                                })
                            }} />
                            {formValidator.current.message(
                                'remark',
                                remarkValue.description,
                                'required',

                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Please Enter Remark',


                                    }
                                })}
                        </div>
                    </div>
                </div>
                <br />
                <div className="form-actions">
                    <button
                        className="btn btn-light-success rounded-pill px-4 text-success"
                        onClick={handleOk}
                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger
               waves-effect text-start"
                        onClick={handleCancel}
                    > Cancel

                    </button>
                </div>
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    remarkslist,
    remarksupdate
})(Remarksmaster);