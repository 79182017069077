import { Card, Tabs, Collapse, Select, Modal, Table } from 'antd';
import React from 'react'
import { useState, useEffect, useRef, Fragment } from 'react';
import DataTable from "react-data-table-component";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { assign, selectunit, table, tabledata, selectassigneedata, showmoredata, saveassigncomliance, syncassigncomliance } from "../../../src/Store/Action/Transcations/Assigncompliance"
import moment from 'moment';
import { toast } from 'react-toastify';
import { entries } from 'lodash';

const _ = require("lodash");
const mapStateToProps = (state) => ({
    unitlist: state.Assigncompliance.assigncompliance
});
const Compliance = ({
    assign,
    selectunit,
    table,
    tabledata,
    showmoredata,
    selectassigneedata,
    saveassigncomliance,
    syncassigncomliance,
    unitlist
}) => {
    const [tempshowmoredatalist, setTempShowmoredatalist] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    console.log(tempshowmoredatalist, "tempshowmoredatalist");
    const [allselect, setAllSelect] = useState(false)
    const { Option } = Select;
    const { Panel } = Collapse;
    const { TabPane } = Tabs;
    const [row, setRow] = useState(false)
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const SelectedEntity = localStorage.getItem('SelectedEntity');
    const sessionValue = localStorage.getItem('sessionValue')
    const [editValue, setEditValue] = useState([])
    const [updateValue, setUpdateValue] = useState([])
    const [updateDate, setUpdateDate] = useState([])
    const [EditDate, setEditDate] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    // const entityid = localStorage.getItem('SelectedEntityid');
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [frequencyid, setFrequencyId] = useState(undefined)
    const [unitid, setUnitId] = useState(undefined)
    const [domain, setDomain] = useState(undefined)
    const [select, setSelect] = useState([])
    const [pushdata, setPushData] = useState([])
    const [selectunit1, setSelectUnit1] = useState([])
    const [freqid, setFreqid] = useState([])
    const [uid, setUId] = useState([])
    const [collapseindex, setCollapseIndex] = useState([])
    const [set, SetSet] = useState([])
    const [triggervalue, SetTriggerValue] = useState([])
    const [key, setKey] = useState("1")
    const [header, setHeader] = useState([])
    const [updatedateedit, SetUpdateDateEdit] = useState([])
    const [updatedata, setUpdateData] = useState([])
    const [temparr, setTemparr] = useState([])
    const [totalrow, setTotalRow] = useState([])
    const [date, setDate] = useState([])
    const [headerlist, setHeaderList] = useState([])
    const [category, setCategory] = useState([])
    const [compliancefrequency, setComplianceFrequency] = useState([])
    const [totaltabledata, setTotalTableData] = useState([])
    const [updatedays, setUpdatedays] = useState([])
    const [assign_statutory, setAssign_statutory] = useState([])
    const [showmoredatalist, setShowMoreDataList] = useState([])
    const [unitname, setUnitname] = useState([])
    const [approvaldrop, setApprovaldrop] = useState([])
    const [assigneedrop, setAssigneedrop] = useState([])
    const [approvalsearch3, setApprovalsearch3] = useState([])
    const [assigneesearch3, setAssigneesearch3] = useState([])
    const [assignpayload, setAssignPayload] = useState([])
    const [triggerindex, setTriggerIndex] = useState([])
    const [updatedaysindex, setupdatedaysIndex] = useState([])
    const [updateeditindex, setUpdateeditindex] = useState([])
    const [dateindex, setDateIndex] = useState([])
    const [rowcompid, setRowCompId] = useState([])
    const [boolean, setBoolean] = useState([])
    const [list, setList] = useState([])
    const [datacount, setdatacount] = useState(null)
    const [entires, setEntires] = useState(null)
    const [showmore1, setShowMore1] = useState(false)
    const [checkedRow, setCheckedRow] = useState(false);
    const [countvalue, setCountvalue] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [approvalonclick, setApprovalOnclick] = useState(false)
    const [tableclick, setTableclick] = useState(false)
    const [assigneeonclick, setAssigneeOnclick] = useState(false)
    const [editpayload, setEditPayload] = useState(false)
    const [rowpayload, setRowPayload] = useState(false)
    const [diffid, setDiffId] = useState([])
    const [updatepayload, setUpdatePayload] = useState(false)
    const [editclick, setEditClick] = useState(false)
    const [editclickdate, setEditClickDate] = useState(false)
    const [categoryname, setCategoryName] = useState("")
    const [clickassignee, setClickassginee] = useState({
        assignee: "",
        approval: "",
        approval_id: null,
        assignee_id: null
    })
    const [assigneelist, setAssigneelist] = useState({
        assign_id: null,
        approval_id: null,
        assignee: "",
        approval: "",
        restricted: ""
    })
    const [data1, setData] = useState({
        legalentity: "",
        division: "",
        category: "",
        domain: "",
        unit1: "",
        compliance_frequency: "",
        timezone: ""
    })
    console.log(showmoredatalist, "showmoredatalist555");
    // const columns = [
    //     {
    //         name: <Fragment>
    //             <input type="checkbox"
    //                 onClick={(e) => {
    //                     for (let i in collapseindex) {
    //                         // for (let j in temparr) {
    //                         let temp = []
    //                         temp.push(headerlist && headerlist[collapseindex[i]] && headerlist[collapseindex[i]])
    //                         setHeader([...header, ...temp])
    //                         setRowCompId([...rowcompid, ...temp])
    //                         // }
    //                     }
    //                     let checked = e.target.checked;
    //                     addAllSelectedDataValues(
    //                         checked
    //                     );
    //                 }}
    //             />
    //         </Fragment>,
    //         selector: (row, rowIndex) => {
    //             return (
    //                 <input type="checkbox"
    //                     name='checked'
    //                     onClick={(e) => {
    //                         let temp = []
    //                         temp.push(row.comp_id)
    //                         setRowCompId([...rowcompid, ...temp])
    //                         setEditPayload(false)
    //                         // for (let i in updateDate) {
    //                         //     if (updateDate[i].comp_id != row.comp_id) {
    //                         //         setRowPayload(true)
    //                         //     }
    //                         // }

    //                         // setRowPayload(true)
    //                         // setUpdatePayload(false)
    //                         for (let i in collapseindex) {
    //                             let temp = []
    //                             temp.push(headerlist && headerlist[collapseindex[i]] && headerlist[collapseindex[i]])
    //                             setHeader([...header, ...temp])
    //                         }
    //                         let checked = e.target.checked;
    //                         addSelectedDataValues(
    //                             checked,
    //                             row.comp_id
    //                         );
    //                     }}
    //                 />

    //             )
    //         },

    //     },
    //     {

    //         name: "Compliance Task",
    //         selector: (row) => row && row.comp_name,
    //         sortable: true,
    //         reorder: true,
    //         width: '400px',

    //     },
    //     {
    //         name: "Application Units",
    //         selector: (row) => <a style={{ padding: "0px 30px" }} onClick={showModal} href="javascript:;">1/1</a>,
    //         sortable: true,
    //         reorder: true,
    //         width: '200px',

    //     },
    //     {
    //         name: "Compliance Frequency",
    //         selector: (row) => row.freq,
    //         sortable: true,
    //         right: true,
    //         reorder: true,
    //         width: '250px',
    //     },
    //     {
    //         name: "Statutory Date/Schedule",
    //         cell: (column) => {
    //             return (
    //                 column.r_every != null && column.repeat_by != null ?
    //                     <p >{column.summary}({moment(column.due_date_list[0]).format("MMM-DD")})</p> : <p >{column.summary}</p>
    //             )
    //         },
    //         // selector: (row) => row.summary,
    //         sortable: true,
    //         right: true,
    //         reorder: true,
    //         width: '250px',
    //     },
    //     {
    //         name: "Trigger Before (Days) *",
    //         selector: (row, rowIndex) => {
    //             return (
    //                 <>
    //                     {row && row.statu_dates && row.statu_dates[0] && row.statu_dates[0].trigger_before_days == null || row && row.statu_dates && row.statu_dates == "" ?
    //                         <input name='triggerInput' style={{ width: "80px", height: "30px" }}
    //                             disabled={temparr.filter((e) => e.comp_id == row.comp_id).length > 0 ? false : true}
    //                             className="form-control" placeholder='Days'
    //                             onBlur={(e) => {
    //                                 let temp = []
    //                                 let temp2 = []
    //                                 temp2.push({
    //                                     comp_id: row.comp_id,
    //                                     value: e.target.value,
    //                                     index: rowIndex
    //                                 })
    //                                 temp.push(e.target.value)
    //                                 SetTriggerValue([...triggervalue, ...temp])
    //                                 setEditValue([...editValue, ...temp2])
    //                                 setEditPayload(true)
    //                             }}

    //                         ></input> :
    //                         <>
    //                             <label>{row && row.statu_dates && row.statu_dates[0] && row.statu_dates[0].trigger_before_days}  <i className="ri-edit-line" onClick={() => {
    //                                 setEditClick(true)
    //                             }}></i> </label>
    //                             {editclick == true && temparr.filter((e) => e.comp_id == row.comp_id).length > 0 ?
    //                                 <input name='triggerInputUpdate' style={{ width: "80px", height: "30px" }} className="form-control" placeholder='Days'

    //                                     onBlur={(e) => {
    //                                         let tempupdate = []
    //                                         let temp2 = []
    //                                         tempupdate.push(e.target.value)
    //                                         temp2.push({
    //                                             comp_id: row.comp_id,
    //                                             value: e.target.value,
    //                                             index: rowIndex
    //                                         })
    //                                         setUpdatedays([...updatedays, ...tempupdate])
    //                                         setUpdateValue([...updateValue, ...temp2])
    //                                         setUpdatePayload(true)
    //                                     }}


    //                                 ></input> : ""}
    //                         </>
    //                     }
    //                 </>

    //             )
    //         },
    //         sortable: true,
    //         right: true,
    //         reorder: true,
    //         width: '250px',

    //     },
    //     {
    //         name: "Due Date *",
    //         selector: (row, rowIndex) => {
    //             return (
    //                 <>
    //                     {row.due_date_list[0] != "" ?
    //                         <>
    //                             <label>{moment(row.due_date_list[0]).format("DD-MM-YYYY")}
    //                                 <i style={{ marginLeft: "5px" }} className="ri-edit-line" onClick={() => {
    //                                     setEditClickDate(true)
    //                                 }}></i>
    //                             </label>
    //                             {editclickdate == true && temparr.filter((e) => e.comp_id == row.comp_id).length > 0 ?
    //                                 <input style={{ width: "200px", height: "30px" }} className="form-control" type="date"
    //                                     onChange={(e) => {
    //                                         let tempupdatedate = []
    //                                         tempupdatedate.push(e.target.value)
    //                                         SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
    //                                         for (let i in updateValue) {
    //                                             if (rowIndex == updateValue[i].index && row.comp_id == updateValue[i].comp_id) {
    //                                                 let temparr = []
    //                                                 temparr.push({
    //                                                     date: e.target.value,
    //                                                     comp_id: row.comp_id,
    //                                                     index: rowIndex
    //                                                 })
    //                                                 if (row.r_every == null && row.repeat_by == null) {
    //                                                     let temparr1 = []
    //                                                     temparr1.push({
    //                                                         date: e.target.value,
    //                                                         comp_id: updateValue[i].comp_id,
    //                                                         value: updateValue[i].value,
    //                                                         frequency: row.freq
    //                                                     })
    //                                                     setUpdateData([...updatedata, ...temparr1])
    //                                                 }
    //                                                 setUpdateDate([...updateDate, ...temparr])
    //                                             }
    //                                         }

    //                                     }}
    //                                 />
    //                                 : ""}
    //                         </>
    //                         :
    //                         <input className='form-control' type="date"
    //                             disabled={temparr.filter((e) => e.comp_id == row.comp_id).length > 0 ? false : true}
    //                             onChange={(e) => {
    //                                 // let date1 = moment(e.target.value).format("DD-MMM-YYYY")
    //                                 let tempdate = []
    //                                 tempdate.push(e.target.value)
    //                                 setDate([...date, ...tempdate])
    //                                 for (let i in editValue) {

    //                                     if (rowIndex == editValue[i].index) {
    //                                         let temparr = []
    //                                         temparr.push({
    //                                             date: e.target.value,
    //                                             comp_id: editValue[i].comp_id,
    //                                             value: editValue[i].value,
    //                                             frequency: row.freq
    //                                         })
    //                                         setEditDate([...EditDate, ...temparr])
    //                                     }
    //                                 }

    //                             }}
    //                         ></input >
    //                     }
    //                 </>
    //             )
    //         },
    //         sortable: true,
    //         right: true,
    //         reorder: true,
    //         width: '250px',


    //     },
    //     {
    //         name: "Validity Date",
    //         sortable: true,
    //         right: true,
    //         selector: (row, rowIndex) => {
    //             return (
    //                 <>
    //                     {row.due_date_list[0] != "" && row && row.statu_dates && row.statu_dates[0] && row.statu_dates[0].trigger_before_days != null && row.r_every != null && row.repeat_by != null ?
    //                         <input className='form-control' type="date"
    //                             // disabled={temparr.filter((e) => e.comp_id == row.comp_id).length > 0 ? false : true}
    //                             onChange={(e) => {
    //                                 for (let j in updateValue) {
    //                                     for (let k in updateDate) {
    //                                         if (rowIndex == updateDate[k].index && row.comp_id == updateDate[k].comp_id && row.comp_id == updateValue[j].comp_id) {
    //                                             let temparr = []
    //                                             temparr.push({
    //                                                 date: updateDate[k].date,
    //                                                 comp_id: updateDate[k].comp_id,
    //                                                 value: updateValue[j].value,
    //                                                 vdate: e.target.value,
    //                                                 frequency: row.freq
    //                                             })
    //                                             setUpdateData([...updatedata, ...temparr])
    //                                         }
    //                                     }
    //                                 }

    //                             }}
    //                         ></input > : ""}
    //                 </>
    //             )
    //         },
    //         reorder: true,
    //         width: '200px',

    //     }
    // ];
    const columns = [
        {
            title: <Fragment>

                <input type="checkbox"
                    onClick={(e) => {
                        setRow(true)
                        console.log(e.target.value, "e8888");
                        // setAllSelect(true)
                        for (let i in collapseindex) {
                            // for (let j in temparr) {
                            let temp = []
                            temp.push(headerlist && headerlist[collapseindex[i]] && headerlist[collapseindex[i]])
                            setHeader([...header, ...temp])
                            setRowCompId([...rowcompid, ...temp])
                            // }
                        }
                        let checked = e.target.checked;
                        addAllSelectedDataValues(
                            checked);
                    }}
                />
            </Fragment>,
            render: (row, record, index) => {
                return (
                    <input type="checkbox"
                        name='checked'
                        // class="checkbox"
                        onClick={(e) => {
                            let temp = []
                            temp.push(record.comp_id)
                            setRowCompId([...rowcompid, ...temp])
                            setEditPayload(false)
                            // for (let i in updateDate) {
                            //     if (updateDate[i].comp_id != row.comp_id) {
                            //         setRowPayload(true)
                            //     }
                            // }

                            // setRowPayload(true)
                            // setUpdatePayload(false)
                            for (let i in collapseindex) {
                                let temp = []
                                temp.push(headerlist && headerlist[collapseindex[i]] && headerlist[collapseindex[i]])
                                setHeader([...header, ...temp])
                            }
                            let checked = e.target.checked;
                            addSelectedDataValues(
                                checked,
                                record.comp_id
                            );
                        }}
                    />

                )
            },
            fixed: 'left',
            width: "60px"

        },
        {
            title: 'Category Name',
            dataIndex: 'category_name',
            key: 'comp_name',
            width: "250px",
            fixed: 'left',
            render: (row, record, index) => record && record.comp_name,
            sortable: true,
            reorder: true,
            // record && record.comp_name
        },
        {
            title: 'Application Units',
            dataIndex: 'Application Units',
            key: 'Application Units',
            render: (row, record, index) => <a style={{ padding: "0px 30px" }} onClick={showModal} href="javascript:;">1/1</a>,
            // record && record.comp_name
        },
        {
            title: 'Compliance Frequency',
            dataIndex: 'Compliance Frequency',
            key: 'freq',
            render: (row, record, index) => record && record.freq
            // record && record.comp_name
        },
        {
            title: 'Statutory Date/Schedule',
            dataIndex: 'Statutory Date/Schedule',
            key: 'statu_dates[0].statutory_date',
            render: (row, record, index) => {
                console.log(record, "record444");
                return (
                    record.r_every != null && record.repeat_by != null ?
                        <p >{record.summary}({moment(record.due_date_list[0]).format("MMM-DD")})</p> : <p >{record.summary}</p>
                )
            }
            // record && record.comp_name
        },
        {
            title: 'Trigger Before (Days) *',
            dataIndex: 'Trigger Before (Days) *',
            key: 'statu_dates[0].statutory_date',
            render: (row, record, index) => {
                return (
                    <>
                        {record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days == null || record && record.statu_dates && record.statu_dates == "" ?
                            <input name='triggerInput' style={{ width: "80px", height: "30px" }}
                                disabled={temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ? false : true}
                                className="form-control" placeholder='Days'
                                onBlur={(e) => {
                                    let temp = []
                                    let temp2 = []
                                    temp2.push({
                                        comp_id: record.comp_id,
                                        value: e.target.value,
                                        index: index
                                    })
                                    temp.push(e.target.value)
                                    SetTriggerValue([...triggervalue, ...temp])
                                    setEditValue([...editValue, ...temp2])
                                    setEditPayload(true)
                                }}

                            ></input> :
                            <>
                                <label>{record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days}  <i className="ri-edit-line" onClick={() => {
                                    setEditClick(true)
                                }}></i> </label>
                                {editclick == true && temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ?
                                    <input name='triggerInputUpdate' style={{ width: "80px", height: "30px" }} className="form-control" placeholder='Days'

                                        onBlur={(e) => {
                                            let tempupdate = []
                                            let temp2 = []
                                            tempupdate.push(e.target.value)
                                            temp2.push({
                                                comp_id: record.comp_id,
                                                value: e.target.value,
                                                index: index
                                            })
                                            setUpdatedays([...updatedays, ...tempupdate])
                                            setUpdateValue([...updateValue, ...temp2])
                                            setUpdatePayload(true)
                                        }}


                                    ></input> : ""}
                            </>
                        }
                    </>

                )
            }
            // record && record.comp_name
        },
        {
            title: 'Due Date *',
            dataIndex: 'Due Date *',
            key: 'due_date_list[0]',
            render: (row, record, index) => {
                return (
                    <>
                        {record.due_date_list[0] != "" ?
                            <>
                                <label>{moment(record.due_date_list[0]).format("DD-MM-YYYY")}
                                    <i style={{ marginLeft: "5px" }} className="ri-edit-line" onClick={() => {
                                        setEditClickDate(true)
                                    }}></i>
                                </label>
                                {editclickdate == true && temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ?
                                    <input style={{ width: "200px", height: "30px" }} className="form-control" type="date"
                                        onChange={(e) => {
                                            let tempupdatedate = []
                                            tempupdatedate.push(e.target.value)
                                            SetUpdateDateEdit([...updatedateedit, ...tempupdatedate])
                                            for (let i in updateValue) {
                                                if (index == updateValue[i].index && record.comp_id == updateValue[i].comp_id) {
                                                    let temparr = []
                                                    temparr.push({
                                                        date: e.target.value,
                                                        comp_id: record.comp_id,
                                                        index: index
                                                    })
                                                    if (record.r_every == null && record.repeat_by == null) {
                                                        let temparr1 = []
                                                        temparr1.push({
                                                            date: e.target.value,
                                                            comp_id: updateValue[i].comp_id,
                                                            value: updateValue[i].value,
                                                            frequency: record.freq
                                                        })
                                                        setUpdateData([...updatedata, ...temparr1])
                                                    }
                                                    setUpdateDate([...updateDate, ...temparr])
                                                }
                                            }

                                        }}
                                    />
                                    : ""}
                            </>
                            :
                            <input className='form-control' type="date"
                                disabled={temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ? false : true}
                                onChange={(e) => {
                                    // let date1 = moment(e.target.value).format("DD-MMM-YYYY")
                                    let tempdate = []
                                    tempdate.push(e.target.value)
                                    setDate([...date, ...tempdate])
                                    for (let i in editValue) {

                                        if (index == editValue[i].index) {
                                            let temparr = []
                                            temparr.push({
                                                date: e.target.value,
                                                comp_id: editValue[i].comp_id,
                                                value: editValue[i].value,
                                                frequency: record.freq
                                            })
                                            setEditDate([...EditDate, ...temparr])
                                        }
                                    }

                                }}
                            ></input >
                        }
                    </>
                )
            },
            sortable: true,
            right: true,
            reorder: true,
            // width: '150px',

        },
        {
            title: 'Validity Date',
            dataIndex: 'Validity Date',
            key: 'due_date_list[0]',
            render: (row, record, index) => {
                return (
                    <>
                        {record.due_date_list[0] != "" && record && record.statu_dates && record.statu_dates[0] && record.statu_dates[0].trigger_before_days != null && record.r_every != null && record.repeat_by != null ?
                            <input className='form-control' type="date"
                                // disabled={temparr.filter((e) => e.comp_id == row.comp_id).length > 0 ? false : true}
                                onChange={(e) => {
                                    for (let j in updateValue) {
                                        for (let k in updateDate) {
                                            if (index == updateDate[k].index && record.comp_id == updateDate[k].comp_id && record.comp_id == updateValue[j].comp_id) {
                                                let temparr = []
                                                temparr.push({
                                                    date: updateDate[k].date,
                                                    comp_id: updateDate[k].comp_id,
                                                    value: updateValue[j].value,
                                                    vdate: e.target.value,
                                                    frequency: record.freq
                                                })
                                                setUpdateData([...updatedata, ...temparr])
                                            }
                                        }
                                    }

                                }}
                                disabled={temparr.filter((e) => e.comp_id == record.comp_id).length > 0 ? false : true}
                            ></input > : ""}
                    </>
                )
            },
            reorder: true,
            // width: '150px',

        }

    ]


    // const collapseindexvalue = ((e) => {
    //     console.log(row,"row");
    //     if (row == true) {
    //         let index = []
    //         index.push(...e)
    //         setCollapseIndex([...collapseindex, ...index])
    //     }
    // })
    console.log(collapseindex, "collapseindex666");
    console.log(header, "header1323");
    const showmore = () => {
        setShowMore1(true)
        const payload10 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetComplianceForUnits",
                    {
                        "le_id": entityid && entityid,
                        "u_ids": unitid,
                        "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                        "r_count": entires,
                        "f_ids": frequencyid,
                        "tz_name": data1.timezone
                    }
                ]

            }
        ]
        showmoredata({
            payload: payload10,
            paramid: paramid
        })
    }
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    console.log(temparr, "temparr123");
    const addAllSelectedDataValues = (checked) => {
        let ele = document.getElementsByName('checked');
        console.log(ele, "eleele");
        if (checked === true) {
            setCheckedRow(true)
            for (let i in collapseindex) {
                setTemparr([...temparr, ...datacount[collapseindex[i]]])
            }
            for (let i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            setTemparr([])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }
    console.log(checkedRow, "checkedRow");
    const addSelectedDataValues = (checked, comp_id, act_name) => {
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {

                array = _.find(totaltabledata, { comp_id: comp_id });
                tempArray.push(array)

            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked === true) {
                array = _.find(totaltabledata, { comp_id: comp_id });
                tempArray.push(array)

            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const Next1 = () => {
        setAddFormSubmit(true)
        setCountvalue(true)
        if (validator.current.allValid() && isAuth) {
            setAddFormSubmit(true)
            setKey("2")
            const payload2 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceTotalToAssign",
                        {
                            "le_id": entityid,
                            "u_ids": unitid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "f_ids": frequencyid
                        }

                    ]
                }
            ]
            table({
                payload: payload2,
                paramid: paramid
            })
            const payload8 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceForUnits",
                        {
                            "le_id": entityid,
                            "u_ids": unitid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "r_count": 0,
                            "f_ids": frequencyid,
                            "tz_name": data1.timezone
                        }
                    ]

                }
            ]
            tabledata({
                payload: payload8,
                paramid: paramid
            })


        }
    }
    const Next2 = () => {
        setTableclick(true)
        if (temparr != "") {
            for (let i in temparr) {
                if (temparr[i].due_date_list[0] != "" && temparr[i].statu_dates[0].trigger_before_days != null ? temparr[i].due_date_list[0] != "" && temparr[i].statu_dates[0].trigger_before_days != null : triggervalue != "" && date != "") {
                    const value123 = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                        return item.is_approver == true
                    })
                    setApprovaldrop(value123)
                    setKey("3")
                }
                else {
                    toast.warning("Trigger before days and Due date fields are required in - ".concat(header[i]))

                }
            }

        }
        else {
            toast.warning("No Compliance selected for assign")

        }
        const payload1 = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetUserToAssignCompliance",
                    {
                        "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                        "u_ids": unitid,
                        "le_id": entityid
                    }
                ]
            }
        ]
        selectassigneedata({
            payload: payload1,
            paramid: paramid
        })

    }

    const Submit = () => {
        setAddFormSubmit1(true)
        if (validator1.current.allValid() && isAuth) {
            setAddFormSubmit1(true)
            const payload4 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "SaveAssignedCompliance",
                        {
                            "assignee": 12,
                            "assignee_name": `${assigneelist.assign_id}- ${assigneelist.assignee}`,
                            "concurrence_person": null,
                            "concurrer_name": null,
                            "approval_person": 17,
                            "approver_name": `${assigneelist.approval_id} - ${assigneelist.approval}`,
                            "assign_compliances": assignpayload,
                            "le_id": entityid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "u_ids": unitid,
                            "tz_name": data1.timezone,
                            "vo_list": [
                                "8"
                            ]
                        }
                    ]

                }

            ]
            saveassigncomliance({
                payload: payload4,
                paramid: paramid
            })
            const payload5 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "SyncAssignedCompliance",
                        {
                            "assignee": 12,
                            "assignee_name": assigneelist.assignee,
                            "concurrence_person": null,
                            "concurrer_name": null,
                            "approval_person": 17,
                            "approver_name": assigneelist.approval,
                            "assign_compliances": assignpayload,
                            "le_id": entityid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "u_ids": unitid,
                            "tz_name": data1.timezone,
                            "vo_list": [
                                "8"
                            ]
                        }
                    ]

                }

            ]
            syncassigncomliance({
                payload: payload5,
                paramid: paramid
            })
            const payload6 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssignCompliancesFormData",
                        {}
                    ]
                }
            ]
            selectunit({
                payload: payload6,
                paramid: paramid
            })

            // setKey("1")
            // setData({
            //     ...data,
            //     legalentity: "",
            //     division: "",
            //     category: "",
            //     domain: "",
            //     unit1: "",
            //     compliance_frequency: "",
            //     timezone: ""
            // })
            // setCheckedRow([])
            // setAssigneelist({
            //     ...assigneelist,
            //     assignee: "",
            //     approval: "",
            //     restricted: ""
            // })
            // setTrigger({
            //     ...trigger,
            //     triggerInput: ""
            // })
            // setDate([])
            toast.success("Compliance Assigned Successfully")
            // setTimeout(() => {
            //     window.location.reload()
            // }, 1500);

        }
        // window.location.reload()

    }

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
    }, [])

    useEffect(() => {
        if (unitlist.tablelist && unitlist.tablelist.length > 0 && unitlist.tablelist != null && unitlist.tablelist != undefined) {
            setAssign_statutory(unitlist && unitlist.tablelist && unitlist.tablelist[0])
        }
        if (showmore1 == true) {
            if (unitlist.showmorelist && unitlist.showmorelist.length > 0 && unitlist.showmorelist != null && unitlist.showmorelist != undefined) {
                // let temp=[]
                // temp.push(showmoredatalist)
                setShowMoreDataList(unitlist && unitlist.showmorelist && unitlist.showmorelist[0])
            }
        }
    }, [unitlist.tablelist, unitlist.showmorelist, showmore1])
    console.log(unitlist && unitlist.showmorelist && unitlist.showmorelist[0], "88888");
    useEffect(() => {
        if (countvalue === true) {
            const count = datacount.map((item) => {
                return item.length
            })
            const total = count.reduce((a, b) => a + b, 0)
            setEntires(total)
        }

    }, [datacount, countvalue])

    useEffect(() => {
        if (showmore1 == true) {
            if (assign_statutory && showmoredatalist) {
                let con = Object.entries(assign_statutory)
                let data = Object.entries(showmoredatalist)
                let concat = con.concat(data)
                setList(concat)
                // if(entires==200){
                //     let ii= Object.entries(tempshowmoredatalist)
                //     console.log(ii,"7777");
                //     let concat8 = concat.concat(ii)
                //     setList(concat8)
                // }
                let con1 = Object.values(assign_statutory)
                let data1 = Object.values(showmoredatalist)
                let concat1 = con1.concat(data1)
                setdatacount(concat1)
                // if(entires==200){
                //     let iii= Object.values(tempshowmoredatalist)
                //     let concat7 = concat.concat(iii)
                //     setList(concat7)
                // }
                let con2 = Object.keys(assign_statutory)
                let data2 = Object.keys(showmoredatalist)
                let concat2 = con2.concat(data2)
                setHeaderList(concat2)
            }
        }
        else {
            if (assign_statutory) {
                setList(Object.entries(assign_statutory))
                setdatacount(Object.values(assign_statutory))
                setHeaderList((Object.keys(assign_statutory)))
            }
        }

    }, [assign_statutory, showmore1, showmoredatalist])
    useEffect(() => {
        if (datacount) {
            var merged = [].concat.apply([], datacount);
            setTotalTableData(merged)
        }
    }, [datacount])
    console.log(datacount, "datacount");
    useEffect(() => {
        let data = freqid.map((item) => {
            return item.frequency_id
        })
        setFrequencyId(data)
        let datadata = uid && uid.map((item) => {
            return item.u_id
        })
        setUnitId(datadata)
    }, [freqid, uid])
    useEffect(() => {
        if (data1.unit1) {
            for (const k in unitid) {
                let arr = unitlist && unitlist.unitdata && unitlist.unitdata.filter((item, i) => {
                    const uniqueCharsrow = [...new Set(item.u_ids)];
                    return item.u_ids.includes(parseInt(unitid[k]))
                })
                if (unitid.length >= 2) {
                    const remove = arr && arr.splice(2, 1)
                    setComplianceFrequency(arr)
                }
                else {
                    setComplianceFrequency(arr)
                }
            }
        }
    }, [unitid, data1])
    useEffect(() => {
        if (unitlist && unitlist.domain && unitlist.domain.length > 0) {
            let data = unitlist && unitlist.domain && unitlist.domain.filter((item) => {
                return item.le_id == entityid
            })
            setDomain(data)
        }
    }, [entityid, unitlist.domain])

    useEffect(() => {
        if (data1.category) {
            let categoryvalue = unitlist.category.filter((item) => {
                return item.cat_id == data1.category
            })
            setCategoryName(categoryvalue[0].cat_name)
            let unitbranch = _.filter(unitlist && unitlist.assignunit && unitlist.assignunit, { category_id: Number(data1.category) })
            setUnitname(unitbranch)
        }
        else {
            setUnitname(unitlist.assignunit)
        }
    }, [data1, unitlist.assignunit])

    useEffect(() => {
        if (tableclick == true) {
            if (approvalonclick == true && unitlist.assign_user != "" && clickassignee.approval_id != "All") {
                let approvallist = approvaldrop.filter((item) => {
                    return item.usr_id == clickassignee.approval_id
                })
                setApprovalsearch3(approvallist)
            }
            else {
                setApprovalsearch3(approvaldrop)

            }
        }

    }, [approvalonclick, clickassignee, approvaldrop, tableclick])

    useEffect(() => {
        if (tableclick == true) {
            if (assigneeonclick == true && unitlist.assign_user != "" && clickassignee.assignee_id !== "All") {
                let approvallist = assigneedrop.filter((item) => {
                    return item.usr_id == clickassignee.assignee_id
                })
                setAssigneesearch3(approvallist)
            }
            else {
                setAssigneesearch3(assigneedrop)
            }
        }
    }, [assigneeonclick, clickassignee, assigneedrop, tableclick])

    useEffect(() => {
        if (tableclick == true) {
            const value123 = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                return item.is_approver == true
            })
            const data = value123 && value123.filter((item) => {
                return item.s_u_name != null
            })
            setApprovaldrop(data)
            const data1 = data && data.map((item) => {
                return item.s_u_name
            })
            const uniqueCharsrow = [...new Set(data1)];
            setSelect(uniqueCharsrow)
            const assignee = unitlist && unitlist.assign_user && unitlist.assign_user.filter((item) => {
                return item.is_assignee == true
            })
            setAssigneedrop(assignee)

        }
    }, [tableclick, unitlist.assign_user])
    useEffect(() => {
        if (isAuth && domain) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssignComplianceUnits",
                        {
                            "le_id": entityid,
                            "d_id": domain && domain[0] && domain[0].d_id && domain[0].d_id,
                            "c_id": 1
                        }
                    ]
                }
            ]
            assign({
                payload: payload,
                paramid: paramid
            })

        }
    }, [isAuth, entityid, domain])
    // useEffect(() => {
    //     if (isAuth) {
    //         const payload1 = [
    //             authtoken,
    //             {
    //                 "session_token": authtoken,
    //                 "request": [
    //                     "GetAssignCompliancesFormData",
    //                     {}
    //                 ]
    //             }
    //         ]
    //         selectunit({
    //             payload: payload1,
    //             paramid: paramid
    //         })
    //     }
    // }, [isAuth])
    useEffect(() => {
        if (isAuth) {

            const payload1 = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetAssignCompliancesFormData",
                        {}
                    ]
                }
            ]
            selectunit({
                payload: payload1,
                paramid: paramid
            })
        }
    }, [isAuth, authtoken, paramid])
    useEffect(() => {
        for (let i in triggervalue) {
            let temp = []
            temp.push(i)
            setTriggerIndex([...triggerindex, ...temp])
        }

    }, [triggervalue])
    useEffect(() => {
        for (let i in updatedays) {
            let temp1 = []
            temp1.push(i)
            setupdatedaysIndex([...updatedaysindex, ...temp1])
        }
    }, [updatedays])
    useEffect(() => {
        for (let i in updatedateedit) {
            let temp = []
            temp.push(i)
            setUpdateeditindex([...updateeditindex, ...temp])
        }
    }, [updatedateedit])
    useEffect(() => {
        for (let i in date) {
            let temp = []
            temp.push(i)
            setDateIndex([...dateindex, ...temp])
        }
    }, [date])
    useEffect(() => {
        if (temparr.length > 0) {
            let tempArray = []
            for (let i in temparr) {
                for (let j in EditDate) {
                    if (temparr[i].comp_id == EditDate[j].comp_id) {
                        tempArray.push({
                            "comp_id": temparr[i].comp_id,
                            "comp_name": temparr[i].comp_name,
                            "statu_dates": [
                                {
                                    "statutory_date": Number(moment(EditDate[j].date).format("D")),
                                    "statutory_month": Number(moment(EditDate[j].date).format("M")),
                                    "trigger_before_days": Number(EditDate[j].value),
                                    "repeat_by": temparr[i].statu_dates[0].repeat_by
                                }
                            ],
                            "d_date":
                                moment(EditDate[j].date).format("DD-MMM-YYYY"),
                            "v_date": null,
                            "trigger_before_days": Number(EditDate[j].value),
                            "u_ids": unitid,
                            "repeat_by": temparr[i].repeat_by,
                            "r_every": temparr[i].r_every,
                            "frequency": EditDate[j].frequency,
                            "act": header[i],
                            "amendment_history_id": temparr[i].amendment_history_id


                        })
                    }

                }
                for (let k in updatedata) {
                    if (temparr[i].comp_id == updatedata[k].comp_id) {
                        tempArray.push({
                            "comp_id": temparr[i].comp_id,
                            "comp_name": temparr[i].comp_name,
                            "statu_dates": [
                                {
                                    "statutory_date": Number(moment(updatedata[k].date).format("D")),
                                    "statutory_month": Number(moment(updatedata[k].date).format("M")),
                                    "trigger_before_days": Number(updatedata[k].value),
                                    "repeat_by": temparr[i].statu_dates[0].repeat_by
                                }
                            ],
                            "d_date": moment(updatedata[k].date).format("DD-MMM-YYYY"),
                            "v_date": moment(updatedata[k].vdate).format("DD-MMM-YYYY"),
                            "trigger_before_days": Number(updatedata[k].value),
                            "u_ids": unitid,
                            "repeat_by": temparr[i].repeat_by,
                            "r_every": temparr[i].r_every,
                            "frequency": updatedata[k].frequency,
                            "act": header[i],
                            "amendment_history_id": temparr[i].amendment_history_id

                        })

                    }
                }
                for (let x in diffid) {
                    if (temparr[i].comp_id == diffid[x]) {
                        tempArray.push({
                            "comp_id": temparr[i].comp_id,
                            "comp_name": temparr[i].comp_name,
                            "statu_dates": [
                                {
                                    "statutory_date": temparr[i].statu_dates[0].statutory_date,
                                    "statutory_month": temparr[i].statu_dates[0].statutory_month,
                                    "trigger_before_days": temparr[i].statu_dates[0].trigger_before_days,
                                    "repeat_by": temparr[i].statu_dates[0].repeat_by
                                }
                            ],
                            "d_date": temparr[i].due_date_list[0],
                            "v_date": null,
                            "trigger_before_days": temparr[i].statu_dates[0].trigger_before_days,
                            "u_ids": unitid,
                            "repeat_by": temparr[i].repeat_by,
                            "r_every": temparr[i].r_every,
                            "frequency": temparr[i].freq,
                            "act": header[i],
                            "amendment_history_id": temparr[i].amendment_history_id


                        })
                    }

                }

                if (tempArray != "") {
                    setAssignPayload(tempArray)

                }

            }
        }
    }, [updatedata, EditDate, temparr, header, unitid, diffid])

    useEffect(() => {
        let con = updatedata.concat(editValue)
        let ss = con.map((item) => {
            return item.comp_id
        })
        var difference = rowcompid.filter(x => ss.indexOf(x) === -1);
        setDiffId(difference)

    }, [updatedata, rowcompid, editValue])

    console.log(assignpayload, 'assignpayload');
    console.log(unitlist.assignunit, "unitlistassignunit");
    console.log(unitname, "unitnameunitname");
    console.log(temparr, "temparr5555");
    return (

        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <Link>Assign Compliance</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <Tabs activeKey={key} type="card">
                                    <TabPane tab="Select Unit" key="1">
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity</b> <span style={{ color: "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Division</b></label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Category</b></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                // defaultValue={localStorage.getItem('SelectedEntity') === "All Legal Entity" ? "" : localStorage.getItem('SelectedEntity')}
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Legal Entity"
                                                                onChange={(data, value) => {
                                                                    setCurrentEntity(data)
                                                                    setData({
                                                                        ...data1,
                                                                        legalentity: data
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                // value={data.legalentity}
                                                                style={{ width: '300px' }}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'legalentity',
                                                                data1.legalentity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })}
                                                        </div>
                                                        :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>

                                                    }
                                                </div>
                                                <div className='col-md-4'>
                                                    <Select
                                                        allowClear={true}
                                                        // disabled={data.legalentity == "" ? true : false}
                                                        size="default"
                                                        placeholder="Enter Division"
                                                        onChange={(value) => {

                                                            let CategoryData = _.filter(unitlist.category, { div_id: Number(value) })
                                                            setCategory(CategoryData)
                                                            setData({
                                                                ...data1,
                                                                division: value
                                                            })

                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={data.division}
                                                        style={{ width: '300px' }}
                                                    >
                                                        {unitlist.division && unitlist.division.length && unitlist.division.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.div_id}>
                                                                    {item.div_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                                <div className='col-md-4'>
                                                    <Select
                                                        allowClear={true}
                                                        disabled={data1.division == "" ? true : false}
                                                        size="default"
                                                        placeholder="Enter Category"
                                                        onChange={(value) => {
                                                            setData({
                                                                ...data1,
                                                                category: value
                                                            })
                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={data.category}
                                                        style={{ width: '300px' }}

                                                    >
                                                        {category.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.cat_id}>
                                                                    {item.cat_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Domain </b><span style={{ color: "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Time Zone </b><span style={{ color: "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Unit </b><span style={{ color: "red" }}>*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <Select
                                                        allowClear={true}
                                                        // disabled={data.division == "" ? true : false}
                                                        size="default"
                                                        placeholder="Enter Domain"
                                                        onChange={(value) => {
                                                            setData({
                                                                ...data1,
                                                                domain: value,
                                                                timezone: unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name
                                                            })
                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={data.domain}
                                                        style={{ width: '300px' }}
                                                    >
                                                        {domain && domain.map((item, i) => {
                                                            // {/* {unitlist && unitlist.domain && unitlist.domain.map((item, i) => { */}

                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.d_name}>
                                                                    {item.d_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>

                                                    {validator.current.message(
                                                        'domain',
                                                        data1.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'>
                                                    <Select
                                                        allowClear={true}
                                                        disabled={data1.domain != "" && data1.timezone == "" ? false : true}
                                                        size="default"
                                                        placeholder="Enter TimeZone"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        value={data1.domain ? unitlist && unitlist.timezone && unitlist.timezone[0] && unitlist.timezone[0].tz_name : ""}
                                                        style={{ width: '300px' }}
                                                    >

                                                    </Select>
                                                    {validator.current.message(
                                                        'timezone',
                                                        data1.timezone,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })}

                                                </div>
                                                <div className='col-md-4'>

                                                    <Select
                                                        mode="tags"
                                                        allowClear={true}
                                                        disabled={data1.domain == "" ? true : false}
                                                        size="default"
                                                        placeholder="Enter Unit"
                                                        onChange={(value) => {

                                                            let fre = []
                                                            for (const k in value) {
                                                                let arr = unitname && unitname.filter((item, i) => {
                                                                    return item.u_name === value[k]
                                                                })
                                                                fre.push(...arr)
                                                            }
                                                            setUId(fre)

                                                            let temp = []
                                                            temp.push()
                                                            setData({
                                                                ...data1,
                                                                unit1: value
                                                            })

                                                            setSelectUnit1(value)

                                                        }}

                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={data.unit1}
                                                        style={{ width: '300px' }}
                                                    >
                                                        {unitname && unitname.length && unitname.map((item, i) => {
                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.u_name}>
                                                                    {item.u_name}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'unit',
                                                        data1.unit1,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Compliance Frequency </b><span style={{ color: "red" }}>*</span></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <Select
                                                        mode="tags"
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Compliance Frequency"
                                                        disabled={data1.unit1 ? false : true}
                                                        onChange={(value) => {
                                                            let fre = []
                                                            for (const k in value) {
                                                                let arr = unitlist.unitdata.filter((item, i) => {
                                                                    return item.frequency === value[k]
                                                                })
                                                                fre.push(...arr)
                                                            }

                                                            setFreqid(fre)

                                                            setData({
                                                                ...data1,
                                                                compliance_frequency: value
                                                            })



                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={data.compliance_frequency}
                                                        style={{ width: '300px' }}
                                                    >
                                                        {compliancefrequency && compliancefrequency.length && compliancefrequency.map((item, i) => {

                                                            return (
                                                                // data.unit ?
                                                                <Option key={item.frequency}>
                                                                    {item.frequency}
                                                                </Option>
                                                                // :""
                                                            );
                                                        })}
                                                    </Select>
                                                    {validator.current.message(
                                                        'compliance',
                                                        data1.compliance_frequency,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })}


                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "20px" }}>
                                            <div className='row'>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'></div>
                                                <div className='col-md-2'>
                                                    <button className="btn btn-info" style={{ float: "right", width: "100px" }} onClick={Next1}>Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane tab="Select Compliance Task" disabled key="2">
                                        {/* <div className='col-md-12' >
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    <button className="btn btn-secondary" style={{ marginLeft: "25px", width: "100px" }} onClick={() => { setKey("1") }}>Previous</button>
                                                </div>
                                                <div className='col-md-4'>
                                                </div>
                                                <div className='col-md-4'>
                                                    <button className="btn btn-info" style={{ float: "right", marginRight: "25px", width: "100px" }} onClick={Next2}>Next</button>
                                                </div>
                                            </div>
                                        </div> */}
                                        <Card >
                                            {list && list.length > 0 ?
                                                list.map((item, i) => {
                                                    return (
                                                        <Collapse
                                                            // onChange={(e) => collapseindexvalue(e)}
                                                            onChange={(e) => {
                                                                console.log(e, "rrrrrrr");
                                                                let index = []
                                                                index.push(...e)
                                                                setCollapseIndex([...collapseindex, ...index])

                                                            }}
                                                        // defaultActiveKey={['1']}
                                                        >
                                                            <Panel header={item[0]} key={i} >
                                                                <div className="container-fluid" style={{ padding: 0 }}>
                                                                    <div className="row">
                                                                        <div className="col-12" >
                                                                            {/* <DataTable
                                                                                columns={columns}
                                                                                data={item[1]}
                                                                                selectableRows={false}
                                                                                pagination
                                                                                onSelectedRowsChange={({
                                                                                    allSelected,
                                                                                    selectedCount,
                                                                                    selectedRows,

                                                                                }) => {
                                                                                    // if (selectedCount > 0) {
                                                                                    let array = []
                                                                                    array.push(item[0])


                                                                                    let checkarray = []
                                                                                    let tempArray = selectedRows && selectedRows.length && selectedRows.map((item) => {
                                                                                        return item
                                                                                    })

                                                                                    if (tempArray.length > 0) {
                                                                                        SetSet([...set, ...tempArray])
                                                                                        setTotalRow([...totalrow, ...tempArray])


                                                                                    }
                                                                                    if (tempArray.length == 0) {
                                                                                        for (const k in set) {
                                                                                            selectedRows.filter((item) => item.comp_id == set[k].comp_id)
                                                                                            SetSet([...set, ...selectedRows])

                                                                                        }
                                                                                        //   SetSet(checkarray)
                                                                                    }

                                                                                }}
                                                                            /> */}
                                                                            <Table columns={columns}  pagination={false} dataSource={item[1]} scroll={{
                                                                                x: 1500,
                                                                            }}
                                                                                size="small"
                                                                                bordered
                                                                               

                                                                            // pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
                                                                            />
                                                                        </div>
                                                                        <br />
                                                                    </div>
                                                                </div>

                                                            </Panel>
                                                        </Collapse>
                                                    )

                                                })
                                                : ''
                                            }
                                        </Card>
                                        <div className='col-md-12' >
                                            <div className='row'>

                                                <div className='col-md-4'>
                                                    <button className="btn btn-info" style={{ marginLeft: "25px", width: "100px" }} onClick={() => { setKey("1") }}>Previous</button>
                                                </div>
                                                <div className='col-md-4'>
                                                    {unitlist && unitlist.heading && unitlist.heading.r_count != entires && entires != 200 ?
                                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "90px" }} onClick={showmore} >Show More</button>
                                                        : ""}
                                                </div>
                                                <div className='col-md-4'>
                                                    <button className="btn btn-info" style={{ float: "right", marginRight: "25px", width: "100px" }} onClick={Next2}>Next</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {unitlist && unitlist.heading && unitlist.heading.r_count > 200 ? entires != 200 : unitlist && unitlist.heading && unitlist.heading.r_count != entires ?
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'></div>
                                                    <div className='col-md-4'>
                                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "50px" }} onClick={showmore} >Show More</button>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                            : ""} */}
                                        <div className='col-md-12' style={{ marginTop: "20px" }}>
                                            <div className='row'>

                                                <div className='col-md-3' >
                                                    <label style={{ marginLeft: "28px" }}><b>Showing  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{entires}</a>  entries</label>
                                                </div>
                                                <div className='col-md-3'></div>
                                                <div className='col-md-3'></div>
                                                <div className='col-md-3'>
                                                    <label style={{ marginLeft: "28px" }}><b>Selected Compliance  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{temparr.length}</a> </label>
                                                </div>

                                            </div>
                                        </div>

                                    </TabPane>
                                    <TabPane tab="Select Assignee" disabled key="3">
                                        <Card>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4' style={{ textAlign: "center" }}>
                                                        <label><b>Assignee </b><span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4' style={{ textAlign: "center" }}>
                                                        <label><b>Approval </b><span style={{ color: "red" }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-4' style={{ textAlign: "center" }}>
                                                        <label><b>View Only Restricted User</b></label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Assignee"
                                                            onChange={(value, data) => {
                                                                setClickassginee({
                                                                    ...clickassignee,
                                                                    assignee_id: value,
                                                                    assignee: data.children
                                                                })
                                                                setAssigneeOnclick(true)

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // value={clickassignee.assignee}
                                                            style={{ width: '300px' }}
                                                        >
                                                            <Option key="All">All</Option>

                                                            {assigneedrop && assigneedrop.map((item, i) => {
                                                                return (
                                                                    <>
                                                                        <Option key={item.usr_id}>
                                                                            {item.s_u_name}
                                                                        </Option>
                                                                    </>

                                                                );
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <div className='col-md-4'>

                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Approval"
                                                            onChange={(value, data) => {

                                                                setClickassginee({
                                                                    ...clickassignee,
                                                                    approval_id: value,
                                                                    approval: data.children
                                                                })
                                                                setApprovalOnclick(true)

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // value={clickassignee.approval}
                                                            style={{ width: '300px' }}
                                                        >
                                                            <Option key="All">All</Option>
                                                            {approvaldrop && approvaldrop.map((item, i) => {

                                                                // {/* {unitlist && unitlist.assign_user && unitlist.assign_user.map((item, i) => { */}
                                                                return (


                                                                    <Option key={item.usr_id}>
                                                                        {item.s_u_name}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-md-4'></div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "20px", marginBottom: "20px" }}>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Assignee"
                                                            onChange={(value, data) => {

                                                                var merged = [].concat.apply([], data.children);
                                                                setAssigneelist({
                                                                    ...assigneelist,
                                                                    assign_id: value,
                                                                    assignee: data.children[2]
                                                                })

                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // value={selectassignee.search_assignee}
                                                            style={{ width: '300px' }}
                                                        >
                                                            {assigneesearch3 && assigneesearch3.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    clickassignee.assignee ?
                                                                        <Option key={item.emp_code}>
                                                                            {item.emp_code} - {item.emp_name}
                                                                        </Option> : ""
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator1.current.message(
                                                            'Assignee',
                                                            assigneelist.assignee,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Required Field Missing',
                                                                }
                                                            })}
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter Approval"
                                                            onChange={(value, data) => {
                                                                setAssigneelist({
                                                                    ...assigneelist,
                                                                    approval_id: value,
                                                                    approval: data.children[2]
                                                                })
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // value={selectassignee.search_approval}
                                                            style={{ width: '300px' }}
                                                        >

                                                            {approvalsearch3 && approvalsearch3.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    clickassignee.approval ?
                                                                        <Option key={item.emp_code}>
                                                                            {item.emp_code} - {item.emp_name}
                                                                        </Option> : ""
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator1.current.message(
                                                            'Approval',
                                                            assigneelist.approval,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Required Field Missing',
                                                                }
                                                            })}
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Enter View only restricted only"
                                                            onChange={(value) => {
                                                                setAssigneelist({
                                                                    ...assigneelist,
                                                                    restricted: value
                                                                })
                                                            }}
                                                            // onSearch={onSearch}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            // value={data.division}
                                                            style={{ width: '300px' }}
                                                        >
                                                            {unitlist.restricted && unitlist.restricted.length && unitlist.restricted.map((item, i) => {
                                                                return (
                                                                    // data.unit ?
                                                                    <Option key={item.emp_name}>
                                                                        {item.emp_name} - {item.emp_name}
                                                                    </Option>
                                                                    // :""
                                                                );
                                                            })}
                                                        </Select>

                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                        {/* <div className='col-md-12' >
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                <button className="btn btn-secondary" style={{ marginLeft: "25px", width: "100px"}} onClick={() => { setKey("2") }}>Previous</button>
                                                </div>
                                                <div className='col-md-4'>
                                                </div>
                                                <div className='col-md-4'>
                                                <button className="btn btn-success" style={{float: "right", marginRight: "25px", width: "100px"}} onClick={Submit}>Submit</button>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4'>
                                                    <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={() => { setKey("2") }}>Previous</button>
                                                    <button className="btn btn-success" style={{ width: "100px" }} onClick={Submit}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Applicable Units" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                <p>{data1.unit1}, {categoryname}</p>
            </Modal>
        </div >

    )
}
export default connect(mapStateToProps, {
    assign,
    selectunit,
    table,
    tabledata,
    showmoredata,
    selectassigneedata,
    saveassigncomliance,
    syncassigncomliance,

})(Compliance);
