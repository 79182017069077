import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Collapse, Input, Modal, Tooltip, Card, Select } from 'antd';
import { InfoCircleOutlined, PlusSquareTwoTone } from '@ant-design/icons';
import { complianceApprovallist, getComplianceFilters, approveCompliance, UploadFile } from '../../Store/Action/Transcations/ComplianceApproval';
import { GetRemarksData } from '../../Store/Action/Transcations/Taskrecall';
import { useLocation } from 'react-router-dom';
import DataTable from "react-data-table-component";
import moment from 'moment';
import EncryptDecrypt from './../../../src/Libs/Encrypt&Decrypt'
import SimpleReactValidator from "simple-react-validator";



const _ = require("lodash");

const mapStateToProps = (state) => ({
    list: state.ComplianceApproval.complianceApproval.complianceList,
    frequencyList: state.ComplianceApproval.complianceApproval.complianceFrequencyList,
    unitList: state.ComplianceApproval.complianceApproval.complianceUnitList,
    domainList: state.ComplianceApproval.complianceApproval.complianceDomainList,
    userList: state.ComplianceApproval.complianceApproval.complianceUserList,
    filterrecall: state.recallfilters.recallfilters

})

const ComplianceApproval = ({
    complianceApprovallist,
    getComplianceFilters,
    approveCompliance,
    GetRemarksData,
    UploadFile,
    list,
    frequencyList,
    unitList,
    domainList,
    userList,
    filterrecall: { recallremarks }

}) => {
    const { Panel } = Collapse;
    const { Search } = Input;
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    //   const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [listArray, setListArray] = useState(false)
    const [remarksModal, setRemarksModal] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [showFilterData, setShowFilterData] = useState({
        le_id: '',
        unit_id: null,
        unit_name: '',
        d_id: null,
        domain_name: '',
        frequency_id: null,
        frequency_name: '',
        us_id: null,
        user_name: '',
        start_count: 0
    })
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true
    })
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [showValueModal, setShowValueModal] = useState(false)
    const [valueModal, setValueModal] = useState({
        unit: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: '',
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: ''
    })
    const [valueForFileUpload, setValueForFileUpload] = useState({
        d_id: '',
        u_id: '',
        start_date: '',
        file_info: [],
    })
    const [edit, setEdit] = useState(false)
    const [fileData, setFileData] = useState({})
    const [fileupload, setFileUpload] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    const column = [
        {
            name: "Sno",
            selector: (row) => row.r_id,
            sortable: true,
            width: '250px',
            reorder: true,
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        }, {
            name: "Description",
            selector: (row) => <a href={() => false} onClick={() => {
                setValueModal({
                    ...valueModal,
                    remarks: row.r_description
                })
                setRemarksModal(false)
            }}>{row.r_description}</a>,
            sortable: true,
            width: '250px',
            reorder: true,
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            name: "",
            selector: false
        }
    ]

    const columns = [
        {
            name: "S.No",
            filterable: true,
            selector: (row) => {
                if (row.compliance_task_frequency == 'Periodical') {
                    return <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "#4FC742" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (row.compliance_task_frequency == 'FlexiReview') {
                    return <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (row.compliance_task_frequency == 'Review') {
                    return <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "#F78453" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
                else if (row.compliance_task_frequency == 'On Occurrence') {
                    return <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else {
                    return <Tooltip title={row && row.compliance_task_frequency}><p><i style={{ color: "#F21625" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
            },
            width: '100px',
            // sortable: true,
            // right: true,
            reorder: true
        },
        {
            name: 'Compliance Task',
            selector: (row) => {

                return (
                    <>
                        <p className="mt-3"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    compliance_history_id: row.compliance_history_id,
                                    unit: row.unit_name,
                                    compliance_task: row.compliance_name,
                                    compliance_frequency: row.compliance_task_frequency,
                                    document_issued_date: row.completion_date,
                                    document_reference_number: row.doc_ref_num,
                                    next_due_date: row.next_due_date,
                                })
                                setValueForFileUpload({
                                    d_id: row.domain_id,
                                    u_id: row.unit_id,
                                    start_date: row.start_date,
                                })
                            }}>{row.statu}</p>
                        <div className="mb-3">
                            <Tooltip title={row.description}>
                                <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{row.compliance_name}</p>
                            </Tooltip>
                        </div>
                    </>
                )
            },
            // sortable: true,
            width: '250px',
            style: { 'whiteSpace': 'unset' },
            reorder: true
        },
        {
            name: "Domain",
            selector: (row) => row.domain_name,
            width: '150px',
            // sortable: true,
            // right: true,
            reorder: true
        },
        {
            name: "Assignee Name",
            selector: (row) => row.assignee_name,
            width: '150px',
            // sortable: true,
            right: true,
            reorder: true
        },
        {
            name: "Start Date",
            selector: (row) => row.start_date,
            // sortable: true,
            right: true,
            reorder: true,
            width: '200px',
        },
        {
            name: "Due Date",
            selector: (row) => row.due_date,
            // sortable: true,
            right: true,
            reorder: true,
            width: '200px',
        },
        {
            name: "Days",
            selector: (row) => row.delayed_by,
            // sortable: true,
            right: true,
            reorder: true,
            width: '100px',
        },
        {
            name: "Timeline",
            selector: (row) => row.delayed_by,
            // sortable: true,
            right: true,
            reorder: true,
            width: '200px',
        },
    ];



    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        if (filteredArray && filteredArray.length > 0) {


            filteredArray && filteredArray.map((item, i) => {

                return (
                    setFilteredArrayState(item)
                )
            })
        }
    }, [filteredArray])



    useEffect(() => {
        if (list && list.length > 0) {

            let uniqueObjArray = [...new Map(list && list.length && list.map((item) => [item["unit_id"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(list, { unit_id: uniqueIds[i] })

                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }

        }
    }, [list])


    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetComplianceApprovalList",
                        {
                            "le_id": entityid,
                            "unit_id": null,
                            "d_id": null,
                            "frequency_id": null,
                            "us_id": null,
                            "start_count": 0
                        }
                    ]
                }
            ]
            const filterAPI = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "complianceApprovalFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            complianceApprovallist({
                payload: payload,
                paramid: paramid
            })
            getComplianceFilters({
                payload: filterAPI,
                paramid: paramid
            })
        }
    }, [isAuth, entityid])

    const showValue = () => {
        setShowValueModal(true);
    };

    const handleOk = () => {
        setRemarksModal(false);

    };



    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    const handleModalValue = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setShowValueModal(false)
            setEdit(false)
            if (fileupload.length && valueModal.action === 'Rectify Approval') {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'ApproveCompliance', {
                                'le_id': entityid,
                                'compliance_history_id': valueModal.compliance_history_id,
                                'approval_status': [valueModal.action],
                                'remarks': valueModal.remarks ? valueModal.remarks : null,
                                'next_due_date': valueModal.next_due_date,
                                'validity_date': valueModal.validity_date ? moment(valueModal.validity_date).format("d-MMM-YYYY") : null,
                                'password': "",
                                'pwd_validate': false,
                                'documents': [
                                    {
                                        "file_size": fileupload && fileupload.length && fileupload[0] && fileupload[0].file_size,
                                        "file_name": fileupload && fileupload.length && fileupload[0] && fileupload[0].file_name,
                                        "file_content": null
                                    }
                                ],
                                'file_size': 0
                            }
                        ]
                    }
                ]
                approveCompliance({
                    payload: payload,
                    paramid: paramid
                })
            }
            else {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'ApproveCompliance', {
                                'le_id': entityid,
                                'compliance_history_id': valueModal.compliance_history_id,
                                'approval_status': [valueModal.action],
                                'remarks': valueModal.remarks ? valueModal.remarks : null,
                                'next_due_date': valueModal.next_due_date,
                                'validity_date': valueModal.validity_date ? moment(valueModal.validity_date).format("d-MMM-YYYY") : null,
                                'password': "",
                                'pwd_validate': false,
                                'documents': null,
                                'file_size': 0
                            }
                        ]
                    }
                ]
                approveCompliance({
                    payload: payload,
                    paramid: paramid
                })
                setValueModal({
                    unit: '',
                    compliance_history_id: '',
                    compliance_task: '',
                    compliance_frequency: '',
                    uploaded_document: '',
                    document_issued_date: '',
                    document_reference_number: '',
                    validity_date: '',
                    next_due_date: '',
                    status: '',
                    remarks: '',
                    action: ''
                })
            }
        }

    }



    const handleCancel = () => {
        setRemarksModal(false)
        setEdit(false)
    };

    const Cancelmodal = () => {
        setShowValueModal(false);
    }

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };

        return result;
    }

    const convert_to_base64 = (file, name, size, callback) => {
        var reader = new FileReader();
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            var binary = "";
            reader.onload = function (readerEvt) {
                var bytes = new Uint8Array(readerEvt.target.result);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                callback(EncryptDecrypt.encryptdata(binary), name, size);
            }
            reader.readAsArrayBuffer(file);
        } else {
            reader.onload = function (readerEvt) {
                var binaryString = readerEvt.target.result;
                callback(EncryptDecrypt.encryptdata(binaryString), name, size);
            };
            reader.readAsBinaryString(file);
        }
    }

    const SavepastRecordOnChange = (e) => {

        // let tempFile = []
        // let temparr = [{
        //     unit_id: Number(unit.unit),
        //     compliance_id: row.compliance_id,
        //     due_date: row.due_date,
        //     completion_date: moment(e && e.target.value).format("d-MMM-YYYY"),
        //     documents: fileupload,
        //     validity_date: null,
        //     pr_completed_by: 5,
        //     amendment_history_id: row.amendment_history_id
        // }]
        if (e.target.name === 'file') {

            var results = [];
            const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
            // \[ removed to avoid terminal error
            // const fileRegex = /^[a-zA-Z0-9-_& ,.+#()\[-\]{}$^=%@]*$/
            let files = e.target.files
            let file_max_size = 26214400
            // let FILE_TYPE = [
            //     "doc", "docx", "rtf", "pdf", "txt", "png", "jpeg", "gif", "csv", "xls", "xlsx",
            //     "rar", "tar", "gz", "ppt", "pptx", "jpg", "bmp", "odt", "odf", "ods", "xml"
            // ];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                var file_name = file.name;
                const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
                if (fileRegex.test(f_name) === false) {
                    return;
                }
                let file_size = file.size;
                if (file_size > file_max_size) {
                    // displayMessage(message.file_maxlimit_exceed);
                    // callback(null);
                    return;

                }
                // var file_extension = file_name.substring(file_name.lastIndexOf('.') + 1).toLowerCase();
                // if (FILE_TYPE.indexOf(file_extension) !== -1) {
                //     // displayMessage(message.invalid_file_format);
                //     // $('#' + fileListener.target.id).val('');
                //     // callback(null);
                //     return;
                // }
                if (file) {
                    convert_to_base64(file, file_name, file_size, function (file_content, name, size) {
                        // if (file_content == null) {
                        //     // callback(message.file_content_empty);
                        // }
                        var fN = name.substring(0, name.lastIndexOf('.'));
                        var fE = name.substring(name.lastIndexOf('.') + 1);
                        var uniqueId = Math.floor(Math.random() * 90000) + 10000;
                        var f_Name = fN + '-' + uniqueId + '.' + fE;

                        var result = uploadFileFormat(size, f_Name, file_content);
                        setFileData(result)
                        results.push(result);
                        setFileUpload(results)
                        // if (results.length == files.length) {
                        //     callback(results);
                        // }


                        const filePayload = [
                            authtoken,
                            {
                                "session_token": authtoken,
                                "request": [
                                    "UploadComplianceTask_temp_upload",
                                    {
                                        "le_id": entityid,
                                        "c_id": 1,
                                        "d_id": valueForFileUpload.d_id,
                                        "u_id": valueForFileUpload.u_id,
                                        "start_date": valueForFileUpload.start_date,
                                        "file_info": results,
                                        "compliance_history_id": valueModal.compliance_history_id,
                                    }
                                ]
                            }
                        ]
                        UploadFile({
                            payload: filePayload,
                            paramid: paramid
                        })
                        setFileUpload(results)

                    });


                }
            }

        }
        React.forwardRef(({ onClick, ...rest }, ref) => {
            return (
                <>
                    <div className="form-check pb-5" style={{ backgroundColor: '' }}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ height: '20px', width: '20px' }}
                            ref={ref}
                            onClick={onClick}
                            {...rest}
                        />
                        <label className="form-check-label" id="booty-check" />
                    </div>
                </>
            )
        })
    }


    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;" className="link"><i className="ri-home-3-line fs-5"></i></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Transactions
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <b>Compliance Approval </b></li>
                            </ol>
                            <h2 className="mb-0 fw-bold"><b>Compliance Approval</b></h2>
                        </nav>

                    </div>

                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body" style={{ padding: "15px 5px" }}>
                                <Collapse accordion bordered={true} defaultActiveKey={['1']}>
                                    <Panel header="Compliance Approval" key="1">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Legal Entity</b></label><span style={{ color: "red" }}>*</span><br />
                                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                            <div className="form-group">
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Legal Entity"
                                                                    onChange={(data, value) => {
                                                                        setCurrentEntity(data)
                                                                        setShowFilterData({
                                                                            ...showFilterData,
                                                                            le_id: data
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    //  value={showFilterData.le_id}
                                                                    style={{ width: '300px' }}
                                                                >
                                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                        return (
                                                                            <Option key={item.le_id}>
                                                                                {item.le_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'le_id',
                                                                    showFilterData.le_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Required Field Missing',
                                                                        }
                                                                    })}
                                                            </div> :
                                                            <p>{localStorage.getItem('SelectedEntity')}</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Domain</b></label><br />
                                                        <div className="form-group">
                                                            <Select

                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Domain"
                                                                onChange={(data, value) => {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        d_id: data,
                                                                        domain_name: value.children
                                                                    })
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                // value={filter.seller}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {domainList && domainList.length && domainList.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.d_id}>
                                                                            {item.d_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Unit</b></label><br />
                                                        {/* <Search placeholder="input search text" value={showFilterData.unit_name}
                                                        style={{ width: 300 }}
                                                        onSearch={() => {
                                                            setModal('Unit')
                                                            showModal()
                                                            // setShowFilterData({
                                                            //     le_id: entityid,
                                                            //     unit_id: null,
                                                            //     unit_name: '',
                                                            //     d_id: null,
                                                            //     domain_name: '',
                                                            //     frequency_id: null,
                                                            //     frequency_name: '',
                                                            //     us_id: null,
                                                            //     user_name: '',
                                                            //     start_count: 0
                                                            // })
                                                        }} /> */}
                                                        <div className="form-group">
                                                            <Select

                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter Unit"
                                                                onChange={(data, value) => {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        unit_id: value.key,
                                                                        unit_name: value.children
                                                                    })
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                // value={filter.seller}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {unitList && unitList.length && unitList.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.unit_id} value={item.unit_name}>
                                                                            {item.unit_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>Frequency</b></label>

                                                        <select name="" className='select2 form-control custom-select bg-white' onChange={(e) => {
                                                            setShowFilterData({
                                                                ...showFilterData,
                                                                frequency_id: e.target.value
                                                            })
                                                        }}>
                                                            <option value="">All</option>
                                                            {frequencyList && frequencyList.length && frequencyList.map((item) => {
                                                                return <option value={item.frequency_id}>{item.frequency_name}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label htmlFor=""><b>User</b></label><br />
                                                        {/* <Search placeholder="input search text" style={{ width: 300 }} value={showFilterData.user_name}
                                                        onSearch={() => {
                                                            setModal('User')
                                                            showModal()
                                                        }}
                                                    /> */}
                                                        <div className="form-group">
                                                            <Select

                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Enter User"
                                                                onChange={(data, value) => {
                                                                    setShowFilterData({
                                                                        ...showFilterData,
                                                                        us_id: data,
                                                                        user_name: value.children
                                                                    })
                                                                }}
                                                                // onSearch={onSearch}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                // value={filter.seller}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {userList && userList.length && userList.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.user_id}>
                                                                            {item.employee_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                            <a href={() => false} style={{ marginLeft: '40%' }}
                                                onClick={() => {
                                                    setListArray(true)
                                                    setAddFormSubmit(true);
                                                    if (validator.current.allValid()) {
                                                        const payload = [
                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetComplianceApprovalList",
                                                                    {
                                                                        "le_id": entityid,
                                                                        "unit_id": showFilterData.unit_id ? Number(showFilterData.unit_id) : null,
                                                                        "d_id": showFilterData.d_id ? Number(showFilterData.d_id) : null,
                                                                        "frequency_id": showFilterData.frequency_id ? Number(showFilterData.frequency_id) : null,
                                                                        "us_id": showFilterData.us_id ? Number(showFilterData.us_id) : null,
                                                                        "start_count": 0
                                                                    }
                                                                ]
                                                            }
                                                        ]
                                                        complianceApprovallist({
                                                            payload: payload,
                                                            paramid: paramid
                                                        })
                                                    }
                                                }}
                                                className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a>
                                        </div>
                                    </Panel>
                                </Collapse>
                                <div>
                                    {listArray === false ?
                                        <div>
                                            <Collapse defaultActiveKey={['1']} accordion>
                                                <Panel header="Compliance Table" key="1">
                                                    {filteredArrayState && Array.isArray(filteredArrayState) && filteredArrayState.length > 0 ?
                                                        <div>
                                                            {filteredArrayState.map((item, i) => {

                                                                return (
                                                                    <Card
                                                                        title={item ? item && item.length && item[0].unit_name : 'Table'}>
                                                                        <DataTable
                                                                            columns={columns}
                                                                            data={item}
                                                                            fixedHeader = {dataTableProperties.fixedHeader}
                                                                            // defaultSortFieldId={1} 
                                                                            pagination={false}
                                                                            selectableRows
                                                                        />
                                                                    </Card>
                                                                )
                                                            })}</div> :
                                                        <div>
                                                            <span>No Compliance Available</span>
                                                        </div>}

                                                </Panel>
                                            </Collapse>
                                        </div> :
                                        <div>
                                            <Collapse defaultActiveKey={['1']} accordion>
                                                <Panel header="Compliance Table" style={{ backgroundColor: 'skyblue' }} key="1">

                                                    <Card
                                                        title={list && list.length && list[0].unit_name}
                                                    >
                                                        <DataTable
                                                            columns={columns}
                                                            data={list}
                                                            fixedHeader={dataTableProperties.fixedHeader}
                                                            // defaultSortFieldId={1} 
                                                            pagination={false}
                                                        // selectableRows
                                                        />
                                                    </Card>
                                                </Panel>
                                            </Collapse>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* <Table dataSource={dataSource} columns={columns} pagination={false} /> */}
                            <Modal title="" visible={showValueModal} onOk={handleModalValue} okText='Submit' onCancel={Cancelmodal} width={50}>
                                <Card>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Unit Name</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.unit}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Compliance Task</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.compliance_task}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Compliance Frequency</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.compliance_frequency}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Uploaded Documents</h5></label>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.uploaded_document}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Task Completion Date/Document Issued Date</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.document_issued_date}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Document Reference Number</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.document_reference_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Validity Date</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    {edit === false ?
                                                        <a href={() => false}>
                                                            <span
                                                                className="btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                                            >
                                                                <i className="ri-edit-line" onClick={() => {
                                                                    setEdit(true)
                                                                }}></i>
                                                            </span>
                                                        </a> :
                                                        <input type="date" className='form-control' value={valueModal.validity_date} name="validity_date" id="" onChange={(e) => {
                                                            setValueModal({
                                                                ...valueModal,
                                                                validity_date: e.target.value
                                                            })
                                                        }} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Next Due Date</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.next_due_date}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Status</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Remarks</h5></label>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <p style={{ float: 'left' }} onChange={(e) => {
                                                        setValueModal({
                                                            ...valueModal,
                                                            remarks: e.target.value
                                                        })
                                                    }}></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Action<span style={{ "color": "red" }}>*</span></h5>
                                                    </label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className="form-group">
                                                        <select name="action" className='form-control' value={valueModal.action} onChange={(e) => {
                                                            setValueModal({
                                                                ...valueModal,
                                                                action: e.target.value
                                                            })
                                                        }} id="">
                                                            <option value="Select">Select</option>
                                                            <option value="Approve">Approve</option>
                                                            <option value="Rectify Approval">Rectify</option>
                                                            <option value="Reject">Reject</option>
                                                        </select>
                                                        {validator.current.message(
                                                            'action',
                                                            valueModal.action,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    regex: 'Select Action'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {valueModal.action === 'Rectify Approval' ?
                                            <div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 mt-3">
                                                            <label htmlFor=""><h5>Remarks</h5></label>
                                                        </div>
                                                        <div className="col-md-6 mt-3">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <textarea name="" className='form-control' id="" cols="10" rows="2" value={valueModal.remarks}></textarea>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <PlusSquareTwoTone onClick={() => { showRemarksModal() }} />
                                                                </div>
                                                                {/* {validator.current.message(
                                                            'remarks',
                                                            valueModal.remarks,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    regex: 'Enter Remarks'
                                                                }
                                                            })} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 mt-3">
                                                            <label htmlFor=""><h5>Upload Documents</h5></label>
                                                        </div>
                                                        <div className="col-md-6 mt-3">
                                                            <input type="file" className='form-control' name="file" onChange={(e) => {
                                                                SavepastRecordOnChange(e)
                                                            }} id="" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 mt-3">
                                                            <label htmlFor=""><h5>Uploaded File Size</h5></label>
                                                        </div>
                                                        <div className="col-md-6 mt-3">
                                                            <p>{fileupload && fileupload.length && fileupload[0] && fileupload[0].file_size}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 mt-3">
                                                            <label htmlFor=""><h5>Attached Documents</h5></label>
                                                        </div>
                                                        <div className="col-md-6 mt-3">
                                                            <p>{fileupload && fileupload.length && fileupload[0] && fileupload[0].file_name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : valueModal.action === 'Reject' ?
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6 mt-3">
                                                            <label htmlFor=""><h5>Remarks</h5></label>
                                                        </div>
                                                        <div className="col-md-6 mt-3">
                                                            <textarea name="" className='form-control' id="" cols="10" rows="5"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ''

                                        }
                                    </div>
                                </Card>
                            </Modal>
                            <Modal title="Remarks List" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
                                <DataTable columns={column}
                                    data={recallremarks}
                                    fixedHeader={dataTableProperties.fixedHeader}
                                    // defaultSortFieldId={1}
                                    pagination />
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default connect(mapStateToProps, {
    complianceApprovallist,
    getComplianceFilters,
    approveCompliance,
    GetRemarksData,
    UploadFile
})(ComplianceApproval)
