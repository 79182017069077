import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { REMARKSMASTER } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage'



export const remarkslist = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
    
            }else{
            dispatch({
                type: REMARKSMASTER,
                payload: decryptData,
            });
        }

    } catch (err) {
        Toaster.error(err)
    }
};

export const remarksupdate = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }
        else if(decryptData[0] === 'UpdateRemarksMasterSuccess'){
            Toaster.success('Remarks Updated Successfully')
            setTimeout(()=>{
                window.location.reload(false);
            }, 2000);
        }else{
            Toaster.error(decryptData[0])
        }

    } catch (err) {
        Toaster.error(err)
    }
};
