import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { SERVICEPROVIDERLIST, TOGGLE_COMMON_LOADER } from './../../types/index';
import { toast } from 'react-toastify';
import alertmessage from './../../../Libs/Altermessage';


export const providerlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: SERVICEPROVIDERLIST,
                payload: decryptData,
            });
        }
    } catch (err) {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        Toaster.error(err);
    }
};

export const addprovider = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'SaveServiceProviderSuccess') {
            toast.success('Details Added Successfully');
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        } else if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();
        } else {
            toast.error(decryptData[0]);
        }


    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updatestatus = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        if (decryptData[0] === 'ChangeServiceProviderStatusSuccess') {
            Toaster.success('Status Updated Successfully')
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        } else if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();
        } else {
            toast.error(decryptData[0]);
        }


    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updateblock = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        if (decryptData[0] === 'BlockServiceProviderSuccess') {
            Toaster.success('Updated Successfully')
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        } else if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();
        } else {
            toast.error(decryptData[0]);
        }

    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updateprovider = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'UpdateServiceProviderSuccess') {
            toast.success('Details Updated Successfully');
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        } else if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();
        } else {
            toast.error(decryptData[0]);
        }

    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
