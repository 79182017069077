import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import { forgotPassword } from '../Store/Action/Forgotpassword';

const mapStateToProps = (state) => ({

})

const Forgotpassword = ({
    forgotPassword
}) => {
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [ForgotData, setForgotData] = useState({
        userId: '',
        groupName: ''
    })
    const validator = useRef(new SimpleReactValidator());

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    const submitForgot = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            forgotPassword({
                payload: ForgotData
            })
        }
    }
    return (
        <Fragment>
            <div className="main-wrapper-bg  ">
                <div className="auth-wrapper d-flex no-block justify-content-center 
                align-items-center">
                    <div className="auth-box p-4 bg-white rounded m-0">
                        <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                        <div id="loginform">
                            <div className="logo">
                                <center>
                                    <h3 className="box-title mb-3">Forgot Password</h3>
                                </center>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="form-horizontal mt-3 form-material" id="loginform">
                                        <div className="form-group mb-3">
                                            <div className="">
                                                <input className="form-control" type="text" required="" placeholder="User ID" onChange={(e) => {
                                                    setForgotData({
                                                        ...ForgotData, userId: e.target.value
                                                    })
                                                }} />
                                                {validator.current.message(
                                                    'userId',
                                                    ForgotData.userId,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'User Id is Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>

                                        <div className="form-group mb-4">

                                            <div className="">

                                                <input className="form-control" type="text" required="" placeholder="Group Short Name" onChange={(e) => {
                                                    setForgotData({
                                                        ...ForgotData, groupName: e.target.value
                                                    })
                                                }} />
                                                {validator.current.message(
                                                    'groupName',
                                                    ForgotData.groupName,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Group Shortname is Required',
                                                        }
                                                    })}
                                            </div>

                                        </div>




                                        <div className="form-group text-center mt-4 mb-3">

                                            <div className="col-xs-12">

                                                <button type='button' className="

                                                        btn btn-primary

                                                        d-block

                                                        w-100

                                                        waves-effect waves-light

                                                        "
                                                    onClick={submitForgot}
                                                >

                                                    Submit

                                                </button>

                                            </div>

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <footer className="footer bg-transparent text-white">2022© Aparajitha Software Services Private Limited</footer>

            </div>

        </Fragment>
    );
}

export default connect(mapStateToProps, {
    forgotPassword
})(Forgotpassword);