import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha
} from "react-simple-captcha";
import SimpleReactValidator from "simple-react-validator";
import { Link, Redirect, useHistory } from 'react-router-dom'; 
import { login } from './../Store/Action/Login'

const mapStateToProps = (state) => ({
})

const Login = ({
    login
}) => {
    const history = useHistory()
    const [Auth,SetAuth] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [captchaError, SetcaptchaError] = useState(false);
    const [LoginData, setLoginData] = useState({
        userName: '',
        password: '',
        groupName: '',
        captcha: '',
    });
    const validator = useRef(new SimpleReactValidator());


    useEffect(() => {
        loadCaptchaEnginge(4, 'white', 'black', 'numbers');

    }, [])

    useEffect(() => {
        validator.current.showMessages()
    }, [])
     
    useEffect(()=>{
      SetAuth(localStorage.getItem('isAuthenticated'));
    }, [])

   

    const submitLogin = (e) => {
        e.preventDefault();
        setAddFormSubmit(true);
        SetcaptchaError(false);
        if (validateCaptcha(LoginData.captcha) === true) {
            if (validator.current.allValid()) {
                login({
                    payload: LoginData,
                    navigate: history
                })
            }
        }
        else {
            SetcaptchaError(true);
        }
    }
    return (
  
        (Auth && Auth ? <Redirect to='/home'/> :<Fragment>

            <div className="main-wrapper-bg  ">
                <div className="auth-wrapper d-flex no-block justify-content-center 
                align-items-center">
                    <div className="auth-box p-4 bg-white rounded m-0">
                        <center><img src="logo-icon.png" height="50px" alt='Logo' /></center>
                        <div id="loginform">
                            <div className="logo">
                                <center>
                                    <h3 className="box-title mb-3">Login</h3>
                                </center>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <form className="commentForm mt-3 form-material" onSubmit={submitLogin}>
                                        <div className="form-group mb-3">
                                            <div className="">
                                                <input className="form-control" type="text" required="" placeholder="Username" onChange={(e) => {
                                                    setLoginData({
                                                        ...LoginData, userName: e.target.value
                                                    })
                                                }} />
                                                {validator.current.message(
                                                    'userName',
                                                    LoginData.userName,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>

                                        </div>

                                        <div className="form-group mb-4">

                                            <div className="">

                                                <input className="form-control" type="password" required="" placeholder="Password" onChange={(e) => {
                                                    setLoginData({
                                                        ...LoginData, password: e.target.value
                                                    })
                                                }} />
                                                {validator.current.message(
                                                    'password',
                                                    LoginData.password,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>

                                        </div>

                                        <div className="form-group mb-4">

                                            <div className="">

                                                <input className="form-control" type="text" required="" placeholder="Group Short Name" onChange={(e) => {
                                                    setLoginData({
                                                        ...LoginData, groupName: e.target.value
                                                    })
                                                }} />
                                                {validator.current.message(
                                                    'groupName',
                                                    LoginData.groupName,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>

                                        </div>

                                        <div className="form-group mb-4">
                                            <div className="form-floating mb-4 d-flex">
                                                <div className="col mt-6">
                                                    <LoadCanvasTemplate />
                                                </div>
                                            </div>
                                            <input className="form-control" placeholder='Enter Captcha' maxLength={4} onChange={(e) => {
                                                setLoginData({
                                                    ...LoginData, captcha: e.target.value
                                                })
                                            }} />
                                        </div>
                                        {
                                            captchaError ?
                                                <span style={{ "color": "red" }}>Incorrect Captcha</span> : null
                                        }
                                        <div className="form-group">

                                            <div className="d-flex">
                                                <div className="ms-auto">

                                                    <Link to="/Forgotpassword" id="to" className="

                                                    d-flex

                                                    align-items-center

                                                    link

                                                    font-weight-medium

                                                "><i className="ri-lock-line me-1 fs-4"></i> Forgot your password?</Link>

                                                </div>

                                            </div>

                                        </div>

                                        <div className="form-group text-center mt-4 mb-3">

                                            <div className="col-xs-12">

                                                <button type='submit' className="

                                                        btn btn-primary

                                                        d-block

                                                        w-100

                                                        waves-effect waves-light

                                                        "
                                                    //onClick={submitLogin}
                                                >

                                                    Login

                                                </button>

                                            </div>

                                        </div>
                                    </form>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

                <footer className="footer bg-transparent text-white">2022© Aparajitha Software Services Private Limited</footer>

            </div>
        </Fragment>)
        

    )
}

export default connect(mapStateToProps, {
    login
})(Login);