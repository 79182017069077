import { RECALLFILTERS, TASKRECALLCOMPLIANCE, REMARKSDATA, UPDATE_TASK_STATUS } from '../../types/index'

const initialState = {
    recallfilters: {
        statusrecall: '',
        userunitsrecall: '',
        recallcompliance: '',
        recallcompliancestatus: '',
        recallremarks: '',
        recallremarksstatus: '',
        updatetaskstatus: ''
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case RECALLFILTERS:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        statusrecall: payload[0],
                        userunitsrecall: payload[1]
                    },
                };
            }
        case TASKRECALLCOMPLIANCE:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        recallcompliancestatus: payload[0],
                        recallcompliance: payload[1].recall_compliances
                    },
                };
            }
        case REMARKSDATA:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        recallremarksstatus: payload[0],
                        recallremarks: payload[1]
                    },
                };
            }
        case UPDATE_TASK_STATUS:
            {
                return {
                    ...state,
                    recallfilters: {
                        ...state.recallfilters,
                        updatetaskstatus: payload[0]
                    },
                };
            }
        default:
            return state;
    }
}