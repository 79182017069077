import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom'
import { PlusSquareTwoTone, ExclamationCircleTwoTone, BookTwoTone } from '@ant-design/icons';
import { Modal, Input, Card, Collapse, Tabs, Select, Tooltip, Table } from 'antd';
import DataTable from "react-data-table-component";
import SimpleReactValidator from "simple-react-validator";
import { reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, showmoredata } from './../../Store/Action/Transcations/ReassignComplaince'
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import { toast } from 'react-toastify';
import moment from 'moment';
import { entries, forEach } from 'lodash';

const _ = require("lodash");

const mapStateToProps = (state) => ({
    lists: state.ReassignComplaince.reassigndata,
    remarks: state.recallfilters.recallfilters

})
const ReassignCompliance = ({
    reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, GetRemarksData, showmoredata,
    lists: { reassigndatalist, reassignunitdata, reassigndatatablelist, reassignusercompliance, savereassigndatalist, showmorelist },
    remarks: { recallremarks }

}) => {

    const history = useHistory();
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddSubmitForm, setAddSubmitForm] = useState(false);
    const [temparr, setTemparr] = useState([])
    console.log(temparr, "temparr-123");
    const [legalstate, Setlegalstate] = useState(false)
    const [unitcategory, Setunitcategory] = useState([])
    const [unitid, Setunitid] = useState([])
    const [childlist, Setchildlist] = useState([])
    const [parentlist, Setparentlist] = useState([])
    console.log(parentlist, "parentlist-345");

    const [checkrow, Setcheckrow] = useState([])

    const [key, setKey] = useState("1")
    const { TabPane } = Tabs;
    const { Search } = Input;
    const { Option } = Select
    const [tab, setTab] = useState(false)
    const { Panel } = Collapse;
    const [titledata, Settitledata] = useState()
    const [editclickdate, setEditClickDate] = useState(false)
    const [checkboxtitle, Setcheckboxtitle] = useState([])
    console.log(checkboxtitle, "checkboxtitle");

    // const [finalcheckrow, Setfinalcheckrow] = useState([])
    // console.log(finalcheckrow,"finalcheckrow");
    const [collapseindex, setCollapseIndex] = useState([])
    console.log(collapseindex, "collapseindex");

    const [legal, setLegal] = useState(false)
    const [remarksModal, setRemarksModal] = useState(false);
    const [checkedRow, setCheckedRow] = useState(false)
    const [checkedRow1, setCheckedRow1] = useState([])

    const [Assignpayload, setAssignPayload] = useState([])
    const [header, setHeader] = useState(null)
    console.log(header, "header");
    const [date, setDate] = useState([])
    const [entires, setEntires] = useState(null)
    const [datacount, Setdatacount] = useState([])
    const [countvalue, setCountvalue] = useState(false)
    const [showmore1, setShowMore1] = useState(false)
    const [showmoredatalist, setShowMoreDataList] = useState([])
    const [trigger, setTrigger] = useState([{
        comp_id: '',
    }])
    const [updateduedate, setUpdateduedate] = useState([])
    const [rowcompid, setRowCompId] = useState([])
    const [diffid, setDiffId] = useState([])
    const [recalldata, Setrecalldata] = useState({
        reasons: ''
    })
    const [categorylist, Setcategorylist] = useState({
        employee_name: '',
        employee_code: ''
    })
    const [assigneelist, Setassigneelist] = useState([])
    const [allassigneelist, Setallassigneelist] = useState([])
    const [finalassignee, Setfinalassignee] = useState([])
    const [finalallassignee, Setfinalallassignee] = useState([])
    const [dataa, setDataa] = useState({
        d_id: '',
        usr_id: '',
        u_ids: '',
        emp_code: '',
        s_u_name: '',
        employee_name: '',
        usr_name: '',
        legalentity: localStorage.getItem('SelectedEntity') === "All Legal Entity" ? '' : localStorage.getItem('SelectedEntity'),
        d_name: '',
        user_type: '',
        unit_name: ''
    })
    const [filteredArray, setFilteredArray] = useState([])
    const [userType, SetuserType] = useState("ALL")
    const [showTable, SetshowTable] = useState(false)
    const [tabletotaldata, Settabletotaldata] = useState([])
    const [headerlist, setHeaderList] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid').replaceAll('"', ''));
    const legalmodel = () => {
        setLegal(true)
    }
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != "null") {
            setCurrentEntity(localStorage.getItem('SelectedEntityid').replaceAll('"', ''))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    const handleOk = () => {
        setRemarksModal(false)
    };
    const handleCancel = () => {
        setRemarksModal(false)
    };
    const previous = () => {
        setTab(false)
    }
    const showmore = () => {
        setShowMore1(true)
        const reassignshowlist = [
            authtoken,
            {
                "session_token": authtoken,

                "request":
                    [
                        "GetReAssignComplianceForUnits",
                        {
                            "le_id": entityid,
                            "d_id": Number(dataa.d_id),
                            "usr_id": Number(dataa.usr_id),
                            "user_type_id": reassignunitdata && reassignunitdata.reassign_units && reassignunitdata.reassign_units[0] && reassignunitdata.reassign_units[0].user_type_id,
                            "u_ids": checkrow,
                            "r_count": entires
                        }
                    ]
            }

        ]
        // reassigndatatable({
        //     payload: reassigncomplainceunit,
        //     paramid: paramid
        // })

        showmoredata({
            payload: reassignshowlist,
            paramid: paramid
        })

    }
    // useEffect(() => {

    //     if (checkrow && checkrow.length > 0) {
    //         let finaldata = [...new Set(checkrow)]

    //         Setfinalcheckrow(finaldata)
    //     }

    // }, [checkrow])
    useEffect(() => {
        let tempArr = []
        for (let k in childlist) {
            for (let n in childlist[k]) {
                if (childlist[k][n] && childlist[k][n].length > 0) {
                    tempArr.push(...tabletotaldata, ...childlist[k][n])
                    Setdatacount(tempArr)
                }
            }
        }
    }, [childlist])
    useEffect(() => {
        let ss = updateduedate.map((item) => {
            return item.comp_id
        })
        var difference = rowcompid.filter(x => ss.indexOf(x) === -1);
        let finaldata = [...new Set(difference)]
        setDiffId(finaldata)
    }, [updateduedate, rowcompid])
    const ontableChange = (e, i) => {
        let propertyName = e.target.name;
        let inputValue = e.target.value;
        let newArray = trigger;
        for (let j in newArray) {
            if (i == j) {
                newArray[j][propertyName] = inputValue;
                break;
            }
        }
        setTrigger([...newArray]);
    }
    const Onsubmit2 = () => {

        const getuserassigncompliance = [
            authtoken,
            {
                "session_token": authtoken,
                "request":
                    [
                        "GetUserToAssignCompliance",
                        {
                            "d_id": Number(dataa.d_id),
                            "u_ids": [unitid],
                            "le_id": entityid
                        }
                    ]
            }
        ]
        // getusercomplaince({
        //     payload: getuserassigncompliance,
        //     paramid: paramid
        // })
        if (temparr.length > 0) {
            getusercomplaince({
                payload: getuserassigncompliance,
                paramid: paramid
            })
            setKey("2")
            setTab(true)
        }
        else {
            toast.warning("No Compliance selected for Reassign")
        }
    }
    const Onsubmit1 = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            const reassigntableAPI = [
                authtoken,
                {
                    "session_token": authtoken,

                    "request":
                        [
                            "GetReAssignComplianceUnits",
                            {
                                "le_id": entityid,
                                "d_id": Number(dataa.d_id),
                                "usr_id": Number(dataa.usr_id),
                                "user_type_id": Number(dataa.user_type),
                                "unit_id": null,
                            }
                        ]

                }
            ]
            reassignunitlist({
                payload: reassigntableAPI,
                paramid: paramid
            })

            SetshowTable(true)
        }
    }
    const OnSubmit = () => {

        const reassigncomplainceunit = [
            authtoken,
            {
                "session_token": authtoken,

                "request":
                    [
                        "GetReAssignComplianceForUnits",
                        {

                            "le_id": entityid,
                            "d_id": Number(dataa.d_id),
                            "usr_id": Number(dataa.usr_id),
                            "user_type_id": reassignunitdata && reassignunitdata.reassign_units && reassignunitdata.reassign_units[0] && reassignunitdata.reassign_units[0].user_type_id,
                            "u_ids": checkrow,


                            "r_count": 0
                        }
                    ]
            }

        ]
        // reassigndatatable({
        //     payload: reassigncomplainceunit,
        //     paramid: paramid
        // })


        if (checkedRow1.length > 0) {
            reassigndatatable({
                payload: reassigncomplainceunit,
                paramid: paramid
            })

            setKey("1")
            setTab(true)
            setCountvalue(true)
        }
        else {
            toast.warning("Select Atleast one unit")
        }

    }


    const Onsubmit3 = () => {

        setAddSubmitForm(true)
        if (validator1.current.allValid() && isAuth) {

            const savereassigncomplianceapi = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":

                        [
                            "ReassignCompliance",
                            {
                                "le_id": entityid,
                                "r_from": 2,
                                "assignee": 3,
                                "assignee_name": dataa.employee_name,
                                "concurrence_person": null,
                                "approval_person": null,
                                "reassigned_compliance": Assignpayload,
                                "reason": recalldata.reasons ? recalldata.reasons : '',
                                "d_id": Number(dataa.d_id),
                                "d_name": dataa.d_name

                            }
                        ]
                }
            ]



            savereassigncomplaince({
                payload: savereassigncomplianceapi,
                paramid: paramid
            })
            // setTimeout(() => {
            //     window.location.reload(false);
            // }, 4000);

        }
    }

    useEffect(() => {

        if (temparr.length !== 0) {

            let tempArray = []

            temparr && temparr.length > 0 && temparr.map((item, i) => {
                Setunitid(temparr[i].u_id,)
                for (let k in updateduedate) {
                    if (temparr[i].comp_id == updateduedate[k].comp_id) {
                        tempArray.push({

                            "u_id": temparr[i].u_id,
                            "comp_id": temparr[i].comp_id,
                            "compliance_name": temparr[i].compliance_name,
                            "c_h_id": temparr[i].c_h_id,
                            "d_date": moment(updateduedate[k].date).format("DD-MMM-YYYY"),
                            "o_assignee": temparr[i].assignee,
                            "o_concurrence_person": null,
                            "o_approval_person": temparr[i].approval_person,
                            "tz_date": temparr[i].tz_date,
                            "ac_id": temparr[i].ac_id,
                            "frequency": temparr[i].frequency,
                            "act": temparr[i].act_name,

                        })
                    }
                }
                for (let x in diffid) {

                    if (temparr[i].comp_id == diffid[x]) {
                        tempArray.push({

                            "u_id": temparr[i].u_id,
                            "comp_id": temparr[i].comp_id,
                            "compliance_name": temparr[i].compliance_name,
                            "c_h_id": temparr[i].c_h_id,
                            "d_date": temparr[i].d_date,
                            "o_assignee": temparr[i].assignee,
                            "o_concurrence_person": null,
                            "o_approval_person": temparr[i].approval_person,
                            "tz_date": temparr[i].tz_date,
                            "ac_id": temparr[i].ac_id,
                            "frequency": temparr[i].frequency,
                            "act": temparr[i].act_name,

                        })
                    }
                }
                if (tempArray.length != 0) {
                    setAssignPayload(tempArray)
                }

            })
        }
    }, [temparr, updateduedate, diffid])


    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    useEffect(() => {

        if (isAuth) {
            if (legalstate === true) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            'GetReassignComplianceFilters', {
                                "le_id": entityid,
                            }
                        ]
                    }
                ]

                reassignlist({
                    payload: payload,
                    paramid: paramid
                })
                Setlegalstate(false)

            }
        }
    }, [isAuth, entityid, legalstate])

    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()

    }, [])
    useEffect(() => {

        let unit_category = reassigndatalist && reassigndatalist.legal_entity_users.filter((item) => {

            return item.employee_name == dataa.usr_name

        })
        Setunitcategory(unit_category)

    }, [reassigndatalist.legal_entity_users, dataa])
    useEffect(() => {

        let final_list = checkboxtitle && checkboxtitle.map((item) => {
            console.log(item.parent, 'itemmm');
            return item.parent

        })

        Setparentlist(final_list)

    }, [checkboxtitle])
    useEffect(() => {
        for (let i in collapseindex) {
            const finalparent = parentlist[collapseindex[i]]
            setHeader(finalparent)
        }

    }, [collapseindex])
    useEffect(() => {
        if (checkedRow1 && checkedRow1.length > 0) {
            let temp_uid = []
            for (let i in checkedRow1) {
                temp_uid.push(checkedRow1[i].u_id)
                // let finaldata = [...new Set(temp_uid)]
            }
            Setcheckrow([...new Set(temp_uid)])
        }
        else {
            Setcheckrow([])
        }

    }, [checkedRow1.length])
    useEffect(() => {
        let temp = [];
        reassigndatalist.legal_entity_users && reassigndatalist.legal_entity_users.map((item) => {
            if (userType == "1") {
                if (item.user_category_id == 5 || item.user_category_id == 6) {
                    temp.push({
                        employee_name: item.employee_name,
                        employee_code: item.user_id
                    })
                }
            }
            else if (userType == "2") {
                if (item.user_category_id == 3 || item.user_category_id == 4) {
                    temp.push({
                        employee_name: item.employee_name,
                        employee_code: item.user_id
                    })
                }
            }
            else if (userType == "3") {
                if (item.user_category_id == 1 || item.user_category_id == 3 || item.user_category_id == 4) {
                    temp.push({
                        employee_name: item.employee_name,
                        employee_code: item.user_id
                    })
                }
            }
            else if (userType == "0") {


                temp.push({
                    employee_name: item.employee_name,
                    employee_code: item.user_id
                })
            }
        })
        Setcategorylist({
            ...categorylist,
            employee_name: temp,
        },
        )
    }, [userType])
    const addSelectedDataValues = (checked, comp_id, act_name) => {
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {

                array = _.find(datacount, { comp_id: comp_id });
                tempArray.push(array)

            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked === true) {
                array = _.find(datacount, { comp_id: comp_id });
                tempArray.push(array)

            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const addAllSelectedDataValues = (checked) => {
        var ele = document.getElementsByName('checked');
        let temp = [];
        if (checked === true) {
            setCheckedRow(true)
            // let temp = []
            for (let i in collapseindex) {
                for (let j in checkboxtitle[i].child) {
                    temp.push(...checkboxtitle[collapseindex[i]].child[j])
                    console.log(temp, "temppp");
                    setTemparr([...temp])
                }
                // setTemparr([...temparr, ...temp])
            }
            for (let i in ele) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            let TempArr = temparr
            setCheckedRow(false)
            console.log(TempArr, "112233");
            let tempArray = []
            for (let i in TempArr) {
                console.log(TempArr, "123123");
                console.log(i, "iiiiii-value");
                // if (TempArr[i].act_name === "The Apprentices Act, 1971") {
                if (TempArr[i].act_name === header) {
                    TempArr.splice(i, 1)
                }
                else {
                    tempArray.push(TempArr[i])
                }
                console.log(tempArray, "temp000");
                setTemparr([...tempArray])
            }
            // setTemparr([...tempArray])

            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }

    useEffect(() => {
        if (showmore1 == true) {
            if (titledata && showmoredatalist) {
                let concat1 = titledata.concat(showmoredatalist)
                Setcheckboxtitle(concat1);
            }
        }
        else {
            if (titledata) {
                Setcheckboxtitle(Object.values(titledata));
            }
        }
    }, [titledata, showmoredatalist, showmore1])

    // useEffect(() => {
    // if (showmore1 == true) {
    //     if (showmoredatalist && showmoredatalist.length > 0) {
    //        
    //         if (titledata.length > 0) {
    //            
    //             let tempArr = titledata.concat(showmoredatalist)
    //             
    //             Setcheckboxtitle(tempArr)
    //             // setDataList([dataList,statutoryUnitList && statutoryUnitList.statutoryListByUnit])
    //         }
    //         else {
    //             if (titledata) {
    //                 Setcheckboxtitle(Object.values(titledata));
    //             }
    //         }

    //     }}
    // }, [titledata, showmoredatalist, showmore1])




    useEffect(() => {
        if (checkboxtitle) {

            let tempARR = []
            let temparr = []
            for (let i in checkboxtitle) {

                tempARR.push(checkboxtitle[i].child)
                temparr.push(checkboxtitle[i].parent)
            }
            Setchildlist(tempARR)
            // Setparentlist(temparr)
        }
    }, [checkboxtitle])
    useEffect(() => {
        let temp = [];
        let temp1 = [];
        reassignusercompliance.assign_users && reassignusercompliance.assign_users.map((item) => {
            if (item.is_assignee === true) {
                if (Number(item.s_u_id) === Number(dataa.s_u_name)) {
                    temp.push(`${item.emp_code} - ${item.emp_name}`)
                }
            }
            if (item.is_assignee === true) {
                temp1.push(`${item.emp_code} - ${item.emp_name}`)
            }
            if (temp.length === 0) {
                Setallassigneelist([...temp1])
            }
            else {
                Setallassigneelist([])
            }
            Setassigneelist([...temp])
        })
    }, [dataa.s_u_name])

    useEffect(() => {

        assigneelist && assigneelist.length && assigneelist.map((item) => {
            if (item === dataa.usr_name[0]) {

                let array = _.remove(assigneelist, (item) => item === dataa.usr_name[0])
            }
            Setfinalassignee([...assigneelist])
        })

    }, [assigneelist])
    useEffect(() => {
        if (allassigneelist.length !== 0) {
            allassigneelist && allassigneelist.length && allassigneelist.map((item) => {
                if (item === dataa.usr_name[0]) {

                    let array = _.remove(allassigneelist, (item) => item === dataa.usr_name[0])
                }
                Setfinalallassignee([...allassigneelist])

            })
        }
        else {
            Setfinalallassignee([])
        }
    }, [allassigneelist])

    useEffect(() => {
        if (countvalue === true) {

            setEntires(datacount.length)

        }

    }, [datacount, countvalue])


    const columns = [

        {
            name: "Unit",
            selector: (row) => {
                return (
                    <>
                        <Tooltip title={row.address}>
                            <p style={{ whiteSpace: 'unset', marginTop: '15px' }} > <ExclamationCircleTwoTone /> &nbsp;{row.u_name}</p>
                        </Tooltip>

                    </>
                )
            },
            width: '500px',
            key: 'u_name',


        },
        {
            name: "No of Compliance Tasks",
            selector: (row) => row.no_of_compliances,
            sortable: true,
            width: '500px',
            reorder: true
        },
    ]

    const columns1 = [
        {
            title: <Fragment>
                <input type="checkbox"
                    onClick={(e) => {
                        setRowCompId([...rowcompid])
                        let checked = e.target.checked;
                        addAllSelectedDataValues(
                            checked
                        );
                    }}
                // onClick={(e) => {
                //     for (let i in collapseindex) {
                //         let temp = []
                //         temp.push(headerlist && headerlist[collapseindex[i]] && headerlist[collapseindex[i]])
                //         setHeader([...header, ...temp])
                //     }

                //     let checked = e.target.checked;
                //     addAllSelectedDataValues(
                //         checked,

                //     );
                // }}
                />
            </Fragment>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            fixed: 'left',
            render: (row, record, rowIndex) => {
                return (
                    <input type="checkbox"
                        name='checked'
                        onClick={(e) => {
                            let temp = []
                            temp.push(record.comp_id)
                            setRowCompId([...rowcompid, ...temp])
                            let checked = e.target.checked;
                            addSelectedDataValues(
                                checked,
                                record.comp_id,
                                record.act_name
                            );
                        }}
                    />

                )
            },
            fixed: 'left',
            width: "25px",
            ellipsis: true
        },
        {
            title: "Compliance Task",
            dataIndex: false,
            key: false,
            render: (record) => {

                return (
                    <>
                        <Tooltip title={record.compliance_description}>
                            <p style={{ whiteSpace: 'unset', marginTop: '15px' }} > <ExclamationCircleTwoTone /> {record.compfielaw_url ? <a href={`${record.compfielaw_url}`}><BookTwoTone /></a> : ''}&nbsp; {record.compliance_name}</p>
                        </Tooltip>
                    </>
                )
            },
            sortable: true,
            reorder: true,
            width: '150px',
            ellipsis: false,
            fixed: 'left',
          
        },
        {
            title: "Compliance Frequency",
            dataIndex: 'frequency',
            key: 'frequency',
            sortable: true,
            reorder: true,
            width: '70px',
            ellipsis: false,

        },
        {
            title: "Statutory Date/Schedule",
            dataIndex: 'summary',
            key: 'summary',
            width: "130px",
            sortable: true,
            reorder: true,
            ellipsis: true,

        },
        {
            title: "Trigger Before (Days)",
            dataIndex: 'trigger_before_days',
            key: 'trigger_before_days',
            sortable: true,
            reorder: true,
            width: '70px',

        },
        {
            title: 'Assignee',
            dataIndex: 'assignee_name',
            key: 'assignee_name',
            sortable: true,
            width: '70px'
        },
        {
            title: 'Concur',
            dataIndex: 'concurrer_name',
            key: 'concurrer_name',
            sortable: true,
            width: '70px'
        },
        {
            title: "Approver",
            dataIndex: 'approver_name',
            key: 'approver_name',
            sortable: true,
            reorder: true,
            width: '70px',

        },
        {
            title: "Due Date * ",
            dataIndex: false,
            key: false,
            render: (row, record, rowIndex) => {
                    return (
                        <>
                            {record.c_h_id !== null ?
                                
                                    <label>{moment(record.d_date).format("DD-MMM-YYYY")}
                                        {/* <i style={{ marginLeft: "5px" }} className="ri-edit-line" onClick={() => {
                                            setEditClickDate(true)
                                        }}></i> */}
                                    </label>
                                    :
                                    (temparr.filter((e) => e.comp_id == record.comp_id).length > 0) ?
                                       <input type='date' style={{ width: "130px", height: "30px" }} className="form-control"
                                    //    max={moment(record.d_date).format("YYYY-MM-DD")}
                                    //    onBlur={(e) => {
                                    // for (let i in updateduedate) {
                                    //        if (rowIndex == updateduedate[i].index && row.comp_id == updateduedate[i].comp_id) {
                                    //        let temparr = []
                                    //        temparr.push({
                                    //            date: e.target.value,
                                    //            comp_id: record.comp_id,
                                    //            index: rowIndex
                                    //        })

                                    //        setUpdateduedate([...updateduedate, ...temparr])
                                    //        }
                                    //     }
                                    //    }}
                                   />
                                        : ""}
                                    
                                </>
                     
                        
                    )
            },
            sortable: true,
            reorder: true,
            width: '70px',


        },
        {
            title: "Validity Date",
            dataIndex: 'v_date',
            key: 'v_date',
            sortable: true,
            reorder: true,
            width: '70px',

        }
    ];

    const columns2 = [
        {
            name: "S no",
            selector: (row) => row.r_id,
            sortable: true,
            width: '300px',
            reorder: true,

        }, {
            name: "Description",
            selector: (row) => <a onClick={() => {
                Setrecalldata({ ...recalldata, reasons: row.r_description })
                setRemarksModal(false)
            }}>{row.r_description}</a>,
            sortable: true,
            width: '500px',
            reorder: true,

        }
    ]

    useEffect(() => {
        if (dataa.legalentity == localStorage.getItem('SelectedEntity')) {
            //     setDataa({
            //         ...dataa,
            //         legalentity: entityid
            //     })
            Setlegalstate(true)
        }
    }, [dataa.legalentity])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let datas = [];
        let filteredArr;
        let filteredArr1;
        if (reassigndatatablelist.reassign_compliances) {

            const ab = reassigndatatablelist.reassign_compliances.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })
            array.map((data, i) => {
                datas = data
                let temp = [];
                if (datas) {
                    reassigndatatablelist.reassign_compliances.map((child) => {
                        if (datas === child.act_name) {
                            temp.push(child)
                            filteredArr = temp.filter((item) => {
                                return item.task_type === 'Current Task'
                            })
                            filteredArr1 = temp.filter((item) => {
                                return item.task_type === 'Upcoming Task'
                            })
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: datas,
                    child: [[...filteredArr], [...filteredArr1]],
                }
                finalarray.push(datavalue)
            })
            Settitledata(finalarray)
        }
    }, [reassigndatatablelist.reassign_compliances])

    useEffect(() => {

        let array = [];
        let finalarray = [];
        let datavalue = [];
        let datas = [];
        let filteredArr;
        let filteredArr1;
        if (showmorelist) {

            const ab = showmorelist.map(function (item) {
                if ((array.indexOf(item.act_name) < 0))
                    array.push(item.act_name)
            })
            array.map((data, i) => {
                datas = data
                let temp = [];
                if (datas) {
                    showmorelist.map((child) => {
                        if (datas === child.act_name) {
                            temp.push(child)
                            filteredArr = temp.filter((item) => {
                                return item.task_type === 'Current Task'
                            })
                            filteredArr1 = temp.filter((item) => {
                                return item.task_type === 'Upcoming Task'
                            })
                        }
                    })
                }

                datavalue = {
                    index: i,
                    parent: datas,
                    child: [[...filteredArr], [...filteredArr1]],
                }
                finalarray.push(datavalue)
            })
            setShowMoreDataList(finalarray)
        }
    }, [showmorelist])


    useEffect(() => {
        if (reassignusercompliance.assign_users && reassignusercompliance.assign_users.length > 0) {

            let uniqueObjArray = [...new Map(reassignusercompliance.assign_users && reassignusercompliance.assign_users.length && reassignusercompliance.assign_users.map((item) => [item["s_u_name"], item])).values()];


            setFilteredArray([...uniqueObjArray])
        }
    }
        , [reassignusercompliance.assign_users])

    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:;" className="link"
                                            ><i className="ri-home-3-line fs-5"></i></a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Transactions
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Reassign Compliance
                                        </li>
                                    </ol>
                                    <h2 className="mb-0 fw-bold"> Reassign Compliance </h2>
                                </nav>

                            </div>
                            {/* <div className="
                col-lg-4 col-md-6
                d-none d-md-flex
                align-items-center
                justify-content-end" >
                                <Link to="/reassign-compliance/:id/:type" type="button" class="btn-close" aria-label="Close"> </Link>
                            </div> */}
                        </div>
                    </div>

                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            {tab === true ?
                                                <Tabs activeKey={key} >

                                                    <TabPane
                                                        tab={
                                                            <span style={{ padding: "0px 75px" }}>
                                                                Select Compliance Tasks
                                                            </span>
                                                        }
                                                        disabled
                                                        key="1"
                                                    >
                                                        <div className='col-md-12' style={{ padding: "10px" }}>
                                                            <div className='row'>
                                                                <div className='col-md-2' >
                                                                    <button className="btn btn-primary btn-md" style={{ float: "left", width: "100px" }} onClick={previous}>Previous</button>
                                                                </div>
                                                                <div className='col-md-2'></div>
                                                                <div className='col-md-2'></div>
                                                                <div className='col-md-2'></div>
                                                                <div className='col-md-2'></div>
                                                                <div className='col-md-2'>
                                                                    <button className="btn btn-primary btn-md" style={{ float: "right", width: "100px" }} onClick={Onsubmit2}>Next</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {checkboxtitle && checkboxtitle.length > 0 &&
                                                            checkboxtitle.map((itemdata, i) => {
                                                                console.log(itemdata, "ttt");
                                                                return (
                                                                    <Collapse onChange={(e) => {
                                                                        console.log(e, "eee");
                                                                        let index = collapseindex
                                                                        let k = index.indexOf(i)
                                                                        console.log(i, "iii");
                                                                        let m
                                                                        let j
                                                                        if (k > -1) {
                                                                            if (index.length > 0) {
                                                                                for (j in index) {
                                                                                    if (index[j] === i) {
                                                                                        m = j
                                                                                    }
                                                                                }
                                                                            }
                                                                            index.splice(m ? m : i, 1)
                                                                            setCollapseIndex([...index])

                                                                        } else {
                                                                            index.push(i)
                                                                            setCollapseIndex([...index])
                                                                        }

                                                                        // setCollapseIndex([...new Set(collapseindex)])

                                                                    }}>
                                                                        <Panel header={itemdata.parent} key={itemdata.index} >

                                                                            {itemdata.child && itemdata.child.length && itemdata.child.map((data, i) => {

                                                                                return (
                                                                                    <>
                                                                                    {/* <div > */}
                                                                                        {/* <div className="row"> */}
                                                                                
                                                                                            {/* <div className="col-12" style={{padding:0}}> */}
                                                                                            <div className="container-fluid" style={{ padding: 0 }}>
                                                                                                {data && data.length ?
                                                                                                    <Card title={data ? data && data.length && data[0].task_type : ''} >
                                                                                                        {/* <DataTable
                                                                                                            columns={columns1}
                                                                                                            data={data && data.length > 0 ? data : ''}
                                                                                                            pagination={true}
                                                                                                        /> */}
                                                                                                        <Table columns={columns1} dataSource={data && data.length > 0 ? data : ''} scroll={{
                                                                                                            x: 1500,
                                                                                                            y: 300,
                                                                                                        }}
                                                                                                            size="small"

                                                                                                        pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
                                                                                                        />
                                                                                                    </Card>
                                                                                                    : ''}
                                                                                            {/* </div> */}
                                                                                             {/* <br /> */}
                                                                                        {/* </div> */}
                                                                                    </div>
                                                                                    </>
                                                                                )
                                                                            })}





                                                                        </Panel>
                                                                    </Collapse>
                                                                )
                                                            })}





                                                        {/* </Card> */}

                                                        {/* <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                            <div className='row'>

                                                                <div className='col-md-3'></div>
                                                                <div className='col-md-3'></div>
                                                                <div className='col-md-3'></div>
                                                                <div className='col-md-3'>
                                                                    <label>Selected Compliance: <a href='javascript:;' style={{ fontSize: "18px" }}>{temparr.length}</a> </label>
                                                                </div>

                                                            </div>
                                                        </div> */}
                                                        {/* <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                            <div className='row'>

                                                                <div className='col-md-3' >
                                                                    <label><b>Showing  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{entires}</a>  entries</label>
                                                                </div>
                                                                <div className='col-md-3'></div>
                                                                <div className='col-md-3'></div>
                                                                <div className='col-md-3'>
                                                                    <label><b>Selected Compliance  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{temparr.length}</a> </label>
                                                                </div>

                                                            </div>
                                                        </div> */}
                                                        {entires != 200 ?
                                                            <div className='col-md-12'>
                                                                <div className='row'>
                                                                    <div className='col-md-4'></div>
                                                                    <div className='col-md-4'>
                                                                        <button className="btn btn-success" style={{ float: "center", marginLeft: "50px", marginTop: "10px" }} onClick={showmore} >Show More</button>
                                                                    </div>
                                                                    <div className='col-md-4'></div>
                                                                </div>
                                                            </div>
                                                            : ""}
                                                        <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                            <div className='row'>

                                                                <div className='col-md-3' >
                                                                    <label><b>Showing  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{entires}</a>  entries</label>
                                                                </div>
                                                                <div className='col-md-3'></div>
                                                                <div className='col-md-3'></div>
                                                                <div className='col-md-3'>
                                                                    <label><b>Selected Compliance  </b><a href='javascript:;' style={{ fontSize: "18px" }}>{temparr.length}</a> </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </TabPane>
                                                    <TabPane
                                                        tab={
                                                            <span style={{ padding: "0px 80px" }}>
                                                                Assignment Settings
                                                            </span>
                                                        }
                                                        disabled
                                                        key="2"
                                                    >


                                                        <Card title={dataa.usr_id ? <div> <b>Current Assignee : {dataa.usr_name}  </b></div> : ''} style={{ marginTop: "20px", boxShadow: "0px 0px 0px 1px grey" }} >
                                                            <div className='col-md-12'>
                                                                <div className='row'>

                                                                    <div className='col-md-6' >

                                                                        <label> <b>New Assignee</b><span style={{ color: "red" }}>*</span></label> <br></br>
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Select Assignee"
                                                                            onChange={(data) => {


                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    s_u_name: data
                                                                                })

                                                                            }}

                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '300px' }}
                                                                        >
                                                                            <Option key={1}>
                                                                                {"All"}
                                                                            </Option>
                                                                            {filteredArray && filteredArray.length && filteredArray.map((item) => {
                                                                                if (item.s_u_name !== null) {
                                                                                    return (
                                                                                        <Option key={item.s_u_id}>
                                                                                            {item.s_u_name}
                                                                                        </Option>
                                                                                    );

                                                                                }




                                                                            })}
                                                                        </Select>
                                                                        {validator1.current.message(
                                                                            's_u_name',
                                                                            dataa.s_u_name,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddSubmitForm ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Required Field Missing',
                                                                                }
                                                                            })}
                                                                    </div>
                                                                    <div className='col-md-6' style={{ marginTop: "20px" }}>
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Search"
                                                                            onChange={(data) => {

                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    employee_name: data
                                                                                })
                                                                            }}

                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            style={{ width: '300px' }}
                                                                        >
                                                                            {
                                                                                finalallassignee && finalallassignee.length ? finalallassignee.map((item) => {
                                                                                    return (
                                                                                        <Option key={item}>
                                                                                            {item}
                                                                                        </Option>
                                                                                    )
                                                                                })
                                                                                    :
                                                                                    finalassignee && finalassignee.length && finalassignee.map((item) => {


                                                                                        return (
                                                                                            <Option key={item}>
                                                                                                {item}
                                                                                            </Option>
                                                                                        );
                                                                                    })
                                                                            }



                                                                        </Select>
                                                                    </div>
                                                                </div>





                                                            </div>
                                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                                <div className='row'>
                                                                    <div className="col-md-4">
                                                                        <div className="form-group">
                                                                            <label htmlFor=""><b>Reason</b> <span style={{ color: "red" }}>*</span></label>
                                                                            <textarea name="remarks" className='form-control' placeholder='Enter reason here'
                                                                                value={recalldata.reasons ? recalldata.reasons : ""} id="remarks" cols="4" rows="4"
                                                                                onChange={(e) => {
                                                                                    Setrecalldata({
                                                                                        ...recalldata,
                                                                                        reasons: e.target.value
                                                                                    })
                                                                                }}></textarea>
                                                                            {validator1.current.message(
                                                                                'reasons',
                                                                                recalldata.reasons,
                                                                                ['required'],
                                                                                {
                                                                                    className: `invalid-feedback ${AddSubmitForm ? 'show' : 'hide'}`,
                                                                                })}
                                                                        </div>
                                                                    </div>

                                                                    <div style={{ marginLeft: '30%' }}>
                                                                        <PlusSquareTwoTone onClick={() => { showRemarksModal() }} />
                                                                    </div>
                                                                    <Modal title="Remarks" visible={remarksModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
                                                                        < DataTable columns={columns2}
                                                                            data={recallremarks}

                                                                            pagination
                                                                        />

                                                                    </Modal>
                                                                </div>
                                                            </div>


                                                        </Card>
                                                        <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                            <div className='row'>
                                                                <div className='col-md-2'>
                                                                    <button className="btn btn-primary btn-md" style={{ float: "left", width: "100px" }} onClick={() => { setKey("1") }}>Previous</button>
                                                                </div>
                                                                <div className='col-md-2'></div>
                                                                <div className='col-md-2'></div>
                                                                <div className='col-md-2'></div>
                                                                <div className='col-md-2'></div>
                                                                <div className='col-md-2'>
                                                                    <button className="btn btn-primary btn-md" style={{ float: "right", width: "100px" }} onClick={Onsubmit3}>Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </TabPane>
                                                </Tabs> :
                                                <Card >
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className="col-md-6" >
                                                                <label htmlFor=""><b>Legal Entity</b></label><span style={{ color: "red" }}>*</span><br />
                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Select Legal Entity"
                                                                            onChange={(data, value) => {
                                                                                Setlegalstate(true)
                                                                                setCurrentEntity(data)
                                                                                setDataa({
                                                                                    ...dataa,
                                                                                    legalentity: data
                                                                                })
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            value={dataa.legalentity}
                                                                            style={{ width: '300px' }}
                                                                        >
                                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.le_id}>
                                                                                        {item.le_name}
                                                                                    </Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                        {validator.current.message(
                                                                            'legalentity',
                                                                            dataa.legalentity,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Legal Entity Required',
                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                                }

                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Domain:</b><span style={{ color: "red" }}>*</span></label><br />

                                                                    <Select
                                                                        allowClear={true}
                                                                        disabled={dataa.legalentity == "" ? true : false}
                                                                        size="default"
                                                                        placeholder="Select Domain"
                                                                        onClick={() => {
                                                                            if (dataa.legalentity == "") {

                                                                                toast.warning("Legal Entity Required")
                                                                            }
                                                                        }}
                                                                        onChange={(data, value) => {


                                                                            setDataa({
                                                                                ...dataa,
                                                                                d_id: data,
                                                                                d_name: value.children
                                                                            })
                                                                        }}
                                                                        value={dataa.d_name}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        style={{ width: '300px' }}
                                                                    >
                                                                        {reassigndatalist.domains && reassigndatalist.domains.length && reassigndatalist.domains.map((item) => {
                                                                            return (
                                                                                <Option key={item.d_id}>
                                                                                    {item.d_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'domain',
                                                                        dataa.d_id,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Domain Required',

                                                                            }
                                                                        })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>

                                                                <label style={{ marginTop: "10px" }}> <b>User:</b><span style={{ color: "red" }}>*</span></label> <br></br>
                                                                <Select
                                                                    allowClear={true}
                                                                    disabled={dataa.d_id == "" ? true : false}
                                                                    size="default"
                                                                    placeholder="Select User"
                                                                    onClick={() => {
                                                                        if (dataa.d_id == "") {

                                                                            toast.warning("Domain Required")
                                                                        }
                                                                    }}
                                                                    onChange={(data, value) => {
                                                                        setDataa({
                                                                            ...dataa,
                                                                            usr_id: [data],
                                                                            usr_name: [value.children]

                                                                        })
                                                                    }}

                                                                    value={dataa.usr_name}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '300px' }}
                                                                >


                                                                    {
                                                                        categorylist.employee_name == '' ? reassigndatalist.legal_entity_users && reassigndatalist.legal_entity_users.map((item) => {
                                                                            return (
                                                                                <Option key={item.user_id}>
                                                                                    {item.employee_name}
                                                                                </Option>
                                                                            );
                                                                        })
                                                                            :
                                                                            categorylist.employee_name && categorylist.employee_name.map((item) => {

                                                                                return (
                                                                                    <Option key={item.employee_code}>
                                                                                        {item.employee_name}
                                                                                    </Option>
                                                                                );
                                                                            })


                                                                    }


                                                                </Select>
                                                                {validator.current.message(
                                                                    'user',
                                                                    dataa.usr_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'User Required'

                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className="col-md-6" style={{ marginTop: "10px" }} >

                                                                <label htmlFor=""><b>User Type</b></label> <br></br>
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select User Type"
                                                                    defaultValue="ALL"
                                                                    onChange={(value) => {


                                                                        SetuserType(value)
                                                                        setDataa({
                                                                            ...dataa,
                                                                            usr_id: '',
                                                                            usr_name: '',
                                                                            user_type: [value],
                                                                        })
                                                                    }}

                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '300px' }}
                                                                >
                                                                    <Option value="0">All</Option>
                                                                    <Option value="1">Assignee</Option>
                                                                    <Option value="2">Concurrence</Option>
                                                                    <Option value="3">Approver</Option>

                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <label htmlFor="" style={{ marginTop: "10px" }}><b>Unit:</b></label><br />
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Unit"
                                                                    disabled={dataa.d_id == "" ? true : false}
                                                                    onClick={() => {
                                                                        if (dataa.d_id == "") {

                                                                            toast.warning("Domain Required")
                                                                        }
                                                                    }}
                                                                    onChange={(data, value) => {

                                                                        setDataa({
                                                                            ...dataa,
                                                                            u_ids: data,
                                                                            unit_name: [value.children]
                                                                        })
                                                                    }}
                                                                    value={dataa.unit_name}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '300px' }}
                                                                >
                                                                    {reassigndatalist.units && reassigndatalist.units.length && reassigndatalist.units.map((item) => {

                                                                        return (
                                                                            <Option key={item.unit_id}>
                                                                                {item.unit_name}
                                                                            </Option>
                                                                        );
                                                                    })}
                                                                </Select>
                                                            </div>
                                                            <div

                                                                className='col-md-6'
                                                            >
                                                                <button className="btn btn-primary btn-md" style={{ marginTop: "25px", width: "80px" }} onClick={Onsubmit1}>Show</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {
                                                        showTable ?
                                                            <div>
                                                                <div className="row" style={{ marginTop: "30px" }}>
                                                                    <div className="col-12">
                                                                        <div className="card">
                                                                            {reassignunitdata.reassign_units && reassignunitdata.reassign_units.length > 0 ?

                                                                                // <Card title={userType && userType == 0 ? "All" : userType == 1 ? "Assignee" : userType == 2 ? "Concurrence" : userType == 3 ? "Approver" : ' All'}>
                                                                                <Card title={unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 5 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 6 ? "Assignee" : unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? "Concurrence" : unitcategory && unitcategory[0] && unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 1 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 3 || unitcategory && unitcategory[0] && unitcategory[0].user_category_id == 4 ? "Approver" : 'All'}>

                                                                                    <DataTable columns={columns}
                                                                                        data={reassignunitdata.reassign_units}
                                                                                        selectableRows
                                                                                        pagination={false}
                                                                                        onSelectedRowsChange={({
                                                                                            allSelected,
                                                                                            selectedCount,
                                                                                            selectedRows

                                                                                        }) => {

                                                                                            if (selectedCount > 0) {
                                                                                                setCheckedRow1(selectedRows)
                                                                                            } else {
                                                                                                setCheckedRow1([])
                                                                                            }

                                                                                        }}
                                                                                    />
                                                                                </Card>
                                                                                : <Card> <lable style={{ marginLeft: '40%', color: "red" }}>'No Records Found'</lable></Card>}
                                                                        </div>
                                                                    </div>
                                                                    <div> <button className="btn btn-primary btn-md" style={{ marginLeft: "80%", width: "100px" }} onClick={OnSubmit}> Reassign</button></div>
                                                                    <div className='col-md-12' style={{ marginTop: "20px" }}>
                                                                        <div className='row'>

                                                                            <div className='col-md-3'>
                                                                                <label> Selected Unit(s): <a href='javascript:;' style={{ fontSize: "18px" }}>{checkedRow1.length}</a> </label>
                                                                            </div>

                                                                            <div className='col-md-3'></div>
                                                                            <div className='col-md-3'></div>
                                                                            <div className='col-md-3'></div>
                                                                            <div className='col-md-3'></div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ' '
                                                    }
                                                </Card>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>

        </Fragment >
    )
}
export default connect(mapStateToProps, {
    reassignlist, reassignunitlist, reassigndatatable, getusercomplaince, savereassigncomplaince, GetRemarksData, showmoredata,
})(ReassignCompliance);