import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Card, Divider, Tabs, Select, Collapse, Tooltip, Modal, Input, Table } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { reviewlist, reviewunitlist, reviewshowsettinglist, reviewsavecompliance } from './../../Store/Action/Transcations/Reviewsettings'
import DataTable from "react-data-table-component";
import moment from 'moment';
import { ExclamationCircleTwoTone, BookTwoTone } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";
import { update } from 'lodash';
import { Toaster } from '../../Libs/Toaster';

const _ = require("lodash");


const mapStateToProps = (state) => ({
    list: state.Reviewsetting.reviewsettings

})

const { Option } = Select;


const Reviewsetting = ({
    reviewlist,
    list,
    reviewunitlist,
    reviewshowsettinglist,
    reviewsavecompliance
}) => {

    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    //  const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const { TabPane } = Tabs;
    // const { Search } = Input;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [unitFormSubmit, setUnitFromSubmit] = useState(false);
    const [selectUnit, setSelectUnit] = useState(false);
    const [selectShow, setSelectShow] = useState(false);
    const [value, setValue] = useState('1');
    const [assignpayload, setAssignPayload] = useState([])
    // const [editclick, setEditClick] = useState(false)
    const [updatedays, setUpdatedays] = useState([])
    const [updateduedate, setUpdateduedate] = useState([])
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true
    })
    const [collapseindex, setCollapseIndex] = useState([])
    const [updateevery, setUpdateevery] = useState([])
    const formValidator = useRef(new SimpleReactValidator());
    const [trigger, setTrigger] = useState([{
        comp_id: '',
        triggerInput: ''
    }])
    const [userDetails, setUserDetails] = useState({
        legal_entity_id: '',
        compliancefrequency: '',
        domain: '',
        timezone: '',
        units: [],
        domainName: '',
        dueDateList: '',
        legal_entity_name: ''
    })

    const [item, setItem] = useState({
        value: ''
    })

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentUnits, setCurrentUnits] = useState([]);
    const [checkAll, setCheckAll] = useState('');
    const showModal = (u_ids) => {
        setIsModalVisible(true);
        let tempArrayName = [];
        let tempConsole = [];
        for (let k in u_ids) {
            tempConsole.push(_.filter(list.unitlist, { u_id: parseInt(u_ids[k]) }))
            //tempArrayName.push(tempConsole)
        }
        setCurrentUnits(tempConsole);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setCurrentUnits([]);
    };
    const validator = useRef(new SimpleReactValidator());
    const validator1 = useRef(new SimpleReactValidator());
    const [checkedRow, setCheckedRow] = useState([]);
    const [date, setDate] = useState([])
    const [temparr, setTemparr] = useState([])
    const [selected, setSelected] = useState([]);
    const [options, setOptions] = useState([{
        label: '',
        value: '',
    }])
    const [selectedUnitValues, setSelectedUnitValues] = useState([])
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false)
    const ontableChange = (e, i) => {
        let propertyName = e.target.name;
        let inputValue = e.target.value;
        let newArray = trigger;
        for (let j in newArray) {
            if (i == j) {
                newArray[j][propertyName] = inputValue;
                break;
            }
        }

        setTrigger([...newArray]);

    }
    const [numbercheck, setNumberCheck] = useState('');
    const [temp, setTemp] = useState({
        tempData: [],
        parentIndexId: []
    });
    const { Panel } = Collapse;
    let repeatsEvery = null;

    const [data, setData] = useState();
    useEffect(() => {

        if (list.showsettinglist !== null) {
            let totalList = []
            let arr = [];
            let countrylisttempdata = [];
            let tempSetArray = [];
            let parId = []
            list && list.showsettinglist && list.showsettinglist.map(function (user) {
                if ((arr.indexOf(user.level_1_s_name) < 0))
                    arr.push(user.level_1_s_name);
            });

            arr.map((pmenu, index) => {
                let entityArray = []
                let tempArray = []

                if (pmenu) {

                    list.showsettinglist && list.showsettinglist.map((subMenuList, childIndex) => {
                        if (pmenu === subMenuList.level_1_s_name) {

                            entityArray = {
                                'id': childIndex,
                                'comp_id': subMenuList.comp_id,
                                'comp_name': subMenuList.comp_name,
                                'compfielaw_url': subMenuList.compfielaw_url,
                                'descp': subMenuList.descp,
                                'domain_id': subMenuList.domain_id,
                                'due_date_list': subMenuList.due_date_list,
                                'f_r_every': subMenuList.f_r_every,
                                'f_repeats_type_id': subMenuList.f_repeats_type_id,
                                'f_s_dates': subMenuList.f_s_dates,
                                'level_1_s_name': subMenuList.level_1_s_name,
                                'r_every': subMenuList.r_every,
                                'repeats_type_id': subMenuList.repeats_type_id,
                                's_dates': subMenuList.s_dates,
                                's_prov': subMenuList.s_prov,
                                'show_popup': subMenuList.show_popup,
                                'tz_date': subMenuList.tz_date,
                                'tz_name': subMenuList.tz_name,
                                'u_ids': subMenuList.u_ids,
                                'parentId': index,
                                'childId': childIndex,
                                'due_date_list_new': moment(new Date(subMenuList.due_date_list[0])).format('YYYY-MM-DD')

                            }
                            tempArray.push(entityArray)
                        }
                    })

                }
                totalList = {
                    'index': index,
                    'parentData': pmenu,
                    'child': tempArray
                }
                countrylisttempdata.push(totalList)
                tempSetArray.push(tempArray)
                parId.push(index);

            })
            setTemp({
                ...temp,
                tempData: countrylisttempdata,
                parentIndexId: parId

            })
            //  setData(tempSetArray)
            getData()
        }
    }, [list.showsettinglist])



    const findById = (array, id) => {

        return array.findIndex((d) => d.id === id);
    }

    const getData = () => {
        let countrylisttempdataNew = [];
        let arrNew = [];
        list && list.showsettinglist && list.showsettinglist.map(function (user) {
            if ((arrNew.indexOf(user.level_1_s_name) < 0))
                arrNew.push(user.level_1_s_name);
        });

        arrNew.map((pmenu, index) => {
            let entityArrayNew = [];
            let tempArraryNew = [];
            if (pmenu) {
                list.showsettinglist && list.showsettinglist.map((subMenuList, childIndex) => {
                    if (pmenu === subMenuList.level_1_s_name) {
                        entityArrayNew = {
                            'id': childIndex,
                            'comp_id': subMenuList.comp_id,
                            'comp_name': subMenuList.comp_name,
                            'compfielaw_url': subMenuList.compfielaw_url,
                            'descp': subMenuList.descp,
                            'domain_id': subMenuList.domain_id,
                            'due_date_list': subMenuList.due_date_list,
                            'f_r_every': subMenuList.f_r_every,
                            'f_repeats_type_id': subMenuList.f_repeats_type_id,
                            'f_s_dates': subMenuList.f_s_dates,
                            'level_1_s_name': subMenuList.level_1_s_name,
                            'r_every': subMenuList.r_every,
                            'repeats_type_id': subMenuList.repeats_type_id,
                            's_dates': subMenuList.s_dates,
                            's_prov': subMenuList.s_prov,
                            'show_popup': subMenuList.show_popup,
                            'tz_date': subMenuList.tz_date,
                            'tz_name': subMenuList.tz_name,
                            'u_ids': subMenuList.u_ids,
                            'parentId': index,
                            'childId': childIndex,
                            'due_date_list_new': moment(new Date(subMenuList.due_date_list[0])).format('YYYY-MM-DD')

                        }
                        tempArraryNew.push(entityArrayNew)
                    }
                })
            }
            countrylisttempdataNew.push(tempArraryNew)
        })
        var merged = [].concat.apply([], countrylisttempdataNew);
        setData(merged)
    }

    const handleTitleEditable = (field) => (row) => (parentId) => (index) => (e) => {

        const newRow = { ...row };
        newRow[field] = e.target.value;
        const newData = data.slice(0);
        newData[findById(data, row.id)] = newRow;
        // update your data model here in state, POST or whatever
        // this will re-render and update the data that is passed to DataTable
        setData(newData);
    };
    const addSelectedDataValues = (checked, comp_id, childId) => {

        let array
        let tempArray = temparr

        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }

            setTemparr([...tempArray])
        } else {
            setCheckAll('')
            if (checked === true) {
                array = _.find(data, { comp_id: comp_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].comp_id === comp_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }
    const addAllSelectedDataValues = (checked, currentParId, parentData) => {
        let array
        let tempArray = temparr
        var ele = document.getElementsByName('checked' + currentParId);
        if (checked === true) {
            setCheckedRow(true)
            array = _.filter(data, { parentId: currentParId });
            for (let i in array) {
                tempArray.push(array[i])
            }

            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentId === currentParId) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }

    // const validationFunction = (currentValue,childId,parentId) => {

    //     let copy = [...validationDetails];
    //     copy[parentId][childId] =+ currentValue
    //     setValidationDetails(copy)
    // }

    // const updateColumn = (data, parentId, childId,val,checkedI) => {
    //     return new Promise((resolve, reject) => {
    //         Array(data).forEach((value,index,array) => {
    //             if (parentId == array[index][parentId].index) {
    //                 array[index].child = array[index][parentId].child.forEach((value,index,array) => {
    //                     if (array[index].childId == childId) {
    //                         array[index].value = val
    //                         resolve(data)
    //                     }else resolve(data)
    //                 })
    //             }
    //         })
    //     })
    // }

    const numbercheckfunction = (e) => {
        if (e.target.value > 7) {
            Toaster.error('Repeats Type Should Not Exceed in Actual Value')
        }
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        setNumberCheck(value);
    }

    // const columns1 = [
    //     {
    //         name: <Fragment>
    //             <input type="checkbox"
    //                 onClick={(e) => {
    //                     let checked = e.target.checked;
    //                     addAllSelectedDataValues(
    //                         checked
    //                     );
    //                 }}
    //             />
    //         </Fragment>,
    //         selector: (row, rowIndex) => {

    //             return (
    //                 <input type="checkbox"
    //                     name='checked'
    //                     onClick={(e) => {
    //                         let checked = e.target.checked;
    //                         addSelectedDataValues(
    //                             checked,
    //                             row.comp_id,
    //                             row.childId
    //                         );
    //                     }}
    //                 />

    //             )
    //         }
    //     },

    //     {
    //         name: 'Statutory Provision',
    //         selector: (row) => row.s_prov,
    //         sortable: true,
    //         width: '250px'
    //     },
    //     {
    //         name: 'Compliance Task',
    //         // selector: (row) => row.comp_name,
    //         selector: (row) => {
    //             return (
    //                 <>
    //                     <Tooltip title={row.descp}>
    //                         <p style={{ whiteSpace: 'unset', marginTop: '15px' }} ><ExclamationCircleTwoTone />  {row.compfielaw_url ? <BookTwoTone /> : ''} &nbsp;{row.comp_name}</p>
    //                     </Tooltip>

    //                 </>
    //             )
    //         },
    //         sortable: true,
    //         width: '250px'
    //     },
    //     {
    //         name: 'Repeats By',
    //         selector: (row) => {
    //             if (row.f_repeats_type_id == 1) {
    //                 repeatsEvery = ' Days'
    //             } else if (row.f_repeats_type_id == 2) {
    //                 repeatsEvery = ' Months'
    //             } else if (row.f_repeats_type_id == 3) {
    //                 repeatsEvery = ' Years'
    //             }
    //             return 'Every ' + row.r_every + repeatsEvery
    //         },
    //         sortable: true,
    //         width: '140px'
    //     },
    //     {
    //         name: 'Repeats Every',

    //         selector: (row, rowIndex) => {
    //             for (var i = 0; i < temparr.length; i++) {
    //                 if (temparr && temparr.length > 0) {
    //                     if (temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {
    //                         return (
    //                             <>
    //                                 <label>{row.r_every} </label>
    //                                 <div className='row'>
    //                                     <div className='col-lg-6'>
    //                                         <input name='triggerInputUpdate' min={1} max={6} autoComplete={'off'} maxLength={3} style={{ width: "80px", height: "35px" }} className="form-control" placeholder='Every'
    //                                             onBlur={(e, i) => {
    //                                                 let temp1 = []
    //                                                 temp1.push(e.target.value)
    //                                                 if (row.childId in updateevery) {
    //                                                     let index = updateevery.indexOf(row)
    //                                                     updateevery[row.childId] = [];
    //                                                     if (index == -1) {
    //                                                         updateevery[row.childId] = e.target.value;
    //                                                     }
    //                                                 } else {
    //                                                     updateevery[row.childId] = [];
    //                                                     updateevery[row.childId] = e.target.value
    //                                                 }
    //                                                 setUpdateevery([...updateevery])
    //                                                 // setUpdateevery([...updateevery, ...temp1])
    //                                                 //  validationFunction(e.target.value, row.childId, row.parentId)                                                                
    //                                             }}
    //                                         // onChange={async (e, i) => {
    //                                         //     let temp1 = []
    //                                         //     temp1.push(e.target.value)
    //                                         //     setUpdateevery([...updateevery, ...temp1])
    //                                         //     //  validationFunction(e.target.value, row.childId, row.parentId)    
    //                                         //     let value = e.target.value
    //                                         //     let tempData = temp
    //                                         //     tempData.tempData = await updateColumn(tempData.tempData,row.parentId,row.childId,value)

    //                                         //     setTemp({
    //                                         //         ...temp,
    //                                         //         ...tempData
    //                                         //     })

    //                                         // }}
    //                                         // onChange={(e) => {

    //                                         //     numbercheckfunction(e)

    //                                         // }}


    //                                         ></input>
    //                                         {formValidator.current.message(
    //                                             'repeats_every',
    //                                             updateevery[row.childId],
    //                                             'required',

    //                                             {
    //                                                 className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
    //                                                 messages: {
    //                                                     required: 'Please Enter Value',

    //                                                 }
    //                                             })}
    //                                     </div>
    //                                     <div className='col-lg-6'>
    //                                         <select className='form-control' style={{ height: "35px" }}><option value="1" selected={row.f_repeats_type_id == 1}>Days</option><option value="2" selected={row.f_repeats_type_id == 2}>Months</option><option value="3" selected={row.f_repeats_type_id == 3}>Years</option></select>
    //                                     </div>
    //                                 </div>
    //                             </>


    //                         )
    //                     }
    //                 }
    //             }
    //         },

    //         sortable: true,
    //         width: '250px'
    //     },
    //     {
    //         name: 'Due Date',
    //         selector: (row, rowIndex) => {


    //             for (var i = 0; i < temparr.length; i++) {

    //                 if (temparr && temparr.length > 0) {

    //                     if (temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {
    //                         return (
    //                             <>
    //                                 <label>{row.due_date_list[0]} </label>
    //                                 <div>

    //                                     <input type='date' name='triggerInput' style={{ width: "120px", height: "35px" }} disabled={temparr != "" ? false : true} className="form-control"
    //                                         onBlur={(e) => {
    //                                             let temp = []
    //                                             temp.push(e.target.value)
    //                                             if (row.childId in updateduedate) {
    //                                                 let index = updateduedate.indexOf(row)
    //                                                 updateduedate[row.childId] = [];
    //                                                 if (index == -1) {
    //                                                     updateduedate[row.childId] = e.target.value;
    //                                                 }

    //                                             } else {
    //                                                 updateduedate[row.childId] = [];
    //                                                 updateduedate[row.childId] = e.target.value
    //                                             }
    //                                             setUpdateduedate([...updateduedate])
    //                                             // setUpdateduedate([...updateduedate, ...temp])
    //                                         }}


    //                                     ></input>
    //                                     {formValidator.current.message(
    //                                         'repeats_every',
    //                                         updateduedate[row.childId],
    //                                         'required',

    //                                         {
    //                                             className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
    //                                             messages: {
    //                                                 required: 'Please Enter Value',

    //                                             }
    //                                         })}
    //                                 </div>

    //                             </>
    //                         )



    //                         // }

    //                     }
    //                 }

    //             }
    //         },

    //         sortable: true,
    //         width: '150px',
    //     },
    //     {
    //         name: 'Trigger Before Days',
    //         selector: (row, rowIndex) => {
    //             for (var i = 0; i < temparr.length; i++) {
    //                 // for (let i in temparr) {
    //                 if (temparr && temparr.length > 0) {
    //                     //  temparr.map((item, i) => {
    //                     //    setLoopDays(temparr[i].comp_id)
    //                     if (temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {
    //                         return (
    //                             <>
    //                                 <label>{row.s_dates[0].trigger_before_days} </label>
    //                                 {/* {temparr[i].comp_id == row.comp_id ? */}
    //                                 <input name='triggerInputUpdate' maxLength={3} autoComplete={'off'} style={{ width: "80px", height: "35px" }} className="form-control" placeholder='Days'
    //                                     onBlur={(e) => {
    //                                         let temp = []
    //                                         temp.push(e.target.value)
    //                                         if (row.childId in updatedays) {
    //                                             let index = updatedays.indexOf(row)
    //                                             updatedays[row.childId] = [];
    //                                             if (index == -1) {
    //                                                 updatedays[row.childId] = e.target.value;
    //                                             }
    //                                         } else {
    //                                             updatedays[row.childId] = [];
    //                                             updatedays[row.childId] = e.target.value
    //                                         }
    //                                         setUpdatedays([...updatedays])
    //                                         //setUpdatedays([...updatedays, ...temp])
    //                                     }}

    //                                 ></input>
    //                                 {formValidator.current.message(
    //                                     'repeats_every',
    //                                     updatedays[row.childId],
    //                                     'required',

    //                                     {
    //                                         className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
    //                                         messages: {
    //                                             required: 'Please Enter Value',

    //                                         }
    //                                     })}
    //                             </>
    //                         )



    //                         // }

    //                     }
    //                 }

    //             }
    //         },
    //         sortable: true,
    //         width: '160px'
    //     },
    //     {
    //         name: 'Applicable Units',
    //         selector: (row) => {
    //             return <Link style={{ padding: "0px 30px" }} onClick={() => { showModal(row.u_ids) }}>{`${row.u_ids.length}/${selected.length}`}</Link>
    //         },
    //         sortable: true,
    //         width: '150px'
    //     },
    // ];

    const columns = [
        {
            title: <Fragment>
                <input type="checkbox" />
            </Fragment>,
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '80px',
            align: 'center',
            render: (text, record) => {
                return (
                    <Fragment>
                        <input type="checkbox"
                            name={'checked' + record.parentId}
                            onClick={(e) => {
                                let checked = e.target.checked;
                                addSelectedDataValues(
                                    checked,
                                    record.comp_id,
                                    record.childId
                                );
                            }}
                        />
                    </Fragment>
                )
            }
        },
        {
            title: 'Statutory Provision',
            dataIndex: 's_prov',
            key: 's_prov',
            ellipsis: true,
            width: '180px'
        },
        {
            title: 'Compliance Task',
            dataIndex: 'comp_name',
            key: 'comp_name',
            ellipsis: true,
            width: '180px',
        },
        {
            title: 'Repeats By',
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '150px',
            render: (text, record) => {
                if (record.f_repeats_type_id == 1) {
                    repeatsEvery = ' Days'
                } else if (record.f_repeats_type_id == 2) {
                    repeatsEvery = ' Months'
                } else if (record.f_repeats_type_id == 3) {
                    repeatsEvery = ' Years'
                }
                return 'Every ' + record.r_every + repeatsEvery
            }
        },
        {
            title: 'Repeats Every',
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '200px',
            render: (text, row) => {
                for (var i = 0; i < temparr.length; i++) {
                    if (temparr && temparr.length > 0) {
                        if (temparr && temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {
                            return (
                                <>
                                    <label>{row.r_every} </label>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Input name='triggerInputUpdate' min={1} max={6} autoComplete={'off'} maxLength={3} style={{ width: "80px", height: "35px" }} className="form-control" placeholder='Every'
                                                onBlur={(e, i) => {
                                                    let temp1 = []
                                                    temp1.push(e.target.value)
                                                    if (row.childId in updateevery) {
                                                        let index = updateevery.indexOf(row)
                                                        updateevery[row.childId] = [];
                                                        if (index == -1) {
                                                            updateevery[row.childId] = e.target.value;
                                                        }
                                                    } else {
                                                        updateevery[row.childId] = [];
                                                        updateevery[row.childId] = e.target.value
                                                    }
                                                    setUpdateevery([...updateevery])
                                                    // setUpdateevery([...updateevery, ...temp1])
                                                    //  validationFunction(e.target.value, row.childId, row.parentId)                                                                
                                                }}

                                            ></Input>
                                            {formValidator.current.message(
                                                'repeats_every',
                                                updateevery[row.childId],
                                                'required',

                                                {
                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Please Enter Value',

                                                    }
                                                })}
                                        </div>
                                        <div className='col-lg-6'>
                                            <select className='form-control' style={{ height: "35px" }}><option value="1" selected={row.f_repeats_type_id == 1}>Days</option><option value="2" selected={row.f_repeats_type_id == 2}>Months</option><option value="3" selected={row.f_repeats_type_id == 3}>Years</option></select>
                                        </div>
                                    </div>
                                </>


                            )
                        }
                    }
                }
            }
        },
        {
            title: 'Due Date',
            dataIndex: false,
            key: false,
            ellipsis: true,
            width: '150px',
            fixed: 'right',
            render: (text, row) => {
                for (var i = 0; i < temparr.length; i++) {

                    if (temparr && temparr.length > 0) {

                        if (temparr && temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {
                            return (
                                <>
                                    <label>{row.due_date_list[0]} </label>
                                    <div>

                                        <input type='date' name='triggerInput' style={{ width: "120px", height: "35px" }} disabled={temparr != "" ? false : true} className="form-control"
                                            onBlur={(e) => {
                                                let temp = []
                                                temp.push(e.target.value)
                                                if (row.childId in updateduedate) {
                                                    let index = updateduedate.indexOf(row)
                                                    updateduedate[row.childId] = [];
                                                    if (index == -1) {
                                                        updateduedate[row.childId] = e.target.value;
                                                    }

                                                } else {
                                                    updateduedate[row.childId] = [];
                                                    updateduedate[row.childId] = e.target.value
                                                }
                                                setUpdateduedate([...updateduedate])
                                                // setUpdateduedate([...updateduedate, ...temp])
                                            }}


                                        ></input>
                                        {formValidator.current.message(
                                            'repeats_every',
                                            updateduedate[row.childId],
                                            'required',

                                            {
                                                className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Value',

                                                }
                                            })}
                                    </div>

                                </>
                            )



                            // }

                        }
                    }
                }
            }
        },
        {
            title: 'Trigger Before Days',
            dataIndex: false,
            ellipsis: true,
            width: '150px',
            fixed: 'right',
            render: (text, row) => {
                for (var i = 0; i < temparr.length; i++) {
                    // for (let i in temparr) {
                    if (temparr && temparr.length > 0) {
                        //  temparr.map((item, i) => {
                        //    setLoopDays(temparr[i].comp_id)
                        if (temparr && temparr.filter(e => (e.comp_id == row.comp_id)).length > 0) {
                            return (
                                <>
                                    <label>{row.s_dates[0].trigger_before_days} </label>
                                    {/* {temparr[i].comp_id == row.comp_id ? */}
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Input name='triggerInputUpdate' maxLength={3} autoComplete={'off'} style={{ width: "80px", height: "35px" }} className="form-control" placeholder='Days'
                                                onBlur={(e) => {
                                                    let temp = []
                                                    temp.push(e.target.value)
                                                    if (row.childId in updatedays) {
                                                        let index = updatedays.indexOf(row)
                                                        updatedays[row.childId] = [];
                                                        if (index == -1) {
                                                            updatedays[row.childId] = e.target.value;
                                                        }
                                                    } else {
                                                        updatedays[row.childId] = [];
                                                        updatedays[row.childId] = e.target.value
                                                    }
                                                    setUpdatedays([...updatedays])
                                                    //setUpdatedays([...updatedays, ...temp])
                                                }}

                                            ></Input>
                                            {formValidator.current.message(
                                                'repeats_every',
                                                updatedays[row.childId],
                                                'required',

                                                {
                                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Please Enter Value',

                                                    }
                                                })}
                                        </div>
                                    </div>
                                </>
                            )



                            // }

                        }
                    }

                }
            }
        },
        {
            title: 'Applicable Units',
            dataIndex: false,
            ellipsis: true,
            width: '150px',
            fixed: 'right',
            render: (text, row) => {
                return (
                    <Link style={{ padding: "0px 30px" }} onClick={() => { showModal(row.u_ids) }}>{`${row.u_ids.length}/${all.length}`}</Link>
                )
            }
        },
    ];

    //Store Select Into array in state
    const [all, setAll] = useState([]);
    const [selectcheckbox, Setselectcheckbox] = useState('block');
    const [unselectcheckbox, Setunselectcheckbox] = useState('none');

    const HandelChange = (obj) => {
        let allArray = [];
        if (obj.includes('all')) {
            if (list && list.unitlist && list.unitlist.length > 0) {
                let temp = []
                for (let i in list.unitlist) {
                    let unitFinalCode = list.unitlist[i].u_code + ' ' + list.unitlist[i].u_name + ' ' + list.unitlist[i].address
                    temp.push(list.unitlist[i].u_id)
                    allArray.push(list.unitlist[i].u_id)

                }
                setAll(temp)
            }
            Setselectcheckbox('none');
            Setunselectcheckbox('block');
        } else if (obj.includes('unselect')) {
            let temp = [];
            setAll(temp);
            Setselectcheckbox('block');
            Setunselectcheckbox('none');
        } else {
            setAll(obj)
        }
        setUserDetails({
            ...userDetails,
            units: (allArray && allArray.length > 0 ? allArray : obj)
        })
        return obj;

    };

    const savevalue = () => {

    }

    const showDetailsOnSubmit = () => {
        setAddFormSubmit(true);
        setUnitFromSubmit(false);
        if (validator.current.allValid()) {
            setSelectShow(true)
            const unitlist = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetReviewSettingsUnitFilters",
                        {
                            "le_id": entityid,
                            "d_id": parseInt(userDetails.domain),
                            "tz_id": parseInt(userDetails.timezone),
                        }
                    ]
                }
            ]
            reviewunitlist({
                payload: unitlist,
                paramid: paramid
            })
        }
    }
    const userDetailOnSubmit = () => {
        setAddFormSubmit(false);
        setUnitFromSubmit(true);
        if (validator1.current.allValid()) {
            setSelectUnit(true)

            setValue('2')
            const showreviewsettinglist = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetReviewSettingsComplianceFilters",
                        {
                            "le_id": entityid,
                            "d_id": parseInt(userDetails.domain),
                            "unit_ids": userDetails.units,
                            "f_id": parseInt(userDetails.compliancefrequency),
                            "sno": 0
                        }
                    ]
                }
            ]
            reviewshowsettinglist({
                payload: showreviewsettinglist,
                paramid: paramid
            })
        }

    }

    const previous = () => {
        setValue('1')
    }

    const saveRecords = () => {
        setAddFormSubmit1(true)
        if (formValidator.current.allValid()) {
            const savecompliancelist = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request":

                        [
                            "SaveReviewSettingsCompliance",

                            {
                                "le_id": entityid,
                                "rs_compliances": assignpayload

                            }
                        ]
                }
            ]
            if (temparr.length > 0) {
                reviewsavecompliance({
                    payload: savecompliancelist,
                    paramid: paramid
                })
            } else {
                toast.warning("Select any one")
            }


        }

    }


    useEffect(() => {

        if (temparr && temparr.length > 0) {
            let tempArray = []
            for (let i in temparr) {
                let getChildId = _.filter(data, { comp_id: temparr[i].comp_id })
                let childIdCurrent = getChildId[0].childId;
                tempArray.push({
                    "comp_id": temparr[i].comp_id,
                    "le_id": entityid,
                    "d_id": parseInt(userDetails.domain),
                    "f_id": parseInt(userDetails.compliancefrequency),
                    "unit_ids": userDetails.units,
                    "repeat_by": updateevery[childIdCurrent] == '' ? temparr[i].r_every : parseInt(updateevery[childIdCurrent]),//temparr[i].r_every !== null ? updateevery[childIdCurrent] == '' ? temparr[i].r_every : parseInt(updateevery[childIdCurrent]) : parseInt(trigger[0].triggerInput),
                    "due_date": moment(updateduedate[childIdCurrent] == '' ? temparr[i].due_date_list[0] : updateduedate[childIdCurrent]).format("DD-MMM-YYYY"),//moment(temparr[i].due_date_list[0] != null ? updateduedate[childIdCurrent] == '' ? temparr[i].due_date_list[0] : (updateduedate[childIdCurrent]) : (trigger[0].triggerInput)).format("DD-MMM-YYYY"),
                    "repeat_type_id": temparr[i].f_repeats_type_id,
                    "trigger_before_days": updatedays[childIdCurrent] == '' ? temparr[i].s_dates[0].trigger_before_days : parseInt(updatedays[childIdCurrent]),//temparr[i].s_dates[0].trigger_before_days != null ? updatedays[childIdCurrent] == '' ? temparr[i].s_dates[0].trigger_before_days : parseInt(updatedays[childIdCurrent]) : parseInt(trigger[0].triggerInput),
                    "statu_dates": [
                        {
                            "statutory_date": updateduedate[childIdCurrent] == '' ? temparr[i].f_s_dates[0].statutory_date : parseInt(moment(updateduedate[childIdCurrent]).format("DD")),//temparr[i].f_s_dates[0].statutory_date != null ? updateduedate[childIdCurrent] == '' ? temparr[i].f_s_dates[0].statutory_date : parseInt(moment(updateduedate[childIdCurrent]).format("DD")) : parseInt(trigger[0].triggerInput),
                            "statutory_month": parseInt(moment(updateduedate[childIdCurrent]).format("MM")),
                            "trigger_before_days": updatedays[childIdCurrent] == '' ? temparr[i].s_dates[0].trigger_before_days : parseInt(updatedays[childIdCurrent]),//temparr[i].f_s_dates[0].trigger_before_days != null ? updatedays[childIdCurrent] == '' ? temparr[i].f_s_dates[0].trigger_before_days : parseInt(updatedays[childIdCurrent]) : parseInt(trigger[0].triggerInput),
                            "repeat_by": temparr[i].f_s_dates[0].repeat_by,
                        }
                    ],
                    "old_repeat_by": temparr[i].r_every,
                    "old_repeat_type_id": temparr[i].repeats_type_id,
                    "old_due_date": null,
                    "old_statu_dates": [{
                        "statutory_date": temparr[i].s_dates[0].statutory_date,
                        "statutory_month": temparr[i].s_dates[0].statutory_month,
                        "trigger_before_days": temparr[i].s_dates[0].trigger_before_days,
                        "repeat_by": temparr[i].s_dates[0].repeat_by,
                    }],
                })
            }
            setAssignPayload(tempArray)
        }
    }, [temparr, updatedays, updateduedate, updateevery, entityid])


    useEffect(() => {
        validator.current.showMessages()
        validator1.current.showMessages()
        formValidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            const payload = [

                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetReviewSettingsFilters",
                        {
                            "le_id": entityid,
                            "div_id": null,
                            "cat_id": null,
                        }
                    ]
                }
            ]
            if (entityid != 'null') {
                reviewlist({
                    payload: payload,
                    paramid: paramid
                })
            }

        }
    }, [isAuth, entityid])

    useEffect(() => {
        if (list && list.unitlist && list.unitlist.length > 0) {
            let temp = []
            for (let i in list.unitlist) {
                let unitFinalCode = list.unitlist[i].u_code + ' ' + list.unitlist[i].u_name + ' ' + list.unitlist[i].address
                temp.push({
                    label: unitFinalCode,
                    value: list.unitlist[i].u_id
                })

            }
            setOptions(temp)
        }


    }, [list.unitlist])

    useEffect(() => {
        if (selected && selected.length > 0) {

            let tempIds = []
            let tempValues = []
            for (let i in selected) {
                tempIds.push(selected[i].value)
                tempValues.push(selected[i].label)

            }
            setUserDetails({
                ...userDetails,
                units: tempIds
            })
            setSelectedUnitValues(tempValues)
            setCurrentUnits(tempValues)
        }

    }, [selected])



    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                    <Link>Review Settings</Link>
                                </li>
                            </ol>
                        </nav>

                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-md-12">
                                    <Tabs activeKey={value} onChange={showDetailsOnSubmit} type="card">
                                        <TabPane tab="Select Unit & Domain" key="1">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Business Group</b></label>  <span style={{ "color": "red" }}>*</span>
                                                            &nbsp;&nbsp;-
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-3">
                                                            <label htmlFor=""><b>Legal Entity</b></label> <span style={{ "color": "red" }}>*</span><br />
                                                            {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                <div className="form-group">
                                                                    <Select
                                                                        allowClear={true}
                                                                        size="default"
                                                                        placeholder="Select Legal Entity"
                                                                        onChange={(data, value) => {
                                                                            setCurrentEntity(data)
                                                                            setUserDetails({
                                                                                ...userDetails,
                                                                                legal_entity_id: data,
                                                                                legal_entity_name: value.children
                                                                            })
                                                                        }}
                                                                        showSearch
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                        //  value={userDetails.legal_entity_id}
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                            return (
                                                                                <Option key={item.le_id}>
                                                                                    {item.le_name}
                                                                                </Option>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                    {validator.current.message(
                                                                        'legal_entity_id',
                                                                        userDetails.legal_entity_id,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Required Field Missing',
                                                                            }
                                                                        })}
                                                                </div> :
                                                                <p>{localStorage.getItem('SelectedEntity')}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Compliance Frequency</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Compliance Frequency"
                                                                    name="compliancefrequency"
                                                                    className="form-control"
                                                                    id="compliancefrequency"
                                                                    onChange={(data, value) => {
                                                                        setUserDetails({
                                                                            ...userDetails,
                                                                            compliancefrequency: data
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '100%' }}
                                                                >
                                                                    {list.compliance_frequency && list.compliance_frequency.length > 0 &&
                                                                        list.compliance_frequency.map((finalList) => (
                                                                            <Option key={finalList.frequency_id}>{finalList.frequency}</Option>
                                                                        ))}

                                                                </Select>
                                                                {validator.current.message(
                                                                    'compliancefrequency',
                                                                    userDetails.compliancefrequency,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Required Field Missing',
                                                                        }
                                                                    })}
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Domain</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                <Select
                                                                    allowClear={true}
                                                                    size="default"
                                                                    placeholder="Select Domain"
                                                                    name="domain"
                                                                    className='form-control'
                                                                    id="domain"
                                                                    onChange={(data, value) => {
                                                                        setUserDetails({
                                                                            ...userDetails,
                                                                            domain: data,
                                                                            domainName: value.children//e.target[e.target.selectedIndex].text
                                                                        })
                                                                    }}>
                                                                    {list.domain_list && list.domain_list.length > 0 &&
                                                                        list.domain_list.map((finalList_domain) => (
                                                                            <Option key={finalList_domain.d_id}>{finalList_domain.d_name}</Option>
                                                                        ))}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'domain',
                                                                    userDetails.domain,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Required Field Missing',
                                                                        }
                                                                    })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor=""><b>Time Zone</b></label>  <span style={{ "color": "red" }}>*</span>
                                                            <Select
                                                                name="timezone"
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Time Zone"
                                                                className='form-control'
                                                                id="timezone"
                                                                onChange={(data) => {
                                                                    setUserDetails({
                                                                        ...userDetails,
                                                                        timezone: data
                                                                    })
                                                                }}>
                                                                {list.time_zone_le && list.time_zone_le.length > 0 &&
                                                                    list.time_zone_le.map((finalList_timezone) => (
                                                                        <Option key={finalList_timezone.tz_id}>{finalList_timezone.tz_name}</Option>
                                                                    ))}
                                                            </Select>
                                                            {validator.current.message(
                                                                'timezone',
                                                                userDetails.timezone,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })}
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6" style={{ "marginTop": "1%" }}>
                                                        <div className="form-group">
                                                            <button className='btn btn-info' onClick={showDetailsOnSubmit}>Show</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Divider />
                                            <div className="col-md-12" style={{ display: selectShow ? 'block' : 'none' }} >
                                                <div className="col-md-12 mt-4">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <label htmlFor=""><b>Units</b></label>  <span style={{ "color": "red" }}>*</span>
                                                            <Select
                                                                name="units"
                                                                id="units"
                                                                className='form-control'
                                                                onChange={(option) => HandelChange(option)}
                                                                mode="multiple"
                                                                value={all}
                                                                maxTagCount='responsive'
                                                            >
                                                                <Option key="all" value="all" style={{ display: selectcheckbox }}>---SELECT ALL---</Option>
                                                                <Option key="unselect" value="unselect" style={{ display: unselectcheckbox }}>--UNSELECT ALL--</Option>
                                                                {list.unitlist && list.unitlist.length > 0 &&
                                                                    list.unitlist.map((finalList_unitlist) => (
                                                                        <Option value={finalList_unitlist.u_id}>{finalList_unitlist.u_code + ' ' + finalList_unitlist.u_name + ' ' + finalList_unitlist.address}</Option>
                                                                    ))}
                                                            </Select>
                                                            {/* <MultiSelect
                                                                options={options}
                                                                value={selected}
                                                                onChange={setSelected}
                                                                labelledBy="Select Units"
                                                                menuPlacement="top"

                                                            /> */}
                                                            {validator1.current.message(
                                                                'units',
                                                                userDetails.units,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${unitFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })}

                                                        </div>
                                                        <div className='col-md-2' style={{ marginLeft: '90%', marginTop: '3%' }} >
                                                            <button className='btn btn-info' onClick={userDetailOnSubmit}>Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </TabPane>
                                        <TabPane tab="Date Settings" disabled={selectUnit === true ? false : true} key="2">
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        &nbsp;<p style={{ "fontWeight": "bold" }}>{(userDetails.legal_entity_name) ? userDetails.legal_entity_name : localStorage.getItem('SelectedEntity')}&nbsp;<span>{"-"}</span>&nbsp;{userDetails.domainName}</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">

                                                    <label><b>Domain Name:</b><span className='mx-2'>{userDetails.domainName}</span></label>

                                                </div>
                                                <div className="col-md-6">

                                                    <label><b>Timeline:</b><span className='mx-2'>{list.timeline}</span></label>

                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="row">
                                                    <Collapse defaultActiveKey={['0']}>
                                                        {temp.tempData.length > 0 &&
                                                            temp.tempData.map((finalList) => (

                                                                <Panel header={finalList.parentData} extra={<span id={'parentid-' + finalList.index} style={{ display: 'none' }}>{finalList.index}</span>} key={finalList.index}>
                                                                    {/* <DataTable
                                                                        columns={columns}
                                                                        data={finalList.child}
                                                                        fixedHeader={dataTableProperties.fixedHeader}
                                                                    /> */}
                                                                    <Table
                                                                        columns={columns}
                                                                        dataSource={finalList.child}
                                                                        bordered
                                                                        scroll={{ x: 1500 }}
                                                                        pagination={false}
                                                                        onHeaderRow={(columns, index) => {
                                                                            return {
                                                                                onClick: (e) => {
                                                                                    let checked = e.target.checked;
                                                                                    addAllSelectedDataValues(
                                                                                        checked, finalList.index, finalList.parentData
                                                                                    );
                                                                                }
                                                                                
                                                                            };
                                                                        }}

                                                                    
                                                                    />

                                                                </Panel>


                                                            ))}
                                                    </Collapse>
                                                    {/* <Collapse defaultActiveKey={['0']}>
                                                        {temp.tempData.length > 0 &&
                                                            temp.tempData.map((finalList) => {
                                                                finalList.child = finalList.child.map((item) => {
                                                                    return {
                                                                        ...item,
                                                                        frequency: '',
                                                                        value: ''
                                                                    }
                                                                })
                                                                return <Panel header={finalList.parentData} key={finalList.index}>
                                                                    <DataTable
                                                                        columns={columns}
                                                                        data={finalList.child}
                                                                        fixedHeader={dataTableProperties.fixedHeader}
                                                                    />

                                                                </Panel>
                                                            }


                                                            )}

                                                    </Collapse> */}
                                                </div>
                                            </div>
                                            <div className='col-md-2' style={{ marginRight: '90%', marginTop: '2%' }} >
                                                <button className='btn btn-info' onClick={previous}>Previous
                                                </button>
                                            </div>
                                            <div className='col-md-2' style={{ marginLeft: '90%', marginTop: '-4%' }} >
                                                <button className='btn btn-info' onClick={saveRecords}>Submit
                                                </button>
                                            </div>
                                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                                <div className='row'>
                                                    <div className='col-md-3'><label><b>Selected Compliance: </b><span>{temparr.length}</span></label></div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>

                                </div>

                            </div>
                        </div>
                    </div>
                    <Modal title="Applicable Units" visible={isModalVisible} onCancel={handleCancel} footer={null}>
                        <ul>
                            {currentUnits.map((childdetails, index) => {
                                return <li>{childdetails[0].u_code + "-" + childdetails[0].u_name + "-" + childdetails[0].address}</li>

                            })}
                        </ul>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, {
    reviewlist,
    reviewunitlist,
    reviewshowsettinglist,
    reviewsavecompliance
})(Reviewsetting);
