import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { COMPLIANCE_APPROVAL_LIST,COMPLIANCE_APPROVAL_FILTER_LIST ,TOGGLE_COMMON_LOADER} from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';


export const complianceApprovallist = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        // console.log(payload);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: COMPLIANCE_APPROVAL_LIST,
            payload: decryptData,
        });
    }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getComplianceFilters = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        // console.log(payload);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
      
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: COMPLIANCE_APPROVAL_FILTER_LIST,
            payload: decryptData,
        });
    }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const approveCompliance = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }
        else if(decryptData[0] == 'ApproveComplianceSuccess'){
            Toaster.success('Compliance Approved Succcessfully!')
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const UploadFile = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
   
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/files`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
       
        if(decryptData[0]=='FileUploadSuccess'){
            Toaster.success("File Upload Successfully"
        )
        }
        else
        {
            Toaster.error("Failed")
        }
        
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }
        // dispatch({
        //     type: COMPLETED_TASK_LIST,
        //     payload: decryptData,
        // });

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
