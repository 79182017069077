import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import {APPROVE_TASK_RECALL,TOGGLE_COMMON_LOADER,APPROVE_TASK_RECALL_LIST} from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';




export const ApproveTaskRecallUnitList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)        
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        console.log(decryptData,'decryptData');
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        dispatch({
            type: APPROVE_TASK_RECALL,
            payload: decryptData[1],
        });
     }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getApproveTaskRecallList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        console.log(executeRequest,'executeReq')
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        console.log(decryptData,'decryptData');
        console.log(decryptData[0],'decryptData[0]');
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } 
        else{
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
        dispatch({
            type: APPROVE_TASK_RECALL_LIST,
            payload: decryptData[1],
        });
     }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const ApproveTaskRecallSubmit = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        console.log(decryptData,'decryptData123');
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } 
        else if(decryptData[0] == 'InvalidPassword'){
            Toaster.error('InvalidPassword')
        }
        else if(decryptData[0] == 'TaskRecallSuccess'){
            Toaster.success('Task recall approved successfully!')
        }
       
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


