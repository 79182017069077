import HTTP from "../../Libs/http";
import { Toaster } from "../../Libs/Toaster";
import url from "../../Libs/URL";
import EncryptDecrypt from "../../Libs/Encrypt&Decrypt";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { TOGGLE_COMMON_LOADER } from "../types";


export const forgotPassword = ({payload, executeCancel}) => async dispatch => {
    try{
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const forgotpayload = [
            payload.groupName,
            [
                'ForgotPassword', {
                    'username': payload.userId,
                    'short_name': payload.groupName,
                    'login_type': 'Web'
                }
            ]
        ]
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info':encoded
            },
        }
        const payloadData = EncryptDecrypt.encryptdata(forgotpayload,'7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: payloadData
        })
        
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response,'7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if(decryptData[0] === 'ForgotPasswordSuccess' || decryptData[0] === 'ForgotLinkHistory'){

        }else{
            Toaster.error(decryptData[0])
        }
        

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};