import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { UNIT_CLOSURE_LIST, TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from './../../../Libs/Altermessage';



export const getUnitClosureList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload);
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
        if (decryptData[0] == 'GetUnitClosureUnitDataSuccess') {

            dispatch({
                type: UNIT_CLOSURE_LIST,
                payload: decryptData,
            });
        } else if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            Toaster.error(decryptData[0])
        }

    } catch (err) {
        Toaster.error(err)
    }
};
export const saveUnitClosureData = ({ payload, executeCancel, paramid, entityid }) => async dispatch => {
    try {
        console.log(payload);
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }
        else if (decryptData[0] == "InvalidPassword") {
            Toaster.error("InvalidPassword!!")
        } else {
            Toaster.success(decryptData[0])
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        }

        // dispatch({
        //     type: UNIT_CLOSURE_LIST,
        //     payload: decryptData,
        // });

    } catch (err) {
        Toaster.error(err)
    }
};


