import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom'
import { Modal, Input, Table, Button, Space, Tooltip, Select } from 'antd';
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { getUnitClosureList, saveUnitClosureData } from './../../Store/Action/Master/UnitClosure'
import { InfoCircleOutlined, PlusSquareTwoTone, UserAddOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import SimpleReactValidator from 'simple-react-validator';
const mapStateToProps = (state) => ({
    unitClosureList: state.UnitClosure.UnitClosure,
    filterrecall: state.recallfilters.recallfilters

});
const UnitClosure = ({
    saveUnitClosureData,
    getUnitClosureList,
    unitClosureList,
    filterrecall: { recallremarks },
    GetRemarksData

}) => {
    const { Option } = Select;
    const location = useLocation();
    const paramid = location.pathname;
    const formValidator = useRef(new SimpleReactValidator());
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [remarks, setRemarks] = useState('')
    //const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const { Search } = Input;
    const [show, setShow] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [FormSubmit, setFormSubmit] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        unit_id: '',
        legal_entity: ''

    })
    const [remarksvalue, Setremarksvalue] = useState();
    const [page, setPage] = useState(1);
    const [modalVisible, setModaVisible] = useState(false)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])
    const columns = [
        {
            title: 'Business Group',
            dataIndex: 'business_group_name',
            key: 'business_group_name',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ? text : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: 'Legal Entity',
            dataIndex: 'legal_entity_name',
            key: 'legal_entity_name',
            ellipsis: true,
        },
        {
            title: 'Division',
            dataIndex: 'division_name',
            key: 'division_name',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Fragment>
                        {text != null ? text : '-'}
                    </Fragment>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            ellipsis: true,
        },
        {
            title: 'Unit',
            dataIndex: 'unit_code',
            key: 'unit_code',
            ellipsis: true,
            width: '200px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <Tooltip title={`${record.address},${record.postal_code}`}>
                            <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{text}-{record.unit_name}</p>
                        </Tooltip>
                    </Fragment>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            key: 'is_active',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Fragment>
                        {
                            text == 0 ? "Active" : "In-Active"
                        }
                    </Fragment>
                )
            }
        },
        {
            title: <><label>Actions</label><Tooltip className='toolTipClass2' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"This Actions Includes UnitClose and Re-Active"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'is_active',
            className: 'actiontd',
            align:'center',
            render: (text, record) => {
                return (
                    <Fragment>
                        {text == 0 ?
                            <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                            align-items-center justify-content-center" onClick={() => {
                                    setShow(true)
                                    closeById(record.unit_id, "closed");
                                }}>
                                <Tooltip title="Close" color='#39cb7f'><CloseOutlined /></Tooltip></span>
                            :
                            <span className="btn btn-sm btn-light-danger text-danger btn-circle d-flex 
                            align-items-center justify-content-center"
                                onClick={() => {
                                    setModalData({
                                        password: '',
                                        reason: ''
                                    })
                                    setShow(true)
                                    closeById(record.unit_id, "reactive");
                                }}>
                                <Tooltip title="Reactive" color='#fc4b6c'><UserAddOutlined /></Tooltip></span>}
                    </Fragment>
                )
            }
        }
    ];

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '10px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ]

    const OnSubmited = () => {
        setAddFormSubmit(true)

        if (formValidator.current.allValid()) {
            setModaVisible(false)
            saveUnitClosureData({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "SaveUnitClosureData",
                                {
                                    "password": modalData.password,
                                    "closed_remarks": remarks,
                                    "unit_id": Number(modalData.unit_id),
                                    "grp_mode": modalData.reason == 'closed' ? "close" : "reactive",
                                    "legal_entity_id": entityid
                                }
                            ]
                        }
                    ],
                paramid: paramid,
                entityid: entityid
            })
            setModalData({
                ...modalData,
                password: ""
            })
            setRemarks("")
        }
    }

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    useEffect(() => {
        formValidator.current.showMessages()
    }, [])
    const passwordcancel = () => {
        setModaVisible(false)
        setModalData({
            ...modalData,
            password: ""
        })
        setRemarks("")
        setAddFormSubmit(false)
    }
    const handleOk = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const handleCancel = () => {
        // setIsModalVisible(false);
        setRemarksModal(false)
    };

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnitClosureUnitData",
                        {
                            "legal_entity_id": entityid,

                        }
                    ]
                }
            ]
            if (entityid != "null") {
                getUnitClosureList({
                    payload: payload,
                    paramid: paramid
                })
            }

        }
    }, [isAuth, entityid])

    const closeById = (unit_id, type) => {

        Swal.fire({
            title: 'Do you want to Close?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setModaVisible(true)
                setModalData({
                    ...modalData,
                    unit_id: unit_id,
                    reason: type
                })


                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };

    useEffect(() => {
        if (modalData.legal_entity == '' || null || undefined) {
            setModalData({
                ...modalData,
                legal_entity: entityid
            })

        }
    }, [modalData.legal_entity])


    const [baseData, setBaseData] = useState([]);

    useEffect(() => {
        if (unitClosureList && unitClosureList.UnitClosureList && unitClosureList.UnitClosureList.length > 0) {
            setBaseData(unitClosureList.UnitClosureList);
        }
    }, [unitClosureList])

    const searchRecords = (e) => {
        const filterTable = unitClosureList.UnitClosureList.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        setBaseData(filterTable)
    };
    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>Master</Link>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <Link>Unit Closure </Link></li>
                                    </ol>
                                </nav>
                                <div style={{"margin-top": "10px"}}>
                                <label style={{"font-weight":'bold'}}>Legal Entity</label>
                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                    <div className="form-group" style={{"margin-top": '-24px',"margin-left": '86px'}}>
                                        <Select
                                            allowClear={true}
                                            size="default"
                                            style={{width:'300px'}}
                                            placeholder="Select Legal Entity"
                                            onChange={(data, value) => {
                                                setCurrentEntity(data)
                                            }}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        >
                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                return (
                                                    <Option key={item.le_id}>
                                                        {item.le_name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </div> :
                                    <span className='ms-2'>{localStorage.getItem('SelectedEntity')}</span>
                                }
                            </div>
                            </div>
                           
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                <div className="search-box cusMargin">
                                    <input className="search-txt" type="text" onChange={searchRecords} placeholder="Type to Search" />
                                    <a className="search-btn">
                                        <i className="fas fa-search"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body" style={{ padding: '0px' }}>
                                        <Table
                                            columns={columns}
                                            className='userprivclass'
                                            dataSource={baseData}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            }}
                                        // onChange={handleChange} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
            <Modal visible={modalVisible} footer={null}
                onCancel={passwordcancel} maskClosable={false}
            >
                <div className="row">
                    <div className="col-md-12">
                        <div className="row pt-3">
                            <div className="col-md-6">
                                <div className='mb-2'>
                                    <label className="control-label">Password:</label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <input
                                        type="password"
                                        id="password"
                                        name='password'
                                        className="form-control"
                                        placeholder="Enter password"
                                        value={modalData.password}
                                        onChange={(e) => {
                                            setModalData({
                                                ...modalData,
                                                password: e.target.value
                                            })
                                        }}
                                    //   value={password.passwordvalue}
                                    />
                                    {formValidator.current.message(
                                        'password',
                                        modalData.password,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter Password',
                                            }
                                        })}

                                </div>

                            </div>
                            <div className='col-md-5'>
                                <div className="mb-2">

                                    <label className="control-label">Reason:</label>

                                    <span style={{ "color": "red" }}>*</span>
                                    {/* <div className="col-md-1" style={{ float: 'right' }}>
                                        <PlusSquareTwoTone onClick={() => { showRemarksModal() }} style={{ marginLeft: '100%', fontSize: "20px" }}></PlusSquareTwoTone> */}
                                    {/* <i className="ri-add-box-fill" onClick={() => { showRemarksModal() }} style={{ marginLeft: '650%' }}></i> */}
                                    {/* </div> */}

                                    <textarea className='form-control'
                                        onChange={(e) => {
                                            setRemarks(e.target.value)
                                        }}
                                        value={remarks}

                                    ></textarea>
                                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" onClick={() => { showRemarksModal() }}>
                                        <PlusOutlined />
                                    </span>

                                    {formValidator.current.message(
                                        'reason',
                                        remarks,
                                        'required',
                                        {
                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Please Enter Reason',
                                            }
                                        })}




                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="form-actions">
                    <button
                        className="btn btn-light-success rounded-pill px-4 text-success"
                        onClick={OnSubmited}
                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-light-danger text-danger px-4 ms-2 font-weight-medium
               waves-effect text-start"
                        onClick={passwordcancel}
                    >
                        Cancel
                    </button>
                </div>

            </Modal>
            <Modal title="Remarks" visible={remarksModal}
                onOk={handleOk}
                onCancel={handleCancel} className="remarksClass">
                <Table
                    className='userprivclass'
                    columns={RemarksColumn}
                    dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                    bordered
                    pagination={{
                        defaultPageSize: dataTableProperties.pagesize,
                        showSizeChanger: dataTableProperties.sizechanger
                    }}
                />
            </Modal>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    getUnitClosureList,
    GetRemarksData,
    saveUnitClosureData
})(UnitClosure);