import React from 'react'
import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { Modal, Input, Card, Collapse } from 'antd';
import _, { forIn, indexOf } from 'lodash';
import DataTable from "react-data-table-component";
import { UserAddOutlined } from '@ant-design/icons';
import { settingsfilterlist, settingslist, addsettings } from './../../Store/Action/Transcations/Criticalitysettings'
import { Select } from 'antd';
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';
import { logDOM } from '@testing-library/react';


const { Option } = Select;
const mapStateToProps = (state) => ({
    filterdatas: state.CriticalitySettings.criticalitysettings.filterdata,
    lists: state.CriticalitySettings.criticalitysettings.list
});

const CriticalitySetting = ({
    settingsfilterlist,
    settingslist,
    filterdatas,
    lists,
    addsettings

}) => {
    const [show, setShow] = useState(false)
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Search } = Input;
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [Settings, SetSettings] = useState({
        le_id: '',
        domain_id: '',
        statutory_nature: '',
        sm_record: ''
    })
    const [criticalitylist, setCriticalityList] = useState([])
    const [legal, setLegal] = useState(false)
    const [compid, setCompId] = useState([])
    const [statutorydata, setStatutorydata] = useState([])
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [category, setCategory] = useState([])
    const [titledata, Settitledata] = useState({
        displauvalue: ''
    })
    const [select, setSelect] = useState({
        rowIndex: '',
        value: '',
        comp_value: ''
    })
    const [criticality, setCriticality] = useState({
        criticalityValue: '',
        criticalityIndex: ''
    })
    const [remarksdata, setRemarksData] = useState([])
    const [tempState, setTempState] = useState([])
    const [remarkstext, Setremarkstext] = useState([])
    const [genExtraValue, setGenExtraValue] = useState('select')
    const [collapseindex, setCollapseIndex] = useState([])
    const [children, setChildren] = useState({
        child: [],
        collapse_index: '',
        listIndex: ''
    })
    const [tempChild, setTempChild] = useState({
        tempChildren: [],
        collapseIndex: ''
    })
    // const [genExtraSelect, setGenExtraSelect] = useState({
    //     value: [],
    //     collapseIndex: []
    // })
    const [genExtraSelect, setGenExtraSelect] = useState([])
    const [uniqueGenExtra, setUniqueGenExtra] = useState([])
    const [indexCollapse, setIndexCollapse] = useState([])
    const [targetValue, setTargetValue] = useState([])
    const [listChild, setListChild] = useState([])

    const columns = [
        {
            name: "Statutory Provision",
            cell: (column) => {
                return (
                    <p style={{ marginTop: 0 }}>{column.statutory_provision}</p>
                )
            },
            // selector: (row) => row.statutory_provision,
            sortable: true,
            width: '300px',
        },
        {
            name: "Compliance Task",
            cell: (column) => {
                return (
                    <p >{column.compliance_task}</p>
                )
            },
            // selector: (row) => row.compliance_task,
            width: '250px',
            sortable: true,
        },
        {
            name: "Compliance Frequency",
            selector: (row) => row.frequency,
            sortable: true,
            width: '250px',
        },
        {
            name: "Panel Consequences",
            cell: (column) => {
                return (
                    <p >{column.penal_consequences}</p>
                )
            },
            // selector: (row) => row.penal_consequences,
            sortable: true,
            width: '350px',
        },
        {
            name: "Applicable/Total units",
            selector: (row) => row.unit_id,
            sortable: true,
            width: '250px',
        },
        {
            name: <UserAddOutlined />,
            selector: (row) => row.criticality,
            sortable: true,
            width: '100px',
        },
        {
            name:
                <span
                    className="btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                    <i className="ri-edit-line"></i>
                </span>,

            sortable: true,
            width: '150px',
            selector: (row, rowIndex) => {
                return (
                    <div>
                        <select name="option" className='form-control' onChange={(e) => { selectOnChange(e, row, rowIndex) }}>
                            <option value="select">--Select--</option>
                            {lists.criticality_list.map((item) => {
                                return (
                                    <>
                                        <option>{item.criticality_name[0]}</option>
                                    </>
                                )
                            })}
                        </select>
                    </div>
                )
            }
        },
        {
            name: "Remarks",
            sortable: true,
            width: '250px',
            selector: (row, rowIndex) => {
                if (compid && compid.length) {
                    console.log(compid, 'compid');
                    if ((compid && compid.indexOf(row.compliance_id) >= 0)) {
                        console.log('sneha');
                        return <> <input placeholder='Enter remarks' className='form-control' style={{ "width": "80%" }}
                            onBlur={(e) => {
                                let tempArray = [];
                                for (let i in remarksdata) {
                                    if (remarksdata[i].rowIndex == rowIndex) {
                                        console.log(remarksdata[i], 'remarksdataremarksdata');
                                        tempArray.push({
                                            compliance_id: row.compliance_id,
                                            remarksindex: rowIndex,
                                            criticality_id: remarksdata[i].value === 'A' ? 1 : remarksdata[i].value === 'B' ? 2 : 3,
                                            remarksvalue: e.target.value
                                        })
                                    }
                                }
                                setTempState([...tempState, ...tempArray])
                            }}
                            hidden={(select.rowIndex == rowIndex && select.value == criticality.criticalityValue || select.rowIndex == rowIndex && select.value == 'select') ? true : false}
                        />
                        </>
                    }
                }
                else if (tempChild.tempChildren && tempChild.tempChildren.length) {
                    for (let i in tempChild.tempChildren) {
                        if (tempChild.tempChildren[i].compliance_id === row.compliance_id) {
                            for (let k in genExtraSelect) {
                                for (let l = 0; l <= remarksdata.length; l++) {
                                    if (((tempChild.tempChildren[i].statutory_name) == (genExtraSelect && genExtraSelect[k].collapseIndex))) {
                                        // if (((tempChild && tempChild.tempChildren && tempChild.tempChildren[i].statutory_name) == (remarksdata && remarksdata.length > 0 && remarksdata[l].statutoryName))) {
                                        return <>  <input type="text" className="form-control" placeholder="Enter Remarks"
                                            hidden={((tempChild.tempChildren[i].criticality) == (genExtraSelect && genExtraSelect[k].value))}
                                            // value={remarksdata && remarksdata.length > 0 && remarksdata.map((item,i)=>{
                                            //     if(((remarksdata[i].statutoryName) == (tempChild.tempChildren[i].statutory_name))){
                                            //         return remarksdata[i].remarks
                                            //     }
                                            // })}
                                            // value={()=>{
                                            //     for(let i in remarksdata){
                                            //         console.log('hello');
                                            //         console.log(((tempChild.tempChildren[i].statutory_name) == (remarksdata && remarksdata[i].statutoryName)),'((tempChild.tempChildren[i].statutory_name) == (remarksdata && remarksdata[i].statutoryName))');
                                            //         console.log((tempChild.tempChildren[i].statutory_name),'(tempChild.tempChildren[i].statutory_name)');
                                            //         console.log((remarksdata && remarksdata[i].statutoryName),'(remarksdata && remarksdata[i].statutoryName)');
                                            //         if(((tempChild.tempChildren[i].statutory_name) == (remarksdata && remarksdata[i].statutoryName))){
                                            //             return remarksdata[i].remarks
                                            //         }
                                            //     }
                                            // }}
                                            value={((tempChild && tempChild.tempChildren && tempChild.tempChildren[i].statutory_name) == (remarksdata && remarksdata.length > 0 && remarksdata[l].statutoryName)) ? remarksdata[l].remarks : ''}
                                            disabled
                                        //  hidden={genExtraSelect.collapseIndex.includes(data && data[0].statutory_name) && genExtraSelect.value.includes(tempChild.tempChildren[i].criticality)} 
                                        />
                                        </>

                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
    ];

    const selectOnChange = (e, row, rowIndex) => {
        let temp = []
        console.log(row.compliance_id, 'selcompliance_id');
        temp.push(row.compliance_id)
        const uniqueChars = [...new Set([...compid, ...temp])];
        if (e.target.value === row.criticality) {
            for (let i in uniqueChars) {
                if (uniqueChars[i] === row.compliance_id) {
                    uniqueChars.splice(i, 1)
                }
            }
        }
        setCompId(uniqueChars)
        setSelect({
            ...select,
            rowIndex: rowIndex,
            value: e.target.value,
            comp_value: row.criticality

        })
        setCriticality({
            ...criticality,
            criticalityValue: row.criticality,
            criticalityIndex: rowIndex
        })
        let temp1 = []
        temp1.push({
            rowIndex: rowIndex,
            value: e.target.value
        })
        setRemarksData([...remarksdata, ...temp1])
    }

    console.log(compid, 'compIdddd');

    const arr = [];
    let statelist = [];
    const handleOk = () => {
        setLegal(false)
    };
    const legalmodel = () => {
        setLegal(true)
    }
    const handleCancel = () => {
        setLegal(false)
    };
    const domainchange = (e) => {
        if (filterdatas.nature_list.length > 0) {
            statelist = _.filter(filterdatas.nature_list, { domain_id: e });
            SetSettings({
                ...Settings,
                domain_id: e,
                statutory_nature: statelist
            })
        }
    }

    const showresult = (e) => {
        setAddFormSubmit(true)
        if (validator.current.allValid() && isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetCriticalitySettings",
                        {
                            "le_id": entityid,
                            "domain_id": Settings.domain_id,
                            "statutory_nature": Settings.statutory_nature,
                            "sm_record": 0
                        }
                    ]
                }
            ]
            settingslist({
                payload: payload,
                paramid: paramid
            })
            setShow(true)
        }
    }
    const addform = () => {
        // if (remarkstext != "") {
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SaveCriticalitySettings",
                    {
                        "le_id": entityid,
                        "criticality_list": criticalitylist
                        // [
                        //     {
                        //         "compliance_id": compid,
                        //         "criticality_id": criticalityid,
                        //         "remarks": remarkstext && remarkstext[0] && remarkstext[0].remarksvalue && remarkstext[0].remarksvalue
                        //     }
                        // ]
                    }
                ]
            }
        ]
        addsettings({
            payload: pay,
            paramid: paramid
        })
        // }
        // else {
        //     toast.warning("Remark field is required")
        // }
    }

    const genextra = (index, List) => {
        console.log(List.child, 'aishuuuu');
        return <> <div className="row">
            <div className={collapseindex && collapseindex.includes(List.index) ? 'col-md-6' : 'col-md-12'}>
                <div className="form-group">
                    <select name="" className='form-control' id="" onChange={(e) => {
                        setListChild(List.child)
                        // setGenExtraValue(e.target.value)
                        let temp = []
                        temp.push(index)
                        const uniqueChars = [...new Set([...collapseindex, ...temp])];
                        setCollapseIndex(uniqueChars)
                        setChildren({
                            ...children,
                            child: List.child,
                            collapse_index: index,
                            listIndex: List.paraent
                        })
                        // let tempArray1 = []
                        // tempArray1.push({
                        //     value: e.target.value,
                        //     collapseIndex: List.paraent
                        // })
                        // console.log(tempArray1, '2ndTemp');
                        // setGenExtraSelect([...genExtraSelect, ...tempArray1])
                        let tempArr = []
                        genExtraSelect.map((item, i) => {
                            if (item.collapseIndex === List.paraent) {
                                console.log('trueeee');
                                genExtraSelect.splice(i, 1);
                                setGenExtraSelect([...genExtraSelect, ...tempArr])
                            }
                        })
                        tempArr.push({
                            value: e.target.value,
                            collapseIndex: List.paraent,
                        })
                        setGenExtraSelect([...genExtraSelect, ...tempArr])
                        let tempIndex = []
                        tempIndex.push(List.paraent)
                        setIndexCollapse([...indexCollapse, ...tempIndex])
                        let tempValue = []
                        tempValue.push(e.target.value)
                        setTargetValue([...targetValue, ...tempValue])
                    }}>
                        <option value="select">--Select--</option>
                        {lists.criticality_list.map((item) => {
                            return (
                                <>
                                    <option>{item.criticality_name[0]}</option>
                                </>
                            )
                        })}
                    </select>
                </div>
            </div>
            {collapseindex && collapseindex.includes(List.index) ?
                <div className="col-md-6">
                    <div className="form-group">
                        <input type="text" className='form-control' placeholder='Enter Remarks'
                            onBlur={(e) => {
                                let temp = []
                                // for (let i in genExtraSelect) {
                                //     console.log('forin');
                                //     console.log(genExtraSelect[i].collapseIndex == List.parent,'genExtraSelect[i].collapseIndex == List.parent');
                                //     if (genExtraSelect[i].collapseIndex == List.parent) {
                                console.log('condition');
                                temp.push({
                                    remarks: e.target.value,
                                    statutoryName: List && List.paraent
                                })
                                //     }
                                // }
                                setRemarksData([...remarksdata, ...temp])
                            }}
                        />
                    </div>
                </div> : ''}
        </div>
        </>


    }

    // useEffect(() => {
    //     if (indexCollapse.length && targetValue.length) {
    //         setGenExtraSelect({
    //             value: targetValue,
    //             collapseIndex: indexCollapse
    //         })
    //     }
    // }, [indexCollapse.length, targetValue.length])

    useEffect(() => {
        validator.current.showMessages()
    }, [])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "CriticalitySettingsFilters",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            settingsfilterlist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, entityid])
    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (lists) {
            const ab = lists.criticality_statu.map(function (item) {
                if ((array.indexOf(item.statutory_name) < 0))
                    array.push(item.statutory_name)

            })
            array.map((data, i) => {
                let array2 = [];
                let temp = [];
                if (data) {
                    lists.criticality_statu && lists.criticality_statu.map((child) => {
                        if (data === child.statutory_name) {
                            temp.push(child)
                        }
                    })
                }
                datavalue = {
                    index: i,
                    paraent: data,
                    child: temp
                }
                finalarray.push(datavalue)
            })
            Settitledata({
                ...titledata,
                displauvalue: finalarray
            })
        }

    }, [lists])

    console.log(titledata, 'titledata');

    useEffect(() => {
        if (Settings.le_id == '' || null || undefined) {
            SetSettings({
                ...Settings,
                le_id: entityid
            })

        }
    }, [Settings.le_id])

    useEffect(() => {
        let tempdata = []
        // if (tempDataa && tempDataa.length) {
        //     setCriticalityList(tempDataa)
        // }
        // else {
        for (let i in remarkstext) {
            tempdata.push({
                "compliance_id": remarkstext[i].compliance_id,
                "criticality_id": remarkstext[i].criticality_id,
                // "remarks": remarkstext && remarkstext[0] && remarkstext[0].remarksvalue && remarkstext[0].remarksvalue
                "remarks": remarkstext && remarkstext[i].remarksvalue
            })
            console.log(tempdata, 'temppppp');
        }
        setCriticalityList(tempdata)
        // }
        if (tempdata != "") {
            setCriticalityList(tempdata)
        }
        // }
    }, [remarkstext, compid])

    useEffect(() => {
        if (tempState && tempState.length) {
            const uniqueChars = [...new Set(tempState)];
            var uniq = _.uniqBy(tempState, function (o) {
                return o.compliance_id;
            });
            console.log(uniq, 'uniq');
            Setremarkstext(uniq)
        }
    }, [tempState.length])

    // useEffect(()=>{
    //     if(genExtraSelect && genExtraSelect.length){
    //         var uniq = _.uniqBy(genExtraSelect, function (o) {
    //             return o.collapseIndex;
    //         });
    //         console.log(uniq, 'uniq');
    //         setUniqueGenExtra(uniq)
    //     }

    // },[genExtraSelect.length])

    useEffect(() => {
        if (children.child && children.child.length) {
            let tempChildArray = children.child
            console.log(tempChildArray, 'tempChildArray');
            // tempChildArray.push(...children)
            setTempChild({
                ...tempChild,
                tempChildren: [...tempChild.tempChildren, ...tempChildArray],
                collapseIndex: children.collapse_index
            })

        }
    }, [children])

    console.log(children, 'children');
    console.log(genExtraValue, 'genExtraValue');
    console.log(tempChild, 'tempChild');
    console.log(genExtraSelect, 'snehaaaa');
    console.log(indexCollapse, 'indexCollapse');
    console.log(listChild, 'listChild');
    console.log(remarksdata, 'remarksdata');

    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <a href="javascript:;" className="link"
                                            ><i className="ri-home-3-line fs-5"></i></a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Transaction
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Criticality Settings
                                        </li>
                                    </ol>
                                </nav>
                                <h2 className="mb-0 fw-bold">Criticality Settings </h2>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <Card >
                                                {/* <div className='col-md-12'>
                                                    <div className="row">
                                                        <label style={{ padding: "10px 10px", backgroundColor: "#52c0f7", color: "white" }}>Criticality Settings</label>
                                                    </div>
                                                </div><br /> */}
                                                {/* <ToastContainer /> */}
                                                {show === false ? <>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <div className='col-md-3'>
                                                                    <label>Business Group:</label><br />
                                                                    -
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <label htmlFor="">Legal Entity</label><span style={{ color: "red" }}>*</span><br />
                                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                                    <div className="form-group">
                                                                        <Select
                                                                            allowClear={true}
                                                                            size="default"
                                                                            placeholder="Select Legal Entity"
                                                                            onChange={(data, value) => {
                                                                                setCurrentEntity(data)
                                                                            }}
                                                                            showSearch
                                                                            optionFilterProp="children"
                                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                            //value={Settings.le_id}
                                                                            style={{ width: '300px' }}
                                                                        >
                                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                                return (
                                                                                    <Option key={item.le_id}>
                                                                                        {item.le_name}
                                                                                    </Option>
                                                                                );
                                                                            })}
                                                                        </Select>
                                                                        {validator.current.message(
                                                                            'le_id',
                                                                            Settings.le_id,
                                                                            'required',
                                                                            {
                                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                messages: {
                                                                                    required: 'Required Field Missing',
                                                                                }
                                                                            })}
                                                                    </div> :
                                                                    <p>{localStorage.getItem('SelectedEntity')}</p>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='row'>
                                                            <div className="col-md-6">
                                                                <label>Domain:<span style={{ color: "red" }}>*</span></label>
                                                                <br />
                                                                <Select
                                                                    size="default"
                                                                    placeholder="Select Domain"
                                                                    onChange={(data) => {
                                                                        let statutory = _.filter(filterdatas.nature_list, { domain_id: Number(data) })
                                                                        setStatutorydata(statutory)
                                                                        SetSettings({
                                                                            ...Settings,
                                                                            domain_id: data
                                                                        })
                                                                    }}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                    style={{ width: '300px', marginTop: "10px" }}
                                                                >
                                                                    {filterdatas && filterdatas.domains_list.length > 0 && filterdatas.domains_list.map((item) => {
                                                                        return <Option value={item.d_id}>{item.d_name}</Option>
                                                                    })}
                                                                </Select>
                                                                {validator.current.message(
                                                                    'view only restricted',
                                                                    Settings.domain_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Required Field Missing',
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label>Statutory Nature:
                                                                    <br></br>
                                                                    <Select
                                                                        // defaultValue={{
                                                                        //     value: 'all',
                                                                        //     label: 'All',
                                                                        // }}
                                                                        style={{ width: '300px', marginTop: "10px" }}
                                                                        value={Settings.statutory_nature}
                                                                        onChange={(data) => {
                                                                            console.log(data, '111');
                                                                            SetSettings({
                                                                                ...Settings,
                                                                                statutory_nature: data
                                                                            })
                                                                            let CategoryData = _.filter(filterdatas && filterdatas.nature_list, { domain_id: Number(data) })
                                                                            setCategory(CategoryData)
                                                                        }}>
                                                                        <Option key='All'>All</Option>
                                                                        {Settings.domain_id ?
                                                                            statutorydata.map((item) => {
                                                                                return (
                                                                                    <Option key={item.statutory_nature}>{item.statutory_nature}</Option>
                                                                                )
                                                                            })

                                                                            :
                                                                            ''
                                                                        }
                                                                        {/* {Settings && Settings.statutory_nature.length > 0 && Settings.statutory_nature.map((item) => {
                                                                            return <Option value={item.domain_id}>{item.statutory_nature}</Option>
                                                                        })} */}
                                                                    </Select>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    {/* <div className="col-lg-6 col-md-5 d-none d-md-flex align-items-center justify-content-end"> */}
                                                    <Link className="btn btn-primary btn-md" onClick={showresult} style={{ float: "right" }}>Show</Link>
                                                    {/* </div> */}

                                                </>
                                                    :
                                                    <div>
                                                        <><div>
                                                            <Collapse defaultActiveKey={['0']}>
                                                                {titledata.displauvalue && titledata.displauvalue.length > 0 && titledata.displauvalue.map((List, i) => {
                                                                    return <Panel header={List.paraent} key={List.index} i
                                                                        extra={genextra(List.index, List)}
                                                                    >
                                                                        <DataTable
                                                                            columns={columns}
                                                                            data={List.child}
                                                                        />
                                                                    </Panel>
                                                                })}

                                                            </Collapse>
                                                        </div><br /><br /></>

                                                        {/* <div className="col-lg-6 col-md-5 d-none d-md-flex align-items-center justify-content-end"> */}
                                                        <button className="btn btn-primary btn-md" onClick={addform} style={{ float: 'right' }}>Submit</button>
                                                        {/* </div> */}

                                                    </div>

                                                }

                                            </Card>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
            {/* <Modal title="Domain" visible={domainModal} onOk={handleOk} onCancel={handleCancel}>
                <Search
                    placeholder="Search"
                    onSearch={onSearch}
                    style={{
                        width: 200,
                    }}
                />
            </Modal> */}
            <Modal title="Legal Entity" visible={legal} onOk={handleOk} onCancel={handleCancel}>
                <Search
                    placeholder="Search"
                // onSearch={onSearch}
                // style={{
                //     width: 200,
                // }}
                />
            </Modal>
            {/* <Modal title="Business Group" visible={business} onOk={handleOk} onCancel={handleCancel}>
                <Search
                    placeholder="Search"
                 onSearch={onSearch}
                 style={{
                     width: 200,
                 }}
                />
            </Modal> */}
        </Fragment>

    )
}

export default connect(mapStateToProps, {
    settingsfilterlist,
    settingslist,
    addsettings
})(CriticalitySetting);