import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { ASSIGNCOMPLIANCE, UNITDATA, TABLEDATA, ASSIGNEE, TABLEDATALIST, SHOWMOREDATA } from '../../types/index'

const initialState = {
    assigncompliance: {
        unitdata: '',
        timezone: "",
        domain: "",
        division: "",
        category: "",
        heading: [],
        assign_user: [],
        user_unit: "",
        restricted: "",
        assignunit: "",
        tablelist: []
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ASSIGNCOMPLIANCE: {
            return {
                ...state,
                assigncompliance: {
                    ...state.assigncompliance,
                    unitdata: payload.unit_comp_frequency,
                    timezone: payload.time_zone_le,
                    assignunit: payload.assign_units,
                },
            };
        }
        case UNITDATA: {
            return {
                ...state,
                assigncompliance: {
                    ...state.assigncompliance,
                    domain: payload.domains,
                    division: payload.div_infos,
                    category: payload.cat_info,

                },
            };
        }
        case TABLEDATA: {
            return {
                ...state,
                assigncompliance: {
                    ...state.assigncompliance,
                    heading: payload
                    // domain: payload.domains,
                    // division: payload.div_infos,
                    // category: payload.cat_info,

                },
            };
        }
        case ASSIGNEE: {
            return {
                ...state,
                assigncompliance: {
                    ...state.assigncompliance,
                    assign_user: payload.assign_users,
                    user_unit: payload.user_unit,
                    restricted: payload.vor_users
                    // domain: payload.domains,
                    // division: payload.div_infos,
                    // category: payload.cat_info,

                },
            };
        }
        case TABLEDATALIST: {
            return {
                ...state,
                assigncompliance: {
                    ...state.assigncompliance,
                    tablelist: [payload.assign_statutory]
                    // domain: payload.domains,
                    // division: payload.div_infos,
                    // category: payload.cat_info,

                },
            };
        }
        case SHOWMOREDATA: {
            return {
                ...state,
                assigncompliance: {
                    ...state.assigncompliance,
                
                    showmorelist: [payload.assign_statutory]
                    // domain: payload.domains,
                    // division: payload.div_infos,
                    // category: payload.cat_info,

                },
            };
        }

        default:
            return state;
    }
}