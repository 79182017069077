import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { REVIEWLIST, REVIEWUNITLIST, REVIEWSHOWSETTINGLIST,REVIEWSAVECOMPLIANCE,TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';
import { toast } from 'react-toastify';

export const reviewlist = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
            dispatch({
                type: REVIEWLIST,
                payload: decryptData[1],
            });
     }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const reviewunitlist = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey =  localStorage.getItem('formkey') 
         const head = {
             headers: {
                 'Caller-Name': paramid
             },
         }
         let encoded = base64_decode(encryptkey);
         const data1 = EncryptDecrypt.encryptdata(payload, encoded);
         const createRequest = HTTP.commonpost({
             url: `${url.CommonURL}/api/client_transaction`,
             headers: head,
             executeCancel,
             body: data1
 
         })
         const executeRequest = await HTTP.execute(createRequest)
         const response = HTTP.parseResponse(executeRequest)
         const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
         dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
             dispatch({
                 type: REVIEWUNITLIST,
                 payload: decryptData[1],
             });
     
         }
 
     } catch (err) {
         Toaster.error(err)
         dispatch({
            type: TOGGLE_COMMON_LOADER
        });
     }
};

export const reviewshowsettinglist = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        console.log(payload,"payload----list");
        const encryptkey =  localStorage.getItem('formkey') 
         const head = {
             headers: {
                 'Caller-Name': paramid
             },
         }
         let encoded = base64_decode(encryptkey);
         const data1 = EncryptDecrypt.encryptdata(payload, encoded);
         const createRequest = HTTP.commonpost({
             url: `${url.CommonURL}/api/client_transaction`,
             headers: head,
             executeCancel,
             body: data1
 
         })
         const executeRequest = await HTTP.execute(createRequest)
         const response = HTTP.parseResponse(executeRequest)
         const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
         console.log(decryptData,"decryptData---tableeeeeeeee");
         dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else if(decryptData[0] == "GetReviewSettingsComplianceFiltersSuccess"){
             dispatch({
                 type: REVIEWSHOWSETTINGLIST,
                 payload: decryptData[1],
             });
     
         }
 
     } catch (err) {
         Toaster.error(err)
         dispatch({
            type: TOGGLE_COMMON_LOADER
        });
     }
};
export const reviewsavecompliance = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        console.log(payload,"payload--saveeeeeee");
        const encryptkey =  localStorage.getItem('formkey') 
         const head = {
             headers: {
                 'Caller-Name': paramid
             },
         }
         let encoded = base64_decode(encryptkey);
         const data1 = EncryptDecrypt.encryptdata(payload, encoded);
         const createRequest = HTTP.commonpost({
             url: `${url.CommonURL}/api/client_transaction`,
             headers: head,
             executeCancel,
             body: data1
 
         })
         const executeRequest = await HTTP.execute(createRequest)
         const response = HTTP.parseResponse(executeRequest)
         const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
         dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else {
            toast.success("Review Settings Submitted Successfully")
            //  dispatch({
            //      type: REVIEWSAVECOMPLIANCE,
            //      payload: decryptData[1],
            //  });
             setTimeout(()=>{
                window.location.reload(false);
            }, 2000);
     
         }
 
     } catch (err) {
         Toaster.error(err)
         dispatch({
            type: TOGGLE_COMMON_LOADER
        });
     }
};
