import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { REASSIGNCOMPLAINCE,REASSIGLISTAPI,REASSIGNTABLELISTAPI,REASSIGGETUSERCOMPLIANCE,TOGGLE_COMMON_LOADER,SHOWMOREDATA} from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';
import { toast } from 'react-toastify';

export const reassignlist = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
      
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        
       
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: REASSIGNCOMPLAINCE,
            payload: decryptData,
        });
    }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const reassignunitlist = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
       
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        
       
        
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: REASSIGLISTAPI,
            payload: decryptData,
        });
    }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const reassigndatatable = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
     
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        
       
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: REASSIGNTABLELISTAPI,
            payload: decryptData,
        });
    }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getusercomplaince = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
       
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: REASSIGGETUSERCOMPLIANCE,
            payload: decryptData,
        });
    }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const savereassigncomplaince = ({ payload, executeCancel,paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
      
       const encryptkey =  localStorage.getItem('formkey') 
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
       
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
            toast.success("Compliance Reassigned Successfully")
        dispatch({
            type: REASSIGGETUSERCOMPLIANCE,
            payload: decryptData,
        });
    }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const showmoredata = ({ payload, executeCancel, paramid }) => async dispatch => {

    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
       
        // if (decryptData[1] != "") {
        dispatch({
            type: SHOWMOREDATA,
            payload: decryptData[1].reassign_compliances,
        });

        // }
        // else {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        // }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};