import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { Card, Divider, Tabs, Radio, Input, Row, Col, Select, Button } from 'antd';
import { ArrowRightOutlined, CheckOutlined, CloseOutlined, ArrowLeftOutlined, PlayCircleOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { getUsermanagementDetails, addUserDetails, addUserManagementDetails, edituserManagementDetails } from './../../Store/Action/Master/UserManagement'
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import { Toaster } from '../../Libs/Toaster'
const _ = require("lodash");
const mapStateToProps = (state) => ({
    usermanagementList: state.UserManagement.userManagement,
})

const AddUserManagement = ({
    getUsermanagementDetails,
    addUserDetails,
    AddType,
    setAddType,
    addUserManagementDetails,
    setShowList,
    showList,
    edituserManagementDetails,
    usermanagementList: { userManagementList, UserLegalDetails, userManagementDetails, GetUserDetails }
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const entityid = localStorage.getItem('SelectedEntityid');
    const { TabPane } = Tabs;
    const { Search } = Input;
    const { Option } = Select;
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [categoryFormSubmit, setCategoryFormSubmit] = useState(false);
    const [LitigationFormSubmit, setLitigationFormSubmit] = useState(false);
    const [active, setActive] = useState('1')
    const [selectUnit, setSelectUnit] = useState(false);
    const [closedUnitData, setClosedtUnitData] = useState([]);
    const [selectUnitData, setSelectUnitData] = useState([]);
    const [closedOption, setClosedOption] = useState([])
    const [EntityData, setEntityData] = useState([])
    const [vendorcategory, setVendorCategory] = useState([
    ])
    const [options, setOptions] = useState([{
        label: '',
        value: '',

    }])
    const [VendorEntityOption, setVendorEntityOption] = useState([{
        label: '',
        value: '',

    }])
    const [Entityoptions, setEntityOptions] = useState([{
        label: '',
        value: '',
    }])
    const [Domainoptions, setDomainOptions] = useState([{
        label: '',
        value: '',
    }])
    const [VendorDomainoptions, setVendorDomainOptions] = useState([{
        label: '',
        value: '',
    }])
    const [LitigationDomainoptions, setLitigationDomainOptions] = useState([{
        label: '',
        value: '',
    }])
    const [categoryoptions, setCategoryOptions] = useState([{
        label: '',
        value: '',
    }])
    const [LitigationEntityoptions, setLitigationEntityOptions] = useState([{
        label: '',
        value: '',
    }])
    const [Vendoroptions, setVendorOptions] = useState([{
        label: '',
        value: '',

    }])
    const [vendorselected, setvendorSelected] = useState([]);
    const [vendorClosedOption, setvendorClosedOption] = useState([{
        label: '',
        value: '',

    }])
    const [DivisionOption, setDivisionOption] = useState([{
        label: '',
        value: '',

    }])
    const [divisions, setDivisions] = useState([])

    const [vendorcloseselected, setVendorCloseSelected] = useState([]);
    const [VendorEntity, setVendorEntity] = useState([])
    const [selected, setSelected] = useState([]);
    const [DomainValue, setDomainValue] = useState([]);
    const [VendorDomainValue, setvendorDomainValue] = useState([]);
    const [LitigationDomainValue, setLitigationDomainValue] = useState([]);
    const [compfieCategory, setcompfieCategory] = useState([]);
    const [closeselected, setCloseSelected] = useState([]);
    const [vendorcategories, setVendorCategories] = useState([
    ])
    const [legalEntity, setLegalEntity] = useState([])
    const [litigationlegalEntity, setlitigationLegalEntity] = useState([])
    const [tab, setTab] = useState()
    const [domain, setDomain] = useState([])
    const [userDetails, setUserDetails] = useState({
        emp_name: '',
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        emp_id: '',
        mobile_no_country_code: '',
        mobile_no: '',
        email: '',
        remarks: '',
        emp_code: '',
        otp: 0

    })
    console.log(showList, 'show');
    const [tabValue, setTabValue] = useState({
        compfie: false,
        compfie_litigation: false,
        compfie_vendor: false
    })
    const [mainTab, setMainTab] = useState(false)
    const [category, setCategory] = useState({
        compfie: '',
        compie_litigation: '',
        compfie_vendor: ''
    })
    const [compfie, setCompfie] = useState({
        category: null,
        seating_Unit: null,
        user_Group: '',
        user_level: '',
        entity: '',
        serviceProvider: '',
        checked_value: '',
        legal_Entity: [],
        Domain: [],
        Division: '',
        businessGroup: ''

        // domain:'',

    })
    console.log(tabValue, 'tabsss');
    console.log(compfie.category, 'categoryyyyyyyy');
    const [compfieVendorValue, setCompfieVendorValue] = useState({
        category: null,
        seating_Unit: null,
        user_Group: null,
        user_level: null,
        entity: '',
        serviceProvider: null,
        checked_value: '',
        legal_Entity: [],
        Domain: [],
        Division: '',

        // domain:'',

    })
    const [compfieLitigation, setCompfieLitigation] = useState({
        category: null,
        user: '',
        legal_Entity: [],
        Domain: [],
        businessGroup: '',
        serviceProvider: ''
    })
    const [compfieDomain, setCompfieDomain] = useState([])
    const [vendorDomain, setVendorDomain] = useState([])
    const [categoryValue, setCategoryValue] = useState({
        category: [],
        seatingUnit: [],
        serviceProvider: [],
        legalEnity: [],
        userGroup: [],
        domain: []
    })
    const [compfieLitigationCategory, setcompfieLitigationCategory] = useState({
        // category: [],
        // seatingUnit: [],
        // serviceProvider: [],
        legalEnity: [],
        // userGroup: [],
        domain: []
    })
    const [EditCompfieVendor, setEditCompfieVendor] = useState({
        category: [],
        seatingUnit: [],
        legalEnity: [],
        userGroup: [],
        domain: []
    })
    const [vendorUnitDetails, setVendorUnitDetails] = useState([])
    const [vendorClosedUnitDetails, setVendorClosedUnitDetails] = useState([])
    const [vendorUnit, setVendorUnit] = useState([])
    const [vendorClosedUnit, setVendorClosedUnit] = useState([])
    const [value, setValue] = useState({
        userGroup: '',
        seatingUnit: '',
        userLevel: '',
        businessGroup: '',
        legalEnity: "",
        category: '',
        domain: '',
        division: ''
    })

    const [userUnits, setUserUnits] = useState([

    ])
    const [userClosedUnits, setClosedUserUnits] = useState([

    ])
    const validator = useRef(new SimpleReactValidator());
    const Formvalidator = useRef(new SimpleReactValidator());
    const LitigationFormvalidator = useRef(new SimpleReactValidator());

    const onChange = (e) => {
        setUserDetails({
            ...userDetails,
            otp: e.target.value
        })
    };


    console.log(userManagementDetails, 'userManagementDetails');
    const onUserDetailOnChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value
        })
    }
    const userDetailOnSubmit = () => {
        setAddFormSubmit(true);
        setCategoryFormSubmit(true)
        // setLitigationFormSubmit(true)
        if (validator.current.allValid()) {
            console.log('aishu');
            console.log(Formvalidator.current.allValid(), 'Formvalidator.current.allValid()');
            if (Formvalidator.current.allValid()) {
                console.log('sneha');
                setSelectUnit(true)
                setActive("2")

                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "EmployeeCodeExists",
                            {
                                "mode": "SAVE",
                                "user_id_optional": null,
                                "employee_code": userDetails.emp_id,
                                "mobile_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`
                            }
                        ]
                        // "request": [
                        //     "EmployeeCodeExists",
                        //     {
                        //         "mode": "SAVE",
                        //         "user_id_optional": null,
                        //         "employee_code": "a5344",
                        //         "mobile_no": "+91-9853322282"
                        //     }
                        // ]
                        // "request": [
                        //     "EmployeeCodeExists",
                        //     {
                        //      "mode": "SAVE",
                        //      "user_id_optional": null,
                        //      "employee_code": "a12345",
                        //      "mobile_no": "+91-9853322222"
                        //     }
                        //    ]
                    }
                ]
                const Editpayload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "EmployeeCodeExists",
                            {
                                "mode": "UPDATE",
                                "user_id_optional": 25,
                                "employee_code": userDetails.emp_id,
                                "mobile_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`
                            }
                        ]
                        // "request": [
                        //     "EmployeeCodeExists",
                        //     {
                        //         "mode": "SAVE",
                        //         "user_id_optional": null,
                        //         "employee_code": "a5344",
                        //         "mobile_no": "+91-9853322282"
                        //     }
                        // ]
                        // "request": [
                        //     "EmployeeCodeExists",
                        //     {
                        //      "mode": "SAVE",
                        //      "user_id_optional": null,
                        //      "employee_code": "a12345",
                        //      "mobile_no": "+91-9853322222"
                        //     }
                        //    ]
                    }
                ]
                addUserDetails({
                    payload: AddType == 'add' ? payload : Editpayload,
                    paramid: paramid
                })


            }
            else {
                Toaster.warning("Please  Enter All Values")
            }

        }
        else {
            Toaster.warning("Please  Enter All Values")
        }
        // setSelectUnit(true)




    }

    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_userDetails.length) {
            let mobile = GetUserDetails && GetUserDetails.ul_userDetails[0].mob_no.split("-")
            setUserDetails({
                ...userDetails,
                emp_name: GetUserDetails && GetUserDetails.ul_userDetails[0].emp_name,
                emp_id: GetUserDetails && GetUserDetails.ul_userDetails[0].emp_code,
                email: GetUserDetails && GetUserDetails.ul_userDetails[0].email_id,
                mobile_no_country_code: mobile && mobile[0],
                mobile_no: mobile && mobile[1],
                otp: GetUserDetails && GetUserDetails.ul_userDetails[0].otp_required,
                remarks: GetUserDetails && GetUserDetails.ul_userDetails[0].otp_remarks

            })
            let userCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category, { u_c_id: GetUserDetails && GetUserDetails.ul_userDetails[0].u_cat_id })
            let SeatingUnit = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { u_unt_id: GetUserDetails && GetUserDetails.ul_userDetails[0].seating_unit_id })
            let UserGroup = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_g_id: GetUserDetails && GetUserDetails.ul_userDetails[0].u_g_id })
            let ServiceProvider = _.filter(userManagementDetails && userManagementDetails.um_service_providers, { u_sp_id: GetUserDetails && GetUserDetails.ul_userDetails[0].sp_id })
            let LegalEntity = _.filter(userManagementDetails && userManagementDetails.um_legal_entity.length && userManagementDetails.um_legal_entity, { le_id: GetUserDetails && GetUserDetails.ul_legal_entities.length && GetUserDetails.ul_legal_entities[0].le_id })
            let Domain = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { u_dm_id: GetUserDetails && GetUserDetails.ul_user_domains.length && GetUserDetails.ul_user_domains[0].u_dm_id })
            let LitigationLegalEntity = _.filter(EntityData && EntityData, { le_id: GetUserDetails && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities[0].le_id })
            let LitigationDomain = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { u_dm_id: GetUserDetails && GetUserDetails.ul_ltg_user_domains.length && GetUserDetails.ul_ltg_user_domains[0].u_dm_id })
            let VendoruserCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category, { u_c_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_category })
            let VendorSeatingUnit = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { u_unt_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_seating_unit })
            let VendorUserGroup = _.filter(userManagementDetails && userManagementDetails.um_user_group, { u_g_id: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_group_id })
            let VendorLegalEntity = _.filter(EntityData && EntityData, { le_id: GetUserDetails && GetUserDetails.ul_vend_legal_entities.length && GetUserDetails.ul_vend_legal_entities[0].le_id })
            let VendorDomain = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { u_dm_id: GetUserDetails && GetUserDetails.ul_vend_user_domains.length && GetUserDetails.ul_vend_user_domains[0].u_dm_id })
            console.log(LegalEntity, 'LegalEntity');
            setCategory({
                ...category,
                compfie: userCategory && userCategory.length && userCategory[0].u_c_id,
                compfie_vendor: VendoruserCategory && VendoruserCategory.length && VendoruserCategory[0].u_c_id

            })
            let temparr2 = []
            if (LegalEntity && LegalEntity.length) {
                let temp = []
                for (let i in LegalEntity) {
                    if (LegalEntity && LegalEntity.length) {
                        temparr2.push({

                            label: LegalEntity && LegalEntity.length && LegalEntity[i].le_name,
                            value: LegalEntity && LegalEntity.length && LegalEntity[i].le_id
                        })

                    }


                }
                // setCompfie({
                //     ...compfie,
                //     legal_Entity: temp
                // })
                setLegalEntity(temparr2)
            }
            let temparr3 = []
            if (Domain && Domain.length) {
                for (let i in Domain) {
                    if (Domain && Domain.length && Domain[i] != undefined) {
                        temparr3.push({

                            label: Domain && Domain.length && Domain[i].u_dm_name,
                            value: Domain && Domain.length && Domain[i].u_dm_id
                        })

                    }
                }

                setDomainValue(temparr3)
            }
            let temparr4 = []
            if (LitigationLegalEntity && LitigationLegalEntity.length) {
                for (let i in LitigationLegalEntity) {
                    if (LitigationLegalEntity && LitigationLegalEntity.length && LitigationLegalEntity[i] != undefined) {
                        temparr4.push({
                            label: LitigationLegalEntity && LitigationLegalEntity.length && LitigationLegalEntity[i].le_name,
                            value: LitigationLegalEntity && LitigationLegalEntity.length && LitigationLegalEntity[i].le_id
                        })

                    }
                }

                setlitigationLegalEntity(temparr4)
            }
            let temparr5 = []
            if (LitigationDomain && LitigationDomain.length) {
                for (let i in LitigationDomain) {
                    if (LitigationDomain && LitigationDomain.length && LitigationDomain[i] != undefined) {
                        temparr5.push({

                            label: LitigationDomain && LitigationDomain.length && LitigationDomain[i].u_dm_name,
                            value: LitigationDomain && LitigationDomain.length && LitigationDomain[i].u_dm_id
                        })

                    }
                }

                setLitigationDomainValue(temparr5)
            }
            let temparr6 = []
            if (VendorLegalEntity && VendorLegalEntity.length) {
                for (let i in VendorLegalEntity) {
                    if (VendorLegalEntity && VendorLegalEntity.length && VendorLegalEntity[i] != undefined) {
                        temparr6.push({

                            label: VendorLegalEntity && VendorLegalEntity.length && VendorLegalEntity[i].le_name,
                            value: VendorLegalEntity && VendorLegalEntity.length && VendorLegalEntity[i].le_id
                        })

                    }
                }

                setVendorEntity(temparr6)
            }
            console.log(VendorDomain, 'VendorDomain');
            let temparr7 = []
            if (Domain && Domain.length) {
                for (let i in Domain) {
                    if (Domain && Domain.length && Domain[i] != undefined) {
                        temparr7.push({

                            label: Domain && Domain.length && Domain[i].u_dm_name,
                            value: Domain && Domain.length && Domain[i].u_dm_id
                        })

                    }
                }

                setvendorDomainValue(temparr7)
            }
            setCategoryValue({
                ...categoryValue,
                category: userCategory,
                domain: Domain,
                serviceProvider: ServiceProvider,
                legalEnity: LegalEntity,
                userGroup: UserGroup,
                seatingUnit: SeatingUnit
            })
            console.log(LegalEntity, 'LegalEntity');
            console.log(categoryValue, 'catt');

            console.log(compfie.user_Group, 'iceee');
            setcompfieLitigationCategory({
                ...compfieLitigationCategory,
                legalEnity: LitigationLegalEntity,
                domain: LitigationDomain
            })
            setEditCompfieVendor({
                ...EditCompfieVendor,
                category: VendoruserCategory,
                domain: VendorDomain,
                seatingUnit: VendorSeatingUnit,
                legalEnity: VendorLegalEntity,
                userGroup: VendorUserGroup
            })
            setCompfie({
                ...compfie,
                category: userCategory && userCategory.length ? userCategory && userCategory.length && userCategory[0].u_c_name : null,
                seating_Unit: SeatingUnit.length ? `${SeatingUnit[0].u_unt_code}-${SeatingUnit[0].u_unt_name}` : null,
                user_level: GetUserDetails && GetUserDetails.ul_userDetails[0].user_level,
                user_Group: UserGroup && UserGroup.length > 0 ? UserGroup[0].u_g_name : null,
                serviceProvider: ServiceProvider && ServiceProvider.length ? ServiceProvider && ServiceProvider.length && ServiceProvider[0].u_sp_name : null,
                // legal_Entity: LegalEntity && LegalEntity.length ? LegalEntity[0].le_name : [],
                // Domain: Domain && Domain.length ? Domain && Domain.length && Domain[0].u_dm_name : []
            })
            setCompfieLitigation({
                ...compfieLitigation,
                category: GetUserDetails && GetUserDetails.ul_userDetails[0].usr_type_id,
                // legal_Entity: LitigationLegalEntity && LitigationLegalEntity.length ? LitigationLegalEntity[0].le_name : [],
                // Domain: LitigationDomain.length ? LitigationDomain && LitigationDomain[0].u_dm_name : []
            })
            setCompfieVendorValue({
                ...compfieVendorValue,
                category: VendoruserCategory && VendoruserCategory.length ? VendoruserCategory && VendoruserCategory.length && VendoruserCategory[0].u_c_name : null,
                seating_Unit: VendorSeatingUnit && VendorSeatingUnit.length ? `${VendorSeatingUnit[0].u_unt_code}-${VendorSeatingUnit[0].u_unt_name}` : null,
                user_level: GetUserDetails && GetUserDetails.ul_userDetails[0].vndr_user_level,
                user_Group: VendorUserGroup && VendorUserGroup.length ? VendorUserGroup && VendorUserGroup.length && VendorUserGroup[0].u_g_name : null,
                // legal_Entity: VendorLegalEntity && VendorLegalEntity.length && VendorLegalEntity[0].le_name,
                serviceProvider: ServiceProvider && ServiceProvider.length ? ServiceProvider && ServiceProvider.length && ServiceProvider[0].u_sp_name : null,

                // Domain: VendorDomain && VendorDomain.length ? VendorDomain && VendorDomain.length && VendorDomain[0].u_dm_name : []
            })



        }
    }, [GetUserDetails.ul_userDetails, GetUserDetails && GetUserDetails.ul_ltg_legal_entities && GetUserDetails.ul_ltg_legal_entities.length && GetUserDetails.ul_ltg_legal_entities])
    useEffect(() => {
        if (AddType == 'add') {
            setUserDetails({
                ...userDetails,
                email: ''
            })
        }

    }, [])
    useEffect(() => {
        if ((GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_ct == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1) && (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) {
            setTabValue({
                ...tabValue,
                compfie: true,
                compfie_litigation: true,
                compfie_vendor: true
            })
        }
        else if ((GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_ct == 1) || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1) || (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1)) {
            setTabValue({
                ...tabValue,
                compfie: GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ct == 1 ? true : false,
                compfie_litigation: GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1 ? true : false,
                compfie_vendor: GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1 ? true : false
            })
        }

    }, [GetUserDetails && GetUserDetails.ul_userDetails])

    // useEffect(() => {
    //     if (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_ltmg == 1) {
    //         setTabValue({
    //             ...tabValue,
    //             compfie_litigation: true
    //         })
    //     }
    // }, [GetUserDetails && GetUserDetails.ul_userDetails, tabValue.compfie, tabValue.compfie_vendor])
    // useEffect(() => {
    //     if (GetUserDetails && GetUserDetails.ul_userDetails[0].is_access_vndr == 1) {
    //         setTabValue({
    //             ...tabValue,
    //             compfie_vendor: true
    //         })

    //     }
    //     // setCompfieTab(true)
    // }, [GetUserDetails && GetUserDetails.ul_userDetails, tabValue.compfie, tabValue.compfie_litigation])

    useEffect(() => {
        if (GetUserDetails && GetUserDetails.ul_user_units.length && AddType == 'edit') {
            console.log(GetUserDetails && GetUserDetails.ul_user_units, 'GetUserDetails && GetUserDetails.ul_user_units');
            let temparr = []
            let temparr2 = []
            let closedTemp = []
            let ClosedTemparr = []
            let categoryTemp = []
            let categoryTemparr = []
            let divisionarr = []
            let divisionArr = []
            for (let i in GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails && GetUserDetails.ul_user_units) {
                if (selectUnitData.length) {
                    let data = _.find(selectUnitData, { u_unt_id: GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails.ul_user_units[i].u_unt_id })
                    temparr.push(data)

                }
                if (closedUnitData.length) {
                    let closedData = _.find(closedUnitData, { u_unt_id: Number(GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails.ul_user_units[i].u_unt_id) })
                    closedTemp.push(closedData)
                }
                if (userManagementDetails && userManagementDetails.um_group_category.length) {
                    console.log(userManagementDetails && userManagementDetails.um_group_category, 'userManagementDetails && userManagementDetails.um_user_category.length');
                    let Data = _.find(userManagementDetails && userManagementDetails.um_group_category, { cat_id: Number(GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails.ul_user_units[i].cat_id) })
                    categoryTemp.push(Data)
                }
                if (userManagementDetails && userManagementDetails.um_cmpf_group_division.length) {
                    console.log(userManagementDetails && userManagementDetails.um_cmpf_group_division, 'userManagementDetails && userManagementDetails.um_cmpf_group_division.length');
                    let Data = _.find(userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division, { d_id: Number(GetUserDetails && GetUserDetails.ul_user_units.length && GetUserDetails.ul_user_units[i].div_id) })
                    divisionarr.push(Data)
                }
            }

            if (temparr && temparr.length) {
                for (let i in temparr) {
                    if (temparr && temparr.length && temparr[i] != undefined) {
                        temparr2.push({

                            label: `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                            value: temparr && temparr.length && temparr[i].u_unt_id
                        })

                    }
                }

                setSelected(temparr2)
            }
            if (categoryTemp && categoryTemp.length) {
                console.log(categoryTemp, 'categoryTemp');
                for (let i in categoryTemp) {
                    if (categoryTemp && categoryTemp.length && categoryTemp[i] != undefined) {
                        categoryTemparr.push({

                            label: categoryTemp && categoryTemp.length && categoryTemp[i].cat_name,
                            value: categoryTemp && categoryTemp.length && categoryTemp[i].cat_id
                        })

                    }
                }

                setcompfieCategory(categoryTemparr)
            }
            if (divisionarr && divisionarr.length) {
                console.log(divisionarr, 'divisionarr');
                for (let i in divisionarr) {
                    if (divisionarr && divisionarr.length && divisionarr[i] != undefined) {
                        divisionArr.push({

                            label: divisionarr && divisionarr.length && divisionarr[i].d_name,
                            value: divisionarr && divisionarr.length && divisionarr[i].d_id
                        })

                    }
                }

                setDivisions(divisionArr)
            }
            if (closedTemp && closedTemp.length) {
                for (let i in closedTemp) {
                    if (closedTemp && closedTemp.length && closedTemp[i] != undefined) {
                        ClosedTemparr.push({

                            label: `${closedTemp && closedTemp.length && closedTemp[i] && closedTemp[i].u_unt_code}-${closedTemp && closedTemp.length && closedTemp[i] && closedTemp[i].u_unt_name}-${closedTemp && closedTemp.length && closedTemp[i] && closedTemp[i].u_unt_address}`,
                            value: closedTemp && closedTemp.length && closedTemp[i] && closedTemp[i].u_unt_id
                        })
                    }
                }
                setCloseSelected(ClosedTemparr)
            }
        }
        if (GetUserDetails && GetUserDetails.vend_unitlist.length && AddType == 'edit') {
            let temparr = []
            let temparr2 = []
            for (let i in GetUserDetails && GetUserDetails.vend_unitlist.length && GetUserDetails && GetUserDetails.vend_unitlist) {
                let data = _.find(vendorUnit, { u_unt_id: GetUserDetails && GetUserDetails.vend_unitlist.length && GetUserDetails.vend_unitlist[i].u_unt_id })
                temparr.push(data)
            }
            if (temparr && temparr.length && temparr[0] != undefined) {
                for (let i in temparr) {
                    if (temparr && temparr.length && temparr[i] != undefined) {
                        temparr2.push({

                            label: `${temparr && temparr.length && temparr[i].u_unt_code}-${temparr && temparr.length && temparr[i].u_unt_name}-${temparr && temparr.length && temparr[i].u_unt_address}`,
                            value: temparr && temparr.length && temparr[i].u_unt_id
                        })

                    }
                }

                setvendorSelected(temparr2)
            }
        }
    }, [GetUserDetails && GetUserDetails.ul_user_units, GetUserDetails && GetUserDetails.vend_unitlist])
    console.log(GetUserDetails.vend_unitlist, 'GetUserDetails.vend_unitlist')

    const userDetailsSaved = () => {
        setAddFormSubmit(true);
        if (AddType == 'add') {
            if (userUnits.length > 0) {
                const payload = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "SaveClientUser",
                            {
                                "u_cat_id": compfie.category != null ? Number(compfie.category) : null,
                                "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                "vend_u_cat_id": compfieVendorValue.category != null ? Number(compfieVendorValue.category) : null,
                                "u_g_id": compfie.user_Group != null ? Number(compfie.user_Group) : null,
                                "vend_u_g_id": compfieVendorValue.user_Group != null ? Number(compfieVendorValue.user_Group) : null,
                                "email_id": userDetails.email,
                                "emp_name": userDetails.emp_name,
                                "emp_code": userDetails.emp_id,
                                "cont_no": "--",
                                "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                "u_level": compfie.user_level != null ? Number(compfie.user_level) : null,
                                "vend_u_level": compfieVendorValue.user_level != null ? Number(compfieVendorValue.user_level) : null,
                                "s_unit": compfie.seating_Unit != null ? Number(compfie.seating_Unit) : null,
                                "vend_s_unit": compfieVendorValue.seating_Unit != null ? Number(compfieVendorValue.seating_Unit) : null,
                                "is_sp": compfie.serviceProvider != '' ? true : false,
                                "sp_id": compfie.serviceProvider != '' ? Number(compfie.serviceProvider) : null,
                                "user_entity_ids": compfie.legal_Entity.length > 0 ?
                                    compfie.legal_Entity


                                    : [],
                                "l_user_entity_ids": compfieLitigation.legal_Entity.length > 0 ?
                                    compfieLitigation.legal_Entity


                                    : [],
                                "vend_user_entity_ids": compfieVendorValue.legal_Entity.length > 0 ?
                                    compfieVendorValue.legal_Entity
                                    : [],
                                "user_domain_ids": compfie.Domain,
                                "l_user_domain_ids": compfieLitigation.Domain,
                                "vend_user_domain_ids": compfieVendorValue.Domain,
                                "user_unit_ids": userUnits.concat(userClosedUnits),
                                "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                "checked_value": "0",
                                "otp_remarks": "OTP feature has been switched off",
                                "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                "ltmg_prdt_access": tabValue.compfie_litigation == true ? 1 : 0,
                                "vndr_prdt_access": tabValue.compfie_vendor == true ? 1 : 0
                            }
                        ]


                    }
                ]
                addUserManagementDetails({
                    payload: payload,
                    paramid: paramid
                })
            }
            else {
                Toaster.warning("Please Select Units")
            }
        }

        if (AddType == 'edit') {
            console.log(compfie && compfie.user_Group, 'compfie&& compfie.user_Group ');
            if (userUnits.length > 0) {
                const payloadEdit = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateClientUser",
                            {

                                "u_id": Number(GetUserDetails && GetUserDetails.ul_userDetails[0].user_id),
                                "u_cat_id": compfie && compfie.category == (categoryValue && categoryValue.category.length && categoryValue.category[0].u_c_name) ? Number(categoryValue && categoryValue.category[0].u_c_id) : compfie.category == null ? null : Number(compfie.category),
                                "l_cat_type_id": compfieLitigation.category != null ? Number(compfieLitigation.category) : null,
                                "vend_u_cat_id": compfieVendorValue.category === (EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_name) ? Number(EditCompfieVendor && EditCompfieVendor.category.length && EditCompfieVendor.category[0].u_c_id) : compfieVendorValue.category == null ? null : compfieVendorValue.category,
                                "u_g_id": compfie && compfie.user_Group === (categoryValue && categoryValue.userGroup.length > 0 && categoryValue.userGroup[0].u_g_name) ? Number(categoryValue && categoryValue.userGroup && categoryValue.userGroup.length && categoryValue.userGroup[0].u_g_id) : compfie && compfie.user_Group == null ? null : Number(compfie && compfie.user_Group),
                                "vend_u_g_id": compfieVendorValue.user_Group === (EditCompfieVendor && EditCompfieVendor.userGroup.length && EditCompfieVendor.userGroup[0].u_g_name) ? Number(EditCompfieVendor && EditCompfieVendor.userGroup[0].u_g_id) : compfieVendorValue.user_Group == null ? null : Number(compfieVendorValue.user_Group),
                                "email_id": userDetails.email,
                                "emp_name": userDetails.emp_name,
                                "emp_code": userDetails.emp_id,
                                "cont_no": "--",
                                "mob_no": `${userDetails.mobile_no_country_code}-${userDetails.mobile_no}`,
                                "u_level": compfie.user_level != null ? Number(compfie.user_level) : null,
                                "vend_u_level": compfieVendorValue.user_level != null ? Number(compfieVendorValue.user_level) : null,
                                "s_unit": compfie.seating_Unit === (`${categoryValue && categoryValue.seatingUnit.length && categoryValue.seatingUnit[0].u_unt_code}-${categoryValue && categoryValue.seatingUnit.length && categoryValue.seatingUnit[0].u_unt_name}`) ? Number(categoryValue && categoryValue.seatingUnit.length && categoryValue.seatingUnit[0].u_unt_id) : compfie.seating_Unit == null ? null : Number(compfie.seating_Unit),
                                "vend_s_unit": compfieVendorValue.seating_Unit === (`${EditCompfieVendor && EditCompfieVendor.seatingUnit.length && EditCompfieVendor.seatingUnit[0].u_unt_code}-${EditCompfieVendor && EditCompfieVendor.seatingUnit.length && EditCompfieVendor.seatingUnit[0].u_unt_name}`) ? Number(EditCompfieVendor && EditCompfieVendor.seatingUnit.length && EditCompfieVendor.seatingUnit[0].u_unt_id) : compfieVendorValue.seating_Unit == null ? null : Number(compfieVendorValue.seating_Unit),

                                "is_sp": compfie.serviceProvider == null || categoryValue.serviceProvider[0].u_sp_id == null ? false : true,
                                "sp_id": compfie.serviceProvider === (categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_name) ? Number(categoryValue && categoryValue.serviceProvider.length && categoryValue.serviceProvider[0].u_sp_id) : compfie.serviceProvider == null ? null : Number(compfie.serviceProvider),
                                "user_entity_ids": compfie.legal_Entity.length > 0 ? compfie.legal_Entity : [],
                                "l_user_entity_ids": compfieLitigation.legal_Entity.length > 0 ? compfieLitigation.legal_Entity : [],
                                "vend_user_entity_ids": compfieVendorValue.legal_Entity && compfieVendorValue.legal_Entity.length > 0 ? compfieVendorValue.legal_Entity && compfieVendorValue.legal_Entity : [],
                                "user_domain_ids": compfie.Domain.length ? compfie.Domain : [],
                                "l_user_domain_ids": compfieLitigation.Domain.length ? compfieLitigation.Domain : [],
                                "vend_user_domain_ids": compfieVendorValue.Domain.length ? compfieVendorValue.Domain : [],
                                "user_unit_ids": userUnits.concat(userClosedUnits),
                                "vndr_user_unit_ids": vendorUnitDetails.concat(vendorClosedUnitDetails),
                                "checked_value": "0",
                                "otp_remarks": "OTP feature has been switched off",
                                "cmpf_prdt_access": tabValue.compfie == true ? 1 : 0,
                                "ltmg_prdt_access": compfieLitigation.category != null == true ? 1 : 0,
                                "vndr_prdt_access": compfieVendorValue.category != null ? 1 : 0,
                                "c_user": compfie.category != null ? true : false,
                                "v_user": compfieVendorValue.category != null ? true : false,
                                "l_user": compfieLitigation.category != null ? true : false,
                            }
                        ]
                    }
                ]
                edituserManagementDetails({
                    payload: payloadEdit,
                    paramid: paramid
                })
            }
            else {
                Toaster.warning("Please Select Units")
            }
        }

    }

    const categoryOnChange = (e) => {
        setCategory({
            ...category,
            compfie: e
        })
        setCompfie({
            ...compfie,
            category: e
        })
    }

    useEffect(() => {
        validator.current.showMessages()
        Formvalidator.current.showMessages()
        LitigationFormvalidator.current.showMessages()
    }, [])

    useEffect(() => {
        if (userManagementDetails && userManagementDetails.um_legal_entity) {
            let entityData = _.filter(userManagementDetails && userManagementDetails.um_legal_entity, { le_id: entityid })
            setEntityData(entityData)
        }
    }, [userManagementDetails && userManagementDetails.ul_legal_entities, entityid])

    useEffect(() => {
        if (userManagementDetails && userManagementDetails.um_legal_units) {
            let entityData = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { is_closed: true })
            setClosedtUnitData(entityData)
            let unitData = _.filter(userManagementDetails && userManagementDetails.um_legal_units, { is_closed: false })
            setSelectUnitData(unitData)
        }
    }, [userManagementDetails && userManagementDetails.um_legal_units])
    useEffect(() => {
        if (userManagementDetails && userManagementDetails.um_vndr_legal_units) {
            let vendorData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_units, { is_closed: true })
            setVendorClosedUnit(vendorData)
            let unitData = _.filter(userManagementDetails && userManagementDetails.um_vndr_legal_units, { is_closed: false })
            setVendorUnit(unitData)
        }
    }, [userManagementDetails && userManagementDetails.um_vndr_legal_units])
    useEffect(() => {
        if (selectUnitData && selectUnitData.length > 0) {
            let temp = []
            for (let i in selectUnitData) {
                temp.push({

                    label: `${selectUnitData[i].u_unt_code}-${selectUnitData[i].u_unt_name}-${selectUnitData[i].u_unt_address}`,
                    value: selectUnitData[i].u_unt_id
                })
            }
            setOptions(temp)
        }

        if (closedUnitData && closedUnitData.length > 0) {
            let temp = []
            for (let i in closedUnitData) {
                temp.push({

                    label: `${closedUnitData[i].u_unt_code}-${closedUnitData[i].u_unt_name}-${closedUnitData[i].u_unt_address}`,
                    value: closedUnitData[i].u_unt_id
                })
            }
            setClosedOption(temp)
        }
        if (vendorUnit && vendorUnit.length > 0) {
            let temp = []
            for (let i in vendorUnit) {
                temp.push({

                    label: `${vendorUnit[i].u_unt_code}-${vendorUnit[i].u_unt_name}-${vendorUnit[i].u_unt_address}`,
                    value: vendorUnit[i].u_unt_id
                })
            }
            setVendorOptions(temp)
        }
        if (vendorClosedUnit && vendorClosedUnit.length > 0) {
            let temp = []
            for (let i in vendorClosedUnit) {
                temp.push({

                    label: `${vendorClosedUnit[i].u_unt_code}-${vendorClosedUnit[i].u_unt_name}-${vendorClosedUnit[i].u_unt_address}`,
                    value: vendorClosedUnit[i].u_unt_id
                })
            }
            setvendorClosedOption(temp)
        }
    }, [selectUnitData, closedUnitData, vendorUnit, vendorClosedUnit])

    useEffect(() => {
        if (EntityData.length) {
            let temparr = []
            for (let i in EntityData) {
                temparr.push({

                    label: EntityData[i].le_name,
                    value: EntityData[i].le_id
                })

                setEntityOptions(temparr)
                setLitigationEntityOptions(temparr)
                setVendorEntityOption(temparr)
            }
        }

        if (userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain[i].u_dm_name,
                    value: userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain[i].u_dm_id
                })

                // setDomainOptions(temparr)
                setLitigationDomainOptions(temparr)
                setVendorDomainOptions(temparr)
            }
        }
        if (userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division[i].d_name,
                    value: userManagementDetails && userManagementDetails.um_cmpf_group_division.length && userManagementDetails && userManagementDetails.um_cmpf_group_division[i].d_id
                })

                setDivisionOption(temparr)
            }
        }
        if (userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category) {
            let temparr = []
            for (let i in userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category) {
                temparr.push({

                    label: userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category[i].cat_name,
                    value: userManagementDetails && userManagementDetails.um_group_category.length && userManagementDetails && userManagementDetails.um_group_category[i].cat_id
                })

                setCategoryOptions(temparr)
            }
        }

    }, [EntityData && EntityData, userManagementDetails])
    console.log(EntityData, 'EntityData');
    useEffect(() => {
        if (selected && selected.length > 0) {
            let tempIds = []
            let temparr = []
            for (let i in selected) {
                tempIds.push(selected[i].value)

            }
            if (tempIds.length) {
                for (let i = 0; i < tempIds.length; i++) {
                    temparr.push(

                        {
                            le_id: entityid,
                            u_id: tempIds[i]
                        }
                    )

                }
                setUserUnits(temparr)
            }
        }
        else {
            setUserUnits([])
        }
        if (closeselected && closeselected.length > 0) {
            let tempIds = []
            let temparr = []
            for (let i in closeselected) {
                tempIds.push(closeselected[i].value)
            }
            if (tempIds.length) {
                for (let i = 0; i < tempIds.length; i++) {
                    temparr.push(

                        {
                            le_id: entityid,
                            u_id: tempIds[i]
                        }
                    )

                }
                setClosedUserUnits(temparr)
            }
        }
        else {
            setClosedUserUnits([])
        }
        if (vendorselected && vendorselected.length > 0) {
            let tempIds = []
            let temparr = []
            for (let i in vendorselected) {
                tempIds.push(vendorselected[i].value)
            }
            if (tempIds.length) {
                for (let i = 0; i < tempIds.length; i++) {
                    temparr.push(

                        {
                            le_id: entityid,
                            u_id: tempIds[i]
                        }
                    )

                }
                setVendorUnitDetails(temparr)
            }
        }
        else {
            setVendorUnitDetails([])
        }
        if (vendorcloseselected && vendorcloseselected.length > 0) {
            let tempIds = []
            let temparr = []
            for (let i in vendorcloseselected) {
                tempIds.push(vendorcloseselected[i].value)
            }
            if (tempIds.length) {
                for (let i = 0; i < tempIds.length; i++) {
                    temparr.push(

                        {
                            le_id: entityid,
                            u_id: tempIds[i]
                        }
                    )

                }
                setVendorClosedUnitDetails(temparr)
            }

        }
        else {
            setVendorClosedUnitDetails([])
        }

    }, [selected, closeselected, vendorselected, vendorcloseselected])


    useEffect(() => {
        if (legalEntity && legalEntity.length > 0) {
            let tempIds = []
            for (let i in legalEntity) {
                tempIds.push(legalEntity[i].value)

            }
            console.log(tempIds, 'tempIds12345');
            if (tempIds.length) {
                console.log(compfie, 'compfie12345');
                setCompfie({
                    ...compfie,
                    legal_Entity: tempIds
                })
            }

        }
        else {
            setCompfie({
                ...compfie,
                legal_Entity: []
            })
        }


        if (litigationlegalEntity && litigationlegalEntity.length > 0) {
            console.log('ppp');
            let tempIds = []
            for (let i in litigationlegalEntity) {
                tempIds.push(litigationlegalEntity[i].value)

            }
            console.log(tempIds, 'tempIds');
            if (tempIds.length) {
                setCompfieLitigation({
                    ...compfieLitigation,
                    legal_Entity: tempIds
                })
            }




        }
        else {
            setCompfieLitigation({
                ...compfieLitigation,
                legal_Entity: []
            })
        }


        if (VendorEntity && VendorEntity.length > 0) {
            console.log(',mmmm');
            let tempIds = []
            for (let i in VendorEntity) {
                tempIds.push(VendorEntity[i].value)

            }
            console.log(tempIds, 'tempIds');
            if (tempIds.length) {
                setCompfieVendorValue({
                    ...compfieVendorValue,
                    legal_Entity: tempIds
                })
            }



        }
        else {
            setCompfieVendorValue({
                ...compfieVendorValue,
                legal_Entity: []
            })
        }

    }, [legalEntity, litigationlegalEntity, VendorEntity])

    useEffect(() => {
        if (compfie.legal_Entity.length > 0) {
            if (DomainValue && DomainValue.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in DomainValue) {
                    tempIds.push(DomainValue[i].value)

                }
                if (tempIds.length) {
                    for (let i = 0; i < tempIds.length; i++) {
                        temparr.push(

                            {
                                le_id: entityid,
                                d_id: Number(tempIds[i])
                            }
                        )

                    }
                    setCompfie({
                        ...compfie,
                        Domain: temparr
                    })
                }


            }
            else {
                setCompfie({
                    ...compfie,
                    Domain: []
                })
            }
        }
        if (compfieLitigation.legal_Entity.length > 0) {
            if (LitigationDomainValue && LitigationDomainValue.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in LitigationDomainValue) {
                    tempIds.push(LitigationDomainValue[i].value)

                }
                if (tempIds.length) {
                    for (let i = 0; i < tempIds.length; i++) {
                        temparr.push(

                            {
                                le_id: entityid,
                                d_id: Number(tempIds[i])
                            }
                        )

                    }
                    setCompfieLitigation({
                        ...compfieLitigation,
                        Domain: temparr
                    })
                }


            }
            else {
                setCompfieLitigation({
                    ...compfieLitigation,
                    Domain: []
                })
            }
        }
        if (compfieVendorValue.legal_Entity.length > 0) {
            if (VendorDomainValue && VendorDomainValue.length > 0) {
                let tempIds = []
                let temparr = []
                for (let i in VendorDomainValue) {
                    tempIds.push(VendorDomainValue[i].value)

                }
                if (tempIds.length) {
                    for (let i = 0; i < tempIds.length; i++) {
                        temparr.push(

                            {
                                le_id: entityid,
                                d_id: Number(tempIds[i])
                            }
                        )

                    }
                    setCompfieVendorValue({
                        ...compfieVendorValue,
                        Domain: temparr
                    })
                }


            }

            else {
                setCompfieVendorValue({
                    ...compfieVendorValue,
                    Domain: []
                })
            }
        }
    }, [DomainValue, LitigationDomainValue, VendorDomainValue, compfie.legal_Entity, compfieLitigation.legal_Entity, compfieVendorValue.legal_Entity])
    useEffect(() => {
        if (userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain.length > 0) {
            console.log(userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain, "kkkkkk");
            const data1 = userManagementDetails && userManagementDetails.um_legal_domain.length && userManagementDetails && userManagementDetails.um_legal_domain.filter((item) => {
                return item.le_id === (legalEntity && legalEntity[0] && legalEntity[0].value)
            })
            let temparr = []
            temparr.push({
                label: data1 && data1[0] && data1[0].u_dm_name,
                value: data1 && data1[0] && data1[0].le_id
            })
            setDomainOptions(temparr)
        }
    }, [userManagementDetails.um_legal_domain, legalEntity])
    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Master</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                    <Link>User Management</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="
        col-lg-4 col-md-6
        d-none d-md-flex
        align-items-center
        justify-content-end
      "
                    >
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div>
                                <div className="card-body">
                                    {/* <div className="row pt-3">
                      <div className="col-md-6">
                        <div className="mb-2"> */}
                                    <div className="col-md-12">
                                        <Tabs activeKey={active} onChange={(key) => {
                                            setActive(key)
                                        }}>
                                            <TabPane tab="User Details" key="1">
                                                {/* <Card> */}
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Employee Name</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                <input type="text" className='form-control' name="emp_name" onChange={onUserDetailOnChange} id="" value={userDetails.emp_name} />
                                                            </div>
                                                            {validator.current.message(
                                                                'emp_name',
                                                                userDetails.emp_name,
                                                                ['required'], //, { regex: /^[A-Za-z]+$/ }
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Please enter Name',
                                                                        regex: 'Enter valid name'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Contact No</b></label>
                                                                {/* <input type="text" className='form-control' name="emp_contact_no" onChange={onUserDetailOnChange} id="" value={userDetails.emp_contact_no} /> */}
                                                                <Input.Group size="default">
                                                                    <Row gutter={8}>
                                                                        <Col span={4}>
                                                                            <Input type="number" placeholder='+00' name='emp_contact_country_code' value={userDetails.emp_contact_country_code} onChange={onUserDetailOnChange} />
                                                                            {validator.current.message(
                                                                                'emp_contact_country_code',
                                                                                userDetails.emp_contact_country_code,
                                                                                [{ regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        // required: 'Required Field Missing',
                                                                                        regex: 'Enter valid country code'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Input type="number" placeholder='000' name='emp_contact_code' value={userDetails.emp_contact_code} onChange={onUserDetailOnChange} />
                                                                            {validator.current.message(
                                                                                'emp_contact_code',
                                                                                userDetails.emp_contact_code,
                                                                                [{ regex: /^(\+\d{1,3}[- ]?)?\d{3}$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        // required: 'Required Field Missing',
                                                                                        regex: 'Enter valid input'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                        <Col span={10}>
                                                                            <Input  type="number" placeholder='1234567890' name='emp_contact_no' value={userDetails.emp_contact_no} onChange={onUserDetailOnChange} />
                                                                            {validator.current.message(
                                                                                'emp_contact_no',
                                                                                userDetails.emp_contact_no,
                                                                                [{ regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                                {
                                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        // required: 'Required Field Missing',
                                                                                        regex: 'Enter valid mobile number'
                                                                                    }
                                                                                })}
                                                                        </Col>
                                                                    </Row>
                                                                </Input.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Employee ID</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    <input type="text" className='form-control' onChange={onUserDetailOnChange} name="emp_id" id="" value={userDetails.emp_id} />
                                                                </div>
                                                                {validator.current.message(
                                                                    'emp_id',
                                                                    userDetails.emp_id,
                                                                    'required',
                                                                    {
                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                        messages: {
                                                                            required: 'Required Field Missing',
                                                                        }
                                                                    })}
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label htmlFor=""><b>Mobile No</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                    {/* <input type="text" className='form-control' onChange={onUserDetailOnChange} name="mobile_no" id="" value={userDetails.mobile_no} /> */}
                                                                    <Input.Group size="default">
                                                                        <Row gutter={8}>
                                                                            <Col span={5}>
                                                                                <Input  type="number" placeholder='+91' value={userDetails.mobile_no_country_code} name='mobile_no_country_code' onChange={onUserDetailOnChange} />
                                                                                {validator.current.message(
                                                                                    'mobile_no_country_code',
                                                                                    userDetails.mobile_no_country_code,
                                                                                    ['required', { regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Required Field Missing',
                                                                                            regex: 'Please enter valid country code'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                            <Col span={15}>
                                                                                <Input  type="number" placeholder='9876543210' value={userDetails.mobile_no} name='mobile_no' onChange={onUserDetailOnChange} />
                                                                                {validator.current.message(
                                                                                    'mobile_no',
                                                                                    userDetails.mobile_no,
                                                                                    ['required', { regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                                    {
                                                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Required Field Missing',
                                                                                            regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })}
                                                                            </Col>
                                                                        </Row>
                                                                    </Input.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Email ID</b></label>  <span style={{ "color": "red" }}>*</span>
                                                                <input type="text" className='form-control' onChange={onUserDetailOnChange} name="email" id="" value={userDetails.email} />
                                                            </div>
                                                            {validator.current.message(
                                                                'email',
                                                                userDetails.email,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                        regex: 'Enter valid email'
                                                                    }
                                                                })}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>Remarks</b></label>
                                                                <input type="text" className='form-control' onChange={onUserDetailOnChange}
                                                                    // value='Otp feature has been switched off'
                                                                    value={userDetails.remarks}
                                                                    name="remarks" id="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label htmlFor=""><b>OTP</b> </label><span style={{ "color": "red" }}>*</span><br />
                                                                <Radio.Group onChange={onChange} value={userDetails.otp} name='otp'>
                                                                    <Radio value={1}>On</Radio>
                                                                    <Radio value={0}>Off</Radio>
                                                                </Radio.Group>
                                                            </div>
                                                            {validator.current.message(
                                                                'otp',
                                                                userDetails.otp,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <Divider />
                                                <div className="col-md-12">
                                                    <Tabs type="card"

                                                        onChange={(key) => {
                                                            setTab(key)
                                                        }}
                                                        //  onChange={() => {
                                                        //     setCategory({
                                                        //         compfie: '',
                                                        //         compie_litigation: '',
                                                        //         compfie_vendor: ''
                                                        //     })
                                                        // }}
                                                        activeKey={tab}

                                                    >
                                                        {GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_ct != 1 && AddType == 'edit' ? "" :
                                                            <TabPane

                                                                disabled={tabValue.compfie == false ? true : false}
                                                                tab={
                                                                    <span style={{"margin-top": "-16px"}}>
                                                                        {
                                                                            tabValue.compfie == false ?
                                                                                <CloseOutlined onClick={() => {
                                                                                    setTab("1")
                                                                                    setTabValue({
                                                                                        ...tabValue,
                                                                                        compfie: !tabValue.compfie
                                                                                    })

                                                                                }}
                                                                                    style={{ color: 'red' }} /> : <CheckOutlined onClick={() => {
                                                                                        if (AddType == 'edit') {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie: tabValue.compfie
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie: !tabValue.compfie
                                                                                            })
                                                                                        }

                                                                                    }} style={{ color: 'green' }}
                                                                                />
                                                                        }

                                                                        Compfie
                                                                    </span>
                                                                }
                                                                key="1">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>Category</b><span style={{ "color": "red" }}>*</span></label>
                                                                                {/* <select name="compfie" className='form-control' id="cars" onChange={categoryOnChange}>
                                                                                        <option value="Select">Select</option>
                                                                                        <option value="Client Executive">Client Executive</option>
                                                                                        <option value="Domain Admin">Domain Admin</option>
                                                                                        <option value="Legal Entity Admin">Legal Entity Admin</option>
                                                                                        <option value="Service Provider Admin">Service Provider Admin</option>
                                                                                        <option value="View only">View only</option>
                                                                                        <option value="View only restricted">View only restricted</option>
                                                                                    </select> */}
                                                                                <br />
                                                                                <Select
                                                                                    // showSearch
                                                                                    // optionFilterProp="children"
                                                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Enter Unit"
                                                                                    onChange={categoryOnChange}
                                                                                    // onChange={(data) => {
                                                                                    //     setUnit({
                                                                                    //         ...unit,
                                                                                    //         unit: data
                                                                                    //     })
                                                                                    // }}
                                                                                    value={compfie.category}
                                                                                    style={{ width: '100%' }}
                                                                                    disabled={tabValue.compfie == false || AddType == 'edit' ? true : false}
                                                                                >
                                                                                    {userManagementDetails && userManagementDetails.um_user_category && userManagementDetails.um_user_category.length > 0 && userManagementDetails.um_user_category.map((item, i) => {

                                                                                        return (
                                                                                            <Option key={item.u_c_id}>
                                                                                                {item.u_c_name}
                                                                                            </Option>


                                                                                        )


                                                                                    })}


                                                                                </Select>
                                                                                {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                    'mobile_no',
                                                                                    compfie.category,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Required Field Missing',
                                                                                        }
                                                                                    })) : ''}


                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <label htmlFor=""><b>User Group</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                            <Select
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={true}
                                                                                size="default"
                                                                                placeholder="Enter Unit"

                                                                                onChange={(data) => {
                                                                                    setValue({
                                                                                        ...value,
                                                                                        userGroup: data
                                                                                    })
                                                                                    setCompfie({
                                                                                        ...compfie,
                                                                                        user_Group: data
                                                                                    })
                                                                                }
                                                                                }
                                                                                value={compfie.user_Group}
                                                                                style={{ width: '100%' }}
                                                                                disabled={tabValue.compfie == false ? true : false}
                                                                            >
                                                                                {userManagementDetails && userManagementDetails.um_user_group.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.u_g_id}>
                                                                                            {item.u_g_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}

                                                                            </Select>
                                                                            {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                'user Group',
                                                                                compfie.user_Group,
                                                                                ['required'],
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Required Field Missing',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''}
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>

                                                                        {category.compfie == '5' || category.compfie == '4' || category.compfie == '3' ? <div className="col-md-12 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor=""><b>Seating Unit</b><span style={{ "color": "red" }}>*</span></label><br />

                                                                                        <Select
                                                                                            // showSearch
                                                                                            // optionFilterProp="children"
                                                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                            allowClear={true}
                                                                                            size="default"
                                                                                            placeholder="Enter Seating Unit"
                                                                                            // onChange={categoryOnChange}
                                                                                            onChange={(data) => {
                                                                                                setValue({
                                                                                                    ...value,
                                                                                                    seatingUnit: data
                                                                                                })
                                                                                                setCompfie({
                                                                                                    ...compfie,
                                                                                                    seating_Unit: data
                                                                                                })
                                                                                            }}
                                                                                            value={compfie.seating_Unit}
                                                                                            style={{ width: '100%' }}
                                                                                        >
                                                                                            {userManagementDetails && userManagementDetails.um_legal_units.map((item, i) => {
                                                                                                return (
                                                                                                    <Option key={item.u_unt_id}>
                                                                                                        {item.u_unt_code}-{item.u_unt_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            })}
                                                                                        </Select>
                                                                                        {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                            'seating_Unit',
                                                                                            compfie.seating_Unit,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Required Field Missing',
                                                                                                    // regex: 'Please enter valid mobile number'
                                                                                                }
                                                                                            })) : ''}
                                                                                        {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                                    </div>

                                                                                </div>
                                                                                {category.compfie == '5' ?
                                                                                    <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>User Level</b><span style={{ "color": "red" }}>*</span></label>
                                                                                            <select name="" id="" className='form-control' value={compfie.user_level} onChange={(e) => {
                                                                                                setCompfie({
                                                                                                    ...compfie,
                                                                                                    user_level: e.target.value
                                                                                                })
                                                                                            }}>
                                                                                                <option value="1">Level 1(Low)</option>
                                                                                                <option value="2">Level 2</option>
                                                                                                <option value="3">Level 3</option>
                                                                                                <option value="4">Level 4</option>
                                                                                                <option value="5">Level 5</option>
                                                                                                <option value="6">Level 6</option>
                                                                                                <option value="7">Level 7</option>
                                                                                                <option value="8">Level 8</option>
                                                                                                <option value="9">Level 9</option>
                                                                                                <option value="10">Level 10(High)</option>

                                                                                            </select>
                                                                                        </div>
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div> : ''}


                                                                        {/* <div className="col-md-12 mt-3">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="">Seating Unit</label><br />
                                                                                            <input type="text" className='form-control' id="" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}

                                                                        {category.compfie == '6' ? <div className="col-md-12 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor=""><b>Service Provider</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                                        <Select
                                                                                            // showSearch
                                                                                            // optionFilterProp="children"
                                                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                            allowClear={true}
                                                                                            size="default"
                                                                                            placeholder="Enter Unit"
                                                                                            // onChange={categoryOnChange}
                                                                                            onChange={(data) => {
                                                                                                setValue({
                                                                                                    ...value,
                                                                                                    serviceProvider: data
                                                                                                })
                                                                                                setCompfie({
                                                                                                    ...compfie,
                                                                                                    serviceProvider: data
                                                                                                })
                                                                                            }}

                                                                                            value={compfie.serviceProvider}
                                                                                            style={{ width: '100%' }}
                                                                                        >
                                                                                            {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                                return (
                                                                                                    <Option key={item.u_sp_id}>
                                                                                                        {item.u_sp_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            })}
                                                                                        </Select>
                                                                                        {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                            'serviceProvider',
                                                                                            compfie.serviceProvider,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Required Field Missing',
                                                                                                    // regex: 'Please enter valid mobile number'
                                                                                                }
                                                                                            })) : ''}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                                <Divider />
                                                                <h5>Settings</h5>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor=""><b>Business Group</b></label><br />
                                                                            <Select
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={true}
                                                                                size="default"
                                                                                placeholder="Enter Unit"
                                                                                // onChange={categoryOnChange}
                                                                                onChange={(data) => {
                                                                                    setValue({
                                                                                        ...value,
                                                                                        businessGroup: data
                                                                                    })
                                                                                    setCompfie({
                                                                                        ...compfie,
                                                                                        businessGroup: data
                                                                                    })
                                                                                }}
                                                                                // value={filter.seller}
                                                                                style={{ width: '100%' }}
                                                                                disabled={tabValue.compfie == false ? true : false}
                                                                            >
                                                                                {userManagementDetails && userManagementDetails.um_business_group.map((item, i) => {
                                                                                    return (

                                                                                        <Option key={item.le_id}>
                                                                                            {item.le_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                            {/* {Formvalidator.current.message(
                                                                                            'mobile_no',
                                                                                            compfie.businessGroup,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Required Field Missing',
                                                                                                    // regex: 'Please enter valid mobile number'
                                                                                                }
                                                                                            })} */}
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>
                                                                        {category.compfie == '5' || category.compfie == '6' ?

                                                                            <div className="col-md-6">
                                                                                <label htmlFor=""><b>Division</b></label><br />
                                                                                <MultiSelect

                                                                                    // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                    options={DivisionOption}
                                                                                    value={divisions}
                                                                                    onChange={setDivisions}
                                                                                    labelledBy="Select"

                                                                                /><br />
                                                                            </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className="col-md-12">
                                                                        <div className="row">



                                                                            <div className="col-md-6">

                                                                                <label htmlFor=""><b>Legal Entity</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                                {/* <Select
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Unit"
                                                                                        mode="multiple"
                                                                                        onChange
                                                                                        defaultValue={compfie.legal_Entity}
                                                                                        // value={filter.seller}
                                                                                        style={{ width: '65%' }}
                                                                                    >
                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}

                                                                                <MultiSelect

                                                                                    // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                    options={Entityoptions}
                                                                                    value={legalEntity}
                                                                                    onChange={setLegalEntity}
                                                                                    labelledBy="Select"
                                                                                    disabled={tabValue.compfie == false ? true : false}

                                                                                />



                                                                                {/* <Select
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Entity"
                                                                                        mode="multiple"
                                                                                        defaultValue={`${compfie.legal_Entity}`}
                                                                                        // defaultValue="Lulu Software Services Private Limited (India)"
                                                                                        disabled={tabValue.compfie == false ? true : false}
                                                                                        // onChange={(e) => {
                                                                                        //     setCompfie({
                                                                                        //         ...compfie,
                                                                                        //         Domain: value
                                                                                        //     })
                                                                                        // }}
                                                                                        onChange={(data) => handled(data)
                                                                                        }
                                                                                        // value={filter.seller}
                                                                                        style={{ width: '65%' }}
                                                                                    >

                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}




                                                                                {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                    'legal_Entity',
                                                                                    compfie.legal_Entity,
                                                                                    ['required'],
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Required Field Missing',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ""}
                                                                                {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}



                                                                            </div>
                                                                            {category.compfie == '5' || category.compfie == '6' ?
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor=""><b>Category</b></label><br />
                                                                                    <MultiSelect

                                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                        options={categoryoptions}
                                                                                        value={compfieCategory}
                                                                                        onChange={setcompfieCategory}
                                                                                        labelledBy="Select"

                                                                                    />

                                                                                </div> : ''}
                                                                            {category.compfie == '5' || category.compfie == '4' || category.compfie == '6' || category.compfie == '2' || category.compfie == '7' ?
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor=""><b>Domain</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                                    {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                                    {/* <Select
                                                                                                // showSearch
                                                                                                // optionFilterProp="children"
                                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Domain"
                                                                                                mode="multiple"
                                                                                                defaultValue={compfie.Domain}

                                                                                                // onChange={(e) => {
                                                                                                //     setCompfie({
                                                                                                //         ...compfie,
                                                                                                //         Domain: value
                                                                                                //     })
                                                                                                // }}
                                                                                                onChange={(data) => handle(data)
                                                                                                }
                                                                                                // value={filter.seller}
                                                                                                style={{ width: '65%' }}
                                                                                            >

                                                                                                {domain && domain.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                    {/* <Select
                                                                                                // showSearch
                                                                                                // optionFilterProp="children"
                                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Domain"
                                                                                                mode="multiple"
                                                                                                defaultValue={compfie.Domain}

                                                                                                // onChange={(e) => {
                                                                                                //     setCompfie({
                                                                                                //         ...compfie,
                                                                                                //         Domain: value
                                                                                                //     })
                                                                                                // }}
                                                                                                onChange={(data) => handle(data)
                                                                                                }
                                                                                                // value={filter.seller}
                                                                                                style={{ width: '65%' }}
                                                                                            >

                                                                                                {domain && domain.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                    <MultiSelect

                                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                        options={Domainoptions}
                                                                                        value={DomainValue}
                                                                                        onChange={setDomainValue}
                                                                                        labelledBy="Select"

                                                                                    />
                                                                                    {tabValue.compfie == true ? (Formvalidator.current.message(
                                                                                        'domain',
                                                                                        compfie.Domain,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Required Field Missing',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div> : ""}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {category.compfie == '2' || category.compfie == '7' ? <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>All units accessible</b></label><br />
                                                                        <input type="checkbox" name="" id="" />

                                                                    </div>
                                                                </div> : ''}
                                                            </TabPane>
                                                        }
                                                        {GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_ltmg != 1 && AddType == 'edit' ? "" :
                                                            <TabPane
                                                                disabled={tabValue.compfie_litigation == false ? true : false}
                                                                tab={
                                                                    <span>
                                                                        {
                                                                            tabValue.compfie_litigation == false ?
                                                                                <CloseOutlined onClick={() => {
                                                                                    setTab("2")
                                                                                    setTabValue({
                                                                                        ...tabValue,
                                                                                        compfie_litigation: !tabValue.compfie_litigation
                                                                                    })

                                                                                }}
                                                                                    style={{ color: 'red' }} disabled={AddType == 'edit' ? true : false} /> : <CheckOutlined onClick={() => {
                                                                                        if (AddType == 'edit') {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie_litigation: tabValue.compfie_litigation
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie_litigation: !tabValue.compfie_litigation
                                                                                            })
                                                                                        }


                                                                                    }} style={{ color: 'green' }}
                                                                                        disabled={AddType == 'edit' ? true : false} />
                                                                        }

                                                                        Compfie Litigation
                                                                    </span>
                                                                }
                                                                key="2">
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>User Category</b><span style={{ "color": "red" }}>*</span></label>
                                                                                <select name="user_category" className='form-control' disabled={tabValue.compfie_litigation == false ? true : false} value={compfieLitigation.category} onChange={(e) => {
                                                                                    setCompfieLitigation({
                                                                                        ...compfieLitigation,
                                                                                        category: e.target.value
                                                                                    })
                                                                                }}>
                                                                                    <option value="Select">Select</option>
                                                                                    <option value="2">Creator</option>
                                                                                    <option value="3">User</option>
                                                                                </select>
                                                                                {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'mobile_no',
                                                                                    compfieLitigation.category,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Required Field Missing',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>User</b> <span style={{ "color": "red" }}>*</span></label>
                                                                                <select className='form-control' id="cars"
                                                                                    disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                    onChange={(e) => {
                                                                                        setCompfieLitigation({
                                                                                            ...compfieLitigation,
                                                                                            user: e.target.value
                                                                                        })



                                                                                        if (e.target.value == "Service Provider User") {
                                                                                            let vendorCategory = _.filter(userManagementDetails && userManagementDetails.um_user_category, { u_c_name: e.target.value })
                                                                                            setVendorCategory(vendorCategory)
                                                                                        }
                                                                                        //   setVendorCategories(category)
                                                                                    }}>
                                                                                    <option value="">Select</option>
                                                                                    <option value="Inhouse User">Inhouse User</option>
                                                                                    <option value="Service Provider User">Service Provider User</option>
                                                                                </select>
                                                                                {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'compie_litigation',
                                                                                    compfieLitigation.user,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Required Field Missing',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                {compfieLitigation.user == 'Service Provider User' ? <div className="col-md-12 mt-3">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>Service Provider</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                                <Select
                                                                                    // showSearch
                                                                                    // optionFilterProp="children"
                                                                                    // disabled={unit.legal_entity === "" ? true : false}
                                                                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Enter Unit"
                                                                                    // onChange={categoryOnChange}
                                                                                    onChange={(data) => {
                                                                                        setValue({
                                                                                            ...value,
                                                                                            serviceProvider: data
                                                                                        })
                                                                                        setCompfieLitigation({
                                                                                            ...compfieLitigation,
                                                                                            serviceProvider: data
                                                                                        })
                                                                                    }}
                                                                                    // value={filter.seller}
                                                                                    style={{ width: '100%' }}
                                                                                >
                                                                                    {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_sp_id}>
                                                                                                {item.u_sp_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })}
                                                                                </Select>
                                                                                {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                    'mobile_no',
                                                                                    compfieLitigation.serviceProvider,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Required Field Missing',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                    : ''}

                                                                <Divider />
                                                                <h5>Settings</h5>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor=""><b>Business Group</b></label><br />
                                                                            <Select
                                                                                disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={true}
                                                                                size="default"
                                                                                placeholder="Enter Unit"
                                                                                // onChange={categoryOnChange}
                                                                                onChange={(data) => {

                                                                                    setCompfieLitigation({
                                                                                        ...compfieLitigation,
                                                                                        businessGroup: data
                                                                                    })
                                                                                }}
                                                                                // value={filter.seller}
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                {userManagementDetails && userManagementDetails.um_business_group.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.le_id}>
                                                                                            {item.le_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>
                                                                        <div className="col-md-6">

                                                                            <label htmlFor=""><b>Legal Entity</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                            {/* <Select
                                                                                        disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Unit"
                                                                                        value={compfieLitigation.legal_Entity}
                                                                                        onChange={(data) => {
                                                                                            setCompfieLitigation({
                                                                                                ...compfieLitigation,
                                                                                                legal_Entity: data
                                                                                            })

                                                                                            let division = _.filter(userManagementDetails && userManagementDetails.um_cmpf_group_division, { le_id: data })
                                                                                            setDivision(division)
                                                                                            let domain = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: data })
                                                                                            setDomain(domain)
                                                                                        }}
                                                                                        // value={filter.seller}
                                                                                        style={{ width: '65%' }}
                                                                                    >
                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}
                                                                            {/* <Select
                                                                                        disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        mode="multiple"
                                                                                        placeholder="Enter Unit"
                                                                                        onChange={(data) => handleChangeLitigation(data)
                                                                                        }
                                                                                        defaultValue={compfieLitigation.legal_Entity}
                                                                                        style={{ width: '65%' }}
                                                                                    >
                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}
                                                                            <MultiSelect

                                                                                // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                options={LitigationEntityoptions}
                                                                                value={litigationlegalEntity}
                                                                                onChange={setlitigationLegalEntity}
                                                                                labelledBy="Select"

                                                                            />

                                                                            {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                                'legalentity',
                                                                                compfieLitigation.legal_Entity,
                                                                                'required',
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Required Field Missing',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''}
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label htmlFor=""><b>Domain</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                    {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                    {/* <Select
                                                                                disabled={tabValue.compfie_litigation == false ? true : false}
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={true}
                                                                                size="default"
                                                                                mode='multiple'
                                                                                placeholder="Enter Unit"
                                                                                onChange={(data) => handleChange(data)
                                                                                }
                                                                                defaultValue={compfieLitigation.Domain}
                                                                                style={{ width: '65%' }}
                                                                            >
                                                                                {domain && domain.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.u_dm_id}>
                                                                                            {item.u_dm_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select> */}


                                                                    <MultiSelect

                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                        options={LitigationDomainoptions}
                                                                        value={LitigationDomainValue}
                                                                        onChange={setLitigationDomainValue}
                                                                        labelledBy="Select"

                                                                    />
                                                                    {tabValue.compfie_litigation == true ? (Formvalidator.current.message(
                                                                        'domain',
                                                                        compfieLitigation.Domain,
                                                                        'required',
                                                                        {
                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Required Field Missing',
                                                                                // regex: 'Please enter valid mobile number'
                                                                            }
                                                                        })) : ''}
                                                                </div>
                                                                <div>


                                                                </div>

                                                            </TabPane>
                                                        }
                                                        {GetUserDetails && GetUserDetails.ul_userDetails.length && GetUserDetails.ul_userDetails[0].is_access_vndr != 1 && AddType == 'edit' ? "" :
                                                            <TabPane
                                                                disabled={tabValue.compfie_vendor == false ? true : false}
                                                                tab={
                                                                    <span>
                                                                        {
                                                                            tabValue.compfie_vendor == false ?
                                                                                <CloseOutlined onClick={() => {
                                                                                    setTabValue({
                                                                                        ...tabValue,
                                                                                        compfie_vendor: !tabValue.compfie_vendor
                                                                                    })
                                                                                    setTab("3")
                                                                                }}
                                                                                    style={{ color: 'red' }} /> : <CheckOutlined onClick={() => {
                                                                                        if (AddType == 'edit') {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie_vendor: tabValue.compfie_vendor
                                                                                            })
                                                                                        }
                                                                                        else {
                                                                                            setTabValue({
                                                                                                ...tabValue,
                                                                                                compfie_vendor: !tabValue.compfie_vendor
                                                                                            })
                                                                                        }

                                                                                    }} style={{ color: 'green' }} />
                                                                        }

                                                                        Compfie Vendor
                                                                    </span>
                                                                }
                                                                key="3">

                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor=""><b>Category</b><span style={{ "color": "red" }}>*</span></label>
                                                                                <br />
                                                                                <Select
                                                                                    disabled={tabValue.compfie_vendor == false || AddType == 'edit' ? true : false}
                                                                                    allowClear={true}
                                                                                    size="default"
                                                                                    placeholder="Enter Unit"
                                                                                    // onChange={categoryOnChange}
                                                                                    onChange={(data) => {
                                                                                        setCategory({
                                                                                            ...category,
                                                                                            compfie_vendor: data
                                                                                        })
                                                                                        setCompfieVendorValue({
                                                                                            ...compfieVendorValue,
                                                                                            category: data
                                                                                        })
                                                                                    }}
                                                                                    value={compfieVendorValue.category}
                                                                                    style={{ width: '100%' }}
                                                                                >
                                                                                    {compfieLitigation.user == 'Service Provider User' ? vendorcategory.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_c_id}>
                                                                                                {item.u_c_name}
                                                                                            </Option>
                                                                                        );
                                                                                    }) : userManagementDetails && userManagementDetails.um_user_category.map((item, i) => {
                                                                                        return (
                                                                                            <Option key={item.u_c_id}>
                                                                                                {item.u_c_name}
                                                                                            </Option>
                                                                                        );
                                                                                    })}
                                                                                </Select>

                                                                                {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                    'category',
                                                                                    compfieVendorValue.category,
                                                                                    'required',
                                                                                    {
                                                                                        className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                        messages: {
                                                                                            required: 'Required Field Missing',
                                                                                            // regex: 'Please enter valid mobile number'
                                                                                        }
                                                                                    })) : ''}

                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-6">
                                                                            <label htmlFor=""><b>User Group</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                            <Select
                                                                                disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={true}
                                                                                size="default"
                                                                                placeholder="Enter Unit"

                                                                                onChange={(data) => {
                                                                                    setCompfieVendorValue({
                                                                                        ...compfieVendorValue,
                                                                                        user_Group: data
                                                                                    })
                                                                                }
                                                                                }
                                                                                value={compfieVendorValue.user_Group}
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                {userManagementDetails && userManagementDetails.um_user_group.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.u_g_id}>
                                                                                            {item.u_g_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>

                                                                        {category.compfie_vendor == '5' || category.compfie_vendor == '4' || category.compfie_vendor == '3' ? <div className="col-md-12 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor=""><b>Seating Unit</b><span style={{ "color": "red" }}>*</span></label><br />

                                                                                        <Select
                                                                                            // showSearch
                                                                                            // optionFilterProp="children"
                                                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                            allowClear={true}
                                                                                            size="default"
                                                                                            placeholder="Enter Unit"

                                                                                            onChange={(data) => {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    seating_Unit: data
                                                                                                })
                                                                                            }}
                                                                                            value={compfieVendorValue.seating_Unit}
                                                                                            style={{ width: '100%' }}
                                                                                        >
                                                                                            {userManagementDetails && userManagementDetails.um_legal_units.map((item, i) => {
                                                                                                return (
                                                                                                    <Option key={item.u_unt_id}>
                                                                                                        {item.u_unt_code}-{item.u_unt_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            })}
                                                                                        </Select>
                                                                                        {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                                        {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                            'seatingunit',
                                                                                            compfieVendorValue.seating_Unit,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Required Field Missing',
                                                                                                    // regex: 'Please enter valid mobile number'
                                                                                                }
                                                                                            })) : ''}
                                                                                    </div>

                                                                                </div>
                                                                                {category.compfie_vendor == '5' ?
                                                                                    <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor=""><b>User Level</b><span style={{ "color": "red" }}>*</span></label>
                                                                                            <select name="" id="" className='form-control' onChange={(e) => {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    user_level: e.target.value
                                                                                                })


                                                                                            }}
                                                                                                value={compfieVendorValue.user_level}
                                                                                            >
                                                                                                <option value="1(Low)">Level 1(Low)</option>
                                                                                                <option value="2">Level 2</option>
                                                                                                <option value="3">Level 3</option>
                                                                                                <option value="4">Level 4</option>
                                                                                                <option value="5">Level 5</option>
                                                                                                <option value="6">Level 6</option>
                                                                                                <option value="7">Level 7</option>
                                                                                                <option value="8">Level 8</option>
                                                                                                <option value="9">Level 9</option>
                                                                                                <option value="10">Level 10(High)</option>

                                                                                            </select>
                                                                                            {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                                'user_level',
                                                                                                compfieVendorValue.user_level,
                                                                                                ['required'],
                                                                                                {
                                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                    messages: {
                                                                                                        required: 'Required Field Missing',
                                                                                                        // regex: 'Please enter valid mobile number'
                                                                                                    }
                                                                                                })) : ''}
                                                                                        </div>
                                                                                    </div> : ''}
                                                                            </div>
                                                                        </div> : ''}


                                                                        {/* <div className="col-md-12 mt-3">
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <div className="form-group">
                                                                                            <label htmlFor="">Seating Unit</label><br />
                                                                                            <input type="text" className='form-control' id="" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}

                                                                        {category.compfie_vendor == '6' ? <div className="col-md-12 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor=""><b>Service Provider</b></label><span style={{ "color": "red" }}>*</span><br />
                                                                                        <Select
                                                                                            // showSearch
                                                                                            // optionFilterProp="children"
                                                                                            // disabled={unit.legal_entity === "" ? true : false}
                                                                                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                            allowClear={true}
                                                                                            size="default"
                                                                                            placeholder="Enter Unit"
                                                                                            // onChange={categoryOnChange}
                                                                                            onChange={(data) => {
                                                                                                setCompfieVendorValue({
                                                                                                    ...compfieVendorValue,
                                                                                                    serviceProvider: data
                                                                                                })
                                                                                            }}
                                                                                            value={compfieVendorValue.serviceProvider}
                                                                                            style={{ width: '100%' }}
                                                                                        >
                                                                                            {userManagementDetails && userManagementDetails.um_service_providers.map((item, i) => {
                                                                                                return (
                                                                                                    <Option key={item.u_sp_id}>
                                                                                                        {item.u_sp_name}
                                                                                                    </Option>
                                                                                                );
                                                                                            })}
                                                                                        </Select>
                                                                                        {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                            'serviceProvider',
                                                                                            compfieVendorValue.serviceProvider,
                                                                                            ['required'],
                                                                                            {
                                                                                                className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                                messages: {
                                                                                                    required: 'Required Field Missing',
                                                                                                    // regex: 'Please enter valid mobile number'
                                                                                                }
                                                                                            })) : ''}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                            : ''}
                                                                    </div>
                                                                </div>
                                                                <Divider />
                                                                <h5>Settings</h5>
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <label htmlFor=""><b>Business Group</b></label><br />
                                                                            <Select
                                                                                disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                // showSearch
                                                                                // optionFilterProp="children"
                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                allowClear={true}
                                                                                size="default"
                                                                                placeholder="Enter Unit"

                                                                                onChange={(data) => {
                                                                                    setCompfieVendorValue({
                                                                                        ...compfieVendorValue,
                                                                                        businessGroup: data
                                                                                    })
                                                                                }}
                                                                                //  value={compfieVendorValue.businessGroup}
                                                                                style={{ width: '100%' }}
                                                                            >
                                                                                {userManagementDetails && userManagementDetails.um_business_group.map((item, i) => {
                                                                                    return (
                                                                                        <Option key={item.le_id}>
                                                                                            {item.le_name}
                                                                                        </Option>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <label htmlFor=""><b>Legal Entity</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                            {/* <Select
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                        // showSearch
                                                                                        // optionFilterProp="children"
                                                                                        // disabled={unit.legal_entity === "" ? true : false}
                                                                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Unit"

                                                                                        onChange={(data) => {
                                                                                            setCompfieVendorValue({
                                                                                                ...compfieVendorValue,
                                                                                                legal_Entity: data
                                                                                            })

                                                                                            let division = _.filter(userManagementDetails && userManagementDetails.um_cmpf_group_division, { le_id: data })
                                                                                            setDivision(division)
                                                                                            let domain = _.filter(userManagementDetails && userManagementDetails.um_legal_domain, { le_id: data })
                                                                                            setDomain(domain)
                                                                                        }}
                                                                                        value={compfieVendorValue.legal_Entity}
                                                                                        style={{ width: '65%' }}
                                                                                    >
                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}

                                                                            {/* <Select
                                                                                 
                                                                                        allowClear={true}
                                                                                        size="default"
                                                                                        placeholder="Enter Entity"
                                                                                        mode="multiple"
                                                                                        onChange={(data) => handledChangeVendor(data)}
                                                                                        defaultValue={compfieVendorValue.legal_Entity}
                                                                                        disabled={tabValue.compfie_vendor == false ? true : false}
                                                                                      
                                                                                        onChange={(data) => handledChangeVendor(data)
                                                                                        }
                                                                                        
                                                                                        style={{ width: '65%' }}
                                                                                    >

                                                                                        {EntityData && EntityData.map((item, i) => {
                                                                                            return (
                                                                                                <Option key={item.le_id}>
                                                                                                    {item.le_name}
                                                                                                </Option>
                                                                                            );
                                                                                        })}
                                                                                    </Select> */}
                                                                            <MultiSelect

                                                                                // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                options={VendorEntityOption}
                                                                                value={VendorEntity}
                                                                                onChange={setVendorEntity}
                                                                                labelledBy="Select"

                                                                            />
                                                                            {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                'legal_Entity',
                                                                                compfieVendorValue.legal_Entity,
                                                                                ['required'],
                                                                                {
                                                                                    className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                    messages: {
                                                                                        required: 'Required Field Missing',
                                                                                        // regex: 'Please enter valid mobile number'
                                                                                    }
                                                                                })) : ''}
                                                                            {/* <select name="cars" className='form-control' id="cars">
                                                                        <option value="volvo">None Selected</option>
                                                                    </select> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div>
                                                                    <div className="col-md-12 mt-3">
                                                                        <div className="row">
                                                                            {category.compfie_vendor == '5' || category.compfie_vendor == '6' ?
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor=""><b>Category</b></label><br />
                                                                                    {/* <Select
                                                                                               
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"

                                                                                                onChange={(data) => {
                                                                                                    setCompfieVendorValue({
                                                                                                        ...compfieVendorValue,
                                                                                                        categoryValue: data
                                                                                                    })
                                                                                                }}
                                                                                                value={compfieVendorValue.categoryValue}
                                                                                                style={{ width: '65%' }}
                                                                                                disabled={AddType == 'edit' ? true : false}
                                                                                            >
                                                                                                {categories && categories.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.cat_id}>
                                                                                                            {item.cat_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}
                                                                                    <MultiSelect

                                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                        options={categoryoptions}
                                                                                        value={compfieCategory}
                                                                                        onChange={setcompfieCategory}
                                                                                        labelledBy="Select"

                                                                                    />
                                                                                </div> : ''}
                                                                            {category.compfie_vendor == '5' || category.compfie_vendor == '4' || category.compfie_vendor == '6' || category.compfie_vendor == '2' || category.compfie == '7' || tab == 2 || category.compfie_vendor == '5' || category.compfie_vendor == '4' || category.compfie_vendor == '6' || category.compfie_vendor == '2' || category.compfie_vendor == '7' ?
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor=""><b>Domain</b><span style={{ "color": "red" }}>*</span></label><br />
                                                                                    {/* <select name="cars" className='form-control' id="cars">
                                                                                <option value="volvo">None Selected</option>
                                                                            </select> */}
                                                                                    {/* <Select
                                                                                              
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"
                                                                                                onChange={(data) => handleChanged(data)
                                                                                                }
                                                                                                mode='multiple'
                                                                                                defaultValue={compfieVendorValue.Domain}

                                                                                                style={{ width: '65%' }}
                                                                                            >
                                                                                                {domain && domain.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.u_dm_id}>
                                                                                                            {item.u_dm_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}


                                                                                    <MultiSelect

                                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                        options={VendorDomainoptions}
                                                                                        value={VendorDomainValue}
                                                                                        onChange={setvendorDomainValue}
                                                                                        labelledBy="Select"

                                                                                    />
                                                                                    {tabValue.compfie_vendor == true ? (Formvalidator.current.message(
                                                                                        'domain',
                                                                                        compfieVendorValue.Domain,
                                                                                        ['required'],
                                                                                        {
                                                                                            className: `invalid-feedback ${categoryFormSubmit ? 'show' : 'hide'}`,
                                                                                            messages: {
                                                                                                required: 'Required Field Missing',
                                                                                                // regex: 'Please enter valid mobile number'
                                                                                            }
                                                                                        })) : ''}
                                                                                </div> : ""}
                                                                        </div>
                                                                    </div>
                                                                    {category.compfie_vendor == '5' || category.compfie_vendor == '6' || category.compfie_vendor == '5' || category.compfie_vendor == '6' ?
                                                                        <div className="col-md-12 mt-3">
                                                                            <div className="row">
                                                                                <div className="col-md-6">
                                                                                    <label htmlFor=""><b>Division</b></label><br />
                                                                                    {/* <Select
                                                                                                // showSearch
                                                                                                // optionFilterProp="children"
                                                                                                // disabled={unit.legal_entity === "" ? true : false}
                                                                                                // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                                                allowClear={true}
                                                                                                size="default"
                                                                                                placeholder="Enter Unit"

                                                                                                onChange={(data) => {
                                                                                                    setCompfieVendorValue({
                                                                                                        ...compfieVendorValue,
                                                                                                        Division: data
                                                                                                    })
                                                                                                    let CategoryData = _.filter(userManagementDetails && userManagementDetails.um_group_category, { d_id: Number(data) })
                                                                                                    setCategories(CategoryData)
                                                                                                }}
                                                                                                value={compfieVendorValue.Division}
                                                                                                style={{ width: '65%' }}
                                                                                            >
                                                                                                {division.map((item, i) => {
                                                                                                    return (
                                                                                                        <Option key={item.d_id}>
                                                                                                            {item.d_name}
                                                                                                        </Option>
                                                                                                    );
                                                                                                })}
                                                                                            </Select> */}


                                                                                    <MultiSelect

                                                                                        // selectedValues={GetUserDetails&&GetUserDetails.ul_user_units.length&&GetUserDetails.ul_user_units[0].u_unt_id}
                                                                                        options={DivisionOption}
                                                                                        value={divisions}
                                                                                        onChange={setDivisions}
                                                                                        labelledBy="Select"

                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                                {category.compfie_vendor == '2' || category.compfie_vendor == '7' ? <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor=""><b>All units accessible</b></label><br />
                                                                        <input type="checkbox" name="" id="" />

                                                                    </div>
                                                                </div> : ''}
                                                            </TabPane>
                                                        }
                                                    </Tabs>
                                                </div>
                                                {compfie.category == 3 || compfie.category == 2 || compfie.category == 7 ? <a href={() => false} style={{ marginLeft: '90%', marginTop: '40%' }} onClick={userDetailsSaved}>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#198754", borderColor: "#00FF7F" }}
                                                        icon={<PlayCircleOutlined />} size='default'>
                                                        Submit
                                                    </Button>

                                                </a> : <a href={() => false} style={{ marginLeft: '90%', marginTop: '80%' }}>
                                                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F", "margin-top": '30px' }}
                                                        icon={<ArrowRightOutlined />} size='default' onClick={userDetailOnSubmit}>
                                                        Next
                                                    </Button>

                                                </a>}

                                            </TabPane>
                                            <TabPane tab="Select Units"
                                                disabled={selectUnit === true ? false : true}
                                                key="2">
                                                <Tabs defaultActiveKey="1" onChange={onChange}>
                                                    {tabValue.compfie == true ?
                                                        <TabPane tab=" Compfie" key="1">
                                                            <MultiSelect
                                                                options={options}
                                                                value={selected}
                                                                onChange={setSelected}
                                                                labelledBy="Select"
                                                                style={{ width: '100px' }}

                                                            /><br />
                                                            <h6>Closed Units</h6>
                                                            <MultiSelect
                                                                options={closedOption}
                                                                value={closeselected}
                                                                onChange={setCloseSelected}
                                                                labelledBy="Select"

                                                            />
                                                        </TabPane> : null
                                                    }                {tabValue.compfie_vendor == true ?
                                                        <TabPane tab="Compfie Vendor" key="2">

                                                            <MultiSelect
                                                                options={Vendoroptions}
                                                                value={vendorselected}
                                                                onChange={setvendorSelected}
                                                                labelledBy="Select"

                                                            /><br />
                                                            <h6>Closed Units</h6>
                                                            <MultiSelect
                                                                options={vendorClosedOption}
                                                                value={vendorcloseselected}
                                                                onChange={setVendorCloseSelected}
                                                                labelledBy="Select"

                                                            />
                                                        </TabPane> : null
                                                    }</Tabs>
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6" style={{ 'margin-top': '20px' }}>
                                                            <a href={() => false}>
                                                                {/* <ArrowRightOutlined /> */}
                                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#00FF7F" }}
                                                                    icon={<ArrowLeftOutlined />} size='default' onClick={() => {
                                                                        setActive('1')
                                                                    }}>
                                                                    Previous
                                                                </Button>
                                                            </a>
                                                        </div>
                                                        <div className="col-md-6" style={{ 'margin-top': '13px', float: 'right' }}>
                                                            <a href={() => false} onClick={userDetailsSaved}>
                                                                {/* <ArrowRightOutlined /> */}
                                                                <Button type="primary" shape="round" className='addbutton' 
                                                                style={{ background: "#198754", borderColor: "#00FF7F",float:'right'}}
                                                                    icon={<PlayCircleOutlined />} size='default'>
                                                                    Submit
                                                                </Button>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </TabPane>
                                        </Tabs>

                                    </div>
                                    {/* <div className="form-actions">
                      <div className="card-body border-top">
                        <button
                          type="submit"
                          className="btn btn-light-success rounded-pill px-4 text-success"
                        >
                          <div className="d-flex align-items-center">
                            <i
                              data-feather="save"
                              className="feather-sm me-1 text-center fill-icon"
                            ></i>
                            Save
                          </div>
                        </button>
                        <Link to='/serviceproviderlist' className="btn btn-light-danger rounded-pill px-4  ms-2 text-danger">Cancel</Link>
                      </div>
                    </div> */}
                                    {/* </div>
                      </div>
                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, {
    getUsermanagementDetails,
    addUserDetails,
    addUserManagementDetails,
    edituserManagementDetails
})(AddUserManagement)