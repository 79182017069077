import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { USERMANAGEMENT_LIST, TOGGLE_COMMON_LOADER , USERMANAGEMENT_CATEGORY_LIST,GET_BY_UNIT} from './../../types/index';
import alertmessage from './../../../Libs/Altermessage';


export const getUsermanagementList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload);
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: USERMANAGEMENT_LIST,
                payload: decryptData,
            });

        }
       

    } catch (err) {
        Toaster.error(err)
    }
};
export const getUsermanagementDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload);
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
       
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: USERMANAGEMENT_CATEGORY_LIST,
                payload: decryptData,
            });

        }
       
    } catch (err) {
        Toaster.error(err)
    }
};
export const updatestatus = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        console.log(decryptData,'decryptData');
        if (decryptData[0] == "HaveComplianceSuccess") {
            Toaster.success("User Deactivated Successfully!!")
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        }
      else  if (decryptData[0] == "InvalidPassword") {
            Toaster.error("InvalidPassword!!")
        }
        else{
            Toaster.error(decryptData[0])
        }

    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const activeStatus = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
       console.log(decryptData,'decryptData');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] == "ChangeClientUserStatusSuccess") {
            Toaster.success("User actived Successfully!!")
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        }
        else if (decryptData[0] == "InvalidPassword") {
            Toaster.error("InvalidPassword!!")
        }
        else {
            Toaster.error(decryptData[0])
        }
    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const disableStatusChange= ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload,'payload');
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] == "BlockUserSuccess") {
            Toaster.success("User Disabled Successfully!!")
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        }
    //   else  if (decryptData[0] == "BlockUserSuccess") {
    //         Toaster.success("User Disabled Successfully!!")
    //         setTimeout(() => {
    //             window.location.reload(false);
    //         }, 2000);
    //     }
        else if (decryptData[0] == "InvalidPassword") {
            Toaster.error("InvalidPassword!!")
        }
        else {
            Toaster.error("Failed")
        }
    } catch (err) {
        Toaster.error(err);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const addUserDetails= ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload,'payload12345');
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
        if(decryptData[0]=='EmployeeCodeSuccess'){
            Toaster.success("Employee Code Added")
        }
        // dispatch({
        //     type: USERMANAGEMENT_LIST,
        //     payload: decryptData,
        // });

    } catch (err) {
        Toaster.error(err)
    }
};
export const addUserManagementDetails= ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload);
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
        Toaster.success(decryptData[0])
        setTimeout(() => {
            window.location.reload(false);
        }, 2000);
        // dispatch({
        //     type: USERMANAGEMENT_LIST,
        //     payload: decryptData,
        // });

    } catch (err) {
        Toaster.error(err)
    }
};


export const getById = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload);
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
        dispatch({
            type: GET_BY_UNIT,
            payload: decryptData,
        });

    } catch (err) {
        Toaster.error(err)
    }
};

export const edituserManagementDetails= ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload);
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
        if(decryptData[0]=='UpdateClientUserSuccess')
        {
            Toaster.success("user details updated successfully!!")

        }
        else{
            Toaster.success(decryptData[0])
        }
        setTimeout(() => {
            window.location.reload(false);
        }, 2000);
        // dispatch({
        //     type: USERMANAGEMENT_LIST,
        //     payload: decryptData,
        // });

    } catch (err) {
        Toaster.error(err)
    }
};

export const resendEmail= ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        console.log(payload);
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
          
        if(decryptData[0]=="ResendRegistrationEmailSuccess"){
            Toaster.success("Registration Mail Resent Successfully")
        }
        else{
            Toaster.error(decryptData[0])
        }
        // setTimeout(() => {
        //     window.location.reload(false);
        // }, 2000);
        // dispatch({
        //     type: USERMANAGEMENT_LIST,
        //     payload: decryptData,
        // });

    } catch (err) {
        Toaster.error(err)
    }
};