import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { sessionLogout } from './../Store/Action/Login';
import { useHistory, Link } from "react-router-dom";

const mapStateToProps = (state) => ({});

const Header = ({
    sessionLogout
}) => {
    const history = useHistory();
    const [entityinfo, Setentityinfo] = useState();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const entity_info = sessionValue.entity_info
    const country_info = sessionValue.country_info

    useEffect(() => {
        let entity = localStorage.getItem('SelectedEntity');
        if (!entity && country_info.length > 0) {
            setIsModalVisible(true)
        } else {
            if (!localStorage.getItem('SelectedEntity') && !localStorage.getItem('SelectedEntityid')) {
                entityselected(entity_info[0].le_name, entity_info[0].le_id)
                history.push('/dashboard');
                window.location.reload(false);
            }
        }
    }, [])

    useEffect(() => {
        let entityvalue = localStorage.getItem('SelectedEntity');
        if (entityvalue) {
            Setentityinfo(entityvalue)
        }

    }, [entityinfo])

    const entityselection = () => {
        localStorage.removeItem('SelectedEntity');
        window.location.reload(false);
    }

    const entity_count = entity_info.length
    const entity_sum = entity_info.reduce((total, entity_info) => total = total + entity_info.u_count, 0)

    useEffect(() => {
        let finalArray = [];
        let countrylistdata = [];
        let countrylisttempdata = [];
        for (let countrylist in country_info) {
            let country_id = country_info[countrylist].c_id
            let country_name = country_info[countrylist].c_name
            let entityArray = []
            let tempArray = []
            let sumValue = 0;
            console.log(country_id)
            for (let entitylist in entity_info) {
                let entitylist_cid = entity_info[entitylist].c_id
                if (entitylist_cid === country_id) {
                    sumValue = entity_info[entitylist].u_count + sumValue
                    entityArray = {
                        'bg_id': entity_info[entitylist].bg_id,
                        'bg_name': entity_info[entitylist].bg_name,
                        'c_id': entity_info[entitylist].c_id,
                        'c_name': entity_info[entitylist].c_name,
                        'le_id': entity_info[entitylist].le_id,
                        'le_name': entity_info[entitylist].le_name,
                        'u_count': entity_info[entitylist].u_count,
                    }
                    tempArray.push(entityArray)
                }
            }
            finalArray[country_name] = tempArray
            countrylistdata = {
                'c_id': country_id,
                'c_name': country_name,
                'allEntitySumNew': sumValue,
                'child': tempArray
            }
            countrylisttempdata.push(countrylistdata)
            SetfinalResult({
                ...finalResult,
                Resultdata: countrylisttempdata
            })


        }

    }, [])

    const entityselected = (c_name, c_id) => {
        localStorage.setItem('SelectedEntity', c_name);
        localStorage.setItem('SelectedEntityid', c_id);
        setIsModalVisible(false);
        window.location.reload(false);
    }

    const logout = () => {
        sessionLogout({})
    }


    console.log(finalResult.Resultdata, 'finalResult.Resultdata');

    return (
        <Fragment>
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                    <div className="navbar-header">
                        <a
                            className="nav-toggler waves-effect waves-light d-block d-md-none"
                            href="/#"
                        ><i className="ri-close-line ri-menu-2-line fs-6"></i></a>
                        <div style={{ "height": "73px" }} className="row border-bottom ">
                            <div></div>
                            <div className="col-md-2">
                                <a id="menu-icon" className="nav-link sidebartoggler d-none d-md-block"><i data-feather="menu"></i></a>
                                <a className="nav-link sidebartoggler d-md-block">
                                    <img id="star-icon" className="d-none staricon" src="favicon.png" alt=""></img>
                                </a>
                            </div>
                            <div id="logo-icon" className="col-md-10">
                                <a className="navbar-brand  text-center logo-icon" href="/homepage">
                                    <img
                                        src="logo-icon.png"
                                        alt="homepage"
                                        className="dark-logo w-75 mt-4 "
                                    />
                                </a>
                            </div>
                        </div>
                        <a
                            className="topbartoggler d-block d-md-none waves-effect waves-light"
                            href="/#"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        ><i className="ri-more-line fs-6"></i></a>
                    </div>
                    <div className="navbar-collapse collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                {/* <!-- <a
                  class="nav-link sidebartoggler d-none d-md-block"
                  href="#!"
                  ><i data-feather="menu"></i
                ></a> --> */}
                            </li>

                        </ul>

                        <ul className="navbar-nav">
                            {sessionValue.entity_info.length > 1 ? <li className="nav-item dropdown"><div className="nav-link text-dark" data-bs-toggle="modal"
                                data-bs-target="#bs-example-modal-xlg">{entityinfo}<i className="ri-list-settings-line" onClick={(e) => {
                                    entityselection()
                                }}></i></div></li> : <li className="nav-item dropdown"><div className="nav-link text-dark" data-bs-toggle="modal"
                                data-bs-target="#bs-example-modal-xlg">{entityinfo}<i className="ri-list-settings-line"></i></div></li>}
                            
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle"
                                    href="/#"
                                    id="2"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i data-feather="message-square"></i>
                                    <div className="notify">
                                        <span className="point bg-warning"></span>
                                    </div>
                                </a>
                                <div
                                    className="
                    dropdown-menu
                    mailbox
                    dropdown-menu-end dropdown-menu-animate-up
                  "
                                    aria-labelledby="2"
                                >
                                    <ul className="list-style-none">
                                        <li>
                                            <div
                                                className="rounded-top p-30 pb-2 d-flex align-items-center"
                                            >
                                                <h6 className="card-title mb-0">No Records Found</h6>

                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i data-feather="bell"></i>
                                    <div className="notify">
                                        <span className="point bg-primary"></span>
                                    </div>
                                </a>
                                <div
                                    className="
                    dropdown-menu dropdown-menu-end
                    mailbox
                    dropdown-menu-animate-up
                  "
                                >
                                    <ul className="list-style-none">
                                        <li>
                                            <div
                                                className="rounded-top p-30 pb-2 d-flex align-items-center"
                                            >
                                                <h6 className="card-title mb-0">No Records Found</h6>

                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown profile-dropdown">
                                <a
                                    className="nav-link dropdown-toggle d-flex align-items-center"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <img
                                        src="3.png"
                                        alt="user"
                                        width="30"
                                        className="profile-pic rounded-circle"
                                    />
                                    <div className="d-none d-md-flex">
                                        <span style={{ "lineHeight": "2.3rem" }} className="ms-2 mt-3"
                                        >
                                            <span className="text-dark fw-bold">{sessionValue.login_response.emp_name}</span>

                                        </span>
                                        <span>
                                            <i data-feather="chevron-down" className="feather-sm"></i>
                                        </span>
                                    </div>

                                </a>
                                <div
                                    className="
                    dropdown-menu dropdown-menu-end
                    mailbox
                    dropdown-menu-animate-up
                  "

                                >
                                    <ul className="list-style-none">
                                        <li className="p-30 pb-2 pt-3">
                                            <div className="rounded-top d-flex align-items-center">
                                                <h3 className="card-title mb-0 fs-4">My Accounts</h3>
                                                <div className="ms-auto">
                                                    <Link  className="link py-0">
                                                        <i data-feather="x-circle"></i>
                                                    </Link>
                                                </div>
                                            </div>

                                        </li>
                                        <li className="p-30 pb-2 pt-0">
                                            <div
                                                className="message-center message-body position-relative"
                                                style={{ "height": "200px" }}
                                            >
                                                <a
                                                    href="profile.html"
                                                    className="
                          message-item
                          px-2
                          d-flex
                          align-items-center
                          border-bottom
                          py-1
                        "
                                                >
                                                    <span
                                                        className="btn btn-light-info btn-rounded-lg text-info p-2"
                                                    >
                                                        <i
                                                            data-feather="user"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5
                                                            className="
                              message-title
                              mb-0
                              mt-1
                              fs-3
                              font-weight-medium
                            "
                                                        >
                                                            My Profile
                                                        </h5>

                                                    </div>
                                                </a>
                                                <a
                                                    href="/#"
                                                    className="
                          message-item
                          px-2
                          d-flex
                          align-items-center
                          border-bottom
                          py-1
                        "
                                                >
                                                    <span
                                                        className="btn btn-light-info btn-rounded-lg text-info p-2"
                                                    >
                                                        <i
                                                            data-feather="settings"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5
                                                            className="
                              message-title
                              mb-0
                              mt-1
                              fs-4
                              font-weight-medium
                            "
                                                        >
                                                            Settings
                                                        </h5>

                                                    </div>
                                                </a>

                                                <a
                                                    href="/#"
                                                    className="
                        message-item
                        px-2
                        d-flex
                        align-items-center
                        border-bottom
                        py-1
                      "
                                                >
                                                    <span
                                                        className="btn btn-light-info btn-rounded-lg text-info p-2"
                                                    >
                                                        <i
                                                            data-feather="dollar-sign"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5
                                                            className="
                            message-title
                            mb-0
                            mt-1
                            fs-4
                            font-weight-medium
                          "
                                                        >
                                                            User Guide
                                                        </h5>

                                                    </div>
                                                </a>

                                                <a
                                                    href="/#"
                                                    className="
                      message-item
                      px-2
                      d-flex
                      align-items-center
                      border-bottom
                      py-1
                    "
                                                >
                                                    <span
                                                        className="btn btn-light-info btn-rounded-lg text-info p-2"
                                                    >
                                                        <i
                                                            data-feather="menu"
                                                            className="feather-sm fill-white"
                                                        ></i>
                                                    </span>
                                                    <div className="w-75 d-inline-block v-middle ps-3 ms-1">
                                                        <h5
                                                            className="
                          message-title
                          mb-0
                          mt-1
                          fs-4
                          font-weight-medium
                        "
                                                        >
                                                            Themes
                                                        </h5>

                                                    </div>
                                                </a>

                                            </div>
                                            <div className="mt-2">
                                                <a
                                                    className="btn btn-info text-white"
                                                    onClick={logout}
                                                >
                                                    Logout
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="bi-grid-3x3-gap-fill text-primary"></i>
                                    <div >
                                        <span className="point bg-primary"></span>
                                    </div>
                                </a>
                                <div
                                    className="
                    dropdown-menu dropdown-menu-end
                    mailbox
                    dropdown-menu-animate-up
                    w-75
                  "
                                >
                                    <ul className="list-style-none">

                                        <li className="p-3 pt-0 pb-0 mt-3">
                                            <div
                                                className="message-center message-body position-relative"
                                                style={{ "height": "100%" }}
                                            >
                                                <a
                                                    href="/#"
                                                    className="
                            message-item
                            px-1
                            d-flex
                            align-items-center
                            border-bottom
                            py-2
                          ">
                                                    <img src="logo-icon.png" className="w-75" alt="" />
                                                </a>
                                                <a
                                                    href="/#"
                                                    className="
                            message-item
                            px-1
                            d-flex
                            align-items-center
                            border-bottom
                            py-2
                          "
                                                >
                                                    <img src="litigation-logo.png" className="w-100" alt="" />
                                                </a>
                                                <a
                                                    href="/#"
                                                    className="
                            message-item
                            px-1
                            d-flex
                            align-items-center
                            
                            py-2
                          "
                                                >
                                                    <img src="vendor-logo.png" className="w-100" alt="" />
                                                </a>

                                            </div>

                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <Modal visible={isModalVisible} footer="" closable={false}>
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" >
                            <button
                                className="btn btn-primary w-100"
                                type="button"

                            >
                                <div className="row w-100 text-start rounded-0 p-2">
                                    <div className="col ">
                                        Select Legal Entity
                                    </div>
                                    <div className="col ">
                                        Legal Entity Count
                                    </div>
                                    <div className="col ">
                                        Unit Count
                                    </div>
                                </div>
                            </button>
                        </h2>

                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" >
                            <button
                                className="accordion-button collapsed"
                                type="button"

                            >
                                <div className="row w-100 text-start rounded-0 p-2">
                                    <div className="col" onClick={() => {
                                        localStorage.setItem('SelectedEntity', "All Legal Entity")
                                        localStorage.setItem('SelectedEntityid', "null")
                                        setIsModalVisible(false);
                                        history.push('/dashboard');
                                        window.location.reload(false);
                                    }}>
                                        All Legal Entity
                                    </div>
                                    <div className="col ">
                                        {entity_count}
                                    </div>
                                    <div className="col ">
                                        {entity_sum}
                                    </div>
                                </div>
                            </button>
                        </h2>

                    </div>

                    {finalResult.Resultdata.length > 0 &&
                        finalResult.Resultdata.map((finalList) => (
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">

                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target={"#collapse" + finalList.c_id}
                                        aria-expanded="false"
                                        aria-controls="collapseOne"
                                    >
                                        <div className="row w-100 text-start rounded-0 p-2">
                                            <div className="col">
                                                {finalList.c_name}
                                            </div>
                                            <div className="col">
                                                {finalList.child.length}
                                            </div>
                                            <div className="col">
                                                {finalList.allEntitySumNew}
                                            </div>
                                        </div>
                                    </button>

                                </h2>

                                <div
                                    id={"collapse" + finalList.c_id}
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample">
                                    <div className="accordion-body">
                                        {finalList.child.length > 0 &&
                                            finalList.child.map((childList) => (
                                                <div className="row w-100 pb-2">
                                                    <div className="col">
                                                        <a data-bs-dismiss="modal" className="cur-point" onClick={(e) => {
                                                            entityselected(childList.le_name, childList.le_id)
                                                            history.push('/dashboard');
                                                            window.location.reload(false);
                                                        }}>{childList.le_name}</a>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                    <div className="col">
                                                        {childList.u_count}
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>

                            </div>
                        ))}

                </div>

            </Modal>
        </Fragment >
    )
};


export default connect(mapStateToProps, {
    sessionLogout
})(Header);
