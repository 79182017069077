import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Select, Tooltip, Card,Button, Popover } from 'antd';
import { ArrowRightOutlined, PlusSquareTwoTone, InfoCircleOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import Swal from 'sweetalert2';
import { ApproveTaskRecallUnitList, getApproveTaskRecallList, ApproveTaskRecallSubmit } from './../../Store/Action/Transcations/ApproveTaskRecall'
import DataTable from "react-data-table-component";
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
const mapStateToProps = (state) => ({
    list: state.ApproveTaskRecallCompliance.AssignTaskRecall,
    filterrecall: state.recallfilters.recallfilters
})


const ApproveTaskRecall = ({
    ApproveTaskRecallUnitList,
    getApproveTaskRecallList,
    ApproveTaskRecallSubmit,
    GetRemarksData,
    filterrecall: { recallremarks },
    list
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const { Option } = Select;
    let temparray = []
    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: '',
        frequency: '',
        asignee: '',
        legal_entity: ''
    })
    const [recalldata, Setrecalldata] = useState({
        action: '',
        remarks: ''
    })
    const [temparr, setTemparr] = useState([])
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const _ = require("lodash");
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [checkedRow, setCheckedRow] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [checkedRowData, setCheckedRowData] = useState([{
        "compliance_id": '',
        "compliance_history_id": '',
        "unit_idl": '',
        "download_doc": [],
        "reviewer_doc": [],
        "interm_doc": [],
        "interim_docs_size": '',
        "reviwer_docs_size": '',
        "document_size": '',
        "recall_status": '',
        "recall_id": ''
    }]);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [filteredArray, setFilteredArray] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showValueModal, setShowValueModal] = useState(false)
    const [acccordionTrue, setAccordionTrue] = useState(false);
    const [passwordmodal, Setpasswordmodal] = useState(false);
    const [FormRecall, setFormRecall] = useState(false);
    const [FormShow, setFormShow] = useState(false);
    const formValidator = useRef(new SimpleReactValidator());
    const recallSubmit = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const [isComplainceModalVisible, setIsComplainceModalVisible] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const dataSource = [
        {
            key: '1',
            compliance_task: 'The Contract labour(Regulation Act 1997)The tamilNadu Contact Labour(Regulations)Rule 1975',
            assignee: 'AS121-Assignee',
            compliance_status: 'Compiled',
            due_date: '30-April-2022',
            completion_date: '28-Apri',
        },
    ];
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    console.log(entityid, 'entityid')
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    const columns = [
        {
            name: <Fragment>
                <input type="checkbox"
                    onClick={(e) => {
                        let checked = e.target.checked;
                        addAllSelectedDataValues(
                            checked
                        );
                    }}
                />
            </Fragment>,
            selector: (row, rowIndex) => {
                return (
                    <input type="checkbox"
                        name='checked'
                        onClick={(e) => {
                            let checked = e.target.checked;
                            addSelectedDataValues(
                                checked,
                                row.compliance_history_id,
                            );
                        }}
                    />

                )
            }
        },
        {
            name: "S.No",
            selector: (row, index) => {
                return (
                    <>
                        <p>{index + 1}</p>
                        {row && row.compliance_frequency == 'Review' ?
                            <Tooltip title={row.compliance_frequency}><p><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip> :
                            <Tooltip title={row.compliance_frequency}><p><i style={{ color: "green" }} class="bi bi-square-fill"></i></p></Tooltip>
                        }
                    </>
                )
            },
            width: '100px',
            sortable: true,
            // right: true,
            reorder: true
        },
        {
            name: "Compliance Task",
            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            selector: (row) => {
                const content = (
                    <table style={{width: "800px"}}class="table table-striped">
                    <tbody>
                                <tr>
                                    <th>Unit Name</th>
                                    <td>{row.unit_name}</td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>{row.address}</td>
                                </tr>
                                <tr>
                                    <th>Division</th>
                                    <td>{row.division_name}</td>
                                </tr>
                                <tr>
                                    <th>Category</th>
                                    <td>{row.category_name}</td>
                                </tr>
                                <tr>
                                    <th>Act/Rules</th>
                                    <td>{row.statu}</td>
                                </tr>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{row.compliance_description}</td>
                                </tr>
                                <tr>
                                    <th>Penal Consequqnces</th>
                                    <td>{row.penal_consequences}</td>
                                </tr>
                                </tbody>
                    </table>
                    );
                return (
                    <>
                        <p className="mt-3"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    compliance_history_id: row.compliance_history_id,
                                    unit: row.unit_name,
                                    compliance_task: row.compliance_name,
                                    compliance_frequency: row.compliance_frequency,
                                    document_issued_date: row.completion_date,
                                    document_reference_number: row.document_reference_number,
                                    next_due_date: row.next_due_date,
                                    address: row.address,
                                    compliance_description: row.compliance_description,
                                    uploaded_document: row.upload_docs,
                                    interim_details: row.interim_docs,
                                    remarks: row.remarks

                                })
                            }}
                        >{row.statu}</p>
                        <div className="mb-3">
                        <div className="mb-3">
                            <Popover content={content} title="Lorem">
                                <Button style={{border:0}}><i class="bi-info-circle-fill"></i></Button>
                            </Popover>
                                {row.compliance_name}
                            </div>
                        </div>
                    </>
                )
            },
            sortable: true,
            width: '250px',
            reorder: true,
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            name: "Assignee",
            selector: (row) => row.assignee_name,
            sortable: true,
            width: '150px',
            reorder: true,
            // sorter: (a, b) => { return a.assignee_name.localeCompare(b.assignee_name) },
            // filters: [
            //     {
            //         text: 'Lulu Software Services Private Limited',
            //         value: 'Lulu Software Services Private Limited',
            //     },

            // ],
            // onFilter: (value, record) => record.legal_entity.indexOf(value) === 0,

        },
        {
            name: "Compliance Status",
            selector: (row) => row.task_status,
            sortable: true,
            width: '150px',
            reorder: true,
            // sorter: (a, b) => { return a.task_status.localeCompare(b.task_status) },
            // filters: [
            //     {
            //         text: 'North',
            //         value: 'North',
            //     },
            //     {
            //         text: 'South',
            //         value: 'South',
            //     },
            // ],
            // onFilter: (value, record) => record.division.indexOf(value) === 0
        },
        {
            name: "Due Date",
            selector: (row) => row.due_date,
            sortable: true,
            width: '150px',
            reorder: true,
            // sorter: (a, b) => { return a.due_date.localeCompare(b.due_date) },
            // filters: [
            //     {
            //         text: 'Jammu and Kashmir',
            //         value: 'Jammu and Kashmir',
            //     },
            //     {
            //         text: 'Tamilnadu',
            //         value: 'Tamilnadu',
            //     },
            // ],
            // onFilter: (value, record) => record.category.indexOf(value) === 0,

        },
        {
            name: "Compliance Date",
            selector: (row) => row.completion_date,
            sortable: true,
            width: '150px',
            reorder: true,
            // sorter: (a, b) => { return a.completion_date.localeCompare(b.completion_date) },

            // filters: [
            //     {
            //         text: 'TN-2127',
            //         value: 'TN-2127',
            //     },
            //     {
            //         text: 'JK-2197',
            //         value: 'JK-2197'
            //     }

            // ],
            // onFilter: (value, record) => record.unit.indexOf(value) === 0,
        },
    ];

    const [valueModal, setValueModal] = useState({
        unit: '',
        address: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: '',
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        compliance_description: '',
        interim_details: ''
    })
    const [password, Setpassword] = useState({
        passwordvalue: '',
    })

    const Remarkscolumn = [
        {
            name: "Sno",
            selector: (row) => row.r_id,
            sortable: true,
            width: '250px',
            // reorder: true,
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        }, {
            name: "Description",
            selector: (row) => <a onClick={() => {
                Setrecalldata({ ...recalldata, remarks: row.r_description })
                setRemarksModal(false)
            }}>{row.r_description}</a>,
            sortable: true,
            width: '450px',
            // reorder: true,
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        }
    ]
    const showValue = () => {
        setShowValueModal(true);
    };
    const passwordcancel = () => {
        Setpasswordmodal(false);
        setAddFormSubmit2(false);
    }
    // const submitpassword = (e) => {
    //     e.preventDefault();
    //     const payload = [
    //         authtoken,
    //         {
    //             "session_token": authtoken,
    //             "request": [
    //                 "",
    //                 {
    //                     "le_id": password.le_id,
    //                     "d_id": password.d_id,
    //                     "u_id": password.u_id,
    //                     "lock": password.lock,
    //                     "password": password.passwordvalue
    //                 }
    //             ]
    //         }
    //     ]
    //     // [
    //     //     authtoken,
    //     //     {
    //     //         "session_token": authtoken,
    //     //         "request": [
    //     //             "UpdateTaskRecallStatus",
    //     //             {
    //     //                 "le_id": entityid,
    //     //                 "check_list": [
    //     //                     {
    //     //                         "compliance_id": 8190,
    //     //                         "compliance_history_id": 91,
    //     //                         "unit_idl": 38884,
    //     //                         "download_doc": [],
    //     //                         "reviewer_doc": [],
    //     //                         "interm_doc": [],
    //     //                         "interim_docs_size": 0,
    //     //                         "reviwer_docs_size": 0,
    //     //                         "document_size": 0,
    //     //                         "recall_status": 0,
    //     //                         "recall_id": null
    //     //                     }
    //     //                 ],
    //     //                 "action": "1",
    //     //                 "remarks": "test",
    //     //                 "password": "Info@123",
    //     //                 "pwd_validate": true
    //     //             }
    //     //         ]
    //     //     }
    //     // ]
    //     Setpasswordmodal(false);
    // }
    const submitpassword = (e) => {
        setAddFormSubmit2(true)

        e.preventDefault();
        if (formValidator2.current.allValid()) {

            let temp = []
            for (let i in temparr) {
                temp.push({
                    "compliance_id": temparr[i].compliance_id,
                    "compliance_history_id": temparr[i].compliance_history_id,
                    "unit_idl": temparr[i].unit_id,
                    "download_doc": temparr[i].download_url,
                    "reviewer_doc": temparr[i].reviwer_newdoc,
                    "interm_doc": temparr[i].interm_newdoc,
                    "interim_docs_size": Number(temparr[i].interim_docs_size),
                    "reviwer_docs_size": Number(temparr[i].reviwer_docs_size),
                    "document_size": temparr[i].document_size,
                    "recall_status": temparr[i].recall_status,
                    "recall_id": temparr[i].recall_id
                })
            }

            ApproveTaskRecallSubmit({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateTaskRecallStatus",
                            {
                                "le_id": entityid,
                                "check_list": temp,
                                "action": recalldata.action,
                                "remarks": recalldata.remarks,
                                "password": password.passwordvalue,
                                "pwd_validate": true
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
            Setpasswordmodal(false);
        }
    }

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };
    const Active = () => {
        setFormRecall(true)
        if (recallSubmit.current.allValid()) {
            Swal.fire({
                title: 'Are you sure?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
            }).then((result) => {
                if (result.isConfirmed) {
                    Setpasswordmodal(true)
                }
            })
        }
    }
    const data = [
        'Racing car sprays burning fuel into crowd.',
        'Japanese princess to wed commoner.',
    ];
    const { Panel } = Collapse;
    const { Search } = Input;

    // const showModal = () => {
    //     setIsModalVisible(true);
    // };

    const handleOk = () => {
        setIsModalVisible(false);
        setIsComplainceModalVisible(false);
        setRemarksModal(false);
        setShowValueModal(false)
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsComplainceModalVisible(false);
        setRemarksModal(false);
        setShowValueModal(false)
    };
    useEffect(() => {
        formValidator.current.showMessages()
        recallSubmit.current.showMessages()
        formValidator2.current.showMessages()

    }, [])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "TaskRecallFilters",
                        {
                            "le_id": entityid,

                        }
                    ]
                }
            ]


            ApproveTaskRecallUnitList({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, entityid])

    const showComplianceModal = () => {
        setIsComplainceModalVisible(true);
    };



    const gettaskrecalllist = () => {
        setFormShow(true)
        if (formValidator.current.allValid()) {
            setAccordionTrue(true)
            getApproveTaskRecallList({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskRecallList",
                                {
                                    "le_id": entityid,
                                    // "unit_id": Number(showdata.unit),
                                    "unit_id": null,
                                    "d_id": Number(showdata.domain),
                                    "frequency_id": Number(showdata.frequency),
                                    "compl_date": "February-2022",
                                    "parent_id": null,
                                    "user_id": null,
                                    "start_count": 0,
                                    "task_status": "Approver Task Recall"

                                }
                            ]
                        }
                    ],
                paramid: paramid
            })
        }
    }
    useEffect(() => {
        let temparr = []
        for (let i in checkedRow) {
            temparr.push({
                compliance_id: checkedRow[i].compliance_id,
                compliance_history_id: checkedRow[i].compliance_history_id,
                unit_idl: checkedRow[i].unit_id,
                download_doc: [],
                reviewer_doc: checkedRow[i].reviewer_doc,
                interim_docs_size: checkedRow[i].interim_docs_size,
                reviwer_docs_size: checkedRow[i].reviwer_docs_size,
                document_size: checkedRow[i].document_size,
                recall_status: checkedRow[i].recall_status,
                recall_id: checkedRow[i].recall_id
            })
        }
        setCheckedRowData(temparr)
    }, [checkedRow])
    useEffect(() => {
        if (list && list.approverecallcompliance && list.approverecallcompliance.length > 0) {
            let uniqueObjArray = [...new Map(list && list.approverecallcompliance && list.approverecallcompliance.length && list.approverecallcompliance.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(list && list.approverecallcompliance, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([...filteredArray, tempArr])
            }
        }
    }, [list && list.approverecallcompliance])
    const addAllSelectedDataValues = (checked) => {
        var ele = document.getElementsByName('checked');
        if (checked === true) {
            setCheckedRow(true)
            setTemparr([...list && list.approverecallcompliance])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            setTemparr([])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }
    const addSelectedDataValues = (checked, compliance_history_id) => {
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(list && list.approverecallcompliance, { compliance_history_id: compliance_history_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].compliance_history_id === compliance_history_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked === true) {
                array = _.find(list && list.approverecallcompliance, { compliance_history_id: compliance_history_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].compliance_history_id === compliance_history_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

 
    console.log(list, 'list');
    console.log(acccordionTrue, "acccordionTrue");
    console.log(recalldata, 'recalldata');
    console.log(showdata, 'showdata');
    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;" className="link"><i className="ri-home-3-line fs-5"></i></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Transaction
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Approve Task Recall
                                </li>
                            </ol>
                        </nav>
                        <h2 className="mb-0 fw-bold">Approve Task Recall</h2>
                    </div>                   
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <Collapse accordion defaultActiveKey={['1']}>
                                <Panel header="Approve Task Recall" key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Legal Entity</b><span style={{ color: "red" }}>*</span></label><br />
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Legal Entity"
                                                                onChange={(data, value) => {
                                                                    setCurrentEntity(data)
                                                                    Setshowdata({
                                                                        ...showdata,
                                                                        legal_entity:data
                                                                    })
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                // value={showdata.legal_entity}
                                                                style={{ width: '300px' }}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'legal_entity',
                                                                showdata.legal_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain</b> <span style={{ "color": "red" }}>*</span></label><br />
                                                    <Select

                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Entity"
                                                        onChange={(data) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                domain: data
                                                            })
                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={filter.seller}
                                                        style={{ width: '300px' }}
                                                    >
                                                        {list && list.domain.length && list.domain.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'domain',
                                                        showdata.domain,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Unit</b> </label><br />
                                                    <Select

                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Entity"
                                                        onChange={(data) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: data
                                                            })
                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={filter.seller}
                                                        style={{ width: '300px' }}
                                                    >
                                                        {list && list.unitdata.length && list.unitdata.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency</b></label><br />
                                                    <Select

                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Entity"
                                                        onChange={(data) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: data
                                                            })
                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={filter.seller}
                                                        style={{ width: '300px' }}
                                                    >
                                                        <Option key={0}>All</Option>
                                                        {list && list.frequency.length && list.frequency.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Asignee</b></label><br />
                                                    <Select

                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter Entity"
                                                        onChange={(data) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                asignee: data
                                                            })
                                                        }}
                                                        // onSearch={onSearch}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={filter.seller}
                                                        style={{ width: '300px' }}
                                                    >
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                        <a href="javascript:;" style={{ marginLeft: '40%' }} onClick={() => { gettaskrecalllist() }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a>
                                    </div>
                                </Panel>
                            </Collapse>
                            {acccordionTrue == true ?
                                <div className="mt-2">
                                    <Collapse defaultActiveKey={['1']} accordion>
                                        {filteredArray && filteredArray.map((item, i) => {
                                            return (

                                                <Panel header="Compliance Table" key="1">
                                                    {/* <Table dataSource={dataSource} columns={columns} pagination={false} /> */}
                                                    <Card
                                                        title={item && item.length && item[i][0].unit_name}
                                                    >
                                                        <DataTable

                                                            columns={columns}
                                                            data={list && list.approverecallcompliance.length && list.approverecallcompliance}
                                                            // defaultSortFieldId={1}
                                                            // pagination
                                                            selectableRows={false}
                                                        // selectableRowsSingle={true}
                                                        //    onSelectedRowsChange={({
                                                        //        allSelected,
                                                        //        selectedCount,
                                                        //        selectedRows,
                                                        //        // allSelected
                                                        //    }) => {

                                                        //        console.log(selectedRows, 'selectedRows');
                                                        //       for(let i in selectedRows)
                                                        //       {
                                                        //        let tempArray=[]

                                                        //            tempArray.push({
                                                        //                unit_id: Number(unit.unit),
                                                        //                compliance_id: selectedRows[i].compliance_id,
                                                        //                due_date: selectedRows[i].due_date,
                                                        //                completion_date:date,
                                                        //                documents: [{
                                                        //                    file_size: parseInt(fileData.file_size),
                                                        //                    file_name: fileData.file_name,
                                                        //                    file_content: null
                                                        //                }],
                                                        //                validity_date: null,
                                                        //                pr_completed_by: 2,
                                                        //                amendment_history_id: selectedRows[i].amendment_history_id
                                                        //            })
                                                        //           setSavePastRecords([...SavepastRecords,...tempArray])
                                                        //       }

                                                        //    }
                                                        //    }
                                                        // selectableRowsComponentProps={"Completed"}

                                                        />
                                                    </Card>
                                                </Panel>
                                            )
                                        })
                                        }
                                    </Collapse>
                                    <div className="col-md-12 m-5">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Action <span style={{ "color": "red" }}>*</span></label>
                                                    <select name="action" className='select2 form-control custom-select bg-white' id="action"
                                                        onChange={(e) => {
                                                            Setrecalldata({
                                                                ...recalldata,
                                                                action: e.target.value
                                                            })
                                                        }}
                                                    >
                                                        <option value="0"> Please Select </option>
                                                        <option value="1"> Rectify & Retain Document </option>
                                                        <option value="2"> Rectify & Remove Document </option>
                                                    </select>
                                                    {recallSubmit.current.message(
                                                        'action',
                                                        recalldata.action,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Remarks <span style={{ "color": "red" }}>*</span></label>
                                                    <textarea name="remarks" className='form-control'
                                                        value={recalldata.remarks ? recalldata.remarks : ""} id="remarks" cols="5" rows="5"
                                                        onChange={(e) => {
                                                            Setrecalldata({
                                                                ...recalldata,
                                                                remarks: e.target.value
                                                            })
                                                        }}
                                                    ></textarea>
                                                    {recallSubmit.current.message(
                                                        'remarks',
                                                        recalldata.remarks,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <PlusSquareTwoTone onClick={() => { showRemarksModal() }} />
                                            </div>
                                            <Modal title="Remarks" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
                                                <DataTable columns={Remarkscolumn}
                                                    data={recallremarks}
                                                    // defaultSortFieldId={1}
                                                    pagination />
                                            </Modal>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                                    <a href="javascript:;" onClick={Active} style={{ marginLeft: '10%' }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Approve</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            <Modal title="Domain" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                <Search
                                    placeholder="Search"
                                    style={{
                                        width: 600,
                                    }}
                                />
                            </Modal>
                            {/* <Modal title="Domain" visible={isComplainceModalVisible} onOk={handleOk} onCancel={handleCancel}>
                                <p>The Contract labour(Regulation Act 1997)The tamilNadu Contact Labour(Regulations)Rule 1975</p>
                            </Modal> */}

                            <Modal title="Domain" visible={showValueModal} footer={false} onCancel={handleCancel} width={50}>
                                <Card>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Unit</h5></label>
                                                </div>
                                                <div className="col-md-1 mt-3">
                                                    <Tooltip title={valueModal.address}>
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                                <div className="col-md-5 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.unit}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Compliance Task</h5></label>
                                                </div>
                                                <div className="col-md-1 mt-3">
                                                    <Tooltip title={valueModal.compliance_description}>
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                                <div className="col-md-5 mt-3">
                                                    <p>{valueModal.compliance_task}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Compliance Frequency</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.compliance_frequency}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Uploaded File Size</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Attached Documents</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.uploaded_document}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Task Completion Date/Document Issued Date</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.document_issued_date}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Document Reference Number</h5></label>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.document_reference_number}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Remarks</h5></label>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.remarks}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>interim details</h5></label>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    <p style={{ float: 'left' }}>{valueModal.interim_details}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6 mt-3">
                                                    <label htmlFor=""><h5>Recall Remarks</h5></label>
                                                </div>
                                                <div className="col-md-4 mt-3">
                                                    {/* <p style={{ float: 'left' }}>{valueModal.interim_details}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* {valueModal.action == 'Rectify' ?
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6 mt-3">
                                                        <label htmlFor=""><h5>Remarks</h5></label>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <textarea name="" className='form-control' id="" cols="10" rows="5"></textarea>
                                                    </div>
                                                </div>
                                            </div> : ''
                                        } */}
                                    </div>
                                </Card>
                            </Modal>
                            <Modal visible={passwordmodal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-6">
                                            <label className="control-label">Password:</label>
                                            <span style={{ "color": "red" }}>*</span>
                                            <input
                                                style={{ marginTop: "10px" }}
                                                type="password"
                                                id="password"
                                                name='passwordvalue'
                                                className="form-control"
                                                placeholder="Enter password"
                                                onChange={(e) => {
                                                    Setpassword({
                                                        ...password,
                                                        passwordvalue: e.target.value
                                                    })
                                                }}
                                            // value={password.passwordvalue}
                                            />
                                            {formValidator2.current.message(
                                                'Password',
                                                password.passwordvalue,
                                                ['required'],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        regex: 'Enter Password'
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="form-actions">
                                    <button
                                        className="btn btn-light-success rounded-pill px-4 text-success"
                                        onClick={submitpassword}
                                    >
                                        {/* <div className="d-flex align-items-center">
                                            <i
                                                data-feather="save"
                                                className="feather-sm me-1 fill-icon"
                                            ></i> */}
                                        Submit
                                        {/* </div> */}
                                    </button>
                                    <button
                                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                                        onClick={passwordcancel}>
                                        {/* <div className="d-flex align-items-center">
                                            <i data-feather="save" className="feather-sm me-1 fill-icon"></i> */}
                                        Cancel
                                        {/* </div> */}
                                    </button>
                                </div>

                            </Modal>

                            {/* <Modal title="Remarks" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
                                <p>Test</p>
                            </Modal> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default connect(mapStateToProps, {
    ApproveTaskRecallUnitList,
    getApproveTaskRecallList,
    GetRemarksData,
    ApproveTaskRecallSubmit
})(ApproveTaskRecall)