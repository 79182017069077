import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Input, Row, Col } from 'antd';
import SimpleReactValidator from "simple-react-validator";
const mapStateToProps = () => ({});
const MyProfile = ({

}) => {
    const { Search } = Input;
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [profile, setProfile] = useState({
        email_id: '',
        emp_contact_country_code: '',
        emp_contact_code: '',
        emp_contact_no: '',
        mobile_no_country_code: '',
        mobile_no: '',
        address: ''
    })
    const [show, setShow] = useState(false)
    // const [Entity, setEntity] = useState("All Legal Entity")
    const [modalVisible, setModaVisible] = useState(false)
    const onUserDetailOnChange = (e) => {
        setProfile({
            ...profile,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        validator.current.showMessages()
    }, [])
    const userDetailOnSubmit = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            // setSelectUnit(true)
        }
    }
 
    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <a href="index.html" className="link"
                                            ><i className="ri-home-3-line fs-5"></i></a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            My Profile
                                        </li>
                                    </ol>
                                </nav>
                                <h1 className="mb-0 fw-bold">My Profile </h1>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="">Employee Name</label>
                                                        <input type="text" className='form-control' name="emp_name" id="" />
                                                    </div>

                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="">Employee ID</label>
                                                        <input type="text" className='form-control' />
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="">Short Name</label>
                                                            <input type="text" className='form-control' />
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="form-group">
                                                                <label htmlFor="">User ID</label>
                                                                <input type="text" className='form-control' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="">Email ID</label>  <span style={{ "color": "red" }}>*</span>
                                                            <input type="text" className='form-control' onChange={onUserDetailOnChange} value={profile.email_id} name="email_id" />
                                                            {validator.current.message(
                                                                'emailId',
                                                                profile.email_id,
                                                                ['required',{
                                                                    regex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                }],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                        regex: 'Enter valid Email'
                                                                    }
                                                                })}               </div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <div className="form-group">
                                                                <label htmlFor="">User Group</label>
                                                                <input type="text" className='form-control' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="">Contact No</label>
                                                            {/* <input type="text" className='form-control' name="emp_contact_no" onChange={onUserDetailOnChange} id="" value={userDetails.emp_contact_no} /> */}
                                                            <Input.Group size="default">
                                                                <Row gutter={8}>
                                                                    <Col span={4}>
                                                                        <Input placeholder='+00' name='emp_contact_country_code' onChange={onUserDetailOnChange}  />
                                                                        {validator.current.message(
                                                                        'emp_contact_country_code',
                                                                        profile.emp_contact_country_code,
                                                                        [{ regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                    </Col>
                                                                    <Col span={4}>
                                                                        <Input placeholder='000' name='emp_contact_code' onChange={onUserDetailOnChange}  />
                                                                        {validator.current.message(
                                                                        'emp_contact_code',
                                                                        profile.emp_contact_code,
                                                                        [{ regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                    </Col>
                                                                    <Col span={10}>
                                                                        <Input placeholder='1234567890' name='emp_contact_no' onChange={onUserDetailOnChange}  />
                                                                        {validator.current.message(
                                                                        'emp_contact_no',
                                                                        profile.emp_contact_no,
                                                                     [{ regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                    </Col>
                                                                </Row>
                                                            </Input.Group>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="">Address</label>
                                                            {/* <input type="text" className='form-control' name="emp_contact_no" onChange={onUserDetailOnChange} id="" value={userDetails.emp_contact_no} /> */}
                                                            <textarea name="address" id="" className='form-control' onChange={onUserDetailOnChange}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="">Mobile No</label> <span style={{ "color": "red" }}>*</span>
                                                            {/* <input type="text" className='form-control' name="emp_contact_no" onChange={onUserDetailOnChange} id="" value={userDetails.emp_contact_no} /> */}
                                                            <Input.Group size="default">
                                                                <Row gutter={8}>
                                                                    <Col span={4}>
                                                                        <Input placeholder='+00' name='mobile_no_country_code' onChange={onUserDetailOnChange} value={profile.mobile_no_country_code} />
                                                                        {validator.current.message(
                                                                        'mobile_no_country_code',
                                                                        profile.mobile_no_country_code,
                                                                        ['required', { regex: /^(\+?\d{1,3}|\d{1,4})$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Required Field Missing',
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                    </Col>
                                                                   

                                                                    <Col span={10}>
                                                                        <Input placeholder='1234567890' name='mobile_no' onChange={onUserDetailOnChange} value={profile.mobile_no} />
                                                                        {validator.current.message(
                                                                        'mobile_no',
                                                                        profile.mobile_no,
                                                                        ['required', { regex: /^(\+\d{1,3}[- ]?)?\d{10}$/ }],
                                                                        {
                                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                            messages: {
                                                                                required: 'Required Field Missing',
                                                                                regex: 'Enter valid mobile number'
                                                                            }
                                                                        })}
                                                                    </Col>
                                                                 
                                                                </Row>
                                                            </Input.Group>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-6"></div>
                                                </div>
                                            </div>
                                        </div><br />
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div
                                                    className="
                col-lg-6 col-md-5
                d-none d-md-flex
               ">
                                                    <Link className="btn btn-light-primary text-primary btn-lg px-4 fs-4" onClick={userDetailOnSubmit}

                                                    >Submit</Link>
                                                </div>
                                                <div
                                                    className="
                col-lg-6 col-md-5
                d-none d-md-flex
               ">
                                                    <Link to='/changePassword' className="btn btn-light-primary text-primary btn-lg px-4 fs-4"
                                                        style={{ 'marginRight': '50%' }}
                                                    >Change Password</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
})(MyProfile);