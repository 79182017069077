import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { COMPLIANCE_APPROVAL_LIST,COMPLIANCE_APPROVAL_FILTER_LIST} from '../../types/index'


const initialState = {
    complianceApproval: {
        complianceList: '',
        complianceFrequencyList:'',
        complianceUnitList:'',
        complianceDomainList:'',
        complianceUserList:'',
        editdatas:'',
        filterdata:''
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case COMPLIANCE_APPROVAL_LIST: {
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    complianceList: payload[1].approval_list,
                },
            };
        }
        case COMPLIANCE_APPROVAL_FILTER_LIST: {
            return {
                ...state,
                complianceApproval: {
                    ...state.complianceApproval,
                    complianceFrequencyList: payload[1].frequency_list,
                    complianceUnitList:payload[1].user_units,
                    complianceDomainList:payload[1].domains_list,
                    complianceUserList:payload[1].users_list
                },
            };
        }

        // case STATUTORYSEDIT:{
        //     return {
        //         ...state,
        //         complianceApproval: {
        //             ...state.complianceApproval,
        //             editdatas: payload[1].applicable_statu,
        //         },
        //     };   
        // }
        // case STATUTORYFILTERDATA:{
        //     return {
        //         ...state,
        //         complianceApproval: {
        //             ...state.complianceApproval,
        //             filterdata: payload[1],
        //         },
        //     };   
        // }
        default:
            return state;
    }
}