import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation } from 'react-router-dom';
import { Collapse, Input, Modal, Table, List, Row, Tooltip, Card, Select, Tree, Form } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';
import { getViewOnlyRestrictedList, getViewOnlyRestUnitData, saverecords } from '../../Store/Action/Master/ViewOnlyRestricted';
import { indexOf, merge } from 'lodash';
import { Toaster } from '../../Libs/Toaster';
const _ = require("lodash");


const mapStateToProps = (state) => ({
    viewOnlyRestList: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestList,
    viewOnlyRestUnitList: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestUnitList,
    viewOnlyRestCompDetails: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.viewOnlyRestCompDetails,
    access_info: state.ViewOnlyRestictedList.ViewOnlyRestrictedList.access_info
})

const ViewOnlyRestricted = ({
    viewOnlyRestList,
    getViewOnlyRestrictedList,
    viewOnlyRestUnitList,
    getViewOnlyRestUnitData,
    viewOnlyRestCompDetails,
    saverecords,
    access_info
}) => {
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Search } = Input;
    const location = useLocation();
    const [show, setShow] = useState(false)
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const paramid = location.pathname;
    // const entityid = localStorage.getItem('SelectedEntityid');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const validator = useRef(new SimpleReactValidator());
    const [complianceTask, setComplianceTask] = useState([])
    const [branchName, setBranchName] = useState([])
    const [uniqueValue, setUniqueValue] = useState([])
    const [output, setOutput] = useState()
    const [userId, setUserId] = useState()
    const [legalentity, setLegalentity] = useState()
    const [value, setValue] = useState()
    const [temp, setTemp] = useState({
        tempData: []
    });
    const [state, setState] = useState([])
    const [data, setData] = useState([]);
    const [tempState, setTempState] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [complianceIds, setComplianceIds] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [existingChecked, setExistingChecked] = useState([]);
    const [existingUnits, setExistingUnits] = useState([]);

    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    console.log(data, 'complianceTasks1')
    console.log(checkedKeys, 'complainceTasks2')

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    const onChange = (key) => {
        console.log(key);
    };
    const HandelChange = (obj, value) => {
        setComplianceTask(obj)
        let diff = Object.keys(checkedKeys).filter(x => !obj.includes(x));
        for (let i = 0; i < value.length; i++) {
            setBranchName(value && value[i].children)
        }
    };

    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetViewonlyRestList",
                        {}
                    ]
                }
            ]
            getViewOnlyRestrictedList({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth, show])

    useEffect(() => {
        validator.current.showMessages()
    }, [])


    useEffect(() => {
        console.log(access_info, 'complianceTasks')
        if (complianceTask && complianceTask.length > 0) {
            const arr = complianceTask && complianceTask
            const obj = viewOnlyRestCompDetails && viewOnlyRestCompDetails[0]
            const select = (arr, obj) => arr.reduce((r, prop) => {
                obj.hasOwnProperty(prop) && (r[prop] = obj[prop]);
                return r;
            }, {});
            const output = select(arr, obj);
            setOutput(output)

        }
        if (access_info) {
            let tempAccessInfo = [];
            let finalKeyWithValue = [];
            let keys;
            let strOutput = [];
            keys = Object.keys(access_info[0])
            Object.values(access_info[0]).map((access_info_list, index) => {
                access_info_list.map((access_info_list_child) => {
                    finalKeyWithValue = keys[index] + "-" + access_info_list_child
                    tempAccessInfo.push(finalKeyWithValue)
                })


            })
            setExistingChecked(tempAccessInfo)
            setExpandedKeys(tempAccessInfo)
            setCheckedKeys(access_info[0])
        }
    }, [complianceTask])

    useEffect(() => {

        if (access_info && access_info != null) {
            if (access_info[0] && access_info.length > 0) {
                setExistingUnits(Object.keys(access_info[0]));
                setComplianceTask(Object.keys(access_info[0]))
            }
        }
    }, [access_info])

    useEffect(() => {
        let tempArrayState = []
        let tempArray = []
        let separatedArray = [];
        let finalArrayPush = [];
        if (output) {
            Object.entries(output).forEach(([key, value]) => {

                if (value && value.length > 0) {
                    let arr = [];

                    let tempSetArray = [];
                    const pmenus = value.map(function (user) {
                        if ((arr.indexOf(user.statutory) < 0))
                            arr.push(user.statutory);
                    });
                    let totalListNew = [];
                    let countrylisttempdata = [];
                    let tempComplianceId = []
                    arr.map((pmenu, index) => {
                        let totalList = []
                        let entityArray = []
                        let tempArray = []


                        if (pmenu) {
                            {
                                value && value.map((subMenuList, childIndex) => {
                                    if (pmenu == subMenuList.statutory) {
                                        entityArray = {
                                            'level': 3,
                                            'unit_id': key,
                                            'comp_id': subMenuList.compliance_id,
                                            'key': key + "-" + subMenuList.compliance_id,
                                            'title': subMenuList.compliance_task,
                                        }
                                        tempArray.push(entityArray)
                                        tempComplianceId.push(subMenuList.compliance_id)
                                    }

                                })
                            }
                        }
                        totalList = {
                            'level': 2,
                            'unit_id': key,
                            'title': pmenu,
                            'key': key + "_" + index,
                            'children': tempArray,
                            'childComplianceId': tempComplianceId,
                        }
                        countrylisttempdata.push(totalList)

                    })
                    const unit_name = viewOnlyRestUnitList.find(element => {
                        return element.unit_id == parseInt(key);
                    })
                    totalListNew = {
                        'level': 1,
                        'title': unit_name.unitname,
                        'key': key,
                        'children': countrylisttempdata,
                        'childComplianceId': tempComplianceId
                    }
                    finalArrayPush.push(totalListNew)
                }


            });
            setData(finalArrayPush)
        }

    }, [output])

    let unit_id = '';
    let yArray = {};
    let myArray = [];
    let x = [];
    const onCheck = (checkedKeysValue) => {
        //let splitStr = checkedKeysValue.split("-")
        console.log(checkedKeysValue, 'check1')
        setExistingChecked(checkedKeysValue)
        let compliance_id = [];
        for (let i = 0; i < checkedKeysValue.length; i++) {

            let str = checkedKeysValue[i];

            if (str.indexOf('_') == -1) {
                if (str.indexOf('-') != -1) {
                    let arr1 = str.split('-')
                    unit_id = arr1[0]
                    if (!yArray[unit_id])
                        yArray[unit_id] = [];

                    if (yArray[unit_id].indexOf(arr1[1]) < 0)
                        yArray[unit_id].push(arr1[1]) //compliance_id.push(arr1[1])                   
                    x.push(arr1[1])
                } else {
                    //let arr1 = str.split('');
                    unit_id = str;
                    if (!yArray[unit_id])
                        yArray[unit_id] = [];
                }
            } else {
                let arr1 = str.split('_');
                unit_id = arr1[0];
                if (!yArray[unit_id])
                    yArray[unit_id] = [];

            }

        }

        myArray = yArray;
        setCheckedKeys(myArray);
    };

    const saveRecords = (e) => {
        e.preventDefault();
        console.log(checkedKeys, 'checkedkeys')
        const savepayload = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SubmitViewonlyRestUserPrivileges",
                    {
                        "user_id": parseInt(userId),
                        "legal_entity_id": entityid,
                        "unit_compliances": checkedKeys
                    }
                ]
            }
        ]
        const payloadbck = [

            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "GetViewonlyRestPrivilegeDetails",
                    {
                        "user_id": Number(userId),
                        "legal_entity_id": entityid
                    }
                ]
            }

        ]
        console.log(savepayload, 'py')
        if (Object.keys(checkedKeys).length > 0) {
            //get Diff from two array and remove it from final array            
            const finalArray = Object.keys(checkedKeys).filter(x => !complianceTask.includes(x));
            if(finalArray){
                finalArray.forEach(e => delete checkedKeys[e]);
            }
                      
            saverecords({
                payload: savepayload,
                paramid: paramid,
                payloadbck: payloadbck
            })
        } else {
            Toaster.warning('Please Select any Compliance Task for selected units')
        }

    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <a href="index.html" className="link">
                                        <Link to='/dashboard'><i className="ri-home-3-line fs-5"></i>
                                        </Link></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Master</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: "large", fontWeight: 'bold' }}>
                                    <Link>View Only Restricted - Privilege Settings</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>

                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>Legal Entity</b></label>&nbsp;<span style={{ "color": "red" }}>*</span>
                                                {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={true}
                                                            size="default"
                                                            placeholder="Select Legal Entity"
                                                            onChange={(data, value) => {
                                                                setCurrentEntity(data)
                                                                setLegalentity(data)
                                                            }}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            //  value={modalData.legal_entity}
                                                            style={{ width: '300px' }}
                                                        >
                                                            {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                return (
                                                                    <Option key={item.le_id}>
                                                                        {item.le_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'legalentity',
                                                            legalentity,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Required Field Missing',
                                                                }
                                                            })}
                                                    </div> :
                                                    <p style={{ marginLeft: "10px", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor=""><b>View Only Restricted</b></label> <span style={{ "color": "red" }}>*</span>
                                                <Select
                                                    allowClear={true}
                                                    className='form-control'
                                                    placeholder="View Only Restricted"
                                                    onChange={(data, value) => {
                                                        //setUserId(e.target.value)
                                                        setUserId(data)
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                >
                                                    {viewOnlyRestList && viewOnlyRestList.length && viewOnlyRestList.map((item) => {
                                                        return (
                                                            <Option key={item.user_id}>
                                                                {`${item.emp_code}-${item.emp_name}`}
                                                            </Option>
                                                        );
                                                    })}

                                                </Select>
                                            </div>
                                            {validator.current.message(
                                                'userId',
                                                userId,
                                                ['required'],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Required Field Missing',
                                                        regex: 'Select view only restricted'
                                                    }
                                                })}
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <button
                                                    style={{ marginTop: '6%', marginLeft: '10%' }}
                                                    onClick={() => {
                                                        // setShow(true)                                                        
                                                        const payload = [

                                                            authtoken,
                                                            {
                                                                "session_token": authtoken,
                                                                "request": [
                                                                    "GetViewonlyRestPrivilegeDetails",
                                                                    {
                                                                        "user_id": Number(userId),
                                                                        "legal_entity_id": entityid
                                                                    }
                                                                ]
                                                            }

                                                        ]
                                                        console.log(payload)
                                                        setAddFormSubmit(true);
                                                        if (validator.current.allValid()) {
                                                            setShow(true)
                                                            getViewOnlyRestUnitData({
                                                                payload: payload,
                                                                paramid: paramid
                                                            })
                                                        }
                                                    }}
                                                    className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {show == true ?
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Select Units</b></label>
                                                    <Select
                                                        name="units"
                                                        id="units"
                                                        className='form-control'
                                                        onChange={(option, value) => HandelChange(option, value)}
                                                        mode="multiple"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        value={complianceTask}
                                                        maxTagCount= 'responsive'
                                                        //value={(existingUnits && existingUnits.length > 0) ? existingUnits : ''}
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    >
                                                        {viewOnlyRestUnitList && viewOnlyRestUnitList.length && viewOnlyRestUnitList.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unitname}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                <br />
                                <Card style={{ top: '-21px' }}>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-10">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Compliance List</b></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Tree
                                        checkable
                                        onCheck={onCheck}
                                        //onExpand={onExpand}
                                        //expandedKeys={expandedKeys}
                                        checkedKeys={existingChecked}
                                        //autoExpandParent={autoExpandParent}
                                        treeData={data}
                                        height={233}
                                    />
                                </Card>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <button className="btn btn-light-primary text-primary btn-xs px-3 fs-3"
                                            style={{
                                                position: 'absolute',
                                                right: '8px',
                                                bottom: '6px'
                                            }}
                                            onClick={saveRecords}>Save Records</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, {
    getViewOnlyRestrictedList,
    getViewOnlyRestUnitData,
    saverecords
})(ViewOnlyRestricted)
