import { Fragment, useState, useEffect, useRef, useMemo } from 'react'
import { connect } from 'react-redux';
import { Route, BrowserRouter, Link, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Table, Modal, Card, Input, Tooltip, Button } from 'antd';
import DataTable from "react-data-table-component";
import { privilegeslist, updatestatususerpreviliges, saveuserpreviliges, updateUserPrivileges } from './../../Store/Action/Master/Userprivileges';
import { Switch } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import _, { merge } from 'lodash'
import { Toaster } from '../../Libs/Toaster';
import { PlusSquareTwoTone, LikeOutlined, DislikeOutlined, PoweroffOutlined, PlusOutlined, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';


const mapStateToProps = (state) => ({
    list: state.Userprivileges.privileges
})

const Userprivilegeslist = ({
    privilegeslist,
    list,
    updatestatususerpreviliges,
    saveuserpreviliges,
    updateUserPrivileges
}) => {
    const [temporary, setTemporary] = useState([])
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [passvisible, Setpassvisible] = useState(false);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [PopupFormSubmit, setPopupFormSubmit] = useState(false);
    const [UserPriviligesFrom, setUserPriviligesForm] = useState(false);
    const [modalVisible, SetmodalVisible] = useState(false);
    const [editmodalVisible, SeteditmodalVisible] = useState(false);
    const [formsResponse, setFormsResponse] = useState([]);
    const [menuList, setmenuList] = useState([]);
    const [selectShow, setSelectShow] = useState(false);
    const [temparr, setTemparr] = useState([]);
    const [checkedRow, setCheckedRow] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [tempSubMenu, settempSubMenu] = useState([]);
    const [passwordata, Setpassword] = useState({

        passvalue: '',
        u_g_id: '',
        is_active: ''
    });
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    //form initialize
    const [userPriviligesDetails, setuserPriviligesDetails] = useState({
        userGroupName: '',
        categoryName: '',
        form_id: [],
    });
    const [formId, setFormId] = useState()
    const [userPriviligeValue, setUserPriviligeValue] = useState({
        user_group_name: '',
        category_name: ''
    })
    const [edit, setEdit] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const { Search } = Input;

    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    //Status change
    const statuschange = (row) => {
        let is_active_current = '';
        if (row.is_active == 1) {
            is_active_current = false
        } else if (row.is_active == 0) {
            is_active_current = true
        }
        Setpassvisible(true);
        Setpassword({
            ...passwordata,
            u_g_id: row.u_g_id,
            is_active: is_active_current
        })


    }
    const passwordcancel = () => {
        Setpassvisible(false);
        Setpassword({
            ...passwordata,
            passvalue: ''
        })
        setAddFormSubmit(false);
    }

    const modalcancel = () => {
        setuserPriviligesDetails({
            ...userPriviligesDetails,
            userGroupName: '',
            categoryName: '',
            form_id: [],
        })
        SetmodalVisible(false);
        //setmenuList([])

        setSelectShow(false);
    }
    const editmodalcancel = () => {
        SeteditmodalVisible(false);
        setmenuList([])
        setuserPriviligesDetails({
            userGroupName: '',
            categoryName: '',
            form_id: [],
        })
        setSelectShow(false);
    }
    const addopen = () => {
        setFormsResponse(list.forms)
        SetmodalVisible(true);
    }
    const fetchdetails = (row) => {
        setFormsResponse(list.forms)
        let temp = []
        for (let i in row.f_ids) {
            temp.push(row.f_ids[i])
        }
        setTemparr(temp)
        SeteditmodalVisible(true);
        setSelectShow(true)
        setuserPriviligesDetails({
            ...userPriviligesDetails,
            userGroupName: row.u_g_name,
            categoryName: row.u_c_id,
            c_name: row.u_c_name,
            form_id: temp,
            u_g_id: row.u_g_id
        })
    }

    const columns = [
        {
            title: 'User Group Name',
            dataIndex: 'u_g_name',
            key: 'u_g_name',
            width: '150px',
        },
        {
            title: 'Category Name',
            dataIndex: 'u_c_name',
            key: 'u_c_name',
            width: '150px',
            filters: [
                {
                    text: 'Client Executive',
                    value: 'Client Executive',
                },
                {
                    text: 'Domain Admin',
                    value: 'Domain Admin',
                },
                {
                    text: 'Group Admin',
                    value: 'Group Admin',
                },
                {
                    text: 'Legal Entity Admin',
                    value: 'Legal Entity Admin',
                },
                {
                    text: 'Service Provider User',
                    value: 'Service Provider User',
                },
                {
                    text: 'View Only',
                    value: 'View Only',
                },
                {
                    text: 'View Only Restricted',
                    value: 'View Only Restricted',
                }
            ],
            onFilter: (value, record) => record.u_c_name.indexOf(value) === 0,
        },
        {
            title: <><label>Actions</label><Tooltip className='toolTipClass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"This Action Includes Edit, Active/Inactive Status Change"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'u_c_name',
            width: '80px',
            className: 'test',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        <Fragment>
                            <Link className="btn btn-sm btn-light-info text-info btn-circle
                                align-items-center justify-content-center" onClick={(e) => {
                                    fetchdetails(record)
                                    setEdit(true)
                                }}>
                                <Tooltip title="Edit" color='green'>
                                    <i className="ri-edit-line"></i></Tooltip>
                            </Link>
                        </Fragment>&nbsp;
                        <Fragment>
                            {record.is_active === 1 ?
                                <Link className="mb-1 badge font-weight-medium bg-light-success text-success w-80"
                                    onClick={(e) => { statuschange(record) }}>
                                    <Tooltip title="Active" color='#39cb7f'>
                                        <LikeOutlined /></Tooltip></Link> :
                                <Link className="mb-1 badge font-weight-medium bg-light-danger text-danger w-80"
                                    onClick={(e) => { statuschange(record) }}>
                                    <Tooltip title="Inactive" color='#fc4b6c'>
                                        <DislikeOutlined /></Tooltip></Link>
                            }
                        </Fragment>
                    </>
                )
            },
            sortable: false,
        },
        // {
        //     title: 'Edit',
        //     dataIndex: '',
        //     width:'100px',
        //     render: (text, record) => {
        //         return (
        //             <Link className="btn btn-sm btn-light-info text-info btn-circle d-flex 
        //                         align-items-center justify-content-center" onClick={(e) => {
        //                     fetchdetails(record)
        //                     setEdit(true)
        //                 }}>
        //                 <i className="ri-edit-line"></i>
        //             </Link>
        //         )
        //     },
        //     sortable: false,
        // },
        // {
        //     title: 'Status',
        //     dataIndex: 'is_active',
        //     width:'120px',
        //     render: (text, record) => {
        //         return (
        //             <div>
        //                 {text === 1 ?
        //                     <Link className="mb-1 badge font-weight-medium bg-light-success text-success w-80"
        //                         onClick={(e) => { statuschange(record) }}>Active</Link> :
        //                     <Link className="mb-1 badge font-weight-medium bg-light-danger text-danger w-80"
        //                         onClick={(e) => { statuschange(record) }}>Inactive</Link>
        //                 }
        //             </div>
        //         )
        //     },
        //     sortable: false,

        // },

    ];

    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages();
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUserPrivileges",
                        {

                        }
                    ]
                }
            ]

            privilegeslist({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])

    const submitpassword = () => {

        if (formValidator.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "ChangeUserPrivilegeStatus",
                        {
                            "u_g_id": passwordata.u_g_id,
                            "is_active": passwordata.is_active,
                            "password": passwordata.passvalue
                        }
                    ]
                }
            ]
            updatestatususerpreviliges({
                payload: pay,
                paramid: paramid
            })
        } else {
            setAddFormSubmit(true)
        }

    }

    const categoryChange = (e) => {
        setuserPriviligesDetails({
            ...userPriviligesDetails,
            [e.target.name]: e.target.value,
        })
        if (e.target.value == 'Select Category') {
            setSelectShow(false)
        } else {
            setSelectShow(true)
        }


    }

    useEffect(() => {
        if (formsResponse && formsResponse[userPriviligesDetails.categoryName] != null) {
            let menu = Object.values(formsResponse[userPriviligesDetails.categoryName].menus);
            setmenuList(menu);
            //convert array of array into single array
            var merged = [].concat.apply([], menu);
            settempSubMenu(merged);
        }

    }, [formsResponse && formsResponse[userPriviligesDetails.categoryName]])

    const addAllSelectedDataValues = (checked) => {
        let tempp = []
        if (checked == true) {
            setCheckedRow(true)
            setTemparr([...tempSubMenu])
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            setTemparr([])
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
        }
    }
    const addSelectedDataValues = (checked, form_id, subMenuList) => {
        let array
        let tempArray = temparr
        if (checkedRow == true) {
            if (checked == true) {
                array = _.find(subMenuList, { form_id: form_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].form_id == form_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked == true) {
                array = _.find(subMenuList, { form_id: form_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].form_id == form_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const editAllSelectedDataValues = (checked) => {
        let tempp = []
        if (checked == true) {
            setCheckedRow(true)
            let tempSubMenuState = tempSubMenu
            for (let i in tempSubMenuState) {
                tempp.push(tempSubMenuState[i].form_id)
            }
            setTemparr(tempp)
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            setTemparr([])
            var ele = document.getElementsByName('checked');
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type == 'checkbox')
                    ele[i].checked = false;
            }
        }
    }

    const editSelectedDataValues = (checked, form_id, subMenuList) => {
        let array
        let tempArray = temparr
        if (checkedRow == true) {
            if (checked == true) {
                array = _.find(subMenuList, { form_id: form_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].form_id == form_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked == true) {
                array = _.find(subMenuList, { form_id: form_id });
                let temporaryArray = array.form_id
                // setTemporary([...temporary,...temporaryArray])
                tempArray.push(temporaryArray)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i] == form_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const submitrec = (e) => {
        e.preventDefault();
        setPopupFormSubmit(true)
        let temp = []
        for (let i in temparr) {
            temp.push(temparr[i].form_id)
        }
        if (formValidator1.current.allValid() && temp != null && temp != '') {
            const savepayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "SaveUserPrivileges",
                        {
                            "u_g_name": userPriviligesDetails.userGroupName,
                            "u_c_id": parseInt(userPriviligesDetails.categoryName),
                            "f_ids": temp
                        }
                    ]
                }
            ]
            saveuserpreviliges({
                payload: savepayload,
                paramid: paramid
            })
        } else {
            Toaster.warning('Please select atleast anyone of the menu')
        }
    }


    const updatePrivilege = (e) => {
        e.preventDefault();
        setPopupFormSubmit(true)
        if (formValidator1.current.allValid() && temparr != null && temparr != '') {
            const savepayload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UpdateUserPrivileges",
                        {
                            "u_g_id": userPriviligesDetails.u_g_id,
                            "u_g_name": userPriviligesDetails.userGroupName,
                            "u_c_id": parseInt(userPriviligesDetails.categoryName),
                            "f_ids": temparr
                        }
                    ]
                }
            ]
            updateUserPrivileges({
                payload: savepayload,
                paramid: paramid
            })
        } else {
            Toaster.warning('Please select atleast anyone of the menu')
        }
    }


    const FilterComponent = ({ filterText, onFilter, onClear }) => {
        return (
            <>

                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            type="text"
                            placeholder="Search Here..."
                            value={filterText}
                            onChange={onFilter}
                            autoFocus
                        />
                    </div>
                </div>

            </>
        )
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <FilterComponent
                    onFilter={(e) => {
                        setFilterText(e.target.value)
                    }}
                    onClear={handleClear}
                    filterText={filterText}
                />

            </>

        );
    }, [filterText, resetPaginationToggle]);

    const filteredItems = list && list.user_groups.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link>Master</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                        <Link>User Privileges</Link>
                                    </li>&nbsp;&nbsp;&nbsp;
                                    {/* <Button
                                        type="primary"
                                        style={{ background: "#42ba96", borderColor: "#42ba96" }}
                                        icon={<PlusOutlined />}
                                        onClick={addopen}
                                        title="Add User Privileges"
                                    /> */}
                                    <Tooltip title="Add User Privilege" color='#1890ff'><Button type="primary"
                                        icon={<PlusCircleOutlined />} size='large' onClick={addopen} /></Tooltip>
                                </ol>
                            </nav>

                        </div>
                        <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end" >
                            {/* <button onClick={addopen} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Add</button> */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className='card-body' style={{ padding: '0px' }}>
                                    {/* <DataTable
                                        columns={columns}
                                        //data={list && list.user_groups}
                                        data={filteredItems}
                                        fixedHeader={dataTableProperties.fixedHeader}
                                        pagination
                                        subHeader
                                        subHeaderComponent={subHeaderComponent}

                                    >

                                    </DataTable> */}
                                    <Table
                                        className='userprivclass'
                                        columns={columns}
                                        bordered
                                        pagination={{
                                            defaultPageSize: dataTableProperties.pagesize,
                                            showSizeChanger: dataTableProperties.sizechanger
                                        }}
                                        dataSource={list && list.user_groups} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={passvisible} footer={null} onCancel={passwordcancel} className="newStyleModalPassword" maskClosable={false}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"><b>Password:</b></label>
                            <span style={{ "color": "red" }}>*</span>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                className="form-control"
                                placeholder="Enter password"
                                value={passwordata.passvalue}
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                            />
                            {formValidator.current.message(
                                'password',
                                passwordata.passvalue,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Required Field Missing',
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <button className="btn btn-light-success rounded-pill px-4 text-success" onClick={submitpassword}>
                        OK
                    </button>

                    <button className="btn btn-light-danger rounded-pill text-danger ms-2  font-weight-medium waves-effect text-start" onClick={passwordcancel} >
                        Cancel
                    </button>
                </div>
            </Modal>
            <Modal title="User Privileges Master" visible={modalVisible} footer={null} className="userprivilegemodel" onCancel={modalcancel} maskClosable={false}>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label><b>User Group Name</b><span style={{ color: "red" }}> * </span> </label>
                                            <input type="text" autoComplete='off' placeholder=" User Group Name" className='form-control' value={userPriviligesDetails.userGroupName} name="groupName" id="" onChange={(e) => {
                                                setuserPriviligesDetails({
                                                    ...userPriviligesDetails,
                                                    userGroupName: e.target.value
                                                })
                                            }} />
                                            {formValidator1.current.message(
                                                'userGroupName',
                                                userPriviligesDetails.userGroupName,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${PopupFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Required Field Missing',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label><b>Category Name</b><span style={{ color: "red" }}> * </span> </label>
                                        <select name="categoryName" className='form-control' value={userPriviligesDetails.categoryName} onChange={categoryChange}>
                                            <option defaultValue="">Select Category</option>
                                            {list.user_category && list.user_category.length > 0 &&
                                                list.user_category.map((categoryList) => (
                                                    <option value={categoryList.u_c_id}>{categoryList.u_c_name}</option>
                                                ))}
                                            {formValidator1.current.message(
                                                'category',
                                                userPriviligesDetails.categoryName,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${PopupFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Required Field Missing',
                                                    }
                                                })}
                                        </select>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <br />
                        {menuList && menuList.length ?
                            <div>
                                <div className='ant-table' style={{ display: selectShow ? 'block' : 'none' }} >
                                    <div className='ant-table-container'>
                                        <div className='ant-table-content tableFixHead'>
                                            <table style={{ tableLayout: 'auto', }}>
                                                <thead className='ant-table-thead'>
                                                    <tr>
                                                        <th className='ant-table-cell custom-width'><input type="checkbox" onClick={(e) => {
                                                            let checked = e.target.checked;
                                                            addAllSelectedDataValues(
                                                                checked
                                                            );
                                                        }} /></th>
                                                        <th className='ant-table-cell'>Form Name</th>
                                                        <th className='ant-table-cell'><center>Compfie</center></th>
                                                        <th className='ant-table-cell'><center>Compfie Vendor</center></th>
                                                    </tr>
                                                </thead>
                                                <tbody className='ant-table-tbody'>
                                                    {menuList && menuList.map((subMenuList) => {
                                                        console.log(subMenuList, 'subMenuList');
                                                        const arr = [];
                                                        const pmenus = subMenuList.map(function (user) {
                                                            if ((arr.indexOf(user.form_type__form_type) < 0))
                                                                arr.push(user.form_type__form_type)
                                                        });
                                                        {
                                                            return arr.map((pmenu) => {
                                                                if (pmenu) {
                                                                    return <Fragment><tr className='ant-table-row ant-table-row-level-0'>
                                                                        <td colSpan={5} className='ant-table-cell'><b>{pmenu}</b></td>
                                                                    </tr>

                                                                        {subMenuList && subMenuList.map((subMenuChildList) => {
                                                                            return <tr className='ant-table-row ant-table-row-level-0'>
                                                                                <td className='ant-table-cell custom-width'><input type="checkbox" name="checked"
                                                                                    onClick={(e) => {
                                                                                        let checked = e.target.checked;
                                                                                        addSelectedDataValues(
                                                                                            checked,
                                                                                            subMenuChildList.form_id,
                                                                                            subMenuList,
                                                                                        );
                                                                                    }} /></td>
                                                                                <td className='ant-table-cell fs'>{subMenuChildList.form_name}</td>
                                                                                <td className='ant-table-cell fs'>{(subMenuChildList.category_id) ? <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> : <center><span className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-forbid-line"></i>
                                                                                </span></center>}</td>
                                                                                <td className='ant-table-cell fs'>{(subMenuChildList.vndr_category_id) ? <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> : <center><span
                                                                                    className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-forbid-line"></i>
                                                                                </span></center>}</td>
                                                                            </tr>
                                                                        })}
                                                                    </Fragment>
                                                                }
                                                            })
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                            <br />
                                            <div>
                                                <button
                                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                                    onClick={submitrec}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            : ''}

                    </div>
                </div>
            </Modal>
            <Modal title="User Privileges Master" visible={editmodalVisible} footer={null} className="userprivilegemodel" onCancel={editmodalcancel} maskClosable={false}>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label><b>User Group Name</b><span style={{ color: "red" }}> * </span> </label>
                                            <input type="text" autoComplete='off' value={userPriviligesDetails.userGroupName} placeholder=" User Group Name" className='form-control' name="groupName" id=""
                                                onChange={(e) => {
                                                    setuserPriviligesDetails({
                                                        ...userPriviligesDetails,
                                                        userGroupName: e.target.value
                                                    })
                                                }}
                                            />
                                            {formValidator1.current.message(
                                                'userGroupName',
                                                userPriviligesDetails.userGroupName,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${PopupFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Required Field Missing',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label><b>Category Name</b></label>
                                        <select name="categoryName" className='form-control' disabled
                                        // onChange={categoryChange}
                                        >
                                            <option defaultValue="">{userPriviligesDetails.c_name}</option>
                                            {/* {list.user_category && list.user_category.length > 0 &&
                                                    list.user_category.map((categoryList) => (
                                                        <option value={categoryList.u_c_id}>{categoryList.u_c_name}</option>
                                                    ))} */}
                                            {formValidator1.current.message(
                                                'category',
                                                userPriviligesDetails.categoryName,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${PopupFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Required Field Missing',
                                                    }
                                                })}
                                        </select>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <br />
                        <div>
                            <div className='ant-table' style={{ display: selectShow ? 'block' : 'none' }} >
                                <div className='ant-table-container'>
                                    <div className='ant-table-content tableFixHead'>
                                        <table style={{ tableLayout: 'auto' }}>
                                            <thead className='ant-table-thead'>
                                                <tr>
                                                    <th className='ant-table-cell custom-width'><input type="checkbox"
                                                        checked={(temparr.length == tempSubMenu.length) == true ? true : false}
                                                        onClick={(e) => {
                                                            let checked = e.target.checked;
                                                            editAllSelectedDataValues(
                                                                checked
                                                            );
                                                        }} /></th>
                                                    <th className='ant-table-cell'>Form Name</th>
                                                    <th className='ant-table-cell'><center>Compfie</center></th>
                                                    <th className='ant-table-cell'><center>Compfie Vendor</center></th>
                                                </tr>
                                            </thead>
                                            <tbody className='ant-table-tbody'>
                                                {menuList && menuList.map((subMenuList) => {
                                                    const arr = [];
                                                    const pmenus = subMenuList.map(function (user) {
                                                        if ((arr.indexOf(user.form_type__form_type) < 0))
                                                            arr.push(user.form_type__form_type)
                                                    });
                                                    {
                                                        return arr.map((pmenu) => {
                                                            if (pmenu) {
                                                                return <Fragment><tr className='ant-table-row ant-table-row-level-0'>
                                                                    <td colSpan={5} className='ant-table-cell'><b>{pmenu}</b></td>
                                                                </tr>

                                                                    {subMenuList && subMenuList.map((subMenuChildList) => {
                                                                        return <tr className='ant-table-row ant-table-row-level-0'>
                                                                            <td className='ant-table-cell custom-width'><input type="checkbox" name="checked" value={checkbox}
                                                                                checked={temparr && temparr.includes(subMenuChildList.form_id) == true ? true : false}
                                                                                onClick={(e) => {
                                                                                    let checked = e.target.checked;

                                                                                    editSelectedDataValues(
                                                                                        checked,
                                                                                        subMenuChildList.form_id,
                                                                                        subMenuList
                                                                                    );
                                                                                }}
                                                                            /></td>
                                                                            <td className='ant-table-cell fs'>{subMenuChildList.form_name}</td>
                                                                            <td className='ant-table-cell fs'>{(subMenuChildList.category_id) ?
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> :
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-forbid-line"></i>
                                                                                </span>
                                                                                </center>}</td>
                                                                            <td className='ant-table-cell fs'>{(subMenuChildList.vndr_category_id) ?
                                                                                <center><span
                                                                                    className="btn btn-sm btn-light-success text-success btn-circle d-flex align-items-center justify-content-center">
                                                                                    <i className="ri-check-line"></i>
                                                                                </span></center> : <center><span
                                                                                    className="btn btn-sm btn-light-danger text-danger btn-circle d-flex align-items-center justify-content-center"
                                                                                >
                                                                                    <i className="ri-forbid-line"></i>
                                                                                </span></center>}</td>
                                                                        </tr>
                                                                    })}
                                                                </Fragment>
                                                            }
                                                        })
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                        <br />
                                        <div>
                                            <button
                                                className="btn btn-light-success rounded-pill px-4 text-success"
                                                onClick={updatePrivilege}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Modal>

        </Fragment>
    )

}

export default connect(mapStateToProps, {
    privilegeslist,
    updatestatususerpreviliges,
    saveuserpreviliges,
    updateUserPrivileges
})(Userprivilegeslist)

