import { useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { Modal, Card, Collapse, Tooltip, Input } from 'antd';
import DataTable from "react-data-table-component";
import { useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall';
import { statutorylist, editstatutory, filterdata, locksettings, SaveStatutorySettings, UpdateStatutorySettings } from './../../Store/Action/Transcations/Statutorysettings'
import FeatherIcon from 'feather-icons-react';
import { EditOutlined, CheckOutlined, CloseOutlined, UserAddOutlined, PlusSquareTwoTone, MinusCircleOutlined } from '@ant-design/icons';

const mapStateToProps = (state) => ({
  list: state.Statutorysetting.statutorysettings.statutorylist,
  editdata: state.Statutorysetting.statutorysettings.editdatas,
  filterdatas: state.Statutorysetting.statutorysettings.filterdata,
  filterrecall: state.recallfilters.recallfilters,

})
const Statutorysetting = ({
  statutorylist,
  editstatutory,
  list,
  editdata,
  filterdata,
  locksettings,
  filterdatas,
  GetRemarksData,
  filterrecall: { recallremarks },
  SaveStatutorySettings,
  UpdateStatutorySettings

}) => {
  const location = useLocation();
  const formValidator = useRef(new SimpleReactValidator());
  const paramid = location.pathname;
  const _ = require("lodash");
  const isAuth = localStorage.getItem('isAuthenticated');
  const authtoken = localStorage.getItem('authToken');
  const entityid = localStorage.getItem('SelectedEntityid');
  const [isModalVisible, SetisModalVisible] = useState(false);
  const [passwordmodal, Setpasswordmodal] = useState(false);
  const [submitpasswordmodal, Setsubmitpasswordmodal] = useState(false);
  const [editshow, seteditshow] = useState(false);
  const [listshow, setlistshow] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [uiddomian, setUiddomain] = useState([]);
  const [statutorypayload, setStatutorypayload] = useState();
  const [tempeditdata, setTempeditdata] = useState();
  console.log(tempeditdata, 'tempeditdata');
  const [remarksModal, setRemarksModal] = useState(false);
  const [collapseremarksModal, setCollapseremarksModal] = useState(false);
  const [minuscircleoutlined, setMinuscircleoutlined] = useState([]);
  const [closeoutlined, setCloseoutlined] = useState([]);
  const [checkoutlined, setCheckoutlined] = useState([]);
  console.log(minuscircleoutlined, 'minuscircleoutlined');
  console.log(closeoutlined, 'closeoutlined');
  console.log(checkoutlined, 'checkoutlined');
  const [remarkvalue, setRemarkvalue] = useState([
    // comp_id: [],
    // remarks: [],
    // collapseId: []
  ]);
  const [messages, setMessages] = useState([])
  console.log(remarkvalue, 'remarkvalue');
  const [tempremarkvalue, setTempremarkvalue] = useState([])
  const [editopt, setEditopt] = useState([])
  console.log(editopt, 'editopt');
  console.log(tempremarkvalue, 'tempremarkvalueremarks');
  const [panelremark, setPanelremark] = useState([]);
  console.log(panelremark, 'panelremark');
  const [remarkmessage, setRemarkmessage] = useState();
  const [collapseremarkmessage, setCollapseremarkmessage] = useState();
  const [collapsename, setCollapsename] = useState();
  console.log(collapseremarkmessage, 'collapseremarkmessage');
  const [checkpanelremark, setCheckpanelremark] = useState([]);
  console.log(checkpanelremark, 'checkpanelremark');
  const [remarksindex, setRemarksindex] = useState({
    remarks: false,
    remarks_id: [],
    parent: [],
    collapseId: []
  });
  console.log(remarksindex, 'remarksindex');
  var [count, setCount] = useState({});
  let [remarkscount, setRemarkscount] = useState(0)
  const [remarksdata, SetRemarksdata] = useState({
    remarks: ''
  })
  const history = useHistory();

  const [password, Setpassword] = useState({
    passwordvalue: '',
    le_id: '',
    d_id: '',
    u_id: '',
    lock: '',
  })

  const { Panel } = Collapse;

  const [titledata, Settitledata] = useState()
  // console.log(titledata, 'titledata');
  const handleCancel = () => {
    setRemarksModal(false)
    SetisModalVisible(false);
  }

  const passwordcancel = () => {
    Setpasswordmodal(false);
  }

  const unlock = (e_data) => {
    Setpasswordmodal(true)
    Setsubmitpasswordmodal(true)
    Setpassword({
      ...password,
      le_id: entityid,
      d_id: e_data.d_id,
      u_id: e_data.u_id,
      lock: e_data.is_locked
    })
  }

  const handleOk = () => {
    setRemarksModal(false)
  };

  const [filtervalue, Setfiltervalue] = useState({
    entity: '',
    division: '',
    category: ''
  })

  useEffect(() => {
    if (remarkmessage !== '') {
      let temp = remarkvalue
      {
        temp.map((item, i) => {
          if (item.comp_id === (tempremarkvalue && tempremarkvalue.length > 0 && tempremarkvalue[0].comp_id)) {
            temp.splice(i, 1);
            temp.push({ comp_id: item.comp_id, collapseId: item.collapseId, remarks: remarkmessage })
            setRemarkvalue([...new Set(temp)])
          }
        })
        // if(checkpanelremark && checkpanelremark.length === 0){
        //   tempArr.push({
        //     comp_id: row.comp_id,
        //     u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
        //   });
        // }
      }
    }

  }, [remarkmessage, remarkscount])

  useEffect(() => {
    if (isAuth) {

      const payload = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettings",
            {
              "le_id": entityid,
              "div_id": null,
              "cat_id": null,
            }
          ]
        }
      ]

      const filterAPI = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettingsFilters",
            {}
          ]
        }
      ]
      statutorylist({
        payload: payload,
        paramid: paramid
      })
      filterdata({
        payload: filterAPI,
        paramid: paramid
      })
    }
  }, [isAuth])

  useEffect(() => {
    setTempeditdata(editdata)
    let array = [];
    let finalarray = [];
    let datavalue = [];
    if (editdata) {
      const ab = editdata.map(function (item) {
        if ((array.indexOf(item.lone_statu_name) < 0))
          array.push(item.lone_statu_name)
      })
      array.map((data, i) => {
        // let array2 = [];
        let temp = [];
        let entityArray = [];
        if (data) {
          editdata.map((child) => {
            if (data === child.lone_statu_name) {
              entityArray = {
                'app_status': child.app_status,
                'comp_id': child.comp_id,
                'comp_name': child.comp_name,
                'compfielaw_url': child.compfielaw_url,
                'criticality_client': child.criticality_client,
                'criticality_know': child.criticality_know,
                'descp': child.descp,
                'frequency_name': child.frequency_name,
                'h_id': child.h_id,
                'is_assigned': child.is_assigned,
                'lone_statu_name': child.lone_statu_name,
                'mapping_provision': child.mapping_provision,
                'not_app_remarks': child.not_app_remarks,
                'opt_status': child.opt_status,
                's_prov': child.s_prov,
                'unit_wise_status': child.unit_wise_status,
                'collapseId': i
              }
              temp.push(entityArray)
            }
          })
        }
        datavalue = {
          index: i,
          parent: data,
          child: temp
        }
        finalarray.push(datavalue)
      })
      Settitledata(finalarray)
    }

  }, [editdata])

  const column = [
    {
      name: "Statutory Provision",
      selector: (row) => row.s_prov,
      sortable: true,
      width: '150px',
      reorder: true,
    },
    {
      name: "Compliance Task",
      selector: (row) => row.comp_name,
      sortable: true,
      width: '150px',
      reorder: true,
    },
    {
      name: "Compliance Frequency",
      selector: (row) => row.frequency_name,
      sortable: true,
      width: '100px',
      reorder: true
    },
    {
      name: "Compliance Description",
      selector: (row) => row.comp_name,
      sortable: true,
      reorder: true,
      width: '110px',
    },
    {
      name: "Criticality",
      selector: (row) => row.criticality_know,
      sortable: true,
      reorder: true,
      width: '100px',
    },
    {
      name:
        <Fragment>
          <EditOutlined />
        </Fragment>,
      selector: (row) => row.app_status === true ? <CheckOutlined /> : <CloseOutlined />,
      // sortable: true,
      // right: true,
      reorder: true,
      width: '50px'
    },
    {
      name: <Fragment>
        <UserAddOutlined />
      </Fragment>,
      selector: (row) => {
        let temp;
        if (row.unit_wise_status[0].comp_app_status === true) {
          temp = 1
        } else {
          temp = 0
        }
        if (row.unit_wise_status[0].comp_opt_status === 1) {
          // if (remarksindex.remarks_id.length === 0) {
          return (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) ? <CheckOutlined onClick={(e) => {
            changeremarks(e, row)
          }} /> :
            (row.unit_wise_status[0].comp_opt_status === 1 && (closeoutlined.indexOf(row.comp_id) >= 0) && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <CloseOutlined onClick={(e) => {
              changeremarks(e, row)
            }} /> :
              (row.unit_wise_status[0].comp_opt_status === 1 && (checkoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) && (minuscircleoutlined.indexOf(row.comp_id) >= 0) ? <MinusCircleOutlined onClick={(e) => {
                changeremarks(e, row)
              }} /> : '')))
          // (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) >= 0) ? <CloseOutlined onClick={(e) => {
          //   changeremarks(e, row)
          // }} /> :
          //   (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) >= 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <MinusCircleOutlined onClick={(e) => {
          //     changeremarks(e, row)
          //   }} /> : '')))
          // } else {
          // return (row.unit_wise_status[0].comp_opt_status === 1 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) ? <CheckOutlined onClick={(e) => {
          //   changeremarks(e, row)
          // }} /> :
          //   (row.unit_wise_status[0].comp_opt_status === 1 && (closeoutlined.indexOf(row.comp_id) >= 0) && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <CloseOutlined onClick={(e) => {
          //     changeremarks(e, row)
          //   }} /> :
          //     (row.unit_wise_status[0].comp_opt_status === 1 && (checkoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) && (minuscircleoutlined.indexOf(row.comp_id) >= 0) ? <MinusCircleOutlined onClick={(e) => {
          //       changeremarks(e, row)
          //     }} /> : '')))
          // }
        } else if (row.unit_wise_status[0].comp_opt_status === 0) {
          return (row.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <CloseOutlined onClick={(e) => {
            changeremarks(e, row)
          }} /> :
            (row.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(row.comp_id) >= 0) && (closeoutlined.indexOf(row.comp_id) < 0) && (checkoutlined.indexOf(row.comp_id) < 0) ? <MinusCircleOutlined onClick={(e) => {
              changeremarks(e, row)
            }} /> :
              (row.unit_wise_status[0].comp_opt_status === 0 && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) && (checkoutlined.indexOf(row.comp_id) >= 0) ? <CheckOutlined onClick={(e) => {
                changeremarks(e, row)
              }} /> : '')))
        } else {
          return (row.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) ? <MinusCircleOutlined onClick={(e) => {
            changeremarks(e, row)
          }} /> :
            (row.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(row.comp_id) >= 0) && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) < 0) ? <CheckOutlined onClick={(e) => {
              changeremarks(e, row)
            }} /> :
              (row.unit_wise_status[0].comp_opt_status === 2 && (checkoutlined.indexOf(row.comp_id) < 0) && (minuscircleoutlined.indexOf(row.comp_id) < 0) && (closeoutlined.indexOf(row.comp_id) >= 0) ? <CloseOutlined onClick={(e) => {
                changeremarks(e, row)
              }} /> : '')))
        }
      },
      reorder: true,
      width: '50px'
    },
    {
      name: "Remarks",
      selector: (row) => {
        let temp;
        if (row.unit_wise_status[0].comp_app_status === true) {
          temp = 1
        } else {
          temp = 0
        }
        if (temp === row.unit_wise_status[0].comp_opt_status) {
          if (remarksindex.remarks === true) {
            if ((remarksindex && remarksindex.remarks_id && remarksindex.remarks_id.indexOf(row.comp_id) >= 0)) {
              let message = []
              remarkvalue.map((item, i) => {
                if (item.comp_id === row.comp_id) {
                  console.log(item,'itemmmmmmmmmmmm');
                  message = item
                }
              })
              // let message = []
              // remarkvalue.map((item, i) => {
              //   if (item.comp_id === row.comp_id) {
              //     console.log(item,'itemmmmmmmmmmmm');
              //     message = item
              //     setMessages([...message])
              //   }
              // })

              // let message = _.filter(remarkvalue, { comp_id: row.comp_id })
              return <> <Input class="form-control" placeholder='Enter remarks' style={{ "width": "80%" }}
                // value={messages && messages.length > 0 && messages.remarks ? messages.remarks : ""}  
                value={message.remarks ? message.remarks : ""}  
                // onBlur={() => {
                //   showRemarksModal(row.comp_id, row.collapseId)
                // }}
                // onChange={(e) => {
                  // Setblockdata({
                  //     ...blockdata,
                  //     remarks: e.target.value
                  // }}
                id="remarks" type={"text"} />
                <PlusSquareTwoTone onClick={() => { showRemarksModal(row.comp_id, row.collapseId) }} />
              </>
            } else {
              if (panelremark.length !== 0 && row.collapseId in panelremark && panelremark[row.collapseId].length !== 0) {
                if (remarksindex.collapseId.indexOf(row.collapseId) >= 0) {
                  if (temp === row.unit_wise_status[0].comp_opt_status) {
                    return <label>{row.not_app_remarks}</label>
                  } else {
                    <> <Input class="form-control" placeholder='Enter remarks' style={{ "width": "80%" }}
                      value={row.unit_wise_status[0].comp_remarks}
                      // onBlur={() => {
                      //   showRemarksModal(row.comp_id, row.collapseId)
                      // }}
                      id="remarks" type={"text"} />
                      <PlusSquareTwoTone onClick={() => { showRemarksModal(row.comp_id, row.collapseId) }} />
                    </>
                  }
                }
              }
            }
          }
        } else {
          if (row.unit_wise_status[0].comp_opt_status === 2) {
            if (remarksindex.remarks === true) {
              if ((remarksindex && remarksindex.remarks_id && remarksindex.remarks_id.indexOf(row.comp_id) >= 0)) {
                let filteredArr = _.filter(remarkvalue, { comp_id: row.comp_id })

                return <> <Input class="form-control" placeholder='Enter remarks' style={{ "width": "80%" }}
                  // value={row.comp_id in panelremark[row.collapseId] ? row.not_app_remarks : row.unit_wise_status[0].comp_remarks}
                  value={filteredArr && filteredArr.length > 0 && filteredArr[0].remarks ? filteredArr[0].remarks : row.unit_wise_status[0].comp_remarks}
                  id="remarks" type={"text"} />
                  <PlusSquareTwoTone onClick={() => { showRemarksModal(row.comp_id, row.collapseId) }} />
                </>
              } else {
                if (panelremark.length !== 0 && row.collapseId in panelremark && panelremark[row.collapseId].length !== 0) {
                  if (remarksindex.collapseId.indexOf(row.collapseId) >= 0) {
                    return <label>{row.not_app_remarks}</label>
                  }
                }
              }
            } else {
              if ((remarksindex && remarksindex.remarks_id && remarksindex.remarks_id.indexOf(row.comp_id) >= 0)) {
                return <> <Input class="form-control" placeholder='Enter remarks' style={{ "width": "80%" }}
                  value={row.unit_wise_status[0].comp_remarks}
                  id="remarks" type={"text"} />
                  <PlusSquareTwoTone onClick={() => { showRemarksModal(row.comp_id, row.collapseId) }} />
                </>
              }
            }
          } else {
            if (row.unit_wise_status[0].comp_opt_status === 1) {
              if (row.collapseId in panelremark) {
                if (row.comp_id in panelremark[row.collapseId]) {
                  panelremark[row.collapseId].push(row.comp_id);
                }
              } else {
                panelremark[row.collapseId] = [];
                panelremark[row.collapseId].push(row.comp_id);
              }
              setPanelremark(panelremark)
              // }
              if (remarksindex.remarks === true) {
                if ((remarksindex && remarksindex.remarks_id && remarksindex.remarks_id.indexOf(row.comp_id) >= 0)) {
                  // remarkvalue 
                  let filteredArr = _.filter(remarkvalue, { comp_id: row.comp_id })

                  return <> <Input class="form-control" placeholder='Enter remarks' style={{ "width": "80%" }}
                    value={filteredArr && filteredArr.length > 0 && filteredArr[0].remarks ? filteredArr[0].remarks : row.unit_wise_status[0].comp_remarks}
                    id="remarks" type={"text"} />
                    <PlusSquareTwoTone onClick={() => { showRemarksModal(row.comp_id, row.collapseId) }} />
                  </>
                } else {
                  if (panelremark.length !== 0 && row.collapseId in panelremark && panelremark[row.collapseId].length !== 0) {
                    if (remarksindex.collapseId.indexOf(row.collapseId) >= 0) {
                      return <label>{row.not_app_remarks}</label>
                    }
                  }
                }
              } else {
                if (panelremark.length !== 0 && row.collapseId in panelremark && panelremark[row.collapseId].length !== 0) {
                  return <> <Input class="form-control" placeholder='Enter remarks' style={{ "width": "80%" }}
                    value={row.unit_wise_status[0].comp_remarks}
                    id="remarks" type={"text"} />
                    <PlusSquareTwoTone onClick={() => { showRemarksModal(row.comp_id, row.collapseId) }} />
                  </>
                }
              }
            }
            if (row.unit_wise_status[0].comp_opt_status === 0) {
              if (row.collapseId in panelremark) {
                if (row.comp_id in panelremark[row.collapseId]) {
                  panelremark[row.collapseId].push(row.comp_id);
                }
              } else {
                panelremark[row.collapseId] = [];
                panelremark[row.collapseId].push(row.comp_id);
              }
              setPanelremark(panelremark)
              // }
              if (remarksindex.remarks === true) {
                if ((remarksindex && remarksindex.remarks_id && remarksindex.remarks_id.indexOf(row.comp_id) >= 0)) {
                  // remarkvalue 
                  let filteredArr = _.filter(remarkvalue, { comp_id: row.comp_id })

                  return <> <Input class="form-control" placeholder='Enter remarks' style={{ "width": "80%" }}
                    value={filteredArr && filteredArr.length > 0 && filteredArr[0].remarks ? filteredArr[0].remarks : row.unit_wise_status[0].comp_remarks}
                    id="remarks" type={"text"} />
                    <PlusSquareTwoTone onClick={() => { showRemarksModal(row.comp_id, row.collapseId) }} />
                  </>
                } else {
                  if (panelremark.length !== 0 && row.collapseId in panelremark && panelremark[row.collapseId].length !== 0) {
                    if (remarksindex.collapseId.indexOf(row.collapseId) >= 0) {
                      return <label>{row.not_app_remarks}</label>
                    }
                  }
                }
              } else {
                if (panelremark.length !== 0 && row.collapseId in panelremark && panelremark[row.collapseId].length !== 0) {
                  return <> <Input class="form-control" placeholder='Enter remarks' style={{ "width": "80%" }}
                    value={row.unit_wise_status[0].comp_remarks}
                    id="remarks" type={"text"} />
                    <PlusSquareTwoTone onClick={() => { showRemarksModal(row.comp_id, row.collapseId) }} />
                  </>
                }
              }
            }
          }
        }
      },
      sortable: true,
      reorder: true,
      width: '150px',
    },
    {
      name: "Viewed",
      selector: (row) => {
        return (
          <>
            {row && row.h_id !== 0 ?
              <Tooltip title={row.compliance_frequency}><p><i style={{ color: "red" }} class="bi bi-square-fill"></i></p></Tooltip> :
              <Tooltip title={row.compliance_frequency}><p><i style={{ color: "orange" }} class="bi bi-square-fill"></i></p></Tooltip>
            }
          </>
        )
      },
      sortable: true,
      reorder: true,
      width: '150px',
    }
  ];

  const columns = [
    {
      name: "Unit",
      selector: (row) => {
        if (row.is_new) {
          return <p style={{ color: '#8F00FF' }}>{row.u_name}</p>
        } else {
          return row.u_name
        }
      },
      sortable: true,
      reorder: true,
    },
    {
      name: "Location",
      selector: (row) => {
        if (row.is_new) {
          return <p style={{ color: '#8F00FF' }}>{row.location}</p>
        } else {
          return row.location
        }
      },
      sortable: true,
      reorder: true,
      width: '150px',
    },
    {
      name: "Domain",
      selector: (row) => {
        if (row.is_new) {
          return <p style={{ color: '#8F00FF' }}>{row.d_name}</p>
        } else {
          return row.d_name
        }
      },
      sortable: true,
      right: false,
      reorder: true,
      width: '150px',
    },
    {
      name: "No.of Compliance",
      selector: (row) => {
        if (row.is_new) {
          return <p style={{ color: '#8F00FF' }}>{row.r_count}</p>
        } else {
          return row.r_count
        }
      },
      sortable: true,
      right: false,
      reorder: true,
      width: '200px',
    },
    {
      name: "Updated By",
      selector: (row) => {
        if (row.is_new) {
          return <p style={{ color: '#8F00FF' }}>{row.usr_by}</p>
        } else {
          return row.usr_by
        }
      },
      width: '150px',
      sortable: true,
      right: false,
      reorder: true
    },
    {
      name: "Updated On",
      selector: (row) => {
        if (row.is_new) {
          return <p style={{ color: '#8F00FF' }}>{row.usr_on}</p>
        } else {
          return row.usr_on
        }
      },
      sortable: true,
      width: '150px',
      right: false,
      reorder: true
    },
    {
      name: "Lock",
      sortable: true,
      right: false,
      reorder: true,
      selector: (row) => {
        return (
          <div>
            {
              row.is_locked === true ? <div onClick={(e) => {
                unlock(row)
              }}>
                <span className="btn btn-m btn-light-danger
                              text-danger btn-circle d-flex
                              align-items-center justify-content-center"
                  style={{
                    width: "30px",
                    height: "30px"
                  }}><FeatherIcon icon="lock" /></span>
              </div> :
                <span className="btn btn-m btn-light-success
                text-success btn-circle d-flex align-items-center
                justify-content-center" style={{
                    width: "30px",
                    height: "30px"
                  }}>
                  <FeatherIcon icon="unlock" /></span>
            }
          </div>
        )
      }
    }
  ];

  // useEffect(()=>{
  //   if (collapseremarkmessage) {
  //     let tempArray = []
  //     let temp = []
  //     let tempedit = tempeditdata

  //     for(let i in tempedit){
  //       if(collapsename === tempedit[i].lone_statu_name){
  //         tempArray.push({
  //           "c_c_id": tempedit[i].unit_wise_status[0].c_comp_id,
  //           "a_status": tempedit[i].unit_wise_status[0].act_status,
  //           "n_a_remarks": collapseremarkmessage,
  //           "comp_id": tempedit[i].comp_id,
  //           "c_o_status": tempedit[i].unit_wise_status[0].comp_opt_status,
  //           "c_remarks": tempedit[i].unit_wise_status[0].comp_remarks,
  //           "u_name": temp && temp.length > 0 ? temp[0].u_name : '',
  //           "u_id": tempedit[i].unit_wise_status[0].unit_id,
  //           "c_a_status": tempedit[i].unit_wise_status[0].comp_app_status,
  //           "h_id": tempedit[i].unit_wise_status[0].h_id
  //         })
  //       }else {
  //         tempArray.push({
  //           "c_c_id": tempedit[i].unit_wise_status[0].c_comp_id,
  //           "a_status": tempedit[i].unit_wise_status[0].act_status,
  //           "n_a_remarks": tempedit[i].not_app_remarks,
  //           "comp_id": tempedit[i].comp_id,
  //           "c_o_status": tempedit[i].unit_wise_status[0].comp_opt_status,
  //           "c_remarks": tempedit[i].unit_wise_status[0].comp_remarks,
  //           "u_name": temp && temp.length > 0 ? temp[0].u_name : '',
  //           "u_id": tempedit[i].unit_wise_status[0].unit_id,
  //           "c_a_status": tempedit[i].unit_wise_status[0].comp_app_status,
  //           "h_id": tempedit[i].unit_wise_status[0].h_id
  //         })
  //       }
  //     }
  //     console.log(tempArray,'llllllllllll');
  //   }
  // },[collapseremarkmessage])

  useEffect(() => {
    if (checkpanelremark && checkpanelremark.length > 0) {
      let tempArray = [] 
      let temp = []
      let tempedit = tempeditdata

      // for(let i in tempedit){
      //   if(collapsename === tempedit[i].lone_statu_name){
      //     tempArray.push({
      //       "c_c_id": tempedit[i].unit_wise_status[0].c_comp_id,
      //       "a_status": tempedit[i].unit_wise_status[0].act_status,
      //       "n_a_remarks": tempedit[i].not_app_remarks,
      //       "comp_id": tempedit[i].comp_id,
      //       "c_o_status": tempedit[i].unit_wise_status[0].comp_opt_status,
      //       "c_remarks": tempedit[i].unit_wise_status[0].comp_remarks,
      //       "u_name": temp && temp.length > 0 ? temp[0].u_name : '',
      //       "u_id": tempedit[i].unit_wise_status[0].unit_id,
      //       "c_a_status": tempedit[i].unit_wise_status[0].comp_app_status,
      //       "h_id": tempedit[i].unit_wise_status[0].h_id
      //     })
      //   }
      // }

      for (let i in tempedit) {
        let filteredArr = _.filter(checkpanelremark, { comp_id: tempedit[i].comp_id })
        if (filteredArr && filteredArr.length > 0) {
          let tempp;
          if (tempedit[i].unit_wise_status[0].comp_app_status === true) {
            tempp = 1
          } else {
            tempp = 0
          }
          let messagefilter = _.filter(remarkvalue, { comp_id: filteredArr[0].comp_id })
          if (messagefilter && messagefilter.length > 0 && messagefilter[0].remarks !== '') {
            temp.push({
              comp_id: filteredArr[0].comp_id, comp_opt_status: filteredArr[0].comp_opt_status,
              u_id: filteredArr[0].u_id, u_name: filteredArr[0].u_name, value: messagefilter[0].remarks
            })
          } else {
            if (tempp === filteredArr[0].comp_opt_status) {
              temp.push({
                comp_id: filteredArr[0].comp_id, comp_opt_status: filteredArr[0].comp_opt_status,
                u_id: filteredArr[0].u_id, u_name: filteredArr[0].u_name, value: tempedit[i].not_app_remarks
              })
            } else {
              temp.push({
                comp_id: filteredArr[0].comp_id, comp_opt_status: filteredArr[0].comp_opt_status,
                u_id: filteredArr[0].u_id, u_name: filteredArr[0].u_name, value: ''
              })
            }
          }
        }
        if (tempedit[i].comp_id === (filteredArr.length > 0 && filteredArr[0].comp_id ? filteredArr[0].comp_id : '')) {
          if (temp && temp.length > 0) {
            for (let j in temp) {
              if (tempedit[i].comp_id === (temp.length > 0 && temp[j].comp_id)) {
                tempArray.push({
                  "c_c_id": tempedit[i].unit_wise_status[0].c_comp_id,
                  "a_status": tempedit[i].unit_wise_status[0].act_status,
                  "n_a_remarks": tempedit[i].not_app_remarks,
                  "comp_id": tempedit[i].comp_id,
                  "c_o_status": temp[j].comp_opt_status,
                  "c_remarks": temp[j].value ? temp[j].value : tempedit[i].unit_wise_status[0].comp_remarks,
                  "u_name": temp[j].u_name,
                  "u_id": temp[j].u_id,
                  "c_a_status": tempedit[i].unit_wise_status[0].comp_app_status,
                  "h_id": 0
                })
              }
            }
          }
        } else {
          tempArray.push({
            "c_c_id": tempedit[i].unit_wise_status[0].c_comp_id,
            "a_status": tempedit[i].unit_wise_status[0].act_status,
            "n_a_remarks": tempedit[i].not_app_remarks,
            "comp_id": tempedit[i].comp_id,
            "c_o_status": tempedit[i].unit_wise_status[0].comp_opt_status,
            "c_remarks": tempedit[i].unit_wise_status[0].comp_remarks,
            "u_name": temp && temp.length > 0 ? temp[0].u_name : '',
            "u_id": tempedit[i].unit_wise_status[0].unit_id,
            "c_a_status": tempedit[i].unit_wise_status[0].comp_app_status,
            "h_id": tempedit[i].unit_wise_status[0].h_id
          })
        }
      }
      console.log(tempArray, 'tempArray');

      setStatutorypayload(tempArray)
    }
  }, [checkpanelremark, remarkvalue, collapsename])

  useEffect(() => {
    if (editdata) {
      let temparr = remarksindex.remarks_id
      let temp;
      editdata && editdata.map((item) => {
        if (item.unit_wise_status[0].comp_app_status === true) {
          temp = 1
        } else {
          temp = 0
        }
        if (temp !== item.unit_wise_status[0].comp_opt_status) {
          temparr.push(item.comp_id)
          setRemarksindex({
            ...remarksindex,
            // remarks: true,
            remarks_id: [...new Set(temparr)],
          })
        }
      })
    }
  }, [editdata])

  useEffect(() => {
    if (editdata && editdata.length > 0) {
      let temp;
      let tempval = [];
      editdata && editdata.map((item) => {
        if (item.unit_wise_status[0].comp_app_status === true) {
          temp = 1
        } else {
          temp = 0
        }
        if (temp !== item.unit_wise_status[0].comp_opt_status) {
          tempval.push(item)
        }
      })
      setEditopt([...tempval])
    }
  }, [editdata])

  const remarkscolumn = [
    {
      name: "Sno",
      selector: (row) => row.r_id,
      sortable: true,
      width: '250px',
      reorder: true,
    }, {
      name: "Description",
      selector: (row) => <a href={() => false} onClick={(e) => {
        let temp = row.r_description
        let temp1 = remarkscount + 1
        setRemarkscount(temp1)
        setRemarkmessage(temp)
        setRemarksModal(false)
        // if(){

        // }
        if (collapseremarksModal === true) {
          setCollapseremarkmessage(temp)
          setCollapseremarksModal(false)
        }
      }}>{row.r_description}</a>,
      sortable: true,
      width: '250px',
      reorder: true,
    }
  ]

  const genExtra = (val, collapseId, parent) => {
    // console.log(collapseId, remarksindex.remarks);
    //&& (remarksindex.collapseId.indexOf(collapseId) < 0 )
    if (val !== '') {
      if (panelremark.length === 0 || !(collapseId in panelremark)) {
        // if (remarksindex.remarks === false || panelremark.length === 0 || !(collapseId in panelremark)) {
        return panel_data(val, collapseId, parent);
      } else if (collapseId in panelremark && panelremark[collapseId].length === 0) {
        return panel_data(val, collapseId, parent);
      }
    } else {

    }
  };

  const updatestatutorySettings = () => {
    Setsubmitpasswordmodal(false)
    Setpasswordmodal(true)
  }

  const savestatutorysettings = () => {
    SaveStatutorySettings({
      payload:
        [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "SaveStatutorySettings",
              {
                "update_statutories": statutorypayload,
                "le_id": entityid,
                "s_s": 1,
                "d_id": 1,
                "u_ids": uiddomian
              },

            ]
          }
        ],
      paramid: paramid
    })
  }

  const panel_data = (val, collapseId, parent) => {
    return <div
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    >
      <label style={{ "paddingRight": "15px" }}>Remarks</label>
      <Input style={{ width: "80px" }} type={"text"} value={val} />
      <PlusSquareTwoTone style={{ "paddingLeft": "5px" }} onClick={() => { showRemarksModall(collapseId, parent) }} />
    </div>
  }

  const editscreen = (e) => {
    let uid = []
    seteditshow(true);
    setlistshow(false);
    for (let i = 0; i < selectedRows.length; i++) {
      uid.push(selectedRows[i].u_id)
    }
    setUiddomain(uid)
    const editvalue = [
      authtoken,
      {
        "session_token": authtoken,
        "request": [
          "GetSettingsCompliances",
          {
            "le_id": entityid,
            "u_ids": uid,
            "r_count": 0,
            "d_id": 1
          }
        ]
      }
    ]
    editstatutory({
      payload: editvalue,
      paramid: paramid
    })
  }

  const previous = () => {
    seteditshow(false);
    setlistshow(true);
  }

  const changeremarks = (e, row) => {
    e.preventDefault();
    let setstatus;
    if (row.unit_wise_status[0].comp_app_status === true) {
      setstatus = 1
    } else {
      setstatus = 0
    }
    let temp = remarksindex.remarks_id
    let temp1 = remarksindex.collapseId
    let comp_id = row.comp_id;
    if (comp_id in count)
      console.log("hi");
    else
      count[comp_id] = 1;
    if (setstatus === row.unit_wise_status[0].comp_opt_status) {
      if (row.app_status === true) {
        if (count[comp_id] === 1) {
          count[comp_id] = count[comp_id] + 1
          setCount(count);
          temp.push(row.comp_id)
          temp1.push(row.collapseId)
          setRemarksindex({
            ...remarksindex,
            remarks: true,
            remarks_id: [...new Set(temp)],
            collapseId: [...new Set(temp1)]
          })

          let tempArr = checkpanelremark
          tempArr.push({
            comp_id: row.comp_id, comp_opt_status: 0,
            u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
          });
          setCheckpanelremark([...tempArr])

          setCloseoutlined([...closeoutlined, row.comp_id])
          const index = minuscircleoutlined.indexOf(row.comp_id);
          if (index > -1) {
            minuscircleoutlined.splice(index, 1);
            setMinuscircleoutlined(minuscircleoutlined)
          }
          const index1 = checkoutlined.indexOf(row.comp_id);
          if (index1 > -1) {
            checkoutlined.splice(index1, 1);
            setCheckoutlined(checkoutlined)
          }
        } else if (count[comp_id] === 2) {
          console.log("true222");
          count[comp_id] = count[comp_id] + 1
          setCount(count);
          setMinuscircleoutlined([...minuscircleoutlined, row.comp_id])
          if (row.collapseId in panelremark)
            panelremark[row.collapseId].push(row.comp_id);
          else {
            panelremark[row.collapseId] = [];
            panelremark[row.collapseId].push(row.comp_id);
          }
          setPanelremark(panelremark)

          let tempArr = checkpanelremark

          tempArr.map((item, i) => {
            if (item.comp_id === row.comp_id) {
              tempArr.splice(i, 1);
              tempArr.push({
                comp_id: row.comp_id, comp_opt_status: 2,
                u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
              });
              setCheckpanelremark([...tempArr])
            }
          })


          if (remarksindex.remarks_id.length === 0) {
            setRemarksindex({
              ...remarksindex,
              remarks: true
            })
          }

          const index = checkoutlined.indexOf(row.comp_id);
          if (index > -1) {
            checkoutlined.splice(index, 1);
            setCheckoutlined(checkoutlined)
          }
          const index1 = closeoutlined.indexOf(row.comp_id);
          if (index1 > -1) {
            closeoutlined.splice(index1, 1);
            setCloseoutlined(closeoutlined)
          }
        } else if (count[comp_id] === 3) {
          const index = minuscircleoutlined.indexOf(row.comp_id);
          if (index > -1) {
            minuscircleoutlined.splice(index, 1);
            setMinuscircleoutlined(minuscircleoutlined)
          }
          setCheckoutlined([...checkoutlined, row.comp_id])
          const index2 = closeoutlined.indexOf(row.comp_id);
          if (index2 > -1) {
            closeoutlined.splice(index2, 1);
            setCloseoutlined(closeoutlined)
          }
          const index3 = panelremark[row.collapseId].indexOf(row.comp_id);
          if (index3 > -1) {
            panelremark[row.collapseId].splice(index3, 1);
            setPanelremark(panelremark)
          }

          let tempArr = checkpanelremark

          tempArr.map((item, i) => {
            if (item.comp_id === row.comp_id) {
              tempArr.splice(i, 1);
              tempArr.push({
                comp_id: row.comp_id, comp_opt_status: 1,
                u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
              });
              setCheckpanelremark([...tempArr])
            }
          })

          let check = remarkvalue
          for (let i in check) {
            if (check[i].comp_id === row.comp_id) {
              check.splice(i, 1);
            }
          }
          setRemarkvalue(check)

          if (remarksindex.remarks_id.length === 0) {
            setRemarksindex({
              ...remarksindex,
              remarks: false
            })
          }
          const remove = remarksindex.remarks_id.indexOf(row.comp_id);
          if (remove > -1) {
            remarksindex.remarks_id.splice(remove, 1);
          }

          count[comp_id] = 1
          setCount(count);

        }
      } else {
        if (count[comp_id] === 1) {
          count[comp_id] = count[comp_id] + 1
          setCount(count);
          temp.push(row.comp_id)
          temp1.push(row.collapseId)
          setRemarksindex({
            ...remarksindex,
            remarks: true,
            remarks_id: [...new Set(temp)],
            collapseId: [...new Set(temp1)]
          })
          // if (row.collapseId in checkpanelremark)
          let tempArr = checkpanelremark
          tempArr.push({
            comp_id: row.comp_id, comp_opt_status: 2,
            u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
          });
          // else {
          // checkpanelremark[row.collapseId] = [];
          // checkpanelremark[row.collapseId].push({ comp_id: row.comp_id, value: 2 });
          // }
          setCheckpanelremark([...tempArr])
          setMinuscircleoutlined([...minuscircleoutlined, row.comp_id])
          const index = closeoutlined.indexOf(row.comp_id);
          if (index > -1) {
            closeoutlined.splice(index, 1);
            setCloseoutlined(closeoutlined)
          }
          const index1 = checkoutlined.indexOf(row.comp_id);
          if (index1 > -1) {
            checkoutlined.splice(index1, 1);
            setCheckoutlined(checkoutlined)
          }
        } else if (count[comp_id] === 2) {
          //let count1 = count[row.comp_id] + 1
          count[comp_id] = count[comp_id] + 1
          setCount(count);
          //setCount(count1);
          //setCount({...count,count[row.comp_id]:count1});
          setCheckoutlined([...checkoutlined, row.comp_id])
          if (row.collapseId in panelremark)
            panelremark[row.collapseId].push(row.comp_id);
          else {
            panelremark[row.collapseId] = [];
            panelremark[row.collapseId].push(row.comp_id);
          }
          setPanelremark(panelremark)

          let tempArr = checkpanelremark

          tempArr.map((item, i) => {
            if (item.comp_id === row.comp_id) {
              tempArr.splice(i, 1);
              tempArr.push({
                comp_id: row.comp_id, comp_opt_status: 1,
                u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
              });
              setCheckpanelremark([...tempArr])
            }
          })

          const index = minuscircleoutlined.indexOf(row.comp_id);
          if (index > -1) {
            minuscircleoutlined.splice(index, 1);
            setMinuscircleoutlined(minuscircleoutlined)
          }
          const index1 = closeoutlined.indexOf(row.comp_id);
          if (index1 > -1) {
            closeoutlined.splice(index1, 1);
            setCloseoutlined(closeoutlined)
          }
        } else if (count[comp_id] === 3) {
          const index = minuscircleoutlined.indexOf(row.comp_id);
          if (index > -1) {
            minuscircleoutlined.splice(index, 1);
            setMinuscircleoutlined(minuscircleoutlined)
          }
          setCloseoutlined([...closeoutlined, row.comp_id])
          const index2 = checkoutlined.indexOf(row.comp_id);
          if (index2 > -1) {
            checkoutlined.splice(index2, 1);
            setCheckoutlined(checkoutlined)
          }
          const index3 = panelremark[row.collapseId].indexOf(row.comp_id);
          if (index3 > -1) {
            panelremark[row.collapseId].splice(index3, 1);
            setPanelremark(panelremark)
          }
          let check = remarkvalue
          for (let i in check) {
            if (check[i].comp_id === row.comp_id) {
              check.splice(i, 1);
            }
          }
          setRemarkvalue(check)

          const remove = remarksindex.remarks_id.indexOf(row.comp_id);

          if (remove > -1) {
            remarksindex.remarks_id.splice(remove, 1);
            // setCloseoutlined(closeoutlined)
          }

          if (remarksindex.remarks_id.length === 0) {
            setRemarksindex({
              ...remarksindex,
              remarks: false
            })
          }

          count[comp_id] = 1
          setCount(count);

          // let tempArr = checkpanelremark

          // tempArr.map((item, i) => {
          //   if (item.comp_id === row.comp_id) {
          //     tempArr.splice(i, 1);
          //   }
          // })
          // setCheckpanelremark([...tempArr])

          let tempArr = checkpanelremark

          tempArr.map((item, i) => {
            if (item.comp_id === row.comp_id) {
              tempArr.splice(i, 1);
              tempArr.push({
                comp_id: row.comp_id, comp_opt_status: 0,
                u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
              });
              setCheckpanelremark([...tempArr])
            }
          })
        }
      }
    } else if (setstatus !== row.unit_wise_status[0].comp_opt_status) {
      if (setstatus === 0) {
        if (row.unit_wise_status[0].comp_opt_status === 2) {
          if (count[comp_id] === 1) {
            console.log('che---1');
            count[comp_id] = count[comp_id] + 1
            setCount(count);
            temp.push(row.comp_id)
            temp1.push(row.collapseId)
            setRemarksindex({
              ...remarksindex,
              remarks: true,
              remarks_id: [...new Set(temp)],
              collapseId: [...new Set(temp1)]
            })
            let tempArr = checkpanelremark
            tempArr.push({
              comp_id: row.comp_id, comp_opt_status: 1,
              u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
            });
            setCheckpanelremark([...tempArr])
            if (row.collapseId in panelremark)
              panelremark[row.collapseId].push(row.comp_id);
            else {
              panelremark[row.collapseId] = [];
              panelremark[row.collapseId].push(row.comp_id);
            }
            setPanelremark(panelremark)
            // setMinuscircleoutlined([...minuscircleoutlined, row.comp_id])
            setCheckoutlined([...checkoutlined, row.comp_id])
            const index = closeoutlined.indexOf(row.comp_id);
            if (index > -1) {
              closeoutlined.splice(index, 1);
              setCloseoutlined(closeoutlined)
            }
            const index1 = minuscircleoutlined.indexOf(row.comp_id);
            if (index1 > -1) {
              minuscircleoutlined.splice(index1, 1);
              setMinuscircleoutlined(minuscircleoutlined)
            }
          } else if (count[comp_id] === 2) {
            console.log('che---2');
            count[comp_id] = count[comp_id] + 1
            setCount(count);
            setCloseoutlined([...closeoutlined, row.comp_id])

            let tempArr = checkpanelremark

            tempArr.map((item, i) => {
              if (item.comp_id === row.comp_id) {
                tempArr.splice(i, 1);
                tempArr.push({
                  comp_id: row.comp_id, comp_opt_status: 0,
                  u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
                });
                setCheckpanelremark([...tempArr])
              }
            })

            const index = minuscircleoutlined.indexOf(row.comp_id);
            if (index > -1) {
              minuscircleoutlined.splice(index, 1);
              setMinuscircleoutlined(minuscircleoutlined)
            }
            const index1 = checkoutlined.indexOf(row.comp_id);
            if (index1 > -1) {
              checkoutlined.splice(index1, 1);
              setCheckoutlined(checkoutlined)
            }
            const index3 = panelremark[row.collapseId].indexOf(row.comp_id);
            if (index3 > -1) {
              panelremark[row.collapseId].splice(index3, 1);
              setPanelremark(panelremark)
            }
            const remove = remarksindex.remarks_id.indexOf(row.comp_id);

            if (remove > -1) {
              remarksindex.remarks_id.splice(remove, 1);
              // setCloseoutlined(closeoutlined)
            }

            if (remarksindex.remarks_id.length === 0) {
              setRemarksindex({
                ...remarksindex,
                remarks: false
              })
            }

          } else if (count[comp_id] === 3) {
            console.log('che---3');
            const index = closeoutlined.indexOf(row.comp_id);
            if (index > -1) {
              closeoutlined.splice(index, 1);
              setCloseoutlined(closeoutlined)
            }
            setMinuscircleoutlined([...minuscircleoutlined, row.comp_id])

            // setCloseoutlined([...closeoutlined, row.comp_id])
            const index2 = checkoutlined.indexOf(row.comp_id);
            if (index2 > -1) {
              checkoutlined.splice(index2, 1);
              setCheckoutlined(checkoutlined)
            }

            // if (remarksindex.remarks_id.length === 0) {
            temp.push(row.comp_id)

            setRemarksindex({
              ...remarksindex,
              remarks: true,
              remarks_id: [...new Set(temp)],
            })
            // }

            count[comp_id] = 1
            setCount(count);

            let tempArr = checkpanelremark

            tempArr.map((item, i) => {
              if (item.comp_id === row.comp_id) {
                tempArr.splice(i, 1);
              }
            })
            setCheckpanelremark([...tempArr])
          }
        }
        if (row.unit_wise_status[0].comp_opt_status === 1) {
          if (count[comp_id] === 1) {
            console.log("check1");
            count[comp_id] = count[comp_id] + 1
            setCount(count);
            if (remarksindex.remarks_id.indexOf(row.comp_id) < 0) {
              temp.push(row.comp_id)
            }
            temp1.push(row.collapseId)

            const remove = remarksindex.remarks_id.indexOf(row.comp_id);

            if (remove > -1) {
              remarksindex.remarks_id.splice(remove, 1);
            }

            if (remarksindex.remarks_id.length === 0) {
              setRemarksindex({
                ...remarksindex,
                remarks: false
              })
            }

            const index3 = panelremark[row.collapseId].indexOf(row.comp_id);
            if (index3 > -1) {
              panelremark[row.collapseId].splice(index3, 1);
              setPanelremark(panelremark)
            }

            let tempArr = checkpanelremark
            tempArr.push({
              comp_id: row.comp_id, comp_opt_status: 0,
              u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
            });
            setCheckpanelremark([...tempArr])

            setCloseoutlined([...closeoutlined, row.comp_id])

            const index1 = minuscircleoutlined.indexOf(row.comp_id);
            if (index1 > -1) {
              minuscircleoutlined.splice(index1, 1);
              setMinuscircleoutlined(minuscircleoutlined)
            }

            const index = checkoutlined.indexOf(row.comp_id);
            if (index > -1) {
              checkoutlined.splice(index, 1);
              setCheckoutlined(checkoutlined)
            }

          } else if (count[comp_id] === 2) {
            console.log("check2");
            count[comp_id] = count[comp_id] + 1
            setCount(count);
            temp.push(row.comp_id)


            let tempArr = checkpanelremark

            tempArr.map((item, i) => {
              if (item.comp_id === row.comp_id) {
                tempArr.splice(i, 1);
                tempArr.push({
                  comp_id: row.comp_id, comp_opt_status: 2,
                  u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
                });
                setCheckpanelremark([...tempArr])
              }
            })

            setMinuscircleoutlined([...minuscircleoutlined, row.comp_id])

            const index = closeoutlined.indexOf(row.comp_id);
            if (index > -1) {
              closeoutlined.splice(index, 1);
              setCloseoutlined(closeoutlined)
            }
            const index1 = checkoutlined.indexOf(row.comp_id);
            if (index1 > -1) {
              checkoutlined.splice(index1, 1);
              setCheckoutlined(checkoutlined)
            }

            setRemarksindex({
              ...remarksindex,
              remarks: true,
              remarks_id: [...new Set(temp)],
            })

          } else if (count[comp_id] === 3) {
            console.log("check3");

            setCheckoutlined([...checkoutlined, row.comp_id])

            const index = closeoutlined.indexOf(row.comp_id);
            if (index > -1) {
              closeoutlined.splice(index, 1);
              setCloseoutlined(closeoutlined)
            }

            const index2 = minuscircleoutlined.indexOf(row.comp_id);
            if (index2 > -1) {
              minuscircleoutlined.splice(index2, 1);
              setMinuscircleoutlined(minuscircleoutlined)
            }

            // const index3 = panelremark[row.collapseId].indexOf(row.comp_id);
            // if (index3 > -1) {
            //   panelremark[row.collapseId].splice(index3, 1);
            //   setPanelremark(panelremark)
            // }

            // const remove = remarksindex.remarks_id.indexOf(row.comp_id);

            // if (remove > -1) {
            //   remarksindex.remarks_id.splice(remove, 1);
            // }

            if (row.collapseId in panelremark)
              panelremark[row.collapseId].push(row.comp_id);
            else {
              panelremark[row.collapseId] = [];
              panelremark[row.collapseId].push(row.comp_id);
            }
            setPanelremark(panelremark)

            if (remarksindex.remarks_id.length === 0) {
              setRemarksindex({
                ...remarksindex,
                remarks: false
              })
            }

            //  const remove1 = remarksindex.collapseId.indexOf(row.collapseId);
            // if (remove1 > -1) {
            //   remarksindex.collapseId.splice(remove1, 1);
            // } 
            // count1 = 1
            //setCount(count1);
            console.log(remarksindex.remarks_id, 'remarksindex.remarks_id');

            count[comp_id] = 1
            setCount(count);

            let tempArr = checkpanelremark

            tempArr.map((item, i) => {
              if (item.comp_id === row.comp_id) {
                tempArr.splice(i, 1);
              }
            })
            setCheckpanelremark([...tempArr])
          }
        }
      }
      if (setstatus === 1) {
        if (row.unit_wise_status[0].comp_opt_status === 2) {
          if (count[comp_id] === 1) {
            console.log('che---1');
            count[comp_id] = count[comp_id] + 1
            setCount(count);
            // temp.push(row.comp_id)
            temp1.push(row.collapseId)

            const remove = remarksindex.remarks_id.indexOf(row.comp_id);

            if (remove > -1) {
              remarksindex.remarks_id.splice(remove, 1);
              // setCloseoutlined(closeoutlined)
            }

            setRemarksindex({
              ...remarksindex,
              remarks: true,
              // remarks_id: [...new Set(temp)],
              collapseId: [...new Set(temp1)]
            })

            let tempArr = checkpanelremark
            tempArr.push({
              comp_id: row.comp_id, comp_opt_status: 1,
              u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
            });
            setCheckpanelremark([...tempArr])
            // if (row.collapseId in panelremark)
            //   panelremark[row.collapseId].push(row.comp_id);
            // else {
            //   panelremark[row.collapseId] = [];
            //   panelremark[row.collapseId].push(row.comp_id);
            // }
            // setPanelremark(panelremark)
            setCheckoutlined([...checkoutlined, row.comp_id])
            const index = closeoutlined.indexOf(row.comp_id);
            if (index > -1) {
              closeoutlined.splice(index, 1);
              setCloseoutlined(closeoutlined)
            }
            const index1 = minuscircleoutlined.indexOf(row.comp_id);
            if (index1 > -1) {
              minuscircleoutlined.splice(index1, 1);
              setMinuscircleoutlined(minuscircleoutlined)
            }
          } else if (count[comp_id] === 2) {
            console.log('che---2');
            count[comp_id] = count[comp_id] + 1
            setCount(count);
            setCloseoutlined([...closeoutlined, row.comp_id])
            temp.push(row.comp_id)

            let tempArr = checkpanelremark

            tempArr.map((item, i) => {
              if (item.comp_id === row.comp_id) {
                tempArr.splice(i, 1);
                tempArr.push({
                  comp_id: row.comp_id, comp_opt_status: 0,
                  u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
                });
                setCheckpanelremark([...tempArr])
              }
            })

            const index = minuscircleoutlined.indexOf(row.comp_id);
            if (index > -1) {
              minuscircleoutlined.splice(index, 1);
              setMinuscircleoutlined(minuscircleoutlined)
            }
            const index1 = checkoutlined.indexOf(row.comp_id);
            if (index1 > -1) {
              checkoutlined.splice(index1, 1);
              setCheckoutlined(checkoutlined)
            }
            const index3 = panelremark[row.collapseId].indexOf(row.comp_id);
            if (index3 > -1) {
              panelremark[row.collapseId].splice(index3, 1);
              setPanelremark(panelremark)
            }

            setRemarksindex({
              ...remarksindex,
              // remarks: true,
              remarks_id: [...new Set(temp)],
            })
            // const remove = remarksindex.remarks_id.indexOf(row.comp_id);

            // if (remove > -1) {
            //   remarksindex.remarks_id.splice(remove, 1);
            //   // setCloseoutlined(closeoutlined)
            // }

            if (remarksindex.remarks_id.length === 0) {
              setRemarksindex({
                ...remarksindex,
                remarks: false
              })
            }

          } else if (count[comp_id] === 3) {
            console.log('che---3');
            const index = closeoutlined.indexOf(row.comp_id);
            if (index > -1) {
              closeoutlined.splice(index, 1);
              setCloseoutlined(closeoutlined)
            }
            setMinuscircleoutlined([...minuscircleoutlined, row.comp_id])

            // setCloseoutlined([...closeoutlined, row.comp_id])
            const index2 = checkoutlined.indexOf(row.comp_id);
            if (index2 > -1) {
              checkoutlined.splice(index2, 1);
              setCheckoutlined(checkoutlined)
            }

            // if (remarksindex.remarks_id.length === 0) {
            // temp.push(row.comp_id)

            // setRemarksindex({
            //   ...remarksindex,
            //   remarks: true,
            //   remarks_id: [...new Set(temp)],
            // })
            // }

            count[comp_id] = 1
            setCount(count);

            let tempArr = checkpanelremark

            tempArr.map((item, i) => {
              if (item.comp_id === row.comp_id) {
                tempArr.splice(i, 1);
              }
            })
            setCheckpanelremark([...tempArr])
          }
        }
        if (row.unit_wise_status[0].comp_opt_status === 0) {
          if (count[comp_id] === 1) {
            console.log("check1");
            count[comp_id] = count[comp_id] + 1
            setCount(count);
            console.log(remarksindex.remarks_id, 'remarksindex.remarks_idssssssssss');

            if (remarksindex.remarks_id.indexOf(row.comp_id) < 0) {
              console.log("2222");
              temp.push(row.comp_id)
            }
            temp1.push(row.collapseId)

            // const remove = remarksindex.remarks_id.indexOf(row.comp_id);

            // if (remove > -1) {
            //   remarksindex.remarks_id.splice(remove, 1);
            // }

            if (remarksindex.remarks_id.length === 0) {
              setRemarksindex({
                ...remarksindex,
                remarks: false
              })
            }

            let tempArr = checkpanelremark
            tempArr.push({
              comp_id: row.comp_id, comp_opt_status: 2,
              u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
            });
            setCheckpanelremark([...tempArr])

            const index1 = closeoutlined.indexOf(row.comp_id);
            if (index1 > -1) {
              closeoutlined.splice(index1, 1);
              setCloseoutlined(closeoutlined)
            }

            const index = checkoutlined.indexOf(row.comp_id);
            if (index > -1) {
              checkoutlined.splice(index, 1);
              setCheckoutlined(checkoutlined)
            }

            setMinuscircleoutlined([...minuscircleoutlined, row.comp_id])

          } else if (count[comp_id] === 2) {
            console.log("check2");
            count[comp_id] = count[comp_id] + 1
            setCount(count);

            let tempArr = checkpanelremark

            tempArr.map((item, i) => {
              if (item.comp_id === row.comp_id) {
                tempArr.splice(i, 1);
                tempArr.push({
                  comp_id: row.comp_id, comp_opt_status: 1,
                  u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
                });
                setCheckpanelremark([...tempArr])
              }
            })
            const index1 = minuscircleoutlined.indexOf(row.comp_id);
            if (index1 > -1) {
              minuscircleoutlined.splice(index1, 1);
              setMinuscircleoutlined(minuscircleoutlined)
            }

            setCheckoutlined([...checkoutlined, row.comp_id])

            const index = closeoutlined.indexOf(row.comp_id);
            if (index > -1) {
              closeoutlined.splice(index, 1);
              setCloseoutlined(closeoutlined)
            }

            const index3 = panelremark[row.collapseId].indexOf(row.comp_id);
            if (index3 > -1) {
              panelremark[row.collapseId].splice(index3, 1);
              setPanelremark(panelremark)
            }
            console.log(remarksindex.remarks_id, 'remarksindex.remarks_id');
            const remove = remarksindex.remarks_id.indexOf(row.comp_id);

            if (remove > -1) {
              remarksindex.remarks_id.splice(remove, 1);
            }

          } else if (count[comp_id] === 3) {
            console.log("check3");

            setCloseoutlined([...closeoutlined, row.comp_id])


            const index = checkoutlined.indexOf(row.comp_id);
            if (index > -1) {
              checkoutlined.splice(index, 1);
              setCheckoutlined(checkoutlined)
            }

            const index2 = minuscircleoutlined.indexOf(row.comp_id);
            if (index2 > -1) {
              minuscircleoutlined.splice(index2, 1);
              setMinuscircleoutlined(minuscircleoutlined)
            }

            if (row.collapseId in panelremark)
              panelremark[row.collapseId].push(row.comp_id);
            else {
              panelremark[row.collapseId] = [];
              panelremark[row.collapseId].push(row.comp_id);
            }
            setPanelremark(panelremark)

            if (remarksindex.remarks_id.length === 0) {
              setRemarksindex({
                ...remarksindex,
                remarks: false
              })
            }

            count[comp_id] = 1
            setCount(count);

            let tempArr = checkpanelremark

            tempArr.map((item, i) => {
              if (item.comp_id === row.comp_id) {
                tempArr.splice(i, 1);
              }
            })
            setCheckpanelremark([...tempArr])
          }
        }
      }
    }
  }

  const showRemarksModal = (comp_id, collapseId) => {
    let temp1 = [{ comp_id: comp_id, collapseId: collapseId }]
    setTempremarkvalue(temp1)
    let temp = remarkvalue
    for (let i in temp) {
      if (temp[i].comp_id === comp_id) {
        temp.splice(i, 1);
      }
    }
    temp.push({ comp_id: comp_id, collapseId: collapseId })
    setRemarkvalue([...new Set(temp)])
    let tempArr = checkpanelremark
    // if (checkpanelremark && checkpanelremark.length === 0) {
    //   tempArr.push({
    //     comp_id: comp_id,
    //     u_id: selectedRows[0].u_id, u_name: selectedRows[0].u_name
    //   });
    // }
    GetRemarksData({
      payload:
        [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetRemarksData",
              {}
            ]
          }
        ],
      paramid: paramid
    })
    setRemarksModal(true);
  };

  const showRemarksModall = (collapseId, parent) => {
    console.log(collapseId, '5555555');
    // let temp1 = [{collapseId: collapseId }]
    setCollapsename(parent)
    console.log(parent, 'parent');
    // setTempremarkvalue(temp1)
    // let temp = remarkvalue
    // for (let i in temp) {
    //   if (temp[i].comp_id === comp_id) {
    //     temp.splice(i, 1);
    //   }
    // }
    // temp.push({ comp_id: comp_id, collapseId: collapseId })
    // setRemarkvalue([...new Set(temp)])
    GetRemarksData({
      payload:
        [
          authtoken,
          {
            "session_token": authtoken,
            "request": [
              "GetRemarksData",
              {}
            ]
          }
        ],
      paramid: paramid
    })
    setRemarksModal(true);
    setCollapseremarksModal(true);
  };

  const filterapply = (e) => {
    let payloaddata;
    if (filtervalue.entity === '') {
      payloaddata = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettings",
            {
              "le_id": entityid,
              "div_id": parseInt(filtervalue.division),
              "cat_id": parseInt(filtervalue.category),
            }
          ]
        }
      ]
    } else {
      payloaddata = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "GetStatutorySettings",
            {
              "le_id": filtervalue.entity,
              "div_id": parseInt(filtervalue.division),
              "cat_id": parseInt(filtervalue.category),
            }
          ]
        }
      ]
    }
    statutorylist({
      payload: payloaddata,
      paramid: paramid
    })
    SetisModalVisible(false);
  }

  const submitpassword = (e) => {
    e.preventDefault();
    if (submitpasswordmodal === true) {
      const payload = [
        authtoken,
        {
          "session_token": authtoken,
          "request": [
            "ChangeStatutorySettingsLock",
            {
              "le_id": password.le_id,
              "d_id": password.d_id,
              "u_id": password.u_id,
              "lock": password.lock,
              "password": password.passwordvalue
            }
          ]
        }
      ]

      locksettings({
        payload: payload,
        paramid: paramid,
        history: history
      })
      Setpasswordmodal(false);
    } else {
      UpdateStatutorySettings({
        payload:
          [
            authtoken,
            {
              "session_token": authtoken,
              "request": [
                "UpdateStatutorySettings",
                {
                  "password": password.passwordvalue,
                  "update_statutories": statutorypayload,
                  "le_id": entityid,
                  "s_s": 1,
                  "d_id": 1,
                  "u_ids": uiddomian
                },

              ]
            }
          ],
        paramid: paramid
      })
    }
  }

  return (
    <div>
      <div className="page-wrapper">
        <div style={{ display: (!listshow ? 'none' : '') }}>
          <div className="page-titles pb-2 pt-1">
            <div className="row">
              <div className="col-lg-8 col-md-6 col-12 align-self-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 d-flex align-items-center">
                    <li className="breadcrumb-item">
                      <a href="index.html" className="link"
                      ><i className="ri-home-3-line fs-5"></i
                      ></a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Statutory Settings
                    </li>
                  </ol>
                </nav>
                <h3 className="mb-0 fw-bold">Statutory Settings</h3>
              </div>
              <div
                className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                {selectedRows.length > 0 ?
                  <div className="btn btn-light-primary text-primary px-4 btn-md"
                    style={{ marginRight: "5%" }}
                    onClick={(e) => {
                      editscreen()
                    }}>Edit</div> : ''
                }
                <button
                  className="btn btn-light-primary text-primary btn-lg px-4 fs-4"
                  data-bs-toggle="modal"
                  data-bs-target="#bs-example-modal-xlg" onClick={(e) => { SetisModalVisible(true) }}>
                  Advanced Search
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">

                  <div className="card-body">
                    <div className="table-responsive">
                      {list && list.length > 0 ?
                        <DataTable
                          columns={columns}
                          data={list}
                          defaultSortFieldId={1}
                          selectableRowDisabled={(row) =>
                            row.is_locked === false ? false : true
                          }
                          selectableRows={list.map((item) => {
                            return item.is_new
                          })}
                          pagination
                          onSelectedRowsChange={({
                            allSelected,
                            selectedCount,
                            selectedRows,
                          }) => {
                            let tempArray = selectedRows.map((item) => {
                              return item
                            })
                            setSelectedRows(tempArray)
                          }}
                        /> : null}
                    </div>
                    <br />
                    {/* {selectedRows.length > 0 ?
                      <div className="btn btn-primary btn-md" onClick={(e) => {
                        editscreen()
                      }}>Edit</div> : ''
                    } */}
                    <br />
                    <div>
                      <p>Statutes assigned to those units are marked in BlackColor</p>
                      <p>Select atleast one unit</p>
                      <p style={{ color: '#8F00FF' }}>Units to be assigned are marked in BlueColor</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: (!editshow ? 'none' : '') }}>
          <div>
            <div>
              <div className="page-titles pt-2 pb-2">
                <div className="row">
                  <div className="col-lg-8 col-md-6 col-12 align-self-center">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0 d-flex align-items-center">
                        <li className="breadcrumb-item">
                          <a href="index.html" className="link"><i className="ri-home-3-line fs-5"></i></a>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                          Statutory Settings
                        </li>
                      </ol>
                    </nav>
                    <h1 className="mb-0 fw-bold">Statutory Settings</h1>
                  </div>
                  <div
                    className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                    <button
                      className="btn btn-light-primary text-primary btn-lg px-4 fs-4"
                      onClick={(e) => {
                        savestatutorysettings(e)
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="container-fluid pt-1">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body mt-1 pt-1 mb-1 pb-1">
                        {titledata && titledata.map((itemdata, i) => {
                          return <div
                            className="accordion accordion-flush">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingOne">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#flush-collapseOne"
                                  aria-expanded="false"
                                  aria-controls="flush-collapseOne">
                                  <div className="col">
                                    <input className="me-2" type="checkbox" />{itemdata.parent}
                                  </div>
                                  <div className="col-3 me-3 d-flex">
                                    <input type="text" className="form-control " placeholder="Enter Remarks" />
                                    <span style={{ "width": "30px", "height": "30px" }}
                                      className="ms-3 btn btn-m btn-light-primary text-primary btn-circle
                            d-flex align-items-center justify-content-center">
                                      <i className="" data-feather="plus-circle"></i>
                                    </span>
                                  </div>
                                </button>
                              </h2>
                              <div
                                id="flush-collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">
                                  <div className="table-responsive">
                                    <table
                                      className="text-dark table table-bordered text-nowrap fs-2">
                                      <thead>
                                        <tr className="bg-light-secondary">
                                          <th>Statutory Provision</th>
                                          <th>Compliance Task</th>
                                          <th>Compliance<br /> Frequency</th>
                                          <th>Compliance Description</th>
                                          <th>Criticality</th>
                                          <th><i className="ri-user-add-line"></i></th>
                                          <th>Remarks</th>
                                          <th>Viewed</th>
                                        </tr>
                                      </thead>
                                      {itemdata.child && itemdata.child.map((data, i) => {
                                        if (itemdata.parent === data.lone_statu_name) {
                                          return <tbody>
                                            <tr>
                                              <td>{data.s_prov}</td>
                                              <td>{data.comp_name}</td>
                                              <td>{data.frequency_name}</td>
                                              <td>{ }</td>
                                              <td><span style={{
                                                "width": "30px",
                                                "height": "30px"
                                              }}
                                                className="btn btn-m btn-light-warning text-warning btn-circle
                                      d-flex align-items-center justify-content-center">
                                                <i className="" data-feather="x"></i>
                                              </span></td>
                                              <td><span style={{ "width": "30px", "height": "30px" }}
                                                className="btn btn-m btn-light-success text-success btn-circle
                                      d-flex align-items-center justify-content-center">
                                                <i className="" data-feather="check"></i>
                                              </span></td>
                                              <td></td>
                                              <td><span style={{
                                                "width": "30px",
                                                "height": "30px"
                                              }}
                                                className="btn btn-m btn-light-warning text-warning btn-circle
                                      d-flex lign-items-center justify-content-center">
                                                <i className="" data-feather="eye"></i>
                                              </span></td>
                                            </tr>
                                          </tbody>
                                        }
                                      })}

                                    </table>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <Card>
                {/* <div className='row'>
                  <div className="col-md-12">
                    <div className="mb-3">
                      &nbsp;<p style={{ "color": "#6666ff", "fontWeight": "bold" }}>{localStorage.getItem('SelectedEntity')}&nbsp;<span>{">>>>"}</span>&nbsp;{userDetails.domainName}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-6">
                      <label>Domain Name</label>
                      <p style={{ "color": "#6666ff", "fontWeight": "bold" }}>{userDetails.domainName}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="col-6">
                      <label>Timeline</label>
                      <p style={{ "color": "#6666ff", "fontWeight": "bold" }}>{list.timeline}</p>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12 mt-3">
                  <div className="row">
                    <Collapse defaultActiveKey={['0']}>
                      {/* {titledata && titledata.map((itemdata, i) => { defaultActiveKey={['0']} */}
                      {titledata &&
                        titledata.map((itemdata, i) => (
                          <Panel header={itemdata.parent} showArrow={false} key={itemdata.index}
                            extra={genExtra(remarksdata.remarks ? remarksdata.remarks : itemdata.child[0].not_app_remarks, itemdata.index, itemdata.parent)}
                          >
                            <DataTable
                              columns={column}
                              data={itemdata.child}
                              // selectableRows
                              pagination={false}
                            // onSelectedRowsChange={({
                            //   allSelected,
                            //   selectedCount,
                            //   selectedRows,
                            // }) => {

                            // if (selectedCount > 0) {
                            // setCheckedRow(selectedRows)
                            // setData(selectedRows)
                            // } else {
                            // setCheckedRow([])
                            // setData([])
                            // }

                            // }}
                            />
                          </Panel>
                        ))}

                    </Collapse>
                  </div>
                </div>
                <div className='col-md-2' style={{ marginRight: '90%', marginTop: '3%' }} >
                  <button className='btn btn-primary' onClick={(e) => { previous(e) }}>Previous
                  </button>
                </div>
                <div className='col-md-2' style={{ marginLeft: '90%' }} >
                  <button className='btn btn-primary'
                    onClick={(e) => {
                      updatestatutorySettings(e)
                    }}
                  >Submit
                  </button>
                </div>
              </Card>
            </div>
          </div>
        </div>

      </div>

      {/* AdvanceSearch Modal popup */}
      <Modal title="Statutory Settings" visible={isModalVisible} footer={null} onCancel={handleCancel}>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label>Business Group:</label>
                &nbsp;&nbsp;-
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Legal Entity:</label>
                {localStorage.getItem('SelectedEntity') === "All" ?
                  <div className="col-md-6">
                    <div className="mb-3">
                      <select
                        className="select2 form-control custom-select bg-white"
                        style={{ "width": "100%", "height": "38px" }} onChange={(e) => {
                          Setfiltervalue({
                            ...filtervalue,
                            entity: e.target.value
                          })
                        }}>
                        <option>--Select--</option>
                        {filterdatas && filterdatas.length > 0 && filterdatas.le_did_infos.map((item) => {
                          return <option value={item.le_id}>{item.le_name}</option>
                        })}
                      </select>
                    </div>
                  </div> : <p style={{ "color": "#6666ff", "fontWeight": "bold" }}>{localStorage.getItem('SelectedEntity')}</p>}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Division</label>
                <select
                  className="select2 form-control custom-select bg-white"
                  style={{ "width": "100%", "height": "38px" }} onChange={(e) => {
                    Setfiltervalue({
                      ...filtervalue,
                      division: e.target.value
                    })
                  }}>
                  <option>--Select--</option>
                  {filterdatas && filterdatas.length > 0 && filterdatas.div_infos.map((item) => {
                    return <option value={item.div_id}>{item.div_name}</option>
                  })}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Category</label>
                <select
                  className="select2 form-control custom-select bg-white"
                  style={{ "width": "100%", "height": "38px" }} onChange={(e) => {
                    Setfiltervalue({
                      ...filtervalue,
                      category: e.target.value
                    })
                  }}>
                  <option>--Select--</option>
                  {filterdatas && filterdatas.length > 0 && filterdatas.cat_info.map((item) => {
                    return <option value={item.cat_id}>{item.cat_name}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light-primary text-primary font-weight-medium 
            waves-effect text-start" onClick={filterapply}>
            Submit
          </button>
          <button
            type="button"
            className="btn btn-light-danger text-danger font-weight-medium
            waves-effect text-start" onClick={handleCancel}>
            Close
          </button>
        </div>
      </Modal>

      {/* Remarks Modal popup */}
      <Modal title="Remarks" visible={remarksModal} onOk={handleOk} onCancel={handleCancel}>
        <DataTable columns={remarkscolumn}
          data={recallremarks}
          // defaultSortFieldId={1}
          pagination />
      </Modal>

      {/* Password Modal popup */}
      <Modal visible={passwordmodal} footer={null} onCancel={passwordcancel}>
        <div className="modal-body" >
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="control-label">Password:</label>
                <span style={{ "color": "red" }}>*</span>
                <input
                  type="password"
                  id="password"
                  name='passwordvalue'
                  className="form-control"
                  placeholder="Enter password"
                  onChange={(e) => {
                    Setpassword({
                      ...password,
                      passwordvalue: e.target.value
                    })
                  }}
                  value={password.passwordvalue}
                />
                {formValidator.current.message(
                  'Password',
                  password.passwordvalue,
                  ['required'],
                  {
                    className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                    messages: {
                      regex: 'Enter Password'
                    }
                  })}
              </div>
            </div>
          </div>
          <div className="form-actions">
            <div className="card-body border-top">
              <button
                className="btn btn-light-success rounded-pill px-4 text-success"
                onClick={submitpassword}>
                <div className="d-flex align-items-center">
                  <i
                    data-feather="save"
                    className="feather-sm me-1 fill-icon"
                  ></i>
                  Ok
                </div>
              </button>
              <button
                className="btn btn-light-danger text-danger font-weight-medium
               waves-effect text-start" onClick={passwordcancel}>
                <div className="d-flex align-items-center">
                  <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                  Cancel
                </div>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div >

  )
}

export default connect(mapStateToProps, {
  statutorylist,
  editstatutory,
  filterdata,
  locksettings,
  GetRemarksData,
  SaveStatutorySettings,
  UpdateStatutorySettings
})(Statutorysetting);