import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { VIEW_ONLY_RESTRICTED_LIST, VIEW_ONLY_RESTRICTED_UNIT_LIST,TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';


export const getViewOnlyRestrictedList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: VIEW_ONLY_RESTRICTED_LIST,
                payload: decryptData,
            });
        }

    } catch (err) {
        Toaster.error(err)
    }
};

export const getViewOnlyRestUnitData = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            console.log(decryptData,'ddcc')
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: VIEW_ONLY_RESTRICTED_UNIT_LIST,
                payload: decryptData,
            });
        }

    } catch (err) {
        Toaster.error(err)
    }
};

export const saverecords = ({ payload, executeCancel, paramid, payloadbck }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
            console.log(decryptData,'ddcc')
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.success('Submitted Successfully') 
            dispatch(
                getViewOnlyRestUnitData({
                    payload: payloadbck,
                    paramid: paramid  
                })
            )
        }

    } catch (err) {
        Toaster.error(err)
    }
};

