import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import {
    DOMAINDATA, DATALIST, TOGGLE_COMMON_LOADER, DOWNLOADLIST, DOWNLOADPATH,
    UPLOADCONTENT, UPLOADERROR, QUEUEDPATH, UPLOADSUCCESS, UNITLIST
} from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';

export const getDomainaDetails = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/bu/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: DATALIST,
                payload: decryptData,
            });
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const getClientTransaction = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, "decryptData123");
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: DOMAINDATA,
                payload: decryptData,
            });
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getUnit = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, "payload44");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            console.log(decryptData, "decryptData555");
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: UNITLIST,
                payload: decryptData,
            });
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getlist = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: DOWNLOADLIST,
                payload: decryptData,
            });
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getdownloadpath = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload, "payload999");
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/bu/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: DOWNLOADPATH,
                payload: decryptData,
            });
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const downloadfiles = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/bu/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else if (decryptData[0] === 'Alive') {
            dispatch(downloadfiles({
                payload: payload,
                paramid: paramid
            }))
        } else if (decryptData[0] == 'InvalidCsvFile' || decryptData[0] == 'DataAlreadyExists' ||
            decryptData[0] == 'Bad Request' || decryptData[0] == 'ExportToCSVEmpty') {
            Toaster.error(decryptData[0]);

        } else {
            if (decryptData[1].link) {
                let token = localStorage.getItem('authToken');
                let sessiontoken = base64_encode(token);
                let path = makekey() + sessiontoken + decryptData[1].link;
                window.open('https://13.234.100.64/' + path);
            }

        }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const uploadcontent = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/bu/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else if (decryptData[0] == 'InvalidCsvFile') {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error(decryptData[0]);
        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: UPLOADCONTENT,
                payload: decryptData,
            });
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const upload_docu_file = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/bu/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        // const decryptData = "success"
        console.log(decryptData, "decryptData555");
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else if (decryptData[0] === 'Alive') {
            dispatch(upload_docu_file({
                payload: payload,
                paramid: paramid
            }))
        } else if (decryptData[0] == 'UploadCompletedTaskCurrentYearCSVFailed') {
            dispatch({
                type: UPLOADERROR,
                payload: decryptData,
            });
            Toaster.error('File Upload Failed')
        } else {
            dispatch({
                type: UPLOADSUCCESS,
                payload: decryptData,
            });
            Toaster.success(decryptData[0]);

        }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getCompletedTaskCsvUploadedList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/bu/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: DOWNLOADLIST,
                payload: decryptData,
            });
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const getqueuepath = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/bu/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            dispatch({
                type: QUEUEDPATH,
                payload: decryptData,
            });
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const queuedocument = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/bu/completed_task`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else if (decryptData[0] === 'Alive') {
            dispatch(queuedocument({
                payload: payload,
                paramid: paramid
            }))
        } else {
            Toaster.success(decryptData[0]);
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);

        }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
function makekey() {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}


