import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { COMPLETED_TASK_LIST, STATUTORY_BY_UNIT, TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from '../../../Libs/Altermessage'



export const getCompletedTask = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });

            dispatch({
                type: COMPLETED_TASK_LIST,
                payload: decryptData,
            });
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const getStatutoriesByUnit = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        } else {
            // dispatch({
            //     type: TOGGLE_COMMON_LOADER
            // });
            dispatch({
                type: STATUTORY_BY_UNIT,
                payload: decryptData,
            });
        }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const SaveCompletedTask = ({ payload, executeCancel, paramid }) => async dispatch => {
    console.log(payload,'payload');
    try {

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        console.log(decryptData, 'decryptData');
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }
        else if (decryptData[0] == "SavePastRecordsSuccess") {
            Toaster.success("Past Record Saved Successfully")
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        }
        else {
            Toaster.warning(decryptData[1].error)
            setTimeout(() => {
                window.location.reload(false);
            }, 2000);
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};
export const UploadFile = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/files`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));

        if (decryptData[0] == 'FileUploadSuccess') {
            Toaster.success("File Upload Successfully"
            )
        }
        else {
            Toaster.error("Failed")
        }

        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }
        // dispatch({
        //     type: COMPLETED_TASK_LIST,
        //     payload: decryptData,
        // });

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

