import HTTP from '../../Libs/http';
import { LOGINSUCCESS } from '../types/index'
import {TOGGLE_COMMON_LOADER } from "../types/index";

const initialState = {
    token: null,
    isAuthenticated: false,
    commonLoading:false
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case LOGINSUCCESS: {
            const  token  = payload.session_token;
            const arrayvalue = {
                'entity_info' : payload.entity_info,
                'country_info': payload.country_info,
                'login_response': payload
            }
            localStorage.setItem('sessionValue', JSON.stringify(arrayvalue))
            const entity_info = payload.entity_info;
            const country_info = payload.country_info;
            HTTP.setAuthToken(token)
            return {
                ...state,
                token: token,
                isAuthenticated: true,
                entity_info: entity_info,
                country_info: country_info
            };
        }
        case TOGGLE_COMMON_LOADER: {
            return {
                ...state,
                commonLoading: !state.commonLoading
            }
        }
        default:
            return state;
    }
}