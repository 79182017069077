import { connect } from 'react-redux';
import { Fragment } from 'react';


const mapStateToProps = (state) => ({
})
const Dashboard = ({

}) => {
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="page-titles pb-0 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <h3 className="mb-0 fw-bold"><span className="link me-2"><i className="ri-home-3-line fs-5"></i></span>Home</h3>
                        </div>
                        <div
                            className="
              col-lg-4 col-md-6
              d-none d-md-flex
              align-items-center
              justify-content-end
            "
                        >

                        </div>

                    </div>
                </div>
                <div className="container-fluid pt-2">
                    <div className="row">
                        <div className="accordion mb-3" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button bg-white text-primary border-0"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="false"
                                        aria-controls="collapseOne"
                                    >
                                        Compliance Status Counts
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">

                                        <div className="row">
                                            <div className="col-md-3">
                                                <a href="../../html/main/compliance-approval.html">
                                                    <div className="card w-100 overflow-hidden card-hover">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-center">
                                                                <div>
                                                                    <h3 className="pt-1 mb-0 h1">
                                                                        1260
                                                                    </h3>
                                                                    <h6 className="text-muted mb-0 fw-normal">Not Complied</h6>
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <span
                                                                        className="
                                      btn btn-xl btn-light-danger
                                      text-danger
                                      btn-circle
                                      d-flex
                                      align-items-center
                                      justify-content-center
                                    "
                                                                    >
                                                                        <i data-feather="alert-circle"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="monthly-sales"></div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-md-3">
                                                <a href="../main/compliance-approval.html"><div className="card w-100 overflow-hidden card-hover">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <h3 className="pt-1 mb-0 h1">
                                                                    21
                                                                </h3>
                                                                <h6 className="text-muted mb-0 fw-normal">Inprogress</h6>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <span
                                                                    className="
                                  btn btn-xl btn-light-info
                                  text-info
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                                >
                                                                    <i data-feather="check-square"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="monthly-sales1"></div>
                                                </div></a>
                                            </div>
                                            <div className="col-md-3">
                                                <a href="../main/compliance-approval.html"><div className="card w-100 overflow-hidden card-hover">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <h3 className="pt-1 mb-0 h1">
                                                                    56
                                                                </h3>
                                                                <h6 className="text-muted mb-0 fw-normal">Action Pending</h6>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <span
                                                                    className="
                                  btn btn-xl btn-light-warning
                                  text-warning
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                                >
                                                                    <i data-feather="folder-minus"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="monthly-sales4"></div>
                                                </div></a>
                                            </div>
                                            <div className="col-md-3">
                                                <a href="../main/compliance-approval.html"><div className="card w-100 overflow-hidden card-hover">
                                                    <div className="card-body">
                                                        <div className="d-flex align-items-center">
                                                            <div>
                                                                <h3 className="pt-1 mb-0 h1">
                                                                    125
                                                                </h3>
                                                                <h6 className="text-muted mb-0 fw-normal fs-2">Approver Pending</h6>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <span
                                                                    className="
                                  btn btn-xl btn-light-secondary
                                  text-secondary
                                  btn-circle
                                  d-flex
                                  align-items-center
                                  justify-content-center
                                "
                                                                >
                                                                    <i data-feather="user-minus"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="monthly-sales3"></div>
                                                </div></a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
})(Dashboard);