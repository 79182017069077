import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { ASSIGNCOMPLIANCE, UNITDATA, TABLEDATA, ASSIGNEE, TOGGLE_COMMON_LOADER,SHOWMOREDATA, TABLEDATALIST,SAVEASSIGNCOMPLIANCE, SYNCASSIGNCOMPLIANCE } from './../../types/index';
export const assign = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        dispatch({
            type: ASSIGNCOMPLIANCE,
            payload: decryptData[1],
        });

        // }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const selectunit = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))

        dispatch({
            type: UNITDATA,
            payload: decryptData[1],
        });

        // }

    } catch (err) {
        Toaster.error(err)
    }
};

export const table = ({ payload, executeCancel, paramid }) => async dispatch => {

    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        console.log(decryptData,"decryptDatayyyy");
        // if (decryptData[1] != "") {
        dispatch({
            type: TABLEDATA,
            payload: decryptData[1],
        });

        // }
        // else {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        // }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const selectassigneedata = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_master_filters`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        dispatch({
            type: ASSIGNEE,
            payload: decryptData[1],
        });


        // }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const saveassigncomliance = ({ payload, executeCancel, paramid }) => async dispatch => {

    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        })
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))

        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
        dispatch({
            type: SAVEASSIGNCOMPLIANCE,
            payload: decryptData[1],
        });

        // }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        })
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });


        // } catch (err) {
        // Toaster.error(err)
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
    }
};

export const syncassigncomliance = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // dispatch({
        //     type: TOGGLE_COMMON_LOADER
        // });
        // if(decryptData[0] === 'GetReviewSettingsFiltersSuccess'){
        dispatch({
            type: SYNCASSIGNCOMPLIANCE,
            payload: decryptData[1],
        });

        // }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const tabledata = ({ payload, executeCancel, paramid }) => async dispatch => {

    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // if (decryptData[1] != "") {
        dispatch({
            type: TABLEDATALIST,
            payload: decryptData[1],
        });

        // }
        // else {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        // }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const showmoredata = ({ payload, executeCancel, paramid }) => async dispatch => {

    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
        // if (decryptData[1] != "") {
        dispatch({
            type: SHOWMOREDATA,
            payload: decryptData[1],
        });

        // }
        // else {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        // }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};