import { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Card, Select, Input, Button, message, Upload, Dragger } from 'antd';
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import {
    getClientTransaction, getUnit, getlist, getDomainaDetails,
    getdownloadpath, downloadfiles, uploadcontent, upload_docu_file,
    getCompletedTaskCsvUploadedList, getqueuepath, queuedocument
} from './../../Store/Action/Transcations/Completedtaskcurrentbulkupload'
import _ from 'lodash';
import { Toaster } from '../../Libs/Toaster';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import DataTable from "react-data-table-component";
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';

const mapStateToProps = (state) => ({
    unit: state.bulkupload.bulkuploads.unitlist,
    lists: state.bulkupload.bulkuploads.downlist,
    downloadpath: state.bulkupload.bulkuploads.downpath,
    uploadpath: state.bulkupload.bulkuploads.uploadcontent,
    uploaderror: state.bulkupload.bulkuploads.errorlog,
    uploadfiledata: state.bulkupload.bulkuploads.uploadfile,
    datalist: state.bulkupload.bulkuploads.datalist,
    bulist: state.bulkupload.bulkuploads.datalist.csv_list,
    queuepath: state.bulkupload.bulkuploads.queuepath
});

const Completedtaskcurrentyearbulkuploadlist = ({
    getClientTransaction,
    getUnit,
    getDomainaDetails,
    getlist,
    unit,
    lists,
    getdownloadpath,
    downloadpath,
    downloadfiles,
    uploadcontent,
    uploadpath,
    upload_docu_file,
    uploaderror,
    uploadfiledata,
    datalist,
    getCompletedTaskCsvUploadedList,
    bulist,
    getqueuepath,
    queuepath,
    queuedocument
}) => {
    console.log(uploadpath, "uploadpath");
    console.log(uploadfiledata, "uploadfiledata");
    const { Dragger } = Upload;
    const { Option } = Select;
    // const [show, setShow] = useState(false)
    const location = useLocation();
    const paramid = location.pathname;
    const authtoken = localStorage.getItem('authToken');
    // const entityid = localStorage.getItem('SelectedEntityid');
    const entityname = localStorage.getItem('SelectedEntity');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const isAuth = localStorage.getItem('isAuthenticated');
    const [showlist, setshowlist] = useState('block');
    const [showdown, setshowdown] = useState('none');
    const [errorshow, seterrorshow] = useState('none');
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [boolean, setBoolean] = useState(false)
    const [downloadFile, setDownloadFile] = useState({
        legal_entity: '',
        domain: '',
        domainid: '',
        unit: '',
        unitid: '',
        unitcode: ''
    })
    const [uploadfile, Setuploadfile] = useState({
        filename: '',
        legal_entity: '',
    })
    const [domain, setDomian] = useState('');
    const [errorlog, Seterrorlog] = useState();
    const [uploadfile1, setUploadFile1] = useState([])
    const [uploadfile2, Setuploadfile2] = useState([])
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );
    const [fileList, setFileList] = useState([]);
    console.log(fileList, "fileList777");
    const handleChange = (info) => {
        console.log(info, "info");
        let newFileList = [...info.fileList]; // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new

        newFileList = newFileList.slice(-2); // 2. Read from response and show file link

        newFileList = newFileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }

            return file;
        });
        setFileList(newFileList);
    };

    const props = {
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange: handleChange,
        multiple: true,
    };
    const columns = [
        {
            name: "Legal Entity Name",
            selector: (row) => row.legal_entity_name,
            sortable: true,
            width: '250px',
            reorder: true,

        },
        {
            name: "Upload FileName",
            selector: (row) => row.csv_name,
            sortable: true,
            reorder: true,
            width: '250px',
        },
        {
            name: "Upload On",
            selector: (row) => row.uploaded_on,
            sortable: true,
            reorder: true,
            width: '200px',
        },
        {
            name: 'No.of.Records',
            selector: (row) => row.total_records,
            sortable: true,
            reorder: true,
            width: '150px',
        },
        {
            name: 'Required Documents',
            selector: (row) => row.total_documents,
            sortable: true,
            reorder: true,
            width: '200px'
        },
        {
            name: 'Uploaded Documents',
            selector: (row) => row.bu_uploaded_documents,
            sortable: true,
            reorder: true,
            width: '200px'
        },
        {
            name: 'RemainingDocuments',
            selector: (row) => row.remaining_documents,
            sortable: true,
            reorder: true,
            width: '200px'
        },
        {
            name: 'UploadDocuments',
            selector: (row) => row.doc_names,
            sortable: true,
            reorder: true,
            width: '200px'
        },
        {
            name: 'QueuedDocuments',
            width: '200px',
            selector: (row) => {
                return (
                    <div>
                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                            onClick={(e) => {
                                queueddoc(row)
                            }}>
                            <i class="ri-refresh-line"></i>

                        </span>
                    </div>
                )
            }
        }

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '80px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };


    const FilterComponent = ({ filterText, onFilter, onClear }) => {
        return (
            <>

                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            type="text"
                            placeholder="Search Here..."
                            value={filterText}
                            onChange={onFilter}
                            autoFocus
                        />
                    </div>
                </div>

            </>
        )
    }
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <>
                <FilterComponent
                    onFilter={(e) => {
                        setFilterText(e.target.value)
                    }}
                    onClear={handleClear}
                    filterText={filterText}
                />

            </>

        );
    }, [filterText, resetPaginationToggle]);

    const filteredItems = bulist && bulist.length > 0 && bulist.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );


    const queueddoc = (row) => {
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "ProcessQueuedTasks",
                    {
                        "file_submit_status": row.file_submit_status,
                        "data_submit_status": row.data_submit_status,
                        "new_csv_id": row.csv_past_id,
                        "country_id": 1,
                        "legal_entity_id": entityid,
                        "domain_id": row.domain_id,
                        "unit_id": row.unit_id,
                        "skip_duplicate": false
                    }
                ]
            }
        ]
        getqueuepath({
            payload: pay,
            paramid: paramid
        })
    }

    useEffect(() => {
        console.log(queuepath, 'queue');
        if (queuepath) {
            console.log(queuepath, 'queue');
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatus",
                        {
                            "legal_entity_id": entityid,
                            "csv_name": queuepath.csv_name
                        }
                    ]
                }
            ]
            queuedocument({
                payload: pay,
                paramid: paramid
            })
        }
    }, [queuepath])

    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    useEffect(() => {
        if (isAuth) {
            let pay;
            let temparr = [];
            if (entityid == 'null') {
                let legal_ent = JSON.parse(localStorage.getItem('sessionValue'));
                for (let i = 0; i < legal_ent.entity_info.length > 0; i++) {
                    let data = legal_ent.entity_info[i].le_id
                    temparr.push(data)
                }
                //console.log(temparr,'temp');
                pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetCompletedTaskCsvUploadedList",
                            {
                                "legal_entity_id": '',
                                "legal_entity_list": temparr
                            }
                        ]
                    }
                ]
            } else {
                pay = [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetCompletedTaskCsvUploadedList",
                            {
                                "legal_entity_id": entityid,
                                "legal_entity_list": [
                                    entityid
                                ]
                            }
                        ]
                    }
                ]
            }
            getDomainaDetails({
                payload: pay,
                paramid: paramid
            })
        }

    }, [isAuth, entityid])

    useEffect(() => {
        if (boolean == true) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetPastRecordsFormData",
                        {
                            "le_id": entityid
                        }
                    ]
                }
            ]
            getClientTransaction({
                payload: payload,
                paramid: paramid
            })
        }
    }, [boolean])
    useEffect(() => {
        if (datalist && entityid != 'null') {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetUnits",
                        {
                            "legal_entity_id": entityid,
                            "domain_id": downloadFile && downloadFile.domain && downloadFile.domain.d_id
                        }
                    ]
                }
            ]
            getUnit({
                payload: payload,
                paramid: paramid
            })
        }
    }, [datalist, entityid, downloadFile])
    useEffect(() => {
        formValidator.current.showMessages()
        formValidator1.current.showMessages()
    }, [])


    useEffect(() => {
        const url = localStorage.setItem('temppathname', paramid)
    }, [])

    useEffect(() => {
        if (uploadpath) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatus",
                        {
                            "legal_entity_id": entityid,
                            "csv_name": uploadpath.csv_name
                        }
                    ]
                }
            ]
            upload_docu_file({
                payload: payload,
                paramid: paramid
            })
        }
    }, [uploadpath])

    useEffect(() => {
        if (uploaderror) {
            Seterrorlog(uploaderror);
            seterrorshow('block');
        }
    }, [uploaderror])

    useEffect(() => {
        if (uploadfiledata) {
            setUploadFile1(uploadfiledata);
            // seterrorshow('block');
        }
    }, [uploadfiledata])



    const download = () => {
        setAddFormSubmit(true)
        if (formValidator.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetDownloadData",
                        {
                            "legal_entity_id": entityid,
                            "unit_id": downloadFile.unit.unit_id,
                            "domain_id": downloadFile.domain.d_id,
                            "compliance_task_frequency": "Periodical",
                            "start_count": 0,
                            "le_name": entityname,
                            "d_name": downloadFile.domain.d_name,
                            "u_name": downloadFile.unit.unit_name,
                            "u_code": downloadFile.unit.unit_code
                        }
                    ]
                }
            ]
            getdownloadpath({
                payload: pay,
                paramid: paramid
            })
        }

    }
    useEffect(() => {
        if (downloadpath) {
            const path = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatus",
                        {
                            "legal_entity_id": entityid,
                            "csv_name": downloadpath.csv_name
                        }
                    ]
                }
            ]
            downloadfiles({
                payload: path,
                paramid: paramid
            })
        }
    }, [downloadpath])

    const uploaddocu = () => {
        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {
            const pay = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UploadCompletedTaskCurrentYearCSV",
                        {
                            "csv_name": uploadfile.filename[0].file_name,
                            "csv_data": uploadfile.filename[0].file_content,
                            "csv_size": uploadfile.filename[0].file_size,
                            "legal_entity_id": entityid
                        }
                    ]
                }
            ]
            uploadcontent({
                payload: pay,
                paramid: paramid
            })
        }

    }
    const uploaddocument = () => {
        const path = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "SaveBulkRecords",
                    {
                        "new_csv_id": 12,
                        "country_id": downloadFile && downloadFile.unit && downloadFile.unit.country_id,
                        "legal_entity_id": entityid,
                        "domain_id": downloadFile && downloadFile.domain && downloadFile.domain.d_id,
                        "unit_id": downloadFile && downloadFile.unit && downloadFile.unit.unit_id,
                        "skip_duplicate": false
                    }
                ]
            }
        ]
        downloadfiles({
            payload: path,
            paramid: paramid
        })

        console.log("hiii");
    }

    // const selectdocument = (e) => {
    //     var results = [];
    //     const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
    //     let files = e.target.files;
    //     let file_max_size = 26214400
    //     for (var i = 0; i < files.length; i++) {
    //         var file = files[i];
    //         var file_name = file.name;
    //         const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
    //         if (fileRegex.test(f_name) === false) {
    //             Toaster.error('Invalid Format');
    //             return;
    //         }
    //         let file_size = file.size;
    //         if (file_size > file_max_size) {
    //             Toaster.error('Maximum File Sixe is 25MB');
    //             return;

    //         }

    //         if (file) {
    //             convert_to_base641(file, function (file_content) {
    //                 var result = uploadFileFormat1(file_size, file_name, file_content);

    //                 results.push(result);
    //                 Setuploadfile2({
    //                     ...uploadfile2,
    //                     filename: results
    //                 })

    //             });

    //         }
    //     }
    // }
    console.log(uploadfile && uploadfile.filename[0] && uploadfile.filename[0].file_name && uploadfile.filename[0].file_name, "uploadfile");
    console.log(uploadfile2, "uploadfile2");
    const convert_to_base641 = (file, callback) => {
        var reader = new FileReader();
        reader.onload = function (readerEvt) {
            var binaryString = readerEvt.target.result;
            let file_content = base64_encode(binaryString);
            callback(file_content);
        };
        reader.readAsBinaryString(file);
    }

    const uploadFileFormat1 = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }



    const uploaddocu1 = (e) => {
        var results = [];
        const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
        let files = e.target.files;
        let file_max_size = 26214400
        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var file_name = file.name;
            const f_name = file_name.substring(0, file_name.lastIndexOf('.'));
            if (fileRegex.test(f_name) === false) {
                Toaster.error('Invalid Format');
                return;
            }
            let file_size = file.size;
            if (file_size > file_max_size) {
                Toaster.error('Maximum File Sixe is 25MB');
                return;

            }

            if (file) {
                convert_to_base64(file, function (file_content) {
                    var result = uploadFileFormat(file_size, file_name, file_content);

                    results.push(result);
                    Setuploadfile({
                        ...uploadfile,
                        filename: results
                    })

                });

            }
        }
    }

    const convert_to_base64 = (file, callback) => {
        var reader = new FileReader();
        reader.onload = function (readerEvt) {
            var binaryString = readerEvt.target.result;
            let file_content = base64_encode(binaryString);
            callback(file_content);
        };
        reader.readAsBinaryString(file);
    }

    const uploadFileFormat = (size, name, content) => {
        var result = {
            'file_size': parseInt(size),
            'file_name': name,
            'file_content': content
        };
        return result;
    }
    console.log(downloadFile, "downloadFile");
    return (
        <Fragment>
            <div style={{ display: showlist }}>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <a href="index.html" className="link"
                                            ><i className="ri-home-3-line fs-5"></i
                                            ></a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Completed Task - Current Year - Bulk Upload
                                        </li>
                                    </ol>
                                    <h2 className="mb-0 fw-bold">Completed Task - Current Year - Bulk Upload</h2>
                                </nav>
                            </div>
                            <div
                                className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                <button className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger" onClick={(e) => {
                                    setBoolean(true)
                                    setshowdown('block')
                                    setshowlist('none')
                                }}>Add</button>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <DataTable
                                            columns={columns}
                                            // data={lists}
                                            data={filteredItems}
                                            fixedHeader={dataTableProperties.fixedHeader}
                                            pagination={false}
                                            subHeader
                                            subHeaderComponent={subHeaderComponent}
                                            responsive
                                            customStyles={customStyles}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div>
            <div style={{ display: showdown }}>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-1">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <a href="index.html" className="link"
                                            ><i className="ri-home-3-line fs-5"></i
                                            ></a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Completed Task - Current Year - Bulk Upload
                                        </li>
                                    </ol>
                                    <h2 className="mb-0 fw-bold">Completed Task - Current Year - Bulk Upload</h2>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row mx-3">
                            <Card className='col-md-6' style={{ marginTop: "10px", boxShadow: "0px 0px 0px 1px grey" }}>
                                <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Download file</h4>
                                {/* <ToastContainer /> */}
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-3' style={{ padding: 0 }}>
                                            <label style={{ marginLeft: "15px" }}><b>Legal Entity </b><span style={{ color: "red" }}>*</span></label>
                                        </div>
                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                            <div className="col-md-9" style={{ padding: 0 }}>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Legal Entity"
                                                    onChange={(data, value) => {
                                                        setCurrentEntity(data)
                                                        setDownloadFile({
                                                            ...downloadFile,
                                                            legal_entity: data
                                                        })
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    //  value={modalData.legal_entity}
                                                    style={{ width: '300px' }}
                                                >
                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                        return (
                                                            <Option key={item.le_id}>
                                                                {item.le_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator.current.message(
                                                    'legal_entity',
                                                    downloadFile.legal_entity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div> :
                                            <div className="col-md-8" style={{ padding: 0 }}>
                                                <p style={{ marginLeft: "10px", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                            </div>
                                        }
                                    </div>
                                </div><br />
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <label style={{ float: "left" }}><b>Domain </b><span style={{ color: "red" }}>*</span></label>
                                        </div>
                                        <div className='col-md-9' style={{ padding: 0 }}>
                                            <select
                                                name="option"
                                                className="form-control"
                                                onChange={(e) => {
                                                    let getData = _.find(unit.domains, { d_id: Number(e.target.value) });
                                                    setDownloadFile({
                                                        ...downloadFile,
                                                        domain: getData
                                                    })
                                                }}
                                                value={downloadFile.domain && downloadFile.domain.d_id}>
                                                <option value="">Select Domain</option>
                                                {
                                                    unit && Array.isArray(unit.domains) && unit.domains.length > 0 && unit.domains.map((item) => {
                                                        return <option value={item.d_id}>{item.d_name}</option>
                                                    })
                                                }
                                            </select>
                                            {formValidator.current.message(
                                                'domain',
                                                downloadFile.domain,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Required Field Missing',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div><br />
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-3'>
                                            <label style={{ float: "left" }}><b>Unit </b><span style={{ color: "red" }}>*</span></label>
                                        </div>
                                        <div className='col-md-9' style={{ padding: 0 }}>
                                            <select
                                                name="option"
                                                className="form-control"
                                                onChange={(e) => {
                                                    let getData = _.find(unit.in_units, { unit_code: e.target.value });
                                                    setDownloadFile({
                                                        ...downloadFile,
                                                        unit: getData
                                                    })
                                                }}
                                                value={downloadFile.unit && downloadFile.unit.unit_code}>
                                                <option value="">Select Unit</option>
                                                {
                                                    unit && Array.isArray(unit.in_units) && unit.in_units.length > 0 && unit.in_units.map((item) => {
                                                        return <option value={item.unit_code}>{item.unit_name}</option>
                                                    })
                                                }
                                            </select>
                                            {formValidator.current.message(
                                                'unit',
                                                downloadFile.unit,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Required Field Missing',
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className='row' style={{ marginTop: "20px" }}>
                                        <div className='col-md-4'></div>
                                        <div className='col-md-4' >
                                            <button style={{ textAlign: "center" }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4" onClick={download}>Download</button>
                                        </div>
                                        <div className='col-md-4'></div>
                                    </div>
                                </div>
                            </Card>
                            <Card className='col-md-6' style={{ marginTop: "10px", boxShadow: "0px 0px 0px 1px grey" }}>
                                <h4 style={{ textAlign: "center", marginBottom: "20px" }}>Upload file</h4>
                                {/* <ToastContainer /> */}
                                {/* title="Upload file" */}
                                <div className='col-md-12'>
                                    <div className='row'>
                                        <div className='col-md-3' style={{ padding: 0 }}>
                                            <label style={{ marginLeft: "15px" }}><b>Legal Entity </b><span style={{ color: "red" }}>*</span></label>
                                        </div>
                                        {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                            <div className="col-md-9" style={{ padding: 0, float: "left" }}>
                                                <Select
                                                    allowClear={true}
                                                    size="default"
                                                    placeholder="Select Legal Entity"
                                                    onChange={(data, value) => {
                                                        setCurrentEntity(data)
                                                        Setuploadfile({
                                                            ...uploadfile,
                                                            legal_entity: data
                                                        })
                                                    }}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    //  value={modalData.legal_entity}
                                                    style={{ width: '300px' }}
                                                >
                                                    {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                        return (
                                                            <Option key={item.le_id}>
                                                                {item.le_name}
                                                            </Option>
                                                        );
                                                    })}
                                                </Select>
                                                {formValidator1.current.message(
                                                    'legal_entity',
                                                    uploadfile.legal_entity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div> :
                                            <div className="col-md-9" style={{ padding: 0, float: "left" }}>
                                                <p style={{ marginLeft: "10px", marginTop: "5px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                            </div>
                                        }
                                    </div>
                                </div><br></br>
                                {uploadfiledata ?
                                    <>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4' >
                                                    <label style={{ float: "left" }}><b>Uploaded File </b><span style={{ color: "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-8' style={{ padding: 0 }}>
                                                    <label>{uploadfile && uploadfile.filename[0] && uploadfile.filename[0].file_name && uploadfile.filename[0].file_name}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className='col-md-12' style={{ marginTop: "10px" }}>
                                            <div className='row'>
                                                <div className='col-md-4' style={{ padding: 0 }}>
                                                    <label style={{ marginLeft: "15px" }}><b>Select Documents</b><span style={{ color: "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-8' >
                                                    <Upload {...props} fileList={fileList}>
                                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                                    </Upload>
                                                    {/* <input accept=".pdf" type="file" className='form-control' onChange={(e) => {
                                                        selectdocument(e)
                                                    }} /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4'>
                                                    <button style={{ textAlign: "center" }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4 mt-3"
                                                        onClick={uploaddocument} >Upload</button>
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-3' >
                                                    <label style={{ float: "left" }}><b>Select File </b><span style={{ color: "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-9' style={{ padding: 0 }}>
                                                    <input type="file" accept=".csv" className='form-control' onChange={(e) => {
                                                        uploaddocu1(e)
                                                    }} />
                                                    <span style={{ color: 'red', position: "absolute", left: '25%', top: "195px" }}>Accept Only CSV Format</span>
                                                </div>
                                            </div>
                                        </div><br></br>   <br></br>
                                        <div className='col-md-12'>
                                            <div className='row' style={{ marginTop: "15px" }}>
                                                <div className='col-md-4'></div>
                                                <div className='col-md-4' >
                                                    <button style={{ textAlign: "center" }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4 mt-3"
                                                        onClick={uploaddocu}>Upload</button>
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </Card>
                            <div style={{ display: errorshow }}>
                                {errorlog ?
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div class="alert alert-success bg-success  m-3" style={{ opacity: 0.8 }} role="alert">
                                                <p className='h5 text-white'>Uploaded Data - Summary</p>
                                                <span className='d-block'>TotalNo.of Records:{errorlog.total}</span>
                                                <span className='d-block'>ValidRecords:{errorlog.max_length_error}</span>
                                                <span className='d-block'>InValidRecords:{errorlog.invalid}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div class="alert alert-danger bg-danger m-3" style={{ opacity: 0.8 }} role="alert">
                                                <p className='h5 text-white'>Invalid data - Summary</p>
                                                <span className='d-block'>InvalidDate:{errorlog.invalid_date}</span>
                                                <span className='d-block'>Invalid File Format:{errorlog.invalid_file_format}</span>
                                                <span className='d-block'>Master Data Inactive:{errorlog.inactive_error}</span>
                                                <span className='d-block'>Mandatory Field Blank{errorlog.mandatory_error}</span>
                                                <span className='d-block'>Invalid Data:{errorlog.invalid}</span>
                                                <span className='d-block'>Duplicate Document Name:{errorlog.duplicate_doc_name}</span>
                                                <span className='d-block'>Invalid Character:{errorlog.invalid_char_error}</span>
                                            </div>
                                        </div>
                                    </div> :
                                    // <div className='row' style={{ marginTop: "20px" }}>
                                    //     <div className='col-md-6'></div>
                                    //     <div className='col-md-6' style={{ padding: 0 }}>
                                    //         <div class="alert alert-light bg-light m-6" style={{ opacity: 0.8, boxShadow: "0px 0px 0px 1px grey" }} role="alert" >
                                    //             <label>Select Documents<span style={{ color: "red" }}>*</span></label>
                                    //             <input style={{ marginTop: "20px", marginBottom: "10px" }} accept=".pdf" type="file" className='form-control' onChange={(e) => {
                                    //                 selectdocument(e)
                                    //             }} />
                                    //             <button style={{ textAlign: "center", marginLeft: "150px" }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4 mt-3"
                                    //                 onClick={uploaddocument} >Upload</button>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    // :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >
    )
}
export default connect(mapStateToProps, {
    getClientTransaction,
    getUnit,
    getlist,
    getDomainaDetails,
    getdownloadpath,
    downloadfiles,
    uploadcontent,
    upload_docu_file,
    getCompletedTaskCsvUploadedList,
    getqueuepath,
    queuedocument
})(Completedtaskcurrentyearbulkuploadlist);