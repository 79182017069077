import HTTP from '../../Libs/http'
import { Toaster } from '../../Libs/Toaster'
import url from './../../Libs/URL'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import { encode as base64_encode } from 'base-64';
import { LOGINSUCCESS,TOGGLE_COMMON_LOADER } from './../types/index';

export const login = ({ payload, navigate, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const loginpayload = [
            payload.groupName,
            [
                'Login', {
                    'login_type': 'Web',
                    'password': payload.password,
                    'short_name': payload.groupName,
                    'username': payload.userName,

                }
            ]
        ]
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const data1 = EncryptDecrypt.encryptdata(loginpayload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if (decryptData[0] === 'UserLoginSuccess') {
            dispatch({
                type: LOGINSUCCESS,
                payload: decryptData[1],
            });
            navigate.push('/')
        } else {
            Toaster.error(decryptData[0])
        }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const sessionLogout = ({payload,executeCancel}) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
       let token = localStorage.getItem('authToken');
        const loginpayload = [
            token,
            [
                "Logout",
                {
                    "session_token": token
                }
            ]
        ]
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded
            },
        }
        const data1 = EncryptDecrypt.encryptdata(loginpayload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
        console.log(decryptData);
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        if(decryptData[0] === 'LogoutSuccess'){
            localStorage.clear();
        window.location.reload(false);
        }else{
            Toaster.error("Invalid Error")
        }
        

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    } 
}