import React, { useEffect, useState, useRef, Fragment } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Collapse, Modal, Select, Tooltip, Card, DatePicker, Space, Descriptions, Button, Popover } from 'antd';
import { PlusSquareTwoTone, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import Swal from 'sweetalert2';
import DataTable from "react-data-table-component";
import { TaskRecallFilters, GetTaskRecallList, GetRemarksData, UpdateTaskRecallStatus } from './../../Store/Action/Transcations/Taskrecall';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

const mapStateToProps = (state) => ({
    filterrecall: state.recallfilters.recallfilters,
    filterdatas: state.Statutorysetting.statutorysettings.filterdata

})

const TaskRecall = ({
    TaskRecallFilters,
    GetTaskRecallList,
    GetRemarksData,
    UpdateTaskRecallStatus,
    filterrecall: { userunitsrecall, recallcompliance, recallremarks },
    filterdatas
}) => {
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true
    })

    const formValidator2 = useRef(new SimpleReactValidator());
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [showValueModal, setShowValueModal] = useState(false)
    const [acccordionTrue, setAccordionTrue] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [passwordmodal, Setpasswordmodal] = useState(false);
    const [FormShow, setFormShow] = useState(false);
    const [checkedRow, setCheckedRow] = useState(false);
    const [mapFilter, setMapFilter] = useState([]);
    const [FormRecall, setFormRecall] = useState(false);
    const [filteredArray, setFilteredArray] = useState([])
    const location = useLocation();
    const paramid = location.pathname;
    const authtoken = localStorage.getItem('authToken');
    //  const entityid = localStorage.getItem('SelectedEntityid');
    const isAuth = localStorage.getItem('isAuthenticated');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.entity_info
    const { Option } = Select;
    const _ = require("lodash");
    const formValidator = useRef(new SimpleReactValidator());
    const recallSubmit = useRef(new SimpleReactValidator());
    const [showdata, Setshowdata] = useState({
        domain: '',
        unit: '',
        frequency: '',
        due_month: '',
        act: '',
        legal_entity: ''
    })
    const [temparr, setTemparr] = useState([])
    const [recalldata, Setrecalldata] = useState({
        action: '',
        remarks: ''
    })

    const [password, Setpassword] = useState({
        passwordvalue: '',
    })

    const [entityid, setCurrentEntity] = useState(localStorage.getItem('SelectedEntityid'));
    console.log(entityid, 'entityid')
    useEffect(() => {
        if (localStorage.getItem('SelectedEntityid') != null) {
            setCurrentEntity(localStorage.getItem('SelectedEntityid'))
        }
    }, [localStorage.getItem('SelectedEntityid')])

    const [valueModal, setValueModal] = useState({
        unit: '',
        address: '',
        compliance_history_id: '',
        compliance_task: '',
        compliance_frequency: '',
        uploaded_document: '',
        document_issued_date: '',
        document_reference_number: '',
        validity_date: '',
        next_due_date: '',
        status: '',
        remarks: '',
        action: '',
        compliance_description: '',
        interim_details: ''
    })

    const column = [
        {
            name: "Sno",
            selector: (row) => row.r_id,
            sortable: true,
            width: '250px',
            reorder: true,
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        }, {
            name: "Description",
            selector: (row) => <a href={() => false} onClick={() => {
                Setrecalldata({ ...recalldata, remarks: row.r_description })
                setRemarksModal(false)
            }}>{row.r_description}</a>,
            sortable: true,
            width: '250px',
            reorder: true,
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        }, {
            name: "",
            selector: false
        }
    ]

    const columns = [
        {
            name: <Fragment>
                <input type="checkbox"
                    onClick={(e) => {
                        let checked = e.target.checked;
                        addAllSelectedDataValues(
                            checked
                        );
                    }}
                />
            </Fragment>,
            selector: (row, rowIndex) => {
                return (
                    <input type="checkbox"
                        name='checked'
                        onClick={(e) => {
                            let checked = e.target.checked;
                            addSelectedDataValues(
                                checked,
                                row.compliance_history_id,
                            );
                        }}
                    />

                )
            }
        },
        {
            name: "S.No",
            selector: (row, index) => {
                console.log(row.compliance_frequency)
                if (row.compliance_frequency == 'Periodical') {
                    return <Tooltip title={row && row.compliance_frequency}><p><i style={{ color: "#4FC742" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (row.compliance_frequency == 'FlexiReview') {
                    return <Tooltip title={row && row.compliance_frequency}><p><i style={{ color: "#F4BDD5" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else if (row.compliance_frequency == 'Review') {
                    return <Tooltip title={row && row.compliance_frequency}><p><i style={{ color: "#F78453" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
                else if (row.compliance_frequency == 'On Occurrence') {
                    return <Tooltip title={row && row.compliance_frequency}><p><i style={{ color: "#FAF329" }} class="bi bi-square-fill"></i></p></Tooltip>
                } else {
                    return <Tooltip title={row && row.compliance_frequency}><p><i style={{ color: "#F21625" }} class="bi bi-square-fill"></i></p></Tooltip>
                }
            },
            width: '100px',
            sortable: true,
            // right: true,
            reorder: true
        },
        {
            name: "Compliance Task",
            // selector: (row) => <><p>{row.statu}</p><br></br><p>{row.compliance_name}</p></>,
            selector: (row) => {
                const content = (
                    <table style={{ width: "500px" }} class="table table-striped" >
                        <tbody>
                            <tr>
                                <th>Unit Name</th>
                                <td>{row.unit_name}</td>
                            </tr>
                            <tr>
                                <th>Address</th>
                                <td>{row.address}</td>
                            </tr>
                            <tr>
                                <th>Division</th>
                                <td>{row.division_name}</td>
                            </tr>
                            <tr>
                                <th>Category</th>
                                <td>{row.category_name}</td>
                            </tr>
                            <tr>
                                <th>Act/Rules</th>
                                <td>{row.statu}</td>
                            </tr>
                            <tr>
                                <th>Compliance Description</th>
                                <td>{row.compliance_description}</td>
                            </tr>
                            <tr>
                                <th>Penal Consequqnces</th>
                                <td>{row.penal_consequences}</td>
                            </tr>
                        </tbody>
                    </table>
                );
                return (
                    <>
                        <p className="mt-3"
                            onClick={() => {
                                showValue()
                                setValueModal({
                                    compliance_history_id: row.compliance_history_id,
                                    unit: row.unit_name,
                                    compliance_task: row.compliance_name,
                                    compliance_frequency: row.compliance_frequency,
                                    document_issued_date: row.completion_date,
                                    document_reference_number: row.document_reference_number,
                                    next_due_date: row.next_due_date,
                                    address: row.address,
                                    compliance_description: row.compliance_description,
                                    uploaded_document: row.upload_docs,
                                    interim_details: row.interim_docs,
                                    remarks: row.remarks

                                })
                            }}>{row.statu}</p>
                        <div>
                            <div className="mb-3">
                                <Popover content={content} title="Lorem">
                                    <Button style={{ border: 0 }}><i class="bi-info-circle-fill"></i></Button>
                                </Popover>
                                {row.compliance_name}
                            </div>
                        </div>
                        {/* <ReactTooltip id="registerTip" place="top" backgroundColor="#DCDCDC"
                            textColor="black">
                            <table class="table">
                                <tbody>
                                <tr>
                                    <th>Unit Name</th>
                                    <td>{row.unit_name}</td>
                                </tr>
                                <tr>
                                    <th>Address</th>
                                    <td>{row.address}</td>
                                </tr>
                                <tr>
                                    <th>Division</th>
                                    <td>{row.division_name}</td>
                                </tr>
                                <tr>
                                    <th>Category</th>
                                    <td>{row.category_name}</td>
                                </tr>
                                <tr>
                                    <th>Act/Rules</th>
                                    <td>{row.statu}</td>
                                </tr>
                                <tr>
                                    <th>Compliance Description</th>
                                    <td>{row.compliance_description}</td>
                                </tr>
                                <tr>
                                    <th>Penal Consequqnces</th>
                                    <td>{row.penal_consequences}</td>
                                </tr>
                                </tbody>
                            </table>
                        </ReactTooltip> */}
                    </>
                )
            },
            sortable: true,
            width: '250px',
            reorder: true,
            // render: (compliance_task) => <a href="javascript:;" onClick={() => { showModal() }}>{compliance_task}</a>,
            // sorter: (a, b) => { return a.statu.localeCompare(b.statu) },
        },
        {
            name: "Assignee",
            selector: (row) => row.assignee_name,
            sortable: true,
            width: '150px',
            reorder: true,
            // sorter: (a, b) => { return a.assignee_name.localeCompare(b.assignee_name) },
            // filters: [
            //     {
            //         text: 'Lulu Software Services Private Limited',
            //         value: 'Lulu Software Services Private Limited',
            //     },

            // ],
            // onFilter: (value, record) => record.legal_entity.indexOf(value) === 0,

        },
        {
            name: "Compliance Status",
            selector: (row) => row.task_status,
            sortable: true,
            width: '150px',
            reorder: true,
            // sorter: (a, b) => { return a.task_status.localeCompare(b.task_status) },
            // filters: [
            //     {
            //         text: 'North',
            //         value: 'North',
            //     },
            //     {
            //         text: 'South',
            //         value: 'South',
            //     },
            // ],
            // onFilter: (value, record) => record.division.indexOf(value) === 0
        },
        {
            name: "Due Date",
            selector: (row) => row.due_date,
            sortable: true,
            width: '150px',
            reorder: true,
            // sorter: (a, b) => { return a.due_date.localeCompare(b.due_date) },
            // filters: [
            //     {
            //         text: 'Jammu and Kashmir',
            //         value: 'Jammu and Kashmir',
            //     },
            //     {
            //         text: 'Tamilnadu',
            //         value: 'Tamilnadu',
            //     },
            // ],
            // onFilter: (value, record) => record.category.indexOf(value) === 0,

        },
        {
            name: "Compliance Date",
            selector: (row) => row.completion_date,
            sortable: true,
            width: '150px',
            reorder: true,
            // sorter: (a, b) => { return a.completion_date.localeCompare(b.completion_date) },

            // filters: [
            //     {
            //         text: 'TN-2127',
            //         value: 'TN-2127',
            //     },
            //     {
            //         text: 'JK-2197',
            //         value: 'JK-2197'
            //     }

            // ],
            // onFilter: (value, record) => record.unit.indexOf(value) === 0,
        },
    ];
    const Active = () => {
        setFormRecall(true)
        if (recallSubmit.current.allValid()) {
            if (temparr.length !== 0) {
                Swal.fire({
                    title: 'Are you sure?',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }).then((result) => {
                    if (result.isConfirmed) {

                        Setpasswordmodal(true)
                    }
                })
            } else {
                toast.error("Please Select Compliance to recall")
            }
        }
    }

    const addAllSelectedDataValues = (checked) => {
        var ele = document.getElementsByName('checked');
        if (checked === true) {
            setCheckedRow(true)
            setTemparr([...recallcompliance])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            setTemparr([])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }

    const addSelectedDataValues = (checked, compliance_history_id) => {
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(recallcompliance, { compliance_history_id: compliance_history_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].compliance_history_id === compliance_history_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        } else {
            if (checked === true) {
                array = _.find(recallcompliance, { compliance_history_id: compliance_history_id });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].compliance_history_id === compliance_history_id) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    const submitpassword = (e) => {
        setAddFormSubmit2(true)

        e.preventDefault();
        if (formValidator2.current.allValid()) {

            let temp = []
            for (let i in temparr) {
                temp.push({
                    "compliance_id": temparr[i].compliance_id,
                    "compliance_history_id": temparr[i].compliance_history_id,
                    "unit_idl": temparr[i].unit_id,
                    "download_doc": temparr[i].download_url,
                    "reviewer_doc": temparr[i].reviwer_newdoc,
                    "interm_doc": temparr[i].interm_newdoc,
                    "interim_docs_size": Number(temparr[i].interim_docs_size),
                    "reviwer_docs_size": Number(temparr[i].reviwer_docs_size),
                    "document_size": temparr[i].document_size,
                    "recall_status": temparr[i].recall_status,
                    "recall_id": temparr[i].recall_id
                })
            }
            UpdateTaskRecallStatus({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "UpdateTaskRecallStatus",
                            {
                                "le_id": entityid,
                                "check_list": temp,
                                "action": recalldata.action,
                                "remarks": recalldata.remarks,
                                "password": password.passwordvalue,
                                "pwd_validate": true
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
        //Setpasswordmodal(false);
    }

    const showValue = () => {
        setShowValueModal(true);
    };

    const passwordcancel = () => {
        Setpasswordmodal(false);
        setAddFormSubmit2(false)
    }

    const { Panel } = Collapse;

    const handleOk = () => {
        setRemarksModal(false)
    };

    const handleCancel = () => {
        setRemarksModal(false)
        setShowValueModal(false);
    };

    const gettaskrecalllist = () => {
        setFormShow(true)
        if (formValidator.current.allValid()) {
            setAccordionTrue(true)
            GetTaskRecallList({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                "GetTaskRecallList",
                                {
                                    "le_id": entityid,
                                    "unit_id": (showdata.unit) ? Number(showdata.unit) : null,
                                    "d_id": Number(showdata.domain),
                                    "frequency_id": (showdata.frequency) ? Number(showdata.frequency) : 0,
                                    "compl_date": showdata.due_month,
                                    "parent_id": Number(showdata.act),
                                    "user_id": null,
                                    "start_count": 0,
                                    "task_status": "Task Recall"
                                }
                            ]
                        }
                    ],
                paramid: paramid
            })
        }
    }

    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };

    useEffect(() => {
        formValidator.current.showMessages()
        recallSubmit.current.showMessages()
        formValidator2.current.showMessages()

    }, [])

    useEffect(() => {
        if (filteredArray.length > 0) {
            {
                filteredArray.map((item) => {
                    return (
                        setMapFilter(item)
                    )
                })
            }
        } else {
            setMapFilter([])
        }
    }, [filteredArray])

    useEffect(() => {
        if (recallcompliance && recallcompliance.length > 0) {
            let uniqueObjArray = [...new Map(recallcompliance && recallcompliance.length > 0 && recallcompliance.map((item) => [item["unit_id"], item])).values()];
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.unit_id
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(recallcompliance, { unit_id: uniqueIds[i] })
                tempArr.push(filteredArr)
                setFilteredArray([tempArr])
            }
        } else {
            setFilteredArray([])
        }
    }, [recallcompliance])

    useEffect(() => {
        if (isAuth) {
            TaskRecallFilters({
                payload: [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "TaskRecallFilters",
                            {
                                "le_id": entityid
                            }
                        ]
                    }
                ],
                paramid: paramid
            })
        }
    }, [isAuth, entityid])

    useEffect(() => {
        if (showdata.legal_entity == '' || null || undefined) {
            Setshowdata({
                ...showdata,
                legal_entity: entityid
            })

        }
    }, [showdata.legal_entity])


    return (
        <div className="page-wrapper">
            <div className="page-titles pb-0 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <a href="javascript:;" className="link"><i className="ri-home-3-line fs-5"></i></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Transaction
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Task Recall
                                </li>
                            </ol>
                        </nav>
                        <h2 className="mb-0 fw-bold">Task Recall</h2>
                    </div>
                    

                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <Collapse accordion defaultActiveKey={['1']}>
                                <Panel header="Task Recall" key="1">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-4" >
                                                <div className="col-md-8" >
                                                    {/* <div className="mb-3"> */}
                                                    {/* <label>Legal Entity:</label> */}
                                                    <label htmlFor=""><b>Legal Entity</b></label><span style={{ color: "red" }}>*</span><br />
                                                    {localStorage.getItem('SelectedEntity') === "All Legal Entity" ?
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={true}
                                                                size="default"
                                                                placeholder="Select Legal Entity"
                                                                onChange={(data, value) => {
                                                                    setCurrentEntity(data)
                                                                }}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                //value={showdata.legal_entity}
                                                                style={{ width: '300px' }}
                                                            >
                                                                {sessionArr && sessionArr.length && sessionArr.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.le_id}>
                                                                            {item.le_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'legal_entity',
                                                                showdata.legal_entity,
                                                                'required',
                                                                {
                                                                    className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                    }
                                                                })}
                                                        </div> :
                                                        <p style={{ width: "300px" }}>{localStorage.getItem('SelectedEntity')}</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Domain</b></label><span style={{ "color": "red" }}>*</span><br />
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        name="domain"
                                                        id="domain"
                                                        placeholder="Enter domain"
                                                        onChange={(data) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                domain: data
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={filter.seller}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {userunitsrecall && userunitsrecall.domains_list && userunitsrecall.domains_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.d_id}>
                                                                    {item.d_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                    {formValidator.current.message(
                                                        'domain',
                                                        showdata.domain,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Unit</b></label><br />
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter unit"
                                                        onChange={(data) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                unit: data
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={filter.seller}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {userunitsrecall && userunitsrecall.user_units && userunitsrecall.user_units.map((item, i) => {
                                                            return (
                                                                <Option key={item.unit_id}>
                                                                    {item.unit_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Frequency</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter frequecy"
                                                        onChange={(data) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                frequency: data
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={filter.seller}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {userunitsrecall && userunitsrecall.frequency_list && userunitsrecall.frequency_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.frequency_id}>
                                                                    {item.frequency_name}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">

                                                <div className="form-group">

                                                    <label htmlFor=""><b>Due Month</b></label> <span style={{ "color": "red" }}>*</span>
                                                    <Space className='date' id='date' direction="vertical" style={{ "width": "100%" }}>
                                                        <DatePicker disabled={showdata.domain === "" ? true : false} style={{ "width": "100%" }} onChange={(date, dateString) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                due_month: moment(dateString).format('MMMM-YYYY')
                                                            })
                                                        }} picker="month" />
                                                    </Space>
                                                    {formValidator.current.message(
                                                        'date',
                                                        showdata.due_month,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormShow ? 'show' : 'hide'}`,
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor=""><b>Act</b></label>
                                                    <Select
                                                        allowClear={true}
                                                        size="default"
                                                        placeholder="Enter act"
                                                        onChange={(data) => {
                                                            Setshowdata({
                                                                ...showdata,
                                                                act: data
                                                            })
                                                        }}
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                        // value={filter.seller}
                                                        style={{ width: '100%' }}
                                                    >
                                                        {userunitsrecall && userunitsrecall.act_list && userunitsrecall.act_list.map((item, i) => {
                                                            return (
                                                                <Option key={item.parent_id}>
                                                                    {item.statutory_mapping}
                                                                </Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                        <a href={() => false} style={{ marginLeft: '40%' }} onClick={gettaskrecalllist} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</a>
                                    </div>
                                </Panel>
                            </Collapse>
                            {acccordionTrue === true ?
                                <div className="mt-2">
                                    <Collapse defaultActiveKey={['1']} accordion>
                                        <Panel header="Compliance Table" key="1">
                                            {mapFilter && mapFilter.length > 0 && mapFilter.map((item) => {
                                                return (
                                                    <Card
                                                        title={item && item.length && item[0].unit_name}
                                                    >
                                                        <DataTable columns={columns}
                                                            data={item}
                                                            // defaultSortFieldId={1}
                                                            pagination
                                                            fixedHeader = {dataTableProperties.fixedHeader}
                                                            
                                                        />
                                                    </Card>
                                                )
                                            })}
                                        </Panel>
                                    </Collapse>
                                    <div className="col-md-12 m-5">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Action</label>
                                                    <span style={{ color: "red" }}>*</span>
                                                    <select name="action" className='select2 form-control custom-select bg-white' id="action"
                                                        onChange={(e) => {
                                                            Setrecalldata({
                                                                ...recalldata,
                                                                action: e.target.value
                                                            })
                                                        }}
                                                    >
                                                        <option value="0"> Please Select </option>
                                                        <option value="1"> Rectify & Retain Document </option>
                                                        <option value="2"> Rectify & Remove Document </option>
                                                    </select>
                                                    {recallSubmit.current.message(
                                                        'action',
                                                        recalldata.action,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Remarks</label>
                                                    <span style={{ color: "red" }}>*</span>
                                                    <textarea name="remarks" className='form-control'
                                                        value={recalldata.remarks ? recalldata.remarks : ""} id="remarks" cols="5" rows="5"
                                                        onChange={(e) => {
                                                            Setrecalldata({
                                                                ...recalldata,
                                                                remarks: e.target.value
                                                            })
                                                        }}></textarea>
                                                    {recallSubmit.current.message(
                                                        'remarks',
                                                        recalldata.remarks,
                                                        ['required'],
                                                        {
                                                            className: `invalid-feedback ${FormRecall ? 'show' : 'hide'}`,
                                                        })}
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <PlusCircleOutlined onClick={() => { showRemarksModal() }} />
                                            </div>
                                            <Modal title="Remarks" visible={remarksModal} footer={null} onOk={handleOk} onCancel={handleCancel}>
                                                <DataTable columns={column}
                                                    data={recallremarks}
                                                    // defaultSortFieldId={1}
                                                    pagination />
                                            </Modal>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    {/* <Link to="" style={{ marginLeft: '40%' }} onClick={()=>{setAccordionTrue(true)}} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Show</Link> */}
                                                    <a href={() => false} onClick={Active} style={{ marginLeft: '10%' }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Recall</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                            <Modal title="Domain" visible={showValueModal} footer={false} onCancel={handleCancel}>
                                <Descriptions title="Other Details" layout="vertical" className='labelclass'>
                                    <Descriptions.Item label="Unit"><Tooltip title={valueModal.address}>
                                        <InfoCircleOutlined />
                                    </Tooltip>&nbsp;{valueModal.unit}</Descriptions.Item>
                                    <Descriptions.Item label="Compliance Task"><Tooltip title={valueModal.compliance_description}>
                                        <InfoCircleOutlined />
                                    </Tooltip>&nbsp;
                                        {valueModal.compliance_task}</Descriptions.Item>
                                    <Descriptions.Item label="Uploaded File Size">-</Descriptions.Item>
                                    <Descriptions.Item label="Attached Documents">{valueModal.uploaded_document}</Descriptions.Item>
                                    <Descriptions.Item label="Task Completion Date/Document Issued Date">{valueModal.document_issued_date}</Descriptions.Item>
                                    <Descriptions.Item label="Document Reference Number">
                                        {valueModal.document_reference_number}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Remarks">
                                        {valueModal.remarks}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Interim details">
                                        {valueModal.interim_details}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Modal>
                            <Modal visible={passwordmodal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-6">
                                            <label className="control-label">Password:</label>
                                            <span style={{ "color": "red" }}>*</span>
                                            <input
                                                style={{ marginTop: "10px" }}
                                                type="password"
                                                id="password"
                                                name='passwordvalue'
                                                className="form-control"
                                                placeholder="Enter password"
                                                onChange={(e) => {
                                                    Setpassword({
                                                        ...password,
                                                        passwordvalue: e.target.value
                                                    })
                                                }}
                                                value={password.passwordvalue}
                                            />
                                            {formValidator2.current.message(
                                                'Password',
                                                password.passwordvalue,
                                                ['required'],
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                                    messages: {
                                                        regex: 'Enter Password'
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className="form-actions">

                                    <button
                                        className="btn btn-light-success rounded-pill px-4 text-success"
                                        onClick={submitpassword}>
                                        {/* <div className="d-flex align-items-center"
                                                    > */}
                                        {/* <i
                                                            data-feather="save"
                                                            className="feather-sm me-1 fill-icon"
                                                        ></i> */}
                                        Submit
                                        {/* </div> */}
                                    </button>
                                    <button
                                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start" onClick={passwordcancel}>
                                        {/* <div className="d-flex align-items-center"> */}
                                        {/* <i data-feather="save" className="feather-sm me-1 fill-icon"></i> */}
                                        Cancel
                                        {/* </div> */}
                                    </button>
                                </div>

                            </Modal>
                        </div>
                    </div>
                </div >
            </div >
        </div >

    )
}

export default connect(mapStateToProps, {
    TaskRecallFilters,
    GetTaskRecallList,
    GetRemarksData,
    UpdateTaskRecallStatus
})(TaskRecall)