import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Card, Divider, Tabs, Radio, Input, Space, Row, Col, Select } from 'antd';
import Swal from 'sweetalert2';
import SimpleReactValidator from "simple-react-validator";
const mapStateToProps = () => ({});
const ChangePassword = ({

}) => {
    const { Search } = Input;
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [password,setPassword] = useState({
      current_password:'',
      new_password:'',
      confirm_password:''
    })
    const [show, setShow] = useState(false)
    // const [Entity, setEntity] = useState("All Legal Entity")
    const [modalVisible, setModaVisible] = useState(false)
    const onUserDetailOnChange = (e) => {
        setPassword({
            ...password,
            [e.target.name]: e.target.value
        })
    }
    const userDetailOnSubmit = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            // setSelectUnit(true)
        }
    }
    useEffect(() => {
        validator.current.showMessages()
    }, [])
  
    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <a href="index.html" className="link"
                                            ><i className="ri-home-3-line fs-5"></i></a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Change Password
                                        </li>
                                    </ol>
                                </nav>
                                <h1 className="mb-0 fw-bold">Change Password </h1>
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="col-md-12">

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Current Password</label>  <span style={{ "color": "red" }}>*</span>
                                                    <input type="text" className='form-control'onChange={onUserDetailOnChange} name="current_password" id="" />
                                                    {validator.current.message(
                                                                'currrent password',
                                                               password.current_password,
                                                               [
                                                                'required',
                                                                {
                                                                    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
                                                                },
                                                            ],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                        regex: 'Minimum eight and maximum fifteen characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)',
                                                                    }
                                                                })}   
                                                </div>

                                            </div><br/>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">New Password</label>  <span style={{ "color": "red" }}>*</span>
                                                    <input type="text" className='form-control'name="new_password" onChange={onUserDetailOnChange}/>
                                                    {validator.current.message(
                                                                'new password',
                                                               password.new_password,
                                                               [
                                                                'required',
                                                                {
                                                                    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
                                                                },
                                                            ],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                        regex: 'Minimum eight and maximum fifteen characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)',
                                                                    }
                                                                })}    
                                                </div>
                                            </div><br/>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Confirm Password</label>  <span style={{ "color": "red" }}>*</span>
                                                    <input type="text" className='form-control' name="confirm_password"onChange={onUserDetailOnChange}/>
                                                    {validator.current.message(
                                                                'confirm_password',
                                                               password.confirm_password,
                                                               

                                                            ['required', `in:${password.new_password}`],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Required Field Missing',
                                                                        in: "New Password and Confirm password doesn't match",
                                                                    }
                                                                })}    
                                                </div>
                                            </div><br/>


                                        </div><br />
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div
                                                    className="
                col-lg-6 col-md-5
                d-none d-md-flex
               ">
                                                    <a className="btn btn-light-primary text-primary btn-lg px-4 fs-4" onClick={userDetailOnSubmit}
                                                        

                                                    >Submit</a>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
})(ChangePassword);