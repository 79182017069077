import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Card,Table} from 'antd';
import { useState } from 'react';

const mapStateToProps = () => ({
});

const Userprivileges = () => {
const [ category,setCategory] = useState("")
    const columns = [
        {
          title: "Form Name",
          dataIndex: "formname",
        //   onHeaderCell: (record, rowIndex) => ({
        //     style: {
        //         backgroundColor: '#1a9bfc',
        //         color: 'white'
        //     }
        // })
        },
        {
          title: "Compfie",
          dataIndex: "compfie",
          render:()=><button type="button" class="btn btn-light"><span class="bi bi-check-circle" style={{color:"green"}}></span></button>,
        //   onHeaderCell: (record, rowIndex) => ({
        //     style: {
        //         backgroundColor: '#1a9bfc',
        //         color: 'white'
        //     }
        // })
        },
        {
          title: "Compfie Vendor",
          dataIndex: "c_vendor",
          render:()=><button type="button" class="btn btn-light"><span class="bi bi-check-circle" style={{color:"green"}}></span></button>,
        //   onHeaderCell: (record, rowIndex) => ({
        //     style: {
        //         backgroundColor: '#1a9bfc',
        //         color: 'white'
        //     }
        // })
        }
      ];
      const data = [
        {
          key: "1",
          formname: "Dashboard",
         
        },
        {
          key: "2",
          formname: "Remarks Master",
         
        },
        {
          key: "3",
          formname: "Task Recall",
         
        },
        {
          key: "4",
          formname: "Easy Upload",
          
        }
      ];
      
      // rowSelection object indicates the need for row selection
      const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
        
        },
        
      };
    
     const  Categorylist = (e) => {
        setCategory({
            ...category,
        [e.target.name] : e.target.value,
     })
        
      }
    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                {/* <li className="breadcrumb-item">
                                    <a href="index.html" className="link"><i className="ri-home-3-line fs-5"></i></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    User Privileges
                                </li> */}
                            </ol>
                        </nav>
                        < div className="row" style={{backgrounColor:'blue'}}>
                            <div className="col-md-11">
                                <h3 className="mb-0 fw-bold">User Privileges Master</h3> </div>
                        </div>
                    </div>
                    <div className="
                col-lg-4 col-md-6
                d-none d-md-flex
                align-items-center
                justify-content-end" >
                          <Link to="/client-user-privilege/:id" type="button" class="btn-close" aria-label="Close"> </Link>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <Card>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label>User Group Name<span style={{ color: "red" }}> * </span> </label>
                                            <input type="text" placeholder=" User Group Name"className='form-control' name="" id="" />
                                        </div>
                                    </div>
                                    <div className='col-md-6 '>
                                        <label>Category Name<span style={{ color: "red" }}> * </span> </label>
                                        <select name="option" className='form-control' onChange ={Categorylist}>
                                            <option value="select_category">Select Category</option>
                                            <option value="Client_Executive" > Client Executive</option>
                                            <option value="Domain_Admin">Domain Admin</option>
                                            <option value="Legal_Entity_Admin">Legal Entity Admin</option>
                                            <option value="Service_Provider_Admin">Service Provider Admin</option>
                                            <option value="View_only">View only</option>
                                            <option value="View_only_restricted">View only restricted</option>
                                        </select>
                                    </div>
                                </div>
                                 <br></br>
                                { category.option === 'Client_Executive' ? 
                                <Table rowSelection={rowSelection} columns={columns} dataSource={data} /> : 
                                category.option === 'Legal_Entity_Admin' ?  
                                <Table rowSelection={rowSelection} columns={columns} dataSource={data} /> :'' }
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, {})(Userprivileges);