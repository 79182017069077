import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { toast } from 'react-toastify';
import { RECALLFILTERS, TASKRECALLCOMPLIANCE, REMARKSDATA, TOGGLE_COMMON_LOADER, UPDATE_TASK_STATUS } from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';

export const TaskRecallFilters = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: RECALLFILTERS,
            payload: decryptData,
        });
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const GetTaskRecallList = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        dispatch({
            type: TASKRECALLCOMPLIANCE,
            payload: decryptData,
        });
    }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const GetRemarksData = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_masters`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();

        }else{
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        dispatch({
            type: REMARKSDATA,
            payload: decryptData,
        });
    }

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const UpdateTaskRecallStatus = ({ payload, executeCancel, paramid }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.commonpost({
            url: `${url.CommonURL}/api/client_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        const response = HTTP.parseResponse(executeRequest)
        const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded));
        if (decryptData[0] === 'InvalidSessionToken') {
            alertmessage.sweetalert();
        } 
        else if(decryptData[0] == 'InvalidPassword'){
            Toaster.error('InvalidPassword')
        }
        else if(decryptData[0] == 'TaskRecallSuccess'){
            Toaster.success('Task recall approved successfully!')
            setTimeout(()=>{
                window.location.reload(false);
            }, 2000);
        }
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        dispatch({
            type: UPDATE_TASK_STATUS,
            payload: decryptData,
        });

    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};