import { combineReducers } from 'redux';
import Login from './Login';
import Statutorysetting from './Transactions/statutorysettings';
import Completedtaskyear from './Transactions/Completedtaskyear';
import ComplianceApproval from './Transactions/ComplianceApproval';
import bulkupload from './Transactions/Completedtaskcurrentbulkupload';
import recallfilters from './Transactions/Taskrecall';
import Reviewsetting from './Transactions/Reviewsettings';
import Assigncompliance from './Transactions/Assigncompliance';
import ReassignComplaince from './Transactions/ReassignComplaince';
import CriticalitySettings from './Transactions/Criticalitysettings'
import UnitClosure from './Master/UnitClosure';
import Remarksmaster from './Master/Remarksmaster';
import ViewOnlyRestictedList from './Master/ViewOnlyRestictedList';
import Serviceprovider from './Master/Serviceprovider'
import Userprivileges from './Master/Userprivileges';
import ApproveTaskRecallCompliance from './Transactions/ApproveTaskRecallCompliance';
import UserManagement from './Transactions/UserManagement';


const appReducer = combineReducers({
    Login,
    Statutorysetting,
    Reviewsetting,
    Completedtaskyear,
    ComplianceApproval,
    Assigncompliance,
    bulkupload,
    recallfilters,
    ReassignComplaince,
    CriticalitySettings,
    UnitClosure,
    Remarksmaster,
    ViewOnlyRestictedList,
    Serviceprovider,
    Userprivileges,
    ApproveTaskRecallCompliance,
    UserManagement

});
export default (state, action) => {
    return appReducer(state, action);
};