export const LOGINSUCCESS = 'LOGINSUCCESS'
export const STATOTORYLIST = 'STATOTORYLIST'
export const STATUTORYFILTERDATA = 'STATUTORYFILTERDATA'
export const STATUTORYSEDIT = 'STATUTORYSEDIT'
export const REVIEWLIST = 'REVIEWLIST'
export const REVIEWUNITLIST = 'REVIEWUNITLIST'
export const REASSIGNCOMPLAINCE = 'REASSIGNCOMPLAINCE'
export const REASSIGLISTAPI = 'REASSIGLISTAPI'
export const COMPLETED_TASK_LIST = 'COMPLETED_TASK_LIST'
export const STATUTORY_BY_UNIT = 'STATUTORY_BY_UNIT'
export const COMPLIANCE_APPROVAL_LIST = 'COMPLIANCE_APPROVAL_LIST'
export const COMPLIANCE_APPROVAL_FILTER_LIST = 'COMPLIANCE_APPROVAL_FILTER_LIST'
export const RECALLFILTERS = 'RECALLFILTERS'
export const TASKRECALLCOMPLIANCE = 'TASKRECALLCOMPLIANCE'
export const REVIEWSHOWSETTINGLIST = 'REVIEWSHOWSETTINGLIST'
export const ASSIGNCOMPLIANCE = "ASSIGNCOMPLIANCE"
export const UNITDATA = "UNITDATA"
export const ASSIGNEE = 'ASSIGNEE'
export const TABLEDATA = "TABLEDATA"
export const DOMAINDATA = 'DOMAINDATA'
export const ASSIGNCOMPLIANCEO = "ASSIGNCOMPLIANCEO"
export const REASSIGNTABLELISTAPI = 'REASSIGNTABLELISTAPI'
export const REASSIGNTABLELIST = 'REASSIGNTABLELIST'
export const CRITICALITYSETTINGS = 'CRITICALITYSETTINGS'
export const REMARKSDATA = 'REMARKSDATA'
export const UNIT_CLOSURE_LIST = 'UNIT_CLOSURE_LIST'
export const REMARKSMASTER = 'REMARKSMASTER'
export const CRITICALITYLIST = 'CRITICALITYLIST'
    // export const TOGGLE_COMMON_LOADER = 'TOGGLE_COMMON_LOADER'
export const SAVEASSIGNCOMPLIANCE = 'SAVEASSIGNCOMPLIANCE'
export const TOGGLE_COMMON_LOADER = 'TOGGLE_COMMON_LOADER'
export const SERVICEPROVIDERLIST = 'SERVICEPROVIDERLIST'
export const REASSIGGETUSERCOMPLIANCE = 'REASSIGGETUSERCOMPLIANCE'
export const REASSIGNSAVECOMPLAINCE = 'REASSIGNSAVECOMPLAINCE'
export const VIEW_ONLY_RESTRICTED_LIST = 'VIEW_ONLY_RESTRICTED_LIST'
export const VIEW_ONLY_RESTRICTED_UNIT_LIST = 'VIEW_ONLY_RESTRICTED_UNIT_LIST'
export const PRIVILEGES = 'PRIVILEGES'
export const APPROVE_TASK_RECALL = 'APPROVE_TASK_RECALL'
export const APPROVE_TASK_RECALL_LIST = 'APPROVE_TASK_RECALL_LIST'
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS'
export const USERMANAGEMENT_LIST = ' USERMANAGEMENT_LIST'
export const USERMANAGEMENT_CATEGORY_LIST = ' USERMANAGEMENT_CATEGORY_LIST'
export const REVIEWSAVECOMPLIANCE = 'REVIEWSAVECOMPLIANCE'
export const DOWNLOADLIST = 'DOWNLOADLIST'
export const DOWNLOADPATH = 'DOWNLOADPATH'
export const UPLOADCONTENT = 'UPLOADCONTENT'
export const SAVESTATUTORY = 'SAVESTATUTORY'
export const UPLOADERROR = 'UPLOADERROR'
export const GET_BY_UNIT = 'GET_BY_UNIT'
export const SYNCASSIGNCOMPLIANCE = 'SYNCASSIGNCOMPLIANCE'
export const DATALIST = 'DATALIST'
export const QUEUED = 'QUEUED'
export const QUEUEDPATH = 'QUEUEDPATH'
export const TABLEDATALIST = 'TABLEDATALIST'
export const SHOWMOREDATA = 'SHOWMOREDATA'
export const UPLOADSUCCESS = 'UPLOADSUCCESS'
export const UNITLIST = 'UNITLIST'