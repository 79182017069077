import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from "react-router-dom";

// import Home from './Home';
import Dashboard  from './Dashboard';
import Serviceproviderlist from './Master/Serviceproviderlist';
import Serviceprovider from './Master/Addserviceprovider';
import Userprivileges from './Master/Userprivilegeslist';
import Adduserprivileges from './Master/Adduserprivileges';
import AddUserManagement from './Master/AddUserManagement';
import Remarksmaster from './Master/Remarksmaster';
import UnitClosure from './Master/UnitClosure';
import Usermanagement from './Master/Usermanagement';
import ViewOnlyRestricted from './Master/ViewOnlyRestricted';
import Statutorysetting from './Transactions/statutorysettings';
import ApproveTaskRecall from './Transactions/ApproveTaskRecall';
import Compliance from './Transactions/Assigncompliance';
import Reviewsetting from './Transactions/Reviewsettings';
import Taskrecall from './Transactions/Taskrecall';
import ReassignComplaince from './Transactions/ReassignComplaince';
import ComplianceApproval from './Transactions/ComplianceApproval';
import CompletedTaskCurrentYear from './Transactions/CompletedTaskCurrentYear';
import MyProfile from './MyProfile';
import ChangePassword from './ChangePassword';
import Completedtaskcurrentyearbulkuploadlist from './Transactions/Completedtaskcurrentyearbulkuploadlist'
import Addcompletedtaskcurrentyearbulkupload from './Transactions/Addcompletedtaskcurrentyearbulkupload'
import CriticalitySetting from './Transactions/CriticalitySetting';

import ConsolidatedReport from "./Report/ComplianceReports/ConsolidatedReport"
import DomainReport from './Report/ComplianceReports/DomainReports';
const RoutesLink = () => {
    return (
        <Switch>
            <Route exact path="/dashboard">
                <Dashboard/>
            </Route>
            <Route exact path="/service-provider/:id">
                <Serviceproviderlist />
            </Route>
            <Route exact path="/addserviceprovider/">
                <Serviceprovider />
            </Route>
            <Route exact path="/client-user-privilege/:id">
                <Userprivileges />
            </Route>
            <Route exact path="/addclient-user-privilege/:id">
                <Adduserprivileges />
            </Route>
           
            <Route exact path="/remarks-master/:id">
                <Remarksmaster />
            </Route>
            <Route exact path="/unit-closure/:id">
                <UnitClosure />
            </Route>
            <Route exact path="/client-user-management/:id">
                <Usermanagement />
            </Route>
            <Route exact path="/client-user-management/:id">
                <AddUserManagement />
            </Route>
            <Route exact path="/view-only-user-privilege/:id">
                <ViewOnlyRestricted/>
            </Route>
            <Route exact path="/assign-compliance/:id/:type">
                <Compliance />
            </Route>
            <Route exact path="/statutory-settings/:id/:type">
                <Statutorysetting />
            </Route>
            <Route exact path="/review-settings/:id/:type">
                <Reviewsetting />
            </Route>
            <Route exact path="/approve-task-recall/:id/:type">
                <ApproveTaskRecall />
            </Route>
            <Route exact path="/criticality-settings/:id/:type">
               <CriticalitySetting/>
            </Route>
            <Route exact path="/task-recall/:id/:type">
                <Taskrecall />
            </Route>
            <Route exact path="/reassign-compliance/:id/:type">
                <ReassignComplaince  />
            </Route>
            <Route exact path="/compliance-approval/:id/:type">
                <ComplianceApproval/>
            </Route>
            <Route exact path="/completed-tasks-current-year/:id/:type">
                <CompletedTaskCurrentYear/>
            </Route>
            <Route exact path="/completed-tasks-current-year-bu/:id/:type">
                <Completedtaskcurrentyearbulkuploadlist/>
            </Route>
            <Route exact path="/profile">
                <MyProfile />
            </Route>
            <Route exact path="/changePassword">
                <ChangePassword />
            </Route>
            <Route exact path="/Addcompletedtaskscurrentyearbu">
                <Addcompletedtaskcurrentyearbulkupload/>
            </Route>
            <Route exact path="/status-report-consolidated/:id">
                <ConsolidatedReport/>
            </Route>
            <Route exact path="/domain-wise-report/:id">
                <DomainReport/>
            </Route>
        </Switch>
    )
}


export default connect(null)(RoutesLink);
