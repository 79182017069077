import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter, Link, useLocation, } from 'react-router-dom';
import { AudioOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Modal, Input, Table, Button, Space, Tooltip, Select, Card } from 'antd';
import { getUsermanagementList, updatestatus, activeStatus, disableStatusChange, getById, resendEmail, getUsermanagementDetails } from './../../Store/Action/Master/UserManagement'
import {
    InfoCircleOutlined, PlusSquareTwoTone, LikeOutlined, DislikeOutlined,
    StopOutlined, SearchOutlined
} from '@ant-design/icons';
import DataTable from "react-data-table-component";
import SimpleReactValidator from 'simple-react-validator';
import { GetRemarksData } from './../../Store/Action/Transcations/Taskrecall'
import AddUserManagement from './AddUserManagement';
const _ = require("lodash");
const { Option } = Select;
const mapStateToProps = (state) => ({
    usermanagementList: state.UserManagement.userManagement,
    filterrecall: state.recallfilters.recallfilters
})
const Usermanagement = ({
    getUsermanagementList,
    resendEmail,
    getUsermanagementDetails,
    GetRemarksData,
    updatestatus,
    getById,
    activeStatus,
    disableStatusChange,
    filterrecall: { recallremarks },
    usermanagementList: { userManagementList, UserLegalDetails, userManagementDetails, GetUserDetails }
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    const entityFormvalidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [EntitySubmit, setEntityFormSubmit] = useState(false);
    const [remarks, setRemarks] = useState('')
    const [filterarray, setFilterArray] = useState([])
    const selectedEntity = localStorage.getItem('SelectedEntity')
    const entityid = localStorage.getItem('SelectedEntityid');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [disableType, setDisableType] = useState("")
    const [remarksModal, setRemarksModal] = useState(false);
    const [show, setShow] = useState(false)
    const [showList, setShowList] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [resetPaginationToggle, setResetPaginationToggle] = useState(
        false
    );

    const [passvisible, Setpassvisible] = useState(false);
    const [EntityValue, setEntityValue] = useState()
    const [main, setmain] = useState({
        country: '',
        entity: ''
    })
    const [type, setType] = useState("");
    const [AddType, setAddType] = useState("list")
    const [finalResult, SetfinalResult] = useState({
        Resultdata: []
    })
    const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
    const entity_info = sessionValue.entity_info
    const country_info = sessionValue.country_info
    const [passwordata, Setpassword] = useState({
        passvalue: '',
        sp_id: '',
        user_id: '',
        product_id: ''
    })
    const [modalData, setModalData] = useState({
        password: '',
        reason: '',
        user_id: ''

    })
    const [active, setActive] = useState({
        u_id: "",
        emp_name: "",
    })
    const [modalVisible, setModaVisible] = useState(false)
    const [business, setBusiness] = useState(false)
    const [legal, setLegal] = useState(false)
    const [Entity, setEntity] = useState({})
    const [filteredArray, setFilteredArray] = useState([])
    const [filteredArrayState, setFilteredArrayState] = useState([])
    const [page, setPage] = useState(1);
    const [remarksvalue, Setremarksvalue] = useState();

    const RemarksColumn = [
        {
            title: 'S.No',
            dataIndex: false,
            width: '10px',
            align: 'center',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Description',
            dataIndex: 'r_description',
            key: 'r_description',
            width: '200px',
            render: (text, record) => <a onClick={() => {
                setRemarks(text)
                setRemarksModal(false)
            }}>{record.r_description}</a>,
        },
    ];

    const [baseData, setBaseData] = useState([]);

    useEffect(() => {
        if (recallremarks) {
            let temp = [];
            for (let i = 0; i < recallremarks.length; i++) {
                if (recallremarks[i].r_description != '') {
                    temp.push(recallremarks[i])
                }
                Setremarksvalue(temp)
            }
        }

    }, [recallremarks])

    const passwordcancel = () => {
        Setpassvisible(false);
        setModaVisible(false)
    }
    const showModal = () => {
        setIsModalVisible(true);
    };
    const businessmodel = () => {
        setBusiness(true)
    }
    const legalmodel = () => {
        setLegal(true)
    }
    useEffect(() => {
        formValidator.current.showMessages()
        entityFormvalidator.current.showMessages()
    }, [])
    console.log(AddType, 'AddTypeAddType');
    const OnSubmited = () => {
        setAddFormSubmit(true)

        if (formValidator.current.allValid()) {
            setModaVisible(false)
            disableStatusChange({
                payload:
                    [
                        authtoken,
                        {
                            "session_token": authtoken,
                            "request": [
                                // "SaveUnitClosureData",
                                // {
                                //     "password": modalData.password,
                                //     "closed_remarks": remarks,
                                //     "unit_id": Number(modalData.unit_id),
                                //     "grp_mode": modalData.reason=='closed'?"close":"reactive",
                                //     "legal_entity_id": entityid
                                // }
                                "BlockUser",
                                {
                                    "user_id": modalData.user_id,
                                    "is_blocked": disableType == 'disable' ? true : false,
                                    "active_status": false,
                                    "remarks": remarks,
                                    "password": modalData.password
                                }

                            ]
                        }
                    ],
                paramid: paramid,
                entityid: entityid
            })
            setModalData({
                ...modalData,
                password: ""
            })
            setRemarks("")
        }
    }
    const showRemarksModal = () => {
        GetRemarksData({
            payload:
                [
                    authtoken,
                    {
                        "session_token": authtoken,
                        "request": [
                            "GetRemarksData",
                            {}
                        ]
                    }
                ],
            paramid: paramid
        })
        setRemarksModal(true);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        setBusiness(false)
        setLegal(false)
        setRemarksModal(false)
        Setpassvisible(false)
    };
    const submitpassword = () => {
        const pay = [
            authtoken,
            {
                "session_token": authtoken,
                // "request": [
                //     "ChangeServiceProviderStatus",
                //     {
                //         "sp_id": passwordata.sp_id,
                //         "active_status": passwordata.active_status,
                //         "password": passwordata.passvalue
                //     }
                // ]

                "request": [
                    "HaveCompliances",
                    {
                        "le_id": entityid,
                        "user_id": passwordata.user_id,
                        "password": passwordata.passvalue,
                        "product_id": ""
                    }
                ]
            }
        ]
        const payloads = [
            authtoken,
            {
                "session_token": authtoken,
                "request": [
                    "ChangeClientUserStatus",
                    {
                        "u_id": active.u_id,
                        "active_status": true,
                        "emp_name": active.emp_name,
                        "password": passwordata.passvalue
                    }
                ]


            }
        ]
        if (type == "active") {
            updatestatus({
                payload: pay,
                paramid: paramid
            })
        }
        else {
            activeStatus({
                payload: payloads,
                paramid: paramid
            })
        }
        Setpassvisible(false);
    }



    const handleCancel = () => {
        setIsModalVisible(false);
        Setpassvisible(false)
        setBusiness(false)
        setLegal(false)
        setModaVisible(false)
        setRemarksModal(false)
    };
    useEffect(() => {
        let finalArray = [];
        let countrylistdata = [];
        let countrylisttempdata = [];
        for (let countrylist in country_info) {
            let country_id = country_info[countrylist].c_id
            let country_name = country_info[countrylist].c_name
            let entityArray = []
            let tempArray = []
            let sumValue = 0;

            for (let entitylist in entity_info) {
                let entitylist_cid = entity_info[entitylist].c_id
                if (entitylist_cid === country_id) {
                    sumValue = entity_info[entitylist].u_count + sumValue
                    entityArray = {
                        'bg_id': entity_info[entitylist].bg_id,
                        'bg_name': entity_info[entitylist].bg_name,
                        'c_id': entity_info[entitylist].c_id,
                        'c_name': entity_info[entitylist].c_name,
                        'le_id': entity_info[entitylist].le_id,
                        'le_name': entity_info[entitylist].le_name,
                        'u_count': entity_info[entitylist].u_count,
                    }
                    tempArray.push(entityArray)
                }
            }
            finalArray[country_name] = tempArray
            countrylistdata = {
                'c_id': country_id,
                'c_name': country_name,
                'allEntitySumNew': sumValue,
                'child': tempArray
            }
            countrylisttempdata.push(countrylistdata)
            SetfinalResult({
                ...finalResult,
                Resultdata: countrylisttempdata
            })


        }

    }, [])
    const FilterComponent = ({ filterText, onFilter, onClear }) => {
        return (
            <>

                <div className='row'>
                    <div className="col-md-12">
                        <Input
                            type="text"
                            placeholder="Search Here..."
                            value={filterText}
                            onChange={onFilter}
                            autoFocus
                        />
                    </div>
                </div>

            </>
        )
    }


    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementList",
                        {}
                    ]
                }
            ]
            getUsermanagementList({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])
    useEffect(() => {
        if (isAuth) {
            const payload = [
                authtoken,
                {
                    "session_token": authtoken,
                    "request": [
                        "UserManagementPrerequisite",
                        {}
                    ]
                }
            ]
            getUsermanagementDetails({
                payload: payload,
                paramid: paramid
            })
        }
    }, [isAuth])



    const { Search } = Input;
    const suffix = (
        <AudioOutlined
            style={{
                fontSize: 16,
                color: '#1890ff',
            }}
        />
    );
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder='Search'
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });





    const columns = [
        {
            title: 'Compfie Category',
            dataIndex: 'u_cat_id',
            align: 'center',
            render: (row, record) => {
                return (
                    <Fragment>
                        {
                            (row == 4 ? <i style={{ color: "#45b0e2" }} class="bi bi-square-fill"></i>
                                : (row == 3 ? <i style={{ color: "#fbca35" }} class="bi bi-square-fill"></i> :
                                    (row == 5 ? <i style={{ color: "#e96154" }} class="bi bi-square-fill"></i> :
                                        (row == 2 ? <i style={{ color: "#9a9da0" }} class="bi bi-square-fill"></i> :
                                            (row == 6 ? <i style={{ color: "#60667a" }} class="bi bi-square-fill"></i> :
                                                row == 7 ? <i style={{ color: "#b29999" }} class="bi bi-square-fill"></i> :
                                                    row == null ? <i style={{ color: "#ff8acc" }} class="bi bi-square-fill"></i> : false)))))
                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Vendor Category',
            dataIndex: 'vndr_user_category',
            align: 'center',
            render: (row, record) => {
                return (
                    <Fragment>
                        {
                            (row == 4 ? <i style={{ color: "#45b0e2" }} class="bi bi-square-fill"></i>
                                : (row == 3 ? <i style={{ color: "#fbca35" }} class="bi bi-square-fill"></i> :
                                    (row == 5 ? <i style={{ color: "#e96154" }} class="bi bi-square-fill"></i> :
                                        (row == 2 ? <i style={{ color: "#9a9da0" }} class="bi bi-square-fill"></i> :
                                            (row == 6 ? <i style={{ color: "#60667a" }} class="bi bi-square-fill"></i> :
                                                row == 7 ? <i style={{ color: "#b29999" }} class="bi bi-square-fill"></i> :
                                                    row == null ? <i style={{ color: "#ff8acc" }} class="bi bi-square-fill"></i> : false)))))
                        }
                    </Fragment>)
            }
        },
        {
            title: 'Users',
            dataIndex: 'emp_name',
            key: 'emp_name',
            ellipsis: true,
            ...getColumnSearchProps('emp_name')
        },
        {
            title: 'User ID',
            dataIndex: 'seatings_unit',
            key: 'seatings_unit',
            ellipsis: true,
            render: (row, record) => {
                return (
                    <Fragment>
                        {record.user_name != null ?
                            <Tooltip title={`${row}`}>
                                <p style={{ whiteSpace: 'unset' }}><InfoCircleOutlined />&nbsp;{record.user_name}</p>
                            </Tooltip> : false
                        }
                    </Fragment>
                )
            }
        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            key: 'email_id',
            ...getColumnSearchProps('email_id'),
            ellipsis: true,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mob_no',
            key: 'mob_no',
            ...getColumnSearchProps('mob_no'),
            ellipsis: true,
        },
        {
            title: 'Registration Email',
            dataIndex: 'user_name',
            key: 'user_name',
            render: (row, record) => {
                return (
                    <Fragment>
                        {
                            row == null ? <Link onClick={() => { resentEmailRegistration(row) }}>Resend</Link> : ''
                        }
                    </Fragment>
                )
            }
        },
        {
            title: <><label>Actions</label><Tooltip className='toolTipClass' style={{ marginBotton: '5px', marginleft: '5px' }} placement="topRight" title={"This Action Includes Edit, Active/Inactive Status Change, Block/Unblock Status Change"}><InfoCircleOutlined /></Tooltip></>,
            dataIndex: 'unblock_days',
            width:'130px',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div className='row'>
                            <div className='col-md-3'>
                                {text == 30 ?
                                    <div className='col-md-2'>
                                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center"
                                            onClick={(e) => { editUser(record.user_id) }}>
                                            <i className="ri-edit-line"></i>
                                        </span>
                                    </div> :
                                    <div className='col-md-2'>
                                        <span className="btn btn-sm btn-light-info text-info btn-circle d-flex align-items-center justify-content-center">
                                            <i className="ri-edit-line"
                                                style={{ cursor: 'not-allowed' }}></i>
                                        </span>
                                    </div>
                                }
                            </div>
                            <div className='col-md-3'>
                                {
                                    record.is_disable === 0 ?
                                        <div>
                                            {record.is_active == true ?
                                                <span className="btn btn-sm mb-1 badge
                                             font-weight-medium bg-light-success text-success w-80" onClick={(e) => { statuschange(record, "active") }}>
                                                    <Tooltip title="Active" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span> :
                                                <span className="btn btn-sm mb-1
                                                badge font-weight-medium bg-light-danger text-danger w-80" onClick={(e) => { statuschange(record, "deactive") }}>
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>
                                            }
                                        </div> :
                                        <div>
                                            {record.is_active == true ?
                                                <span className="btn btn-sm mb-1 badge
                                             font-weight-medium bg-light-success text-success w-80">
                                                    <Tooltip title="Active" color='#39cb7f'><LikeOutlined /></Tooltip>
                                                </span>
                                                :
                                                <span className="btn btn-sm mb-1
                                                badge font-weight-medium bg-light-danger text-danger w-80">
                                                    <Tooltip title="De-Active" color='#fc4b6c'><DislikeOutlined /></Tooltip>
                                                </span>


                                            }
                                        </div>

                                }
                            </div>
                            <div className='col-md-3 usermanagementMl'>
                                {record.is_disable === 0 ?
                                    <span className="btn btn-sm btn-light-success
                                    text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                            setShow(true)
                                            DisableById(record.user_id, "disable");
                                        }}>
                                        <Tooltip title="Click here to Disable" color='#39cb7f'><CheckOutlined /></Tooltip>
                                    </span>
                                    :
                                    <div>
                                        { record.unblock_days === 0 ? '':
                                            <span className="btn btn-sm btn-light-danger
                                            text-danger btn-circle d-flex align-items-center justify-content-center" onClick={() => {
                                                    setShow(true)
                                                    DisableById(record.user_id, "unDisable");
                                                }}>
                                                <Tooltip title={record.unblock_days + '-' + 'Days Left'} color='#fc4b6c'><StopOutlined /></Tooltip>
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];

    const resentEmailRegistration = (row) => {
        const payload = [
            authtoken,
            {
                "session_token": authtoken,

                "request": [
                    "ResendRegistrationEmail",
                    {
                        "user_id": row.user_id
                    }
                ]
            }
        ]
        resendEmail({
            payload: payload,
            paramid: paramid
        })

    }


    const editUser = (user_id) => {
        setAddType("edit")
        setShowList(true)
        const payload = [
            authtoken,
            {
                "session_token": authtoken,

                "request": [
                    "UserManagementEditView",
                    {
                        "user_id": user_id
                    }
                ]
            }
        ]
        getById({
            payload: payload,
            paramid: paramid
        })


    }
    const DisableById = (user_id, type) => {
        setDisableType(type)
        Swal.fire({
            title: 'Do you want to Close?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setModaVisible(true)
                setModalData({
                    ...modalData,
                    user_id: user_id,
                    // reason:type
                })


                // Swal.fire('Closed.!', 'Closed...', 'success');
            }
        });
    };
    const statuschange = (e, type) => {
        Setpassvisible(true);
        if (type == "active") {
            Setpassword({
                ...passwordata,
                user_id: e.user_id
            })
        }
        else {
            setActive({
                ...active,
                u_id: e.user_id,
                emp_name: e.emp_name
            })
        }
        setType(type)

    }

    useEffect(() => {

        let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: entityid })
        setEntity(LegalEntityData)
    }, [entityid, UserLegalDetails])


    useEffect(() => {
        if (userManagementList && userManagementList.length > 0 && selectedEntity == 'All Legal Entity') {
            let uniqueObjArray = [...new Map(userManagementList && userManagementList.length && userManagementList.map((item) => [item["le_id"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.le_id
            })

            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(userManagementList, { le_id: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            setFilteredArray(tempArr)

        }

    }, [userManagementList])

    return (
        <>
            {showList == false ? <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                {AddType == 'add' ?
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link>Master</Link>
                                        </li>

                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                            <Link>AdduserManagement</Link>
                                        </li>
                                    </ol> : <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <Link>Master</Link>
                                        </li>

                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <Link>User Management</Link>
                                        </li>
                                    </ol>}
                            </nav>
                        </div>
                        <div
                            className="
                col-lg-4 col-md-6
                d-none d-md-flex
                align-items-center
                justify-content-end">
                            <Button type="primary" shape="round" className='addbutton'
                                icon={<PlusOutlined />} size='default' onClick={() => {
                                    setShowList(true)
                                    setAddType('add')
                                }}>
                                Add
                            </Button>
                            {/* <Link onClick={() => {
                                setShowList(true)
                                setAddType('add')
                            }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4">Add</Link> */}
                        </div>
                    </div>
                    <Card style={{ marginTop: "20px", boxShadow: "0px 0px 0px 1px grey" }}>
                        {/* <ToastContainer /> */}
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-1'>
                                    <label><b>Country</b><span style={{ color: "red" }}>*</span></label>
                                </div>
                                <div className='col-md-2'>
                                    <Select

                                        allowClear={true}
                                        size="default"
                                        placeholder="Enter Entity"
                                        onChange={(data) => {


                                            setmain({
                                                ...main,
                                                country: "india"
                                            })
                                        }}
                                        // onSearch={onSearch}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '150%' }}
                                    >
                                        <Option>{finalResult && finalResult.Resultdata.length && finalResult.Resultdata[0].c_name}</Option>
                                    </Select>
                                    {entityFormvalidator.current.message(
                                        '',
                                        main.country,
                                        'required',
                                        {
                                            className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Required Field Missing',
                                            }
                                        })}
                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-1 ' >
                                    <label><b>Business Group</b></label>

                                </div>
                                <div className='col-md-2'>
                                    <Select

                                        allowClear={true}
                                        size="default"
                                        // placeholder="Enter Entity"
                                        // onChange={(data) => {
                                        //     setUnit({
                                        //         ...unit,
                                        //         legal_entity: data
                                        //     })
                                        // }}
                                        // onSearch={onSearch}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        // value={filter.seller}
                                        style={{ width: '150%' }}
                                    >
                                        {finalResult && finalResult.Resultdata.length && finalResult.Resultdata[0].child.map((item, i) => {
                                            return (
                                                <Option key={item.bg_id}>
                                                    {item.bg_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className='col-md-1'></div>
                                <div className='col-md-1'>
                                    <label style={{ float: "left" }}><b>Legal Entity</b><span style={{ color: "red" }}>*</span></label>
                                </div>
                                <div className='col-md-2'>
                                    <Select

                                        allowClear={true}
                                        size="default"
                                        // placeholder="Enter Entity"
                                        onChange={(data) => {
                                            setEntityValue(
                                                data
                                            )
                                            setmain({
                                                ...main,
                                                entity: data
                                            })
                                            // setUnit({
                                            //     ...unit,
                                            //     legal_entity: data
                                            // })
                                        }}
                                        // onSearch={onSearch}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        // value={filter.seller}
                                        style={{ width: '150%' }}
                                    >


                                        {finalResult && finalResult.Resultdata.length && finalResult.Resultdata[0].child.map((item, i) => {
                                            return (
                                                <Option key={item.le_id}>
                                                    {item.le_name}
                                                </Option>
                                            );
                                        })}
                                    </Select>

                                    {entityFormvalidator.current.message(
                                        'Entity',
                                        main.entity,
                                        'required',
                                        {
                                            className: `invalid-feedback ${EntitySubmit ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Required Field Missing',
                                            }
                                        })}
                                </div>
                                <div className='col-md-1'></div>
                            </div>
                            <div className='col-md-12'>
                                <div className='row' style={{ marginTop: "20px" }}>
                                    <div className='col-md-5'></div>
                                    <div className='col-md-2' >
                                        <Link style={{ textAlign: "center" }} className="btn btn-light-primary text-primary btn-lg px-4 fs-4" onClick={(e) => {
                                            e.preventDefault()
                                            setEntityFormSubmit(true)
                                            if (entityFormvalidator.current.allValid()) {
                                                if (EntityValue) {
                                                    if (userManagementList && userManagementList.length) {
                                                        let data = _.filter(userManagementList, { le_id: EntityValue })
                                                        setFilterArray(data)
                                                    }
                                                    let LegalEntityData = _.find(UserLegalDetails && UserLegalDetails, { le_id: EntityValue })
                                                    setEntity(LegalEntityData)
                                                }
                                            }
                                        }}>Show</Link>
                                    </div>
                                    <div className='col-md-5'></div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    {selectedEntity === 'All Legal Entity' && filterarray && filterarray.length == 0 ?
                        filteredArray && filteredArray.length && filteredArray.map((item, i) => {

                            let data = _.find(UserLegalDetails && UserLegalDetails.length && UserLegalDetails, { le_id: item && item.length && item[0] && item[0].le_id })
                            return (
                                <Card style={{ marginTop: "20px", boxShadow: "0px 0px 1px 1px grey" }}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-2'>
                                                <label ><b>Country: </b>{data && data.c_name}</label>
                                            </div>
                                            <div className='col-md-4' style={{ textAlign: "center" }}>
                                                <label ><b>Business Group: </b>{data && data.b_g_name}</label>
                                            </div>
                                            <div className='col-md-6' tyle={{ textAlign: "center" }}>
                                                <label><b>Legal Entity: </b>{data && data.le_name}</label>
                                            </div>
                                            {/* <hr></hr> */}
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label><span><b>Compfie Total Licence(s):</b></span> {data && data.total_licences} <span><b>Used:</b></span> {data && data.used_licences} | <span><b>Remaining:</b></span> {Number(data && data.total_licences) - Number(data && data.used_licences)}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ textAlign: "center" }}><span><b>Contract From:</b></span> {data && data.cont_from}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label style={{ textAlign: "center" }}><span><b>Contract To:</b></span> {data && data.cont_to}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label><span><b>Compfie Litigation Total Licence(s):</b></span> {data && data.l_total_licences} <span><b>Used:</b></span> {data && data.l_used_licences} | <span><b>Remaining:</b></span> {Number(data && data.l_total_licences) - Number(data && data.l_used_licences)}</label>
                                            </div>
                                            <div className='col-md-3'>


                                                <label ><span><b>Contract From:</b></span> {data && data.l_cont_from}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label ><span><b>Contract To:</b></span> {data && data.l_cont_to}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label><span><b>Compfie Vendor Total Licence(s):</b></span> {data && data.v_total_licences} <span><b>Used:</b></span> {data && data.v_used_licences} | <span><b>Remaining:</b></span> {Number(data && data.v_total_licences) - Number(data && data.v_used_licences)}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label ><span><b>Contract From:</b></span> {data && data.v_cont_from}</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <label><span><b>Contract To:</b></span> {data && data.v_cont_to}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-md-12' style={{ marginTop: "20px" }}>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <i style={{ color: "#45b0e2" }} class="bi bi-square-fill"></i> <label>Domain Admin</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <i style={{ color: "#e96154" }} class="bi bi-square-fill"></i> <label>Inhouse User</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <i style={{ color: "#fbca35" }} class="bi bi-square-fill"></i> <label>Legal Entity Admin</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <i style={{ color: "#60667a" }} class="bi bi-square-fill"></i> <label>Service Provider User</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-3'>
                                                <i style={{ color: "#9a9da0" }} class="bi bi-square-fill"></i> <label>View Only</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <i style={{ color: "#b29999" }} class="bi bi-square-fill"></i> <label>View Only Restricted</label>
                                            </div>
                                            <div className='col-md-3'>
                                                <i style={{ color: "#00FFFF" }} class="bi bi-square-fill"></i> <label>Litigation User</label>
                                            </div>
                                            <div className='col-md-3'>
                                                {/* <i style={{ color: "skyblue" }} class="bi bi-square-fill"></i> <label>Litigation User</label> */}
                                                <i style={{ color: "#ff8acc" }} class="bi bi-square-fill"></i> <label>Not Access</label>

                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        {/* <DataTable
                                            columns={columns}
                                            data={item}
                                            // defaultSortFieldId={1}
                                            selectableRows={false}
                                            pagination={false}
                                            fixedHeader={dataTableProperties.fixedHeader} 
                                        />*/}
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            dataSource={item}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            }}
                                        />

                                    </div>
                                </Card>
                            )
                        })
                        :
                        <Card style={{ marginTop: "20px", boxShadow: "0px 0px 1px 1px grey" }}>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-2' style={{ textAlign: "center" }}>
                                        <label ><b>Country: </b>{Entity && Entity.c_name}</label>
                                    </div>
                                    <div className='col-md-4' style={{ textAlign: "center" }} >
                                        <label ><b>Business Group:</b>  {Entity && Entity.b_g_name}</label>
                                    </div>
                                    <div className='col-md-6' style={{ textAlign: "center" }}>
                                        <label><b>Legal Entity:</b>{Entity && Entity.le_name}</label>
                                    </div>
                                    {/* <hr></hr> */}
                                </div>
                            </div>
                            <div className='col-md-12' style={{ marginTop: "20px" }}>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label><span><b>Compfie Total Licence(s):</b></span> {Entity && Entity.total_licences} <span><b>Used:</b></span> {Entity && Entity.used_licences} | <span><b>Remaining:</b></span> {Number(Entity && Entity.total_licences) - Number(Entity && Entity.used_licences)}</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <label style={{ textAlign: "center" }}><span><b>Contract From:</b></span> {Entity && Entity.cont_from}</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <label style={{ textAlign: "center" }}><span><b>Contract To:</b></span> {Entity && Entity.cont_to}</label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <label><span><b>Compfie Litigation Total Licence(s):</b></span> {Entity && Entity.l_total_licences} <span><b>Used:</b></span> {Entity && Entity.l_used_licences} | <span><b>Remaining:</b></span> {Number(Entity && Entity.l_total_licences) - Number(Entity && Entity.l_used_licences)}</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <label ><span><b>Contract From:</b></span> {Entity && Entity.l_cont_from}</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <label ><span><b>Contract To:</b></span> {Entity && Entity.l_cont_to}</label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                <div className='row'>
                                    <div className='col-md-6'>

                                        <label><span><b>Compfie Vendor Total Licence(s):</b></span> {Entity && Entity.v_total_licences} <span><b>Used:</b></span> {Entity && Entity.v_used_licences} | <span><b>Remaining:</b></span> {Number(Entity && Entity.v_total_licences) - Number(Entity && Entity.v_used_licences)}</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <label ><span><b>Contract From:</b></span> {Entity && Entity.v_cont_from}</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <label ><span><b>Contract To:</b></span> {Entity && Entity.v_cont_to}</label>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-12' style={{ marginTop: "30px" }}>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#45b0e2" }} class="bi bi-square-fill"></i> <label>Domain Admin</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#e96154" }} class="bi bi-square-fill"></i> <label>Inhouse User</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#fbca35" }} class="bi bi-square-fill"></i> <label>Legal Entity Admin</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#60667a" }} class="bi bi-square-fill"></i> <label>Service Provider User</label>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12' style={{ marginTop: "10px" }}>
                                <div className='row'>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#9a9da0" }} class="bi bi-square-fill"></i> <label>View Only</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#b29999" }} class="bi bi-square-fill"></i> <label>View Only Restricted</label>
                                    </div>
                                    <div className='col-md-3'>
                                        <i style={{ color: "#00FFFF" }} class="bi bi-square-fill"></i> <label>Litigation User</label>
                                    </div>
                                    <div className='col-md-3'>
                                        {/* <i style={{ color: "skyblue" }} class="bi bi-square-fill"></i> <label>Litigation User</label> */}
                                        <i style={{ color: "#ff8acc" }} class="bi bi-square-fill"></i> <label>Not Access</label>

                                    </div>

                                </div>
                            </div>
                            <div className='col-md-12'>
                                {/* <DataTable
                                    columns={columns}
                                    data={filterarray && filterarray.length ? SubfilteredItems : filteredItems}
                                    // defaultSortFieldId={1}
                                    selectableRows={false}
                                    pagination={false}
                                    subHeader
                                    subHeaderComponent={subHeaderComponent}
                                /> */}
                                <Table
                                    className='userprivclass'
                                    columns={columns}
                                    dataSource={userManagementList}
                                    bordered
                                    pagination={{
                                        defaultPageSize: dataTableProperties.pagesize,
                                        showSizeChanger: dataTableProperties.sizechanger
                                    }}
                                />
                            </div>
                        </Card>
                    }

                    <Modal title="Country" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <Search
                            placeholder="Search"
                        // onSearch={onSearch}
                        // style={{
                        //     width: 200,
                        // }}
                        />
                    </Modal>
                    <Modal title="Business Group" visible={business} onOk={handleOk} onCancel={handleCancel}>
                        <Search
                            placeholder="Search"
                        // onSearch={onSearch}
                        // style={{
                        //     width: 200,
                        // }}
                        />
                    </Modal>
                    <Modal title="Legal Entity" visible={legal} onOk={handleOk} onCancel={handleCancel}>
                        <Search
                            placeholder="Search"
                        // onSearch={onSearch}
                        // style={{
                        //     width: 200,
                        // }}
                        />
                    </Modal>

                    {/* <Modal visible={modalVisible} footer={null}
                        onCancel={passwordcancel}
                    >

                        <div className="row">
                            <div className="row pt-3">

                                <div className="col-md-6">
                                    <div className='mb-2'>
                                        <label className="control-label">Password:</label>
                                        <span style={{ "color": "red" }}>*</span>
                                        <input
                                            type="password"
                                            id="password"
                                            name='password'
                                            className="form-control"
                                            placeholder="Enter password"
                                            value={modalData.password}
                                            onChange={(e) => {
                                                setModalData({
                                                    ...modalData,
                                                    password: e.target.value
                                                })
                                            }}
                                        //   value={password.passwordvalue}
                                        />
                                        {formValidator.current.message(
                                            'password',
                                            modalData.password,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Password',
                                                }
                                            })}

                                    </div><br />
                                </div>


                                <div className="col-md-5">
                                    <div className="mb-2">
                                        <label className="control-label">Reason:</label>

                                        <span style={{ "color": "red" }}>*</span>
                                        <div className="col-md-2">
                                            <PlusSquareTwoTone onClick={() => { showRemarksModal() }} style={{ marginLeft: '650%' }} />
                                        </div>
                                        <textarea className='form-control'
                                            onChange={(e) => {
                                                setRemarks(e.target.value)
                                            }}
                                            value={remarks}

                                        ></textarea>

                                        {formValidator.current.message(
                                            'reason',
                                            remarks,
                                            'required',
                                            {
                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                messages: {
                                                    required: 'Please Enter Reason',
                                                }
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="form-actions">
                            <div className="card-body border-top">
                                <button
                                    className="btn btn-light-success rounded-pill px-4 text-success"
                                // onClick={submitpassword}
                                >
                                    <div className="d-flex align-items-center" onClick={OnSubmited}>
                                        <i
                                            data-feather="save"
                                            className="feather-sm me-1 fill-icon"

                                        ></i>
                                        Ok
                                    </div>
                                </button>
                                <button
                                    className="btn btn-light-danger text-danger font-weight-medium
               waves-effect text-start"
                                    onClick={passwordcancel}
                                >
                                    <div className="d-flex align-items-center">
                                        <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                        Cancel
                                    </div>
                                </button>
                            </div>
                        </div>

                    </Modal> */}

                    {/* <Modal visible={modalVisible} footer={null}
                onCancel={passwordcancel} className="newStyleModalPassword">
                <div className="modal-body" >
                            <div className="row">
                                <div className="col-md-6">
                                    <div class="mb-3">
                                    </div>
                                    <div className="mb-3">

                                        <input
                                            type="password"
                                            id="password"
                                            name='passwordvalue'
                                            className="form-control"
                                            placeholder="Enter password"
                                            onChange={(e) => {
                                                Setpassword({
                                                    ...passwordata,
                                                    passvalue: e.target.value

                                                })
                                            }}
                                            value={passwordata.passvalue}
                                        />
                                        {/* {formValidator.current.message(
                  'Password',
                  password.passwordvalue,
                  ['required'],
                  {
                    className: `invalid-feedback ${formValidator ? 'show' : 'hide'}`,
                    messages: {
                      regex: 'Enter Password'
                    }
                  })} */}
                    {/* </div>
                                </div><br />

                            </div>
                            <div className="form-actions">
                                <div className="card-body border-top">
                                    <button
                                        className="btn btn-light-success rounded-pill px-4 text-success"
                                        onClick={submitpassword}
                                    >
                                        <div className="d-flex align-items-center">
                                            <i
                                                data-feather="save"
                                                className="feather-sm me-1 fill-icon"
                                            ></i>
                                            Ok
                                        </div>
                                    </button>
                                    <button
                                        className="btn btn-light-danger text-danger font-weight-medium
               waves-effect text-start"
                                        onClick={passwordcancel}
                                    >
                                        <div className="d-flex align-items-center">
                                            <i data-feather="save" className="feather-sm me-1 fill-icon"></i>
                                            Cancel
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
            </Modal> */}


                    <Modal visible={modalVisible} footer={null}
                        onCancel={passwordcancel}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row pt-3">
                                    <div className="col-md-6">
                                        <div className='mb-2'>
                                            <label className="control-label">Password:</label>
                                            <span style={{ "color": "red" }}>*</span>
                                            <input
                                                type="password"
                                                id="password"
                                                name='passwordvalue'
                                                className="form-control"
                                                placeholder="Enter password"
                                                value={modalData.password}
                                                onChange={(e) => {
                                                    setModalData({
                                                        ...modalData,
                                                        password: e.target.value
                                                    })
                                                }}
                                            />
                                            {formValidator.current.message(
                                                'password',
                                                modalData.password,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Please Enter Password',
                                                    }
                                                })}

                                        </div>

                                    </div>
                                    <div className='col-md-5'>
                                        <div className="mb-2">
                                            <label className="control-label">Reason:</label>
                                            <span style={{ "color": "red" }}>*</span>
                                            <textarea className='form-control' name='remark'
                                                onChange={(e) => {
                                                    setRemarks(e.target.value)
                                                }}
                                                value={remarks}>
                                            </textarea>
                                            <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                                                onClick={() => { showRemarksModal() }}>
                                                <PlusOutlined />
                                            </span>
                                            {formValidator.current.message(
                                                'reason',
                                                remarks,
                                                'required',
                                                {
                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                    messages: {
                                                        required: 'Please Enter Reason',
                                                    }
                                                })}

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="form-actions">
                            <button
                                className="btn btn-light-success rounded-pill px-4 text-success"
                                onClick={OnSubmited}
                            >
                                Submit
                            </button>
                            <button
                                className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger
               waves-effect text-start"
                                onClick={passwordcancel}
                            > Cancel

                            </button>
                        </div>
                    </Modal>

                    <Modal visible={passvisible} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-6">
                                    <label className="control-label">Password:</label>
                                    <span style={{ "color": "red" }}>*</span>
                                    <input
                                        type="password"
                                        id="password"
                                        name='passwordvalue'
                                        className="form-control"
                                        placeholder="Enter password"
                                        onChange={(e) => {
                                            Setpassword({
                                                ...passwordata,
                                                passvalue: e.target.value

                                            })
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <br />
                        <div className="form-actions">
                            <button className="btn btn-light-success rounded-pill px-4 text-success" onClick={submitpassword}>
                                OK
                            </button>

                            <button className="btn btn-light-danger text-danger font-weight-medium waves-effect text-start" onClick={passwordcancel} >
                                Cancel
                            </button>
                        </div>
                    </Modal>
                    <Modal title="Remarks" visible={remarksModal} className="remarksClass"
                        onOk={handleOk}
                        onCancel={handleCancel}>
                        <Table
                            className='userprivclass'
                            columns={RemarksColumn}
                            dataSource={remarksvalue && remarksvalue.length > 0 && remarksvalue}
                            bordered
                            pagination={{
                                defaultPageSize: dataTableProperties.pagesize,
                                showSizeChanger: dataTableProperties.sizechanger
                            }}
                        />
                    </Modal>
                </div>
            </div > : <AddUserManagement setAddType={setAddType} showList={showList} setShowList={setShowList} AddType={AddType} />}

        </>
    )
}
export default connect(mapStateToProps, {
    getUsermanagementList,
    updatestatus,
    activeStatus,
    GetRemarksData,
    disableStatusChange,
    getUsermanagementDetails,
    getById,
    resendEmail
})(Usermanagement)